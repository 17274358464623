import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
import { _translatorTitle } from "../helpers";
import { IMAGE_URL } from "../constants";
import { get } from "js-cookie";

export class Committee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: this.props.match.params.id,
      selectedText: [],
      selStructure: false,
      selActivity: false,
      selBusiness_plan: false,
      selDocument: false,
      selContact: false,
    };
  }
  componentDidUpdate() {
    if (this.state.currentPath !== this.props.match.params.id) {
      this.updateState();
    }
  }

  getId = (arr) => {
    const comityData = this.props.CommitteesData.filter(
      (item) => item.id == this.props.match.params.id
    )[0];
    // console.log("arr: ", arr);
    if (arr === null) {
      return "white";
    } else {
      if (arr.lenth && arr.id === comityData.structure.id) {
        return "#00bbd1";
      } else {
        return "white";
      }
    }
  };
  updateState = () => {
    this.setState(
      {
        currentPath: this.props.match.params.id,
      },
      () => {
        this.props.setLoader("CommitteesData");
        this.props.fetchDetailsData(
          "CommitteesData",
          this.props.match.params.id
        );
      }
    );
  };

  componentDidMount() {
    this.props.fetchDetailsData("CommitteesData", this.props.match.params.id);
    window.scrollTo(0, 0);
  }
  render() {
    const { mlang, CommitteesData } = this.props;
    const {
      selectedText,
      selStructure,
      selActivity,
      selBusiness_plan,
      selDocument,
      selContact,
    } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log("COmity Data de: ", CommitteesData);
    const comityData = CommitteesData.filter(
      (item) => item.id == this.props.match.params.id
    )[0];
    // console.log("comity data: ", CommitteesData[0]);
    // console.log("comity data: ", comityData);
    // console.log("Activity array: ", comityData.activity);
    // console.log("Structure array: ", comityData.structure);
    this.getId(selectedText);
    // console.log("Selected text: ", selectedText);
    // console.log("selStructure text: ", selStructure);
    return (
      <div>
        <div className="full_page">
          <section className="auth">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="tadbir">
                    <div className="tadbir__calendar">
                      <div className="section_title mt-2 mb-5">
                        <h3 style={{ fontWeight: "bold" }}>
                          {_translatorTitle("", comityData, mlang)}
                        </h3>
                        <img
                          className="img-fluid mt-3"
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          src="assets/images/title_border.png"
                          alt=""
                        />
                      </div>
                      <div className="row mb-3 font-weight-bold">
                        {/* <div className="col-lg-3 col-md-6">
                          <div className="list-group">
                            <a
                              style={{ color: "white", cursor: "pointer" }}
                              className="list-group-item list-group-item-action active"
                            >
                              {_translatorTitle("", comityData, mlang)}
                            </a>
                          </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6">
                          <div className="list-group">
                            <a
                              style={{
                                cursor: "pointer",
                                // background: this.getId(selectedText),

                                backgroundColor: selStructure
                                  ? "#00bbd1"
                                  : "white",
                                borderColor: selStructure
                                  ? "#00bbd1"
                                  : "rgba(0, 0, 0, 0.125)",
                                color: selStructure ? "white" : "black",
                              }}
                              onClick={() =>
                                this.setState({
                                  selectedText: comityData.structure,
                                  selStructure: true,
                                  selActivity: false,
                                  selBusiness_plan: false,
                                  selDocument: false,
                                  selContact: false,
                                })
                              }
                              className="list-group-item list-group-item-action active"
                            >
                              {data.techCommityTeam}
                            </a>
                            <a
                              style={{
                                cursor: "pointer",
                                background: selActivity ? "#00bbd1" : "white",
                                borderColor: selActivity
                                  ? "#00bbd1"
                                  : "rgba(0, 0, 0, 0.125)",
                                color: selActivity ? "white" : "black",
                              }}
                              onClick={() =>
                                this.setState({
                                  selectedText: comityData.activity,
                                  selStructure: false,
                                  selActivity: true,
                                  selBusiness_plan: false,
                                  selDocument: false,
                                  selContact: false,
                                })
                              }
                              className="list-group-item list-group-item-action"
                            >
                              {data.techCommityActivity}
                            </a>
                            <a
                              style={{
                                cursor: "pointer",
                                background: selBusiness_plan
                                  ? "#00bbd1"
                                  : "white",
                                borderColor: selBusiness_plan
                                  ? "#00bbd1"
                                  : "rgba(0, 0, 0, 0.125)",
                                color: selBusiness_plan ? "white" : "black",
                              }}
                              onClick={() =>
                                this.setState({
                                  selectedText: comityData.business_plan,
                                  selStructure: false,
                                  selActivity: false,
                                  selBusiness_plan: true,
                                  selDocument: false,
                                  selContact: false,
                                })
                              }
                              className="list-group-item list-group-item-action"
                            >
                              {data.techCommityWorkPlan}
                            </a>
                            <a
                              style={{
                                cursor: "pointer",
                                background: selDocument ? "#00bbd1" : "white",
                                borderColor: selDocument
                                  ? "#00bbd1"
                                  : "rgba(0, 0, 0, 0.125)",
                                color: selDocument ? "white" : "black",
                              }}
                              onClick={() =>
                                this.setState({
                                  selectedText: comityData.document,
                                  selStructure: false,
                                  selActivity: false,
                                  selBusiness_plan: false,
                                  selDocument: true,
                                  selContact: false,
                                })
                              }
                              className="list-group-item list-group-item-action"
                            >
                              {data.techCommityDocs}
                            </a>
                            <a
                              style={{
                                cursor: "pointer",
                                background: selContact ? "#00bbd1" : "white",
                                borderColor: selContact
                                  ? "#00bbd1"
                                  : "rgba(0, 0, 0, 0.125)",
                                color: selContact ? "white" : "black",
                              }}
                              onClick={() =>
                                this.setState({
                                  selectedText: comityData.contact,
                                  selStructure: false,
                                  selActivity: false,
                                  selBusiness_plan: false,
                                  selDocument: false,
                                  selContact: true,
                                })
                              }
                              className="list-group-item list-group-item-action"
                            >
                              {data.techCommityContact}
                            </a>
                          </div>
                        </div>
                        <div
                          className="col-lg-8 col-md-12"
                          style={{ maxHeight: "246px", overflow: "auto" }}
                        >
                          <div className="drop__cont">
                            <div className="drop__texte">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    selectedText && mlang === "uz"
                                      ? selectedText.description
                                      : selectedText && mlang === "ru"
                                      ? selectedText.description_ru
                                      : selectedText && mlang === "en"
                                      ? selectedText.description_en
                                      : `${data.noInformation}`,
                                }}
                              ></p>
                            </div>
                            <a
                              style={{
                                display: selectedText ? "block" : "none",
                              }}
                              target="_blank"
                              href={
                                // selectedText
                                // ? `${IMAGE_URL}${selectedText.file}`
                                // : ""
                                selectedText && mlang === "uz"
                                  ? `${IMAGE_URL}${selectedText.file}`
                                  : selectedText && mlang === "ru"
                                  ? `${IMAGE_URL}${selectedText.file_ru}`
                                  : selectedText && mlang === "en"
                                  ? `${IMAGE_URL}${selectedText.file_en}`
                                  : `${data.noInformation}`
                              }
                              className="drop__pdf"
                            >
                              <img
                                style={{
                                  cursor: "pointer",
                                  display:
                                    selectedText &&
                                    selectedText.file &&
                                    mlang === "uz"
                                      ? "block"
                                      : selectedText &&
                                        selectedText.file_ru &&
                                        mlang === "ru"
                                      ? "block"
                                      : selectedText &&
                                        selectedText.file_en &&
                                        mlang === "en"
                                      ? "block"
                                      : "none",
                                }}
                                width="42px"
                                height="42px"
                                src="./assets/images/pdf.svg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-lg-5" style={{ display: "none" }}>
                      <div className="col-lg-12 d-flex justify-content-center">
                        <ul className="pagination">
                          <li className="page-item active">
                            <a href className="page-link">
                              1
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    CommitteesData: state.fetchDataReducer.CommitteesData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Committee);
