import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { _translator } from "../../helpers";
import { langData } from "../../index.i18n";
import * as Actions from "../../redux/actions";

class Council extends React.Component {
  componentDidMount() {
    this.props.setLoader("CouncilData");
    this.props.fetchDetailsData("CouncilData");
    window.scrollTo(0, 0);
  }
  render() {
    const { mlang, CouncilData, LoadingCouncilData } = this.props;
    // const data = mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    return LoadingCouncilData ? (
      <Loader />
    ) : (
      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
        {/* single-blog start */}
        <article className="blog-post-wrapper">
          <div className="blog-banner">
            <div className="blog-content">
              {/* <a href="#"> */}
                <h4>{_translator("title", CouncilData, mlang)}</h4>
              {/* </a> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: _translator("description", CouncilData, mlang),
                }}
                style={{ marginTop: 20, paddingRight: 10 }}
              />
            </div>
          </div>
        </article>
        <div className="clear" />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    CouncilData: state.fetchDataReducer.CouncilData,
    LoadingCouncilData: state.fetchDataReducer.LoadingCouncilData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Council);
