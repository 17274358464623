// import gerb from "/assets/images/gerb.png"
// import ombudsman from "/assets/images/logo.png"
// import lex from "/assets/images/logo2.png"
// import plata from "/assets/images/logo_small.png"

export const UsefulLinksData = [
  {
    id: 1,
    title: "O‘zbekiston Respublikasi Prezidentining rasmiy veb-sayti",
    title_en: "Official website of the President of Uzbekistan",
    title_ru: "Официальный веб-сайт Президента Республики Узбекистан",
    imgSrc: "/assets/images/gerb.png",
    links: "https://president.uz/ru",
  },
  {
    id: 2,
    title: "O‘zbekiston Respublikasi Oliy Majlisi Qonunchilik Palatasi",
    title_en:
      "Legislative Chamber of the Oliy Majlis of the Republic of Uzbekistan",

    title_ru: "Законодательная палата Олий Мажлиса Республики Узбекистан",

    imgSrc: "/assets/images/logo_small.png",
    links: "http://parliament.gov.uz/ru/",
  },
  {
    id: 3,
    title:
      "O'zbekiston Respublikasi Oliy Majlisining Inson huquqlari bo'yicha Vakili (Ombudsman) rasmiy sayti",
    title_en:
      "Official website Authorized Person of the Oliy Majlis of the Republic of Uzbekistan for Human Rights (Ombudsman)",

    title_ru:
      "Официальный сайт Уполномоченного Олий Мажлиса Республики Узбекистан по правам человека (Омбудсман)",

    imgSrc: "/assets/images/logo.png",
    links: "http://ombudsman.uz/ru/",
  },

  {
    id: 4,
    title:
      "O‘zbekiston Respublikasi Qonun hujjatlari ma’lumotlari milliy bazasi",
    title_en: "National database of legislation of the Republic of Uzbekistan",

    title_ru: "Национальная база данных законодательства Республики Узбекистан",

    imgSrc: "/assets/images/logo2.png",
    links: "https://lex.uz/",
  },
  {
    id: 5,
    title:
      "O‘zbekiston standartlashtirish, metrologiya va sertifikatlashtirish agentligi",
    title_en:
      "Standartization, metrology and certification agency of Uzbekistan",

    title_ru: "Узбекское агентство стандартизации, метрологии и сертификации",

    imgSrc: "/assets/images/gerb.png",
    links: "https://www.standart.uz/ru",
  },
]
