import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../constants";
import { _translator } from "../../helpers";

export function ManagementCard({
  genDirector,
  receptionDays,
  phoneNumber,
  email,
  data,
  mlang,
  biography,
  responsibilities,
}) {
  return (
    <div className="rah__box">
      <div className="rah__top">
        <img src={IMAGE_URL + data.avatar} alt="" />
        <ul>
          <li>
            <b>{_translator("position", data, mlang)}</b>
          </li>
          {/* <li>
            <b>{_translator("position", data, mlang)}</b>
          </li> */}
          <li>
            <b>{_translator("username", data, mlang)}</b>
          </li>
          <li>
            <Link to={`/management/${data.id}`} style={{ color: "blue" }}>
              {biography}
            </Link>
          </li>
          {/* <li>
            <Link to={`/management/duties/${data.id}`}>{responsibilities}</Link>
          </li> */}
          <li>
            {phoneNumber}: {data.phone}
          </li>
          <li>
            {receptionDays}
            {_translator("reception_time", data, mlang)}
          </li>
          <li>
            {email}: {data.email}
          </li>
        </ul>
      </div>
    </div>
  );
}
export function StaffCard({ phoneNumber, email, data, mlang }) {
  return (
    <div className="rah__box">
      <div className="rah__top">
        <img src={IMAGE_URL + data.avatar} alt="" />
        <ul>
          <li>
            <b>{data.page ? _translator("title", data.page, mlang) : ""}</b>
          </li>
          <li>
            <b>{_translator("position", data, mlang)}</b>
          </li>
          <li>
            <b>{_translator("username", data, mlang)}</b>
          </li>
          <li>
            {phoneNumber}: {data.phone}
          </li>
          <li>
            {email}: {data.email}
          </li>
        </ul>
      </div>
    </div>
  );
}
