import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { MostReadedNews } from "../components/Cards";
import { UsefulLinksCard } from "../components/Cards";
import { GiveOpinion, Complaints } from "../components/Cards";

function SideBar({ mlang, NewsListData }) {
  return (
    <>
      <MostReadedNews mlang={mlang} NewsListData={NewsListData} />
      <UsefulLinksCard mlang={mlang} />
      <GiveOpinion isSideMenu={true} mlang={mlang} />
      <Complaints isSideMenu={true} mlang={mlang} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    NewsListData: state.fetchDataReducer.NewsListData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
