import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
import { Link } from "react-router-dom";
import Ads from "./Ads";
import { _translator } from "../helpers";

const Footer = ({ mlang, AboutData }) => {
  const data =
    mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
  // console.log("About data: ", AboutData)
  // let test = AboutData.main_phone.replace(/\s/g, "");
  // console.log("About data: ", test);

  return (
    <>
      <Ads />
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-4 col-lg-4 text-center footer_col">
              <div className="footer_title">
                <h2>{data.contact}</h2>
                <div className="contact_border" />
              </div>
              <div className="contact_detail">
                <p>{_translator("address", AboutData, mlang)}</p>
                <p>
                  {data.email}:{" "}
                  <a href={`mailto:${AboutData.email}`}>{AboutData.email}</a>
                </p>
                <p>
                  Shartnomalar uchun:{" "}
                  <a href={`mailto:contract@standart.uz`}>
                    contract@standart.uz
                  </a>
                </p>
                <p>
                  {data.phoneNumber}:
                  <a href="tel:+998712538567"> {AboutData.main_phone}</a>
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-sm-4 col-lg-4 text-center footer_col">
              <div className="footer_detail">
                <h2>
                  <Link to="/">
                    <img
                      className="footer__logo"
                      style={{ width: 120, height: 50, resize: "cover" }}
                      // src="http://45.76.216.68/standart/public/assets/images/logost.png"
                      src={
                        mlang === "uz"
                          ? "/assets/images/footerLogo.png"
                          : mlang === "ru"
                          ? "/assets/images/logo-rus-small.png"
                          : "/assets/images/logo-eng-small.png"
                      }
                      alt=""
                    />
                  </Link>
                </h2>
                <p>{_translator("company_text", AboutData, mlang)}</p>
              </div>
            </div>
            <div className="col-sm-12 col-sm-4 col-lg-4 text-center footer_col">
              <div className="footer_title">
                <h2>{data.sendMessage}</h2>
                <div className="contact_border" />
              </div>
              <form className="form-subscribe" action="#">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control input-lg"
                    placeholder="Email"
                    style={{ color: "white" }}
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn btn-success btn-lg"
                      type="submit"
                      style={{
                        display: "block",
                        position: "relative",
                        zIndex: 2,
                      }}
                    >
                      <i className="fa fa-paper-plane" aria-hidden="true" />
                    </button>
                  </span>
                </div>
              </form>
              <ul id="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://telegram.org/" target="_blank" rel="noopener noreferrer">
                    <svg
                      enableBackground="new 0 0 24 24"
                      height={512}
                      viewBox="0 0 24 24"
                      width={512}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m9.417 15.181-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931l3.622-16.972.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693 12.643-7.911c.595-.394 1.136-.176.691.218z"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://myaccount.google.com/" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="bottom_border" />
          <p>
            © <span>{data.instituteStandard}</span> {data.allRightsReserved} |{" "}
            <Link to="/contacts">{data.termsOfUse}</Link>
          </p>
        </div>
      </footer>
    </>
  );
};
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    AboutData: state.fetchDataReducer.AboutData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
