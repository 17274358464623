import React from "react";
import { Link } from "react-router-dom";
import { NewsBlogCarousel } from "../components/Carousels";
import { MainTitle } from "../components/Titles";

export default function NewsList({ title, data, buttonTitle, mlang, allNews }) {
  return (
    <section className="news" id="blog">
      <div className="container">
        <MainTitle title={title} />
        <NewsBlogCarousel data={data} buttonTitle={buttonTitle} mlang={mlang} />
        <div
          // style={{ position: "relative", left: "40%" }}
          className="siner__buttons"
        >
          <p style={{ margin: "auto" }}>
            <Link style={{ color: "#fff" }} to="/news">
              {allNews}
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
}
