import React, { Component } from "react";
import Loader from "../components/Loader";
import axios from "axios";
import { FETCH_URL } from "../constants";

export default class EmailVerify extends Component {
  componentDidMount() {
    this.sentVerification();
  }

  sentVerification = () => {
    const paramId = this.props.match.params.id;
    axios
      .get(`${FETCH_URL}admin/verify/${paramId}`)
      .then((res) => {
        if (res.data.success) {
          // this.props.history.push("/login")
          // console.log("res: ", res)
        } else {
          alert(res.data.errors.err_msg);
        }
      })
      .catch((err) => alert(err));
  };
  render() {
    return <Loader />;
  }
}
