import React from "react";
import { UniteCarousel } from "../components/Carousels";
import { TitleShort } from "../components/Titles";
import UnitCard from "../components/UnitCard";

export default function UniteList({ title, data, mlang }) {
  // console.log("unit list data: ", data);
  return (
    <section className="services" id="services">
      <div className="services-content">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 d-none d-lg-block">
              <TitleShort title={title} />
              <div className="row">
                {data.map((item) => (
                  <UnitCard data={item} mlang={mlang} key={item.id} />
                ))}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 d-lg-none">
              <TitleShort title={title} />
              <div className="row">
                <UniteCarousel data={data} mlang={mlang} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
