import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../redux/actions";
import {langData} from "../index.i18n";
import {Link} from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import Cookies from "js-cookie";
import {toast, ToastContainer} from "react-toastify";
import {FETCH_URL} from "../constants";
import {Alert, Badge} from "antd";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            password: "",
            username: "",
        };
    }

    componentDidMount() {
        const {userToken} = this.props;
        // console.log(" profile token: ", userToken);

        if (!userToken) {
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            this.props.fetchUserData(userToken);
        }
    }

    changeText = (field, e) => {
        // console.log(field, e.target.value);
        this.setState({
            [field]: e.target.value,
        });
    };

    updateUserData = () => {
        const {userToken, mlang} = this.props;
        const dataLang =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        };
        const data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            phone: this.state.phoneNumber,
            password: this.state.password,
            username: this.state.username,
        };
        if (this.state.password.length < 7) {
            toast.error(dataLang.invalidPassword);
        } else {
            axios

                .post(`${FETCH_URL}profile`, data, config)
                .then((response) => {
                    // console.log("updateData: ", response);
                    if (response.data.success) {
                        toast.success(dataLang.changesAccepted);
                        // this.setState = { password: "" };
                    }
                })
                .catch((e) => console.log("error: ", e));
        }
    };

    emptyPassword = () => {
        this.setState = {password: ""};
    };

    render() {
        const {mlang, LoadingUserData, UserData, isLogged} = this.props;
        const {firstName, lastName, phoneNumber, password} = this.state;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        let fullName = "";
        if (UserData?.first_name && UserData?.last_name) {
            fullName = `${UserData?.first_name} ${UserData?.last_name}`;
        } else {
            fullName = UserData?.username;
        }
        // console.log("FUll name", fullName);
        return LoadingUserData && isLogged ? (
            <Loader/>
        ) : !LoadingUserData && !isLogged ? (
            (this.props.setLoader("UserData"),
                this.props.history.push("/login"),
                null)
        ) : (
            <div>
                <div className="full_page">
                    <section className="auth">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <div className="profile">
                                        <div className="pro__left">
                                            <div className="pro__avatar">
                                                <div
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                        // background: "red",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        style={{width: "60%", height: "60%"}}
                                                        src="/assets/images/user.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="pro__text">
                                                    {/* <span>{`${UserData.first_name} ${UserData.last_name}`}</span> */}
                                                    <span>{fullName}</span>
                                                    <a href={`mailto:${UserData.email}`}>
                                                        {UserData.email}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="pro__nav">
                                                <ul>
                                                    <li className="activee">
                                                        <Link to="/profile">
                                                            <img
                                                                src="assets/images/user-profile2.svg"
                                                                alt=""
                                                            />
                                                            {data.profileSettings}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/orders">
                                                            <img src="assets/images/orders.svg" alt=""/>
                                                            {data.myOrders}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/subscription">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.subscriptions}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/reesters">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.view_reestr}
                                                        </Link>
                                                    </li>
                                                    <Badge className="w-100" count="999" overflowCount={10}>
                                                        <li>
                                                            <Link to="/notifications">
                                                                <img src="assets/images/subscription.svg" alt=""/>
                                                                {data.notifications}
                                                            </Link>
                                                        </li>
                                                    </Badge>
                                                    {/* <li>
                            <Link to="/">
                              <img
                                src="assets/images/heart-shape-outline.svg"
                                alt=""
                              />
                              {data.favorites}
                            </Link>
                          </li> */}
                                                    <li
                                                        onClick={() => {
                                                            this.props.setToken("");
                                                            Cookies.remove("userToken");
                                                            Cookies.remove("accessToken");
                                                            Cookies.remove("userCode");
                                                        }}
                                                    >
                                                        <Link to="/">
                                                            <img src="assets/images/power.svg" alt=""/>
                                                            {data.signOutButton}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="pro__settings">
                                            <h2>{data.profileSettings}</h2>
                                            <div>
                                                <div className="row">
                                                    <div
                                                        className="pro__input"
                                                        style={{
                                                            marginRight: 10,
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <span>{data.firstName}*</span>
                                                        <input
                                                            required
                                                            value={firstName}
                                                            placeholder={UserData.first_name}
                                                            type="text"
                                                            onChange={(e) => this.changeText("firstName", e)}
                                                        />
                                                    </div>
                                                    <div
                                                        className="pro__input"
                                                        style={{marginRight: 10, marginLeft: 10}}
                                                    >
                                                        <span>{data.secondName}*</span>
                                                        <input
                                                            required
                                                            value={lastName}
                                                            placeholder={UserData.last_name}
                                                            type="text"
                                                            onChange={(e) => this.changeText("lastName", e)}
                                                        />
                                                    </div>
                                                    <div
                                                        className="pro__input"
                                                        style={{marginRight: 10, marginLeft: 10}}
                                                    >
                                                        <span>{data.email}*</span>
                                                        <input
                                                            value={UserData.email}
                                                            type="email"
                                                            disabled="disabled"
                                                        />
                                                    </div>
                                                    <div
                                                        className="pro__input"
                                                        style={{marginRight: 10, marginLeft: 10}}
                                                    >
                                                        <span>{data.phoneNumber}*</span>
                                                        <input
                                                            required
                                                            value={UserData.phone}
                                                            type="tel"
                                                            value={
                                                                phoneNumber
                                                                    ? phoneNumber
                                                                    : `+998${UserData.phone}`
                                                            }
                                                            disabled="disabled"
                                                            onChange={(e) =>
                                                                this.changeText("phoneNumber", e)
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className="pro__input"
                                                        style={{marginRight: 10, marginLeft: 10}}
                                                    >
                                                        <span>{data.newPassword}</span>
                                                        <input
                                                            required
                                                            type="password"
                                                            value={password}
                                                            placeholder={data.enterNewPassword}
                                                            onChange={(e) => this.changeText("password", e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="pro__button">
                            <span style={{marginRight: 10, marginLeft: 10}}>
                              {data.mustFilled}
                            </span>
                                                        <button
                                                            style={{marginRight: 10, marginLeft: 10}}
                                                            onClick={() => this.updateUserData()}
                                                        >
                                                            {data.update}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer/>
                    </section>
                </div>
                {/* /scroll_to_top start */}
                <Link to="/" className="scrollToTop">
                    <i className="fa fa-arrow-up" aria-hidden="true"/>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        userToken: state.fetchDataReducer.userToken,
        UserData: state.fetchDataReducer.UserData,
        isLogged: state.fetchDataReducer.isLogged,
        LoadingUserData: state.fetchDataReducer.LoadingUserData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
