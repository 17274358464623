import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Fonds() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div className="container" style={{ textAlign: "center" }}>
        <div
          className="my-lg-5 fonds-title"
          style={{
            // backgroundColor: "rgb(73, 197, 235)",
            borderRadius: "10px",
            textAlign: "center",
            display: "inline-block",
            padding: "0 15px",
          }}
        >
          <h2 className="text-center">
            STANDARTLASHTIRISH SOHASIDAGI DAVLAT FONDI
          </h2>
        </div>
        <div className="row my-lg-5 justify-content-center fonds-content">
          <div
            className="card position-relative hvr-grow  col-lg-2 col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-4"
            style={{
              display: "block",
              backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "30px",
              textAlign: "center",
              paddingTop: "40px",
              cursor: "pointer",
            }}
          >
            <Link to="/fond2">
              <img
                className="img"
                src="/assets/images/Picture2.png"
                style={{ width: "50%" }}
                alt="fond2"
              />
              <p
                className="text-center"
                style={{ fontSize: "22px", fontWeight: "bold" }}
              >
                ISO Standartlari
              </p>
            </Link>
          </div>

          <div
            className="card position-relative hvr-grow  col-lg-2 col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-4"
            style={{
              display: "block",
              backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "30px",
              textAlign: "center",

              paddingTop: "40px",
              cursor: "pointer",
            }}
          >
            <Link to="/fond2">
              <img
                className="img"
                src="/assets/images/Picture3.png"
                style={{ width: "50%" }}
                alt="fond2"
              />
              <p
                className="text-center"
                style={{ fontSize: "22px", fontWeight: "bold" }}
              >
                EN Standartlari
              </p>
            </Link>
          </div>
          <div
            className="card position-relative hvr-grow  col-lg-2 col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-4"
            style={{
              display: "block",
              backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "30px",
              textAlign: "center",

              paddingTop: "40px",
              cursor: "pointer",
            }}
          >
            <Link to="/fond2">
              <img
                className="img"
                src="/assets/images/Picture4.png"
                style={{ width: "50%" }}
                alt="fond2"
              />
              <p
                className="text-center"
                style={{ fontSize: "22px", fontWeight: "bold" }}
              >
                IEC Standartlari
              </p>
            </Link>
          </div>
          <div
            className="card position-relative hvr-grow  col-lg-2 col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-4"
            style={{
              display: "block",
              backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "30px",
              textAlign: "center",
              paddingTop: "40px",
              cursor: "pointer",
            }}
          >
            <Link to="/fond2">
              <img
                className="img"
                src="/assets/images/Picture5.png"
                style={{ width: "50%" }}
                alt="fond2"
              />
              <p
                className="text-center"
                style={{ fontSize: "22px", fontWeight: "bold" }}
              >
                GOST Standartlari
              </p>
            </Link>
          </div>
          <div
            className="card position-relative hvr-grow  col-lg-2 col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-4"
            style={{
              display: "block",
              backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "30px",
              textAlign: "center",

              paddingTop: "40px",
              cursor: "pointer",
            }}
          >
            <Link to="/fond2">
              <img
                className="img"
                src="/assets/images/Picture6.png"
                style={{ width: "50%" }}
                alt="fond2"
              />
              <p
                className="text-center"
                style={{ fontSize: "22px", fontWeight: "bold" }}
              >
                HALAL Standartlari
              </p>
            </Link>
          </div>
          <div
            className="card position-relative hvr-grow  col-lg-2 col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-4"
            style={{
              display: "block",
              backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "30px",
              textAlign: "center",

              paddingTop: "40px",
              cursor: "pointer",
            }}
          >
            <Link to="/fond2">
              <img
                className="img"
                src="/assets/images/Picture7.png"
                style={{ width: "50%" }}
                alt=""
              />
              <p
                className="text-center"
                style={{ fontSize: "22px", fontWeight: "bold" }}
              >
                Davlat Standartlari
              </p>
            </Link>
          </div>
          <div
            className="card position-relative hvr-grow  col-lg-2 col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-4"
            style={{
              display: "block",
              backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "30px",
              textAlign: "center",

              paddingTop: "40px",
              cursor: "pointer",
            }}
          >
            <img
              className="img"
              src="/assets/images/Picture8.png"
              style={{ width: "50%" }}
              alt=""
            />
            <p
              className="text-center"
              style={{ fontSize: "22px", fontWeight: "bold" }}
            >
              Xorijiy Davlatlar Standartlari
            </p>
          </div>
          <div
            className="card position-relative hvr-grow  col-lg-2 col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-4"
            style={{
              display: "block",
              backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "30px",
              textAlign: "center",

              paddingTop: "40px",
              cursor: "pointer",
            }}
          >
            <img
              className="img"
              src="/assets/images/Picture9.png"
              style={{ width: "50%" }}
              alt=""
            />
            <p
              className="text-center"
              style={{ fontSize: "22px", fontWeight: "bold" }}
            >
              Boshqa Xalqaro Standartlar
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
