import React, { Component } from "react"

export default class SearchBarMain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputText: "",
    }
  }
  submitSearch() {
    const { inputText } = this.state
    const { fetchSearchData } = this.props
    if (inputText.length) {
      fetchSearchData(inputText)
    }
  }
  render() {
    return (
      <div className="col-xl-12 col-lg-12">
        <div className="search-page">
          <div className="search-main">
            <input
              type="search"
              placeholder={this.props.placeholderTitle}
              // required
              value={this.state.inputText}
              onChange={(e) => this.setState({ inputText: e.target.value })}
            />
            <button onClick={() => this.submitSearch()}>
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
      </div>
    )
  }
}
