import React from "react";
import { Link } from "react-router-dom";

export function ButtonGreen({ title, path }) {
  return (
    <div className="col-md-12">
      <div className="more_projects">
        <Link to="./gallery" className="projects_more">
          {title}
        </Link>
      </div>
    </div>
  );
}
export function ButtonDark({ title }) {
  return (
    <div className="col-lg-3 col-md-12">
      <div className="single-new-project">
        <a
          // href="http://e-study.uzsti.uz/"
          href="https://e-study.uzsti.uz/"
          target="_blank"
          className="slide-btn"
        >
          {title}
        </a>
      </div>
    </div>
  );
}
