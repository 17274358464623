import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../redux/actions";
import {langData} from "../index.i18n";
import {Link} from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import Cookies from "js-cookie";
import {toast, ToastContainer} from "react-toastify";
import {FETCH_URL} from "../constants";
import OrderTable from "../components/Tables";

class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            password: "",
            username: "",
            open_contract: false,
            subscriptions: {},
            lastSubscribe: []
        };
    }

    componentDidMount() {
        const {userToken} = this.props;
        this.getLastSubscribes()
        // console.log(" profile token: ", userToken);

        if (!userToken) {
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            this.props.fetchUserData(userToken);
        }
    }

    getUserSubscriptions = () => {
        const {userToken} = this.props;
        axios

            .post(`${FETCH_URL}subscriptions`, null, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            })
            .then((response) => {
                if (response.data.success) {
                    let a = document.createElement('a');
                    a.href = `https://admin.uzsti.uz/storage/${response?.data?.data?.contract}`; 
                    a.target = "_blank"
                    a.download = response?.data?.data?.contract;
                    a.click();
                    a.remove();
                    this.setState({open_contract: false})
                }
            })
            .catch((e) => console.log("error: ", e));
    }

    getLastSubscribes = () => {
        const {userToken} = this.props;
        axios

            .get(`${FETCH_URL}subscriptions`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            })
            .then((response) => {
                if (response.data.success) {
                    this.setState({lastSubscribe: response?.data?.data})
                }
            })
            .catch((e) => console.log("error: ", e));
    }

    changeText = (field, e) => {
        // console.log(field, e.target.value);
        this.setState({
            [field]: e.target.value,
        });
    };

    updateUserData = () => {
        const {userToken, mlang} = this.props;
        const dataLang =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        };
        const data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            phone: this.state.phoneNumber,
            password: this.state.password,
            username: this.state.username,
        };
        if (this.state.password.length < 7) {
            toast.error(dataLang.invalidPassword);
        } else {
            axios

                .post(`${FETCH_URL}profile`, data, config)
                .then((response) => {
                    // console.log("updateData: ", response);
                    if (response.data.success) {
                        toast.success(dataLang.changesAccepted);
                        // this.setState = { password: "" };
                    }
                })
                .catch((e) => console.log("error: ", e));
        }
    };

    emptyPassword = () => {
        this.setState = {password: ""};
    };

    downloadContract = () => {


    }

    render() {
        const {mlang, LoadingUserData, UserData, isLogged} = this.props;
        const {firstName, lastName, phoneNumber, password} = this.state;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        let fullName = "";
        if (UserData.first_name && UserData.last_name) {
            fullName = `${UserData.first_name} ${UserData.last_name}`;
        } else {
            fullName = UserData.username;
        }
        // console.log("FUll name", fullName);
        return LoadingUserData && isLogged ? (
            <Loader/>
        ) : !LoadingUserData && !isLogged ? (
            (this.props.setLoader("UserData"),
                this.props.history.push("/login"),
                null)
        ) : (
            <div>
                <div className="full_page">
                    <section className="auth">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile">
                                        <div className="pro__left">
                                            <div className="pro__avatar">
                                                <div
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                        // background: "red",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        style={{width: "60%", height: "60%"}}
                                                        src="/assets/images/user.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="pro__text">
                                                    {/* <span>{`${UserData.first_name} ${UserData.last_name}`}</span> */}
                                                    <span>{fullName}</span>
                                                    <a href={`mailto:${UserData.email}`}>
                                                        {UserData.email}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="pro__nav">
                                                <ul>
                                                    <li>
                                                        <Link to="/profile">
                                                            <img
                                                                src="assets/images/user-profile2.svg"
                                                                alt=""
                                                            />
                                                            {data.profileSettings}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/orders">
                                                            <img src="assets/images/orders.svg" alt=""/>
                                                            {data.myOrders}
                                                        </Link>
                                                    </li>
                                                    <li className="activee">
                                                        <Link to="/subscription">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.subscriptions}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/reesters">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.view_reestr}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/notifications">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.notifications}
                                                        </Link>
                                                    </li>
                                                    {/* <li>
                            <Link to="/">
                              <img
                                src="assets/images/heart-shape-outline.svg"
                                alt=""
                              />
                              {data.favorites}
                            </Link>
                          </li> */}
                                                    <li
                                                        onClick={() => {
                                                            this.props.setToken("");
                                                            Cookies.remove("userToken");
                                                            Cookies.remove("accessToken");
                                                            Cookies.remove("userCode");
                                                        }}
                                                    >
                                                        <Link to="/">
                                                            <img src="assets/images/power.svg" alt=""/>
                                                            {data.signOutButton}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="pro__settings">
                                            <h2>{data.subscriptions}</h2>
                                            <div>
                                                <div className="row" style={{
                                                    maxHeight: 500,
                                                    overflowY: "auto",
                                                    overflowX: "visible"
                                                }}>
                                                    <table className="chiter-table table table-sm table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th>№</th>
                                                            <th>{data.from_when}</th>
                                                            <th>{data.to_when}</th>
                                                            <th style={{width: 160}}>{data.status}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state?.lastSubscribe?.data?.map((item, index) => <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.start_date ? new Date(item?.start_date).toLocaleDateString() : ""}</td>
                                                            <td>{item?.end_date ? new Date(item?.end_date).toLocaleDateString() : ""}</td>
                                                            <td>
                                                                {item?.status === 2 ? <span
                                                                    className="rounded bg-success mb-0 text-light py-2 text-center"
                                                                    style={{width: 150}}>
                                                                    {data.orderPaid}
                                                                </span> : item?.status === 3 ? "" : <span
                                                                    className="rounded bg-danger mb-0 text-light py-2 text-center"
                                                                    style={{width: 150}}>
                                                                    {data.orderNotPaid}
                                                                </span>}
                                                            </td>
                                                        </tr>)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {this.state?.lastSubscribe?.data?.filter(item => item?.status === 2)?.length > 0 ? "" :
                                                    <div className="row">
                                                        {!this.state?.open_contract ? <div className="pro__button">
                                                                <button
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginRight: 10,
                                                                        marginLeft: 10
                                                                    }}
                                                                    onClick={() => this.setState({open_contract: true})}
                                                                >
                                                                    {data.subscribe}
                                                                </button>
                                                            </div> :
                                                            <div
                                                                className="d-flex justify-content-start align-items-center">
                                                                {data?.generate_contract_for_subscrbe}
                                                                <span onClick={() => this.getUserSubscriptions()}
                                                                      className="mx-1 mb-0" style={{cursor: "pointer"}}>
                                                    <img width={30} src="/assets/images/dw.png" alt=""/>
                                                    </span>
                                                            </div>}
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer/>
                    </section>
                </div>
                {/* /scroll_to_top start */}
                <Link to="/" className="scrollToTop">
                    <i className="fa fa-arrow-up" aria-hidden="true"/>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        userToken: state.fetchDataReducer.userToken,
        UserData: state.fetchDataReducer.UserData,
        isLogged: state.fetchDataReducer.isLogged,
        LoadingUserData: state.fetchDataReducer.LoadingUserData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
