import React from "react"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Loader from "../components/Loader"
import { IMAGE_URL } from "../constants"
import * as Actions from "../redux/actions"
class Gallery extends React.Component {
  componentDidMount() {
    this.props.fetchGalleryPageData()
    window.scrollTo(0, 0)
  }
  componentDidUpdate() {
    window.scrollTo(0, 0)
  }

  render() {
    const { GalleryPageData, LoadingGalleryPageData } = this.props

    return LoadingGalleryPageData ? (
      <Loader />
    ) : (
      <div>
        <div className="full_page">
          <div className="projects" id="projects">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row gallery-height">
                    {GalleryPageData.map((item) => (
                      <div
                        key={item.id.toString()}
                        className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 projects_box p-0"
                      >
                        <figure
                          className="imghvr-blur"
                          style={{
                            height: "97%",
                            width: "97%",
                            alignSelf: "center",
                            // borderRadius: 10,

                            // paddingBottom: 10,
                            background: "#f7f7f7",
                          }}
                        >
                          <img
                            data-fancybox="gallery"
                            style={{ height: "270px", objectFit: "cover" }}
                            src={`${IMAGE_URL}${item.image}`}
                            href={`${IMAGE_URL}${item.image}`}
                            alt={44}
                          />
                          {/* <figcaption>
                                 <div class="images_link"> <i class="fa fa-link" aria-hidden="true"></i> </div>
                                 <h3>Aquisition Plan</h3>
                                 <p>Business Planning</p>
                              </figcaption> */}
                          <a href="javascript:;" />
                        </figure>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /scroll_to_top start */}
        <a href="#" className="scrollToTop">
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </a>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    GalleryPageData: state.fetchDataReducer.GalleryPageData,
    LoadingGalleryPageData: state.fetchDataReducer.LoadingGalleryPageData,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
