import React, { Component } from "react";
import Clock from "react-live-clock";
import Moment from "moment";
import Cookies from "js-cookie";
import uz from "moment/locale/uz";
import ru from "moment/locale/ru";
import en from "moment/locale/en-gb";
import { _kirilToLatin } from "../helpers";

export default class CurrentTime extends Component {
  render() {
    const { mlang } = this.props;
    const time = new Date();
    return (
      <div
        style={{
          fontSize: 14,
          // fontWeight: "900",
          //   color: "#00bbd1",
          color: "white",
          // background: "white",
          borderRadius: 10,
          boxShadow: "3px 3px 12px 0px rgba(50, 50, 50, 0.75)",
          fontFamily: ["SegoeUi", "Arial", "Helvetica", "sans-serif"],
        }}
      >
        <span style={{ marginRight: 10, textTransform: "capitalize" }}>
          {Moment(time).format("YYYY")}{" "}
          <span style={{ marginRight: 10 }}>
            {mlang === "uz" ? "Yil" : mlang === "ru" ? "Год" : "Year"} |
          </span>
          {Moment(time).format("D")}{" "}
          {mlang === "uz"
            ? _kirilToLatin(Moment(time).locale(mlang).format("MMMM"))
            : Moment(time).locale(mlang).format("MMMM")}{" "}
          |{" "}
          {/* {Moment(time).locale(mlang).format("MMMM Do YYYY, h:mm:ss a")} */}
        </span>
        <span style={{ fontSize: 18 }}>
          <Clock
            format={"HH:mm:ss"}
            ticking={true}
            timezone={"Asia/Tashkent"}
          />
        </span>
      </div>
    );
  }
}
