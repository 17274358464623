import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import { ClientOpinionCarousel } from "../components/Carousels";
export default function ClientOpinionList({ mlang }) {
  return (
    <section className="clients">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="section_title1">
              <h2>
                {mlang === "uz"
                  ? "Qayta tayyorlash va malaka oshirish markazi"
                  : mlang === "ru"
                  ? " Центр переподготовки и повышения квалификации"
                  : " Center for retraining and advanced training"}
              </h2>
              <img
                className="img-fluid"
                src="assets/images/title_border2.png"
                alt=""
              />
            </div>
          </div>
          <ClientOpinionCarousel mlang={mlang} />
        </div>
      </div>
    </section>
  );
}
