export const langData = {
  uz: {
    responsibilities: "Majburiyatlari",
    biography: "Biografiyasi",
    phoneNumber: "Telefon raqam",
    receptionDays: "Qabul kunlari: ",
    email: "Elektron pochta",
  },
  ru: {
    responsibilities: "Обязанности",
    biography: "Биография",
    phoneNumber: "Номер телефона",
    receptionDays: "Приемные дни: ",
    email: "Электронная почта",
  },
  en: {
    responsibilities: "Responsibilities",
    biography: "Biography",
    phoneNumber: "Phone number",
    receptionDays: "Reception days: ",
    email: "Email",
  },
};
