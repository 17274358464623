import React, { Component } from "react";
import { IMAGE_URL } from "../constants";
import SideBar from "../containers/SideBar";
import { _translator, _translatorTitle } from "../helpers";

export class BudgetCard extends Component {
  render() {
    const { data, mlang } = this.props;
    return (
      <div>
        <div className="full_page">
          <div className="blog_pages">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                  {/* single-blog start */}
                  <article className="blog-post-wrapper">
                    <div className="blog-banner">
                      <div className="blog-content">
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          target="_blank"
                          href={
                            mlang === "uz" && data.file_uz
                              ? IMAGE_URL + data.file_uz
                              : mlang === "ru" && data.file_ru
                              ? IMAGE_URL + data.file_ru
                              : mlang === "en" && data.file_en
                              ? IMAGE_URL + data.file_en
                              : ""
                          }
                        >
                          <h4
                            style={{
                              fontWeight: "bold",
                              display: "inline-block",
                              textTransform: "uppercase",
                            }}
                          >
                            {_translatorTitle("", data, mlang)}
                            {/* {data.title_uz} */}
                          </h4>
                        </a>
                        {/* <i
                                  style={{
                                    display:
                                      (mlang === "uz" && data.file) ||
                                      (mlang === "ru" && data.file_ru) ||
                                      (mlang === "en" && data.file_en)
                                        ? "block"
                                        : "none",
                                    textAlign: "center",
                                    fontSize: 40,
                                    color: "#302b68",
                                    verticalAlign: "middle",
                                  }}
                                  class="fas fa-file-pdf"
                                ></i>
                              </a> */}

                        <p
                          className="description-style"
                          dangerouslySetInnerHTML={{
                            __html:
                              mlang === "uz"
                                ? data.short_desc_uz
                                : mlang === "ru"
                                ? data.short_desc_ru
                                : data.short_desc_en,
                          }}
                          style={{ marginTop: 20, paddingRight: 10 }}
                        />
                        <p
                          className="description-style"
                          dangerouslySetInnerHTML={{
                            __html:
                              mlang === "uz"
                                ? data.desc_uz
                                : mlang === "ru"
                                ? data.desc_ru
                                : data.desc_en,
                          }}
                          style={{ marginTop: 20, paddingRight: 10 }}
                        />
                      </div>
                      {/* <img
                              className="img-fluid"
                              src={mlang === "uz"
                                ? { data.img }
                                : { data }}
                            /> */}
                      <img
                        style={{
                          display:
                            data.img_uz || data.img_ru || data.img_en
                              ? "block"
                              : "none",
                        }}
                        className="img-fluid"
                        src={
                          mlang === "uz"
                            ? IMAGE_URL + data.img_uz
                            : mlang === "ru"
                            ? IMAGE_URL + data.img_ru
                            : IMAGE_URL + data.img_en
                        }
                      />
                    </div>
                  </article>
                  <div className="clear" />
                  {/* single-blog end */}
                </div>
                {/* <div className="col-lg-4 col-md-12">
                  <SideBar />
                </div> */}
              </div>
            </div>
          </div>
          {/* /blog_con end*/}
        </div>
        {/* /scroll_to_top start*/}
        {/* <a href="#" className="scrollToTop">
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </a> */}
      </div>
    );
  }
}

export default BudgetCard;
