import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../redux/actions";
import {langData} from "../index.i18n";
import {Link, withRouter} from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {FETCH_URL} from "../constants";
import {Button, Card, Empty, Image, Input, Modal, Pagination} from "antd";
import {Form, FormLabel} from "react-bootstrap";

class RegistryProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            password: "",
            username: "",
            open_contract: false,
            subscriptions: {},
            lastSubscribe: {},
            modal: {
                open: false,
                items: []
            }
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const {userToken} = this.props;
        this.getLastSubscribes(id)
        // console.log(" profile token: ", userToken);

        if (!userToken) {
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            this.props.fetchUserData(userToken);
        }
    }


    getLastSubscribes = (id) => {
        const {userToken} = this.props;
        axios
            .get(`${FETCH_URL}registry/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            })
            .then((response) => {
                if (response.data.success) {
                    this.setState({lastSubscribe: response?.data?.data})
                }
            })
            .catch((e) => console.log("error: ", e));
    }

    openImageGallery() {
        const {userToken} = this.props;
        const id = this.props.match.params.id;
        axios
            .get(`${FETCH_URL}preview/${this.state?.lastSubscribe?.standart?.id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        modal: {
                            open: true,
                            items: response?.data?.images
                        }
                    })
                }
            })
            .catch((e) => console.log("error: ", e));
    }

    render() {
        const {mlang, LoadingUserData, isLogged} = this.props;
        const {modal} = this.state;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        return LoadingUserData && isLogged ? (
            <Loader/>
        ) : !LoadingUserData && !isLogged ? (
            (this.props.setLoader("UserData"),
                this.props.history.push("/login"),
                null)
        ) : (
            <div>
                <Modal width={1300} title={modal?.item?.name} footer={false} open={modal?.open}
                       onCancel={() => this.setState({modal: {open: false, item: null}})}>
                    <div className="mt-5">
                        <Image.PreviewGroup
                            preview={{
                                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                            }}
                        >
                            {this.state?.modal?.items?.map(ph=><Image height={250} width={250} src={`https://Admin.uzsti.uz/storage/previews/${ph?.path}`}/>)}
                        </Image.PreviewGroup>
                    </div>
                </Modal>
                <div className="full_page">
                    <section className="auth">
                        <div className="container-fluid px-5">
                            <div className="row px-3">
                                <div className="col-md-12">
                                    <div className="profile">
                                        {/* <div className="pro__left">
                                            <div className="pro__avatar">
                                                <div
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                        // background: "red",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        style={{width: "60%", height: "60%"}}
                                                        src="/assets/images/user.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="pro__text">
                                                    <span>{fullName}</span>
                                                    <a href={`mailto:${UserData.email}`}>
                                                        {UserData.email}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="pro__nav">
                                                <ul>
                                                    <li>
                                                        <Link to="/profile">
                                                            <img
                                                                src="assets/images/user-profile2.svg"
                                                                alt=""
                                                            />
                                                            {data.profileSettings}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/orders">
                                                            <img src="assets/images/orders.svg" alt=""/>
                                                            {data.myOrders}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/subscription">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.subscriptions}
                                                        </Link>
                                                    </li>
                                                    <li className="activee">
                                                        <Link to="/reesters">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.view_reestr}
                                                        </Link>
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            this.props.setToken("");
                                                            Cookies.remove("userToken");
                                                            Cookies.remove("accessToken");
                                                            Cookies.remove("userCode");
                                                        }}
                                                    >
                                                        <Link to="/">
                                                            <img src="assets/images/power.svg" alt=""/>
                                                            {data.signOutButton}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        <div className="pro__settings w-100">
                                            {/*<div className="d-flex align-items-center">*/}
                                            {/*    <h2 className="ml-3">{data.subscriptions}</h2>*/}
                                            {/*</div>*/}
                                            <div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Card title={
                                                            <h6 className="my-2 d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <svg className="mr-1"
                                                                         onClick={() => this.props.history.goBack()}
                                                                         style={{cursor: "pointer"}} width={25}
                                                                         height={25}
                                                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                         viewBox="0 0 24 24"
                                                                         strokeWidth="1.5" stroke="currentColor">
                                                                        <path strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
                                                                    </svg>
                                                                    {this.state?.lastSubscribe?.name}
                                                                </div>
                                                                <button onClick={() => this.openImageGallery()} className="btn btn-info">Faylni oldindan ko'rish
                                                                </button>
                                                            </h6>
                                                        } className="shadow">
                                                            <div
                                                                className="d-flex mb-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    Name
                                                                </span>
                                                                <span className="mb-0">
                                                                    {this.state?.lastSubscribe?.name}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex mb-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    ГОСТ belgisi
                                                                </span>
                                                                <span className="mb-0">
                                                                    {this.state?.lastSubscribe?.code}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex my-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    OKS
                                                                </span>
                                                                <span className="mb-0">
                                                                    {this.state?.lastSubscribe?.oks}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex my-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    Standart name
                                                                </span>
                                                                <span className="mb-0">
                                                                    {this.state?.lastSubscribe?.standart?.name_standart}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex my-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    Created at
                                                                </span>
                                                                <span className="mb-0">
                                                                    {new Date(this.state?.lastSubscribe?.created_at).toLocaleDateString()}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex my-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    Introduced at
                                                                </span>
                                                                <span className="mb-0">
                                                                    {this.state?.lastSubscribe?.introduced_at}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex mt-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    Rule accepts
                                                                </span>
                                                                <span className="mb-0">
                                                                    {this.state?.lastSubscribe?.rule_accepts}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex mt-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    Rule cancels
                                                                </span>
                                                                <span className="mb-0">
                                                                    {this.state?.lastSubscribe?.rule_cancels}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex mt-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    Status name
                                                                </span>
                                                                <span className="mb-0">
                                                                    <span style={{cursor: "pointer"}}
                                                                          className={`rounded ${this.state?.lastSubscribe?.status === 2 ? "bg-success" : "bg-warning"} mb-0 text-light p-2 text-center`}>
                                                                    {this.state?.lastSubscribe?.status_name}
                                                                </span>
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="d-flex mt-2 justify-content-between align-items-center bg-light text-secondary rounded p-3 shadow-sm">
                                                                <span className="mb-0">
                                                                    Comment
                                                                </span>
                                                                <span className="mb-0">
                                                                    {this.state?.lastSubscribe?.comment}
                                                                </span>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer/>
                    </section>
                </div>
                {/* /scroll_to_top start */}
                <Link to="/" className="scrollToTop">
                    <i className="fa fa-arrow-up" aria-hidden="true"/>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        userToken: state.fetchDataReducer.userToken,
        UserData: state.fetchDataReducer.UserData,
        isLogged: state.fetchDataReducer.isLogged,
        LoadingUserData: state.fetchDataReducer.LoadingUserData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistryProfile));
