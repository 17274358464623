import axios from "axios";
import Cookies from "js-cookie";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {bindActionCreators} from "redux";
import {FETCH_URL} from "../constants";
import {langData} from "../index.i18n";
import * as Actions from "../redux/actions";
import InputMask from "react-input-mask";

class Restore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            userPhone: "",
            captcha: "",
            key: "",
            imageData: ""
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getCaptcha()
    }

    getCaptcha = () => {
        axios.get("https://admin.uzsti.uz/captcha/api/math").then((res) => {
            this.setState({imgData: res?.data?.img ? res?.data?.img?.replaceAll("\\", "") : "", key: res?.data?.key})
        })
    }

    updateText = (path, e) => {
        this.setState({
            [path]: e.target.value,
        });
    };

    restorePassword = () => {
        const {mlang} = this.props;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        const {userPhone, captcha,key} = this.state;
        if (userPhone.length !== 12) {
            toast.error(data.invalidPhone);
        } else if (userPhone) {
            axios
                .post(`${FETCH_URL}reset-psw?phone=${this.state.userPhone.slice(3)}&captcha=${this.state.captcha}&key=${this.state.key}`)
                // .then((response) => console.log("Thoughts response: ", response))
                .then((response) => {
                    if (response.data.success === true) {
                        this.setState(
                            {
                                userPhone: "",
                                showModal: true,
                            },
                            () => {
                                toast.success(data.passwordRestoredText);
                            }
                        );
                    } else {
                        toast.error(data.userNotFound);
                    }
                })
                .catch((err) => console.warn(err));
        } else {
            toast.error(data.allFieldMustBeFilled);
        }
    };

    render() {
        const {mlang} = this.props;
        const {showModal} = this.state;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        // console.log("params: ", this.props.match.params.id);
        // console.log("phone input", this.state.userPhone);
        return (
            <div>
                <div className="full_page">
                    <section className="auth">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="reg">
                                        <div
                                            className="reg__box"
                                            style={{
                                                display: showModal ? "none" : "block",
                                            }}
                                        >
                                            <h2>{data.restorePassword}</h2>
                                            <span>{data.enterPhoneNumber}</span>
                                            <form>
                                                <input
                                                    className="inputt"
                                                    type="number"
                                                    placeholder={data.phoneNumberEnter}
                                                    onChange={(e) => this.updateText("userPhone", e)}
                                                    value={this.state.userPhone}
                                                />
                                                {/* <InputMask
                          alwaysShowMask={true}
                          // maskChar={null}
                          mask="+\9\98999999999"
                          className="inputt"
                          // type="phone"
                          // placeholder={data.phoneNumberEnter}
                          onChange={(e) => this.updateText("userPhone", e)}
                          value={this.state.userPhone}
                        /> */}
                                                {/* <div className="auth__pas">
                          <input
                            className="inputt"
                            type="password"
                            placeholder={data.passwordTitle}
                            onChange={(e) =>
                              this.updateText("passwordInput", e)
                            }
                            value={this.state.passwordInput}
                          />
                          <a href="#">{data.forgotPassword}</a>
                        </div> */}
                                                {/* <div className="reg__check">
                          <Link to="/register">
                            <h3 style={{ textAlign: "center" }}>
                              {data.register}
                            </h3>
                          </Link>
                        </div> */}
                                                <div className="d-flex justify-content-between">
                                                    <img style={{cursor: "pointer"}} onClick={this.getCaptcha}
                                                         height={49}
                                                         src={this.state?.imgData}
                                                    />
                                                    <input onChange={(e) => {
                                                        this.updateText("captcha", e)
                                                    }} placeholder="Natija" className="inputt"
                                                           type="text"/>
                                                </div>
                                                <button
                                                    type="button"
                                                    value="submit"
                                                    onClick={() => this.restorePassword()}
                                                >
                                                    {data.smsConfirm}
                                                </button>
                                            </form>
                                        </div>
                                        <div
                                            className="reg__box"
                                            style={{
                                                display: showModal ? "block" : "none",
                                            }}
                                        >
                                            <h2>{data.passwordRestored}</h2>
                                            <span>{data.restoreText}</span>
                                            <button type="button">
                                                <Link style={{color: "white"}} to="/login">
                                                    {data.enterRes}
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer/>
                    </section>
                    {/* /call_to_action end */}
                </div>
                {/* /scroll_to_top start */}
                {/* <a href="#" className="scrollToTop">
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </a> */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Restore);
