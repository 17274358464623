export const langData = {
  uz: {
    feedbackTitle: "Murojaat va takliflar",
    citizensFeedback: "FUQAROLARNING MUROJAATLARI",
    more: "Batafsil...",
    questioningText:
      "Standartlashtirish, sertifikatlashtirish va texnik jihatdan tartibga solish ilmiy-tadqiqot instituti haqida fikringizni bildiring!",
    excellent: "Zo'r",
    good: "Yaxshi",
    bad: "Yomon",
    mayBeImproved: "Yana ham yaxshilasa bo'ladi",
    vote: "Ovoz berish",
    anyComplaints: "Shikoyatlar mavjudmi?",
    sendOnline: "Onlayn tarzda  yuboring!",
    mostRead: "Eng ko'p o'qilganlar",
    usefulLinks: "Foydali havolalar",
  },
  ru: {
    feedbackTitle: "Обращения и предложения",
    citizensFeedback: "ОБРАЩЕНИЯ  ГРАЖДАН",
    more: "Подробно...",
    questioningText:
      "Оставьте свое мнения о Научно-исследовательском институте стандартизации, сертификации и технического регулирования",
    excellent: "Отлично",
    good: "Хорошо",
    bad: "Удовлетворительно",
    mayBeImproved: "Можно улучшить",
    vote: "Голосовать",
    anyComplaints: "Есть какие-либо жалобы?",
    sendOnline: "Онлайн!",
    mostRead: "Популярное",
    usefulLinks: "Полезные ссылки",
  },
  en: {
    feedbackTitle: "Appeals and suggestions",
    citizensFeedback: "CITIZENS  APPEALS",
    more: "More...",
    questioningText:
      "Leave your opinion on Scientific-research institute for standardization, certification and technical regulation",
    excellent: "Excellent",
    good: "Good",
    bad: "Bad",
    mayBeImproved: "Will be even more improved",
    vote: "Vote",
    anyComplaints: "Any complaints?",
    sendOnline: "Send online!",
    mostRead: "Most read",
    usefulLinks: "Foydali havolalar",
  },
};
