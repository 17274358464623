import React from "react";
import { _translator } from "../helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";

function Achievments({ mlang, AboutData }) {
  // console.log("About data: ", AcheivementData[0].title);
  return (
    <div className="achievements" id="counter">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-6 border_col ">
            <div className="achievements_box counter-box-0">
              <div className="counter_box">
                <div className="counter-value" data-count={2556}>
                  {AboutData.counter_1}
                </div>
                <p>{_translator("title", AcheivementData[0], mlang)}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6 border_col ">
            <div className="achievements_box counter-box-0">
              <div className="counter_box">
                <div className="counter-value" data-count={2556}>
                  {AboutData.counter_2}
                </div>
                <p>{_translator("title", AcheivementData[1], mlang)}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6 border_col ">
            <div className="achievements_box counter-box-0">
              <div className="counter_box">
                <div className="counter-value" data-count={2556}>
                  {AboutData.counter_3}
                </div>
                <p>{_translator("title", AcheivementData[2], mlang)}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6 border_col ">
            <div className="achievements_box counter-box-0">
              <div className="counter_box">
                <div className="counter-value" data-count={2556}>
                  {AboutData.counter_4}
                </div>
                <p>{_translator("title", AcheivementData[3], mlang)}</p>
              </div>
            </div>
          </div>

          {/* {AcheivementData.map((item) => (
            <div className="col-lg-3 col-md-3 col-6 border_col ">
              <div className="achievements_box counter-box-0">
                <div className="counter_box">
                  <div className="counter-value" data-count={2556}>
                    {item.amount}
                  </div>
                  <p>{_translator("title", item, mlang)}</p>
                </div>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    AboutData: state.fetchDataReducer.AboutData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Achievments);

const AcheivementData = [
  {
    id: 1,
    title: "Rahbariyat",
    title_ru: "Руководство",
    title_en: "Management",
    amount: 4,
  },
  {
    id: 2,
    title: "Bo'limlar",
    title_ru: "Отделении",
    title_en: "Departments",
    amount: 10,
  },
  {
    id: 3,
    title: "Xodimlar",
    title_ru: "Персонал",
    title_en: "Staff",
    amount: 30,
  },
  {
    id: 4,
    title: "O'quv kurslari",
    title_ru: "Курсы",
    title_en: "Courses",
    amount: 20,
  },
];
