import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../redux/actions";
import {langData} from "../index.i18n";
import {_translator} from "../helpers";
import ContactForm from "../components/ContactForm";
import {FormMain} from "../components/Forms";

class Contacts extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {mlang, AboutData} = this.props;

        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

        return (
            <div>
                <div className="full_page">
                    <div className="map_con">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="map">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d5997.520111820882!2d69.229911!3d41.27056!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDE2JzE0LjAiTiA2OcKwMTMnNDcuNyJF!5e0!3m2!1sru!2s!4v1719292779460!5m2!1sru!2s"
                                            width="100%"
                                            height={420}
                                            frameBorder={0}
                                            style={{border: 0}}
                                            allowFullScreen
                                            aria-hidden="false"
                                            tabIndex={0}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="contact_page">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <h2>{data.contactBy}</h2>
                                    <img
                                        className="img-fluid"
                                        src="assets/images/about_border.png"
                                        alt="border_img"
                                    />
                                    <div className="get_title1">
                                        <div className="address_box">
                                            <div className="add_box">
                                                <i className="fa fa-map-marker" aria-hidden="true"/>
                                                <h4>{data.address}:</h4>
                                                <p>{_translator("address", AboutData, mlang)}</p>
                                            </div>
                                        </div>
                                        <div className="call_box">
                                            <div className="call_box">
                                                <i className="fa fa-envelope" aria-hidden="true"/>
                                                <h4>{data.youHaveQuestion}</h4>
                                                <p>
                                                    <a href="mailto:smsiti@mail.ru">{AboutData.email}</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="call_box">
                                            <div className="call_box">
                                                <i className="fa fa-envelope" aria-hidden="true"/>
                                                <h4>Shartnomalar uchun</h4>
                                                <p>
                                                    <a href="mailto:smsiti@mail.ru">
                                                        contract@standart.uz
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="call_box">
                                            <i className="fa fa-phone" aria-hidden="true"/>
                                            <h4>{data.callUs}</h4>
                                            <p>
                                                <a href="tel:+998712538567">{AboutData.main_phone}</a>
                                            </p>
                                        </div>
                                        <div className="call_box">
                                            <i className="fa fa-fax" aria-hidden="true"/>
                                            <h4>{data.fax}</h4>
                                            <p>
                                                <a href="#" href="tel:+998712537134">
                                                    {AboutData.fax}
                                                </a>
                                            </p>
                                        </div>
                                        <div className="call_box">
                                            <i className="fa fa-bus" aria-hidden="true"/>
                                            <h4 style={{display: "inline-block", marginLeft: 10}}>
                                                {data.bus}
                                            </h4>
                                            <p>
                                                <a style={{marginLeft: 25}}>18, 33, 69, 75, 114</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <FormMain formSize={8} mlang={mlang}/>
                            </div>
                        </div>
                    </section>
                </div>
                {/* /scroll_to_top start */}
                <a href="#" className="scrollToTop">
                    <i className="fa fa-arrow-up" aria-hidden="true"/>
                </a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        AboutData: state.fetchDataReducer.AboutData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
