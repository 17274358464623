import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as Actions from "../redux/actions"
import { ButtonDark } from "../components/Buttons"
import { langData } from "../index.i18n"

function Ads({ mlang }) {
  const data =
    mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en

  return (
    <section id="new-project" className="new-project">
      <div className="container">
        <div className="new-project-details">
          <div className="row">
            <div className="offset-lg-1 col-lg-8 col-md-12">
              <div className="single-new-project">
                <h3>{data.AdviceToSignUp}</h3>
              </div>
            </div>
            <ButtonDark title={data.SingUpNowButton} />
          </div>
        </div>
      </div>
    </section>
  )
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Ads)
