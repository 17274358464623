export const FETCH_URL = process.env.REACT_APP_FETCH_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const JOURNAL_URL = process.env.REACT_APP_JOURNAL_URL;

// export const FETCH_URL = "http://127.0.0.1:8000/api/v1/";
// export const IMAGE_URL = "http://127.0.0.1:8000/storage/";
// export const JOURNAL_URL = "http://127.0.0.1:8000/api/v1/standarts";

// export const FETCH_URL = "https://uzsti.uz/api/v1/";
// export const IMAGE_URL = "https://uzsti.uz/storage/";
// export const JOURNAL_URL = "https://uzsti.uz/api/v1/standarts";

// export const FETCH_URL = "https://smsiti.smarttechnology.uz/api/v1/";
// export const IMAGE_URL = "https://smsiti.smarttechnology.uz/storage/";
// export const JOURNAL_URL = "https://standart.smarttechnology.uz/api/standarts";
