import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
import { HashLink } from "react-router-hash-link";

class ContactUs extends Component {
  render() {
    const { mlang } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    return (
      <ul className="right__panel">
        <li>
          <span>{data.myOpinion}</span>
          <HashLink to="/feedback#top">
            <i className="fa fa-comment" />
          </HashLink>
        </li>
        <li>
          <span>{data.narrator}</span>
          <span
            // onClick={() => window.responsiveVoice.cancel()}
            type="button"
            value="cancel"
          >
            <i className="fa fa-volume-up fa-flip-horizontal" />
          </span>
        </li>
        <li>
          <span>{data.questionAndAnswer}</span>
          <HashLink to="/contacts#top">
            <img src="/assets/images/faq.png" alt="" />
          </HashLink>
        </li>
        <li>
          <span>{data.stats}</span>
          <HashLink to="/feedback#stats">
            <img src="/assets/images/chart.png" alt="" />
          </HashLink>
        </li>
        <li>
          <span>{data.helpLine}</span>
          <HashLink to="/phones#top">
            <i className="fa fa-phone" aria-hidden="true" />
          </HashLink>
        </li>
        <li>
          <Link to="/phones">
            <i className="fa fa-phone" aria-hidden="true" />
            <span>{data.helpLine}</span>
          </Link>
        </li>
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    AboutData: state.fetchDataReducer.AboutData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
