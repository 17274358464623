import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import * as Actions from "../../redux/actions";
import { langData } from "./index.i18n";
import "./style.css";
import LinesEllipsisLoose from "react-lines-ellipsis/lib/loose";
import { Link } from "react-router-dom";
import { Pagination } from "react-laravel-paginex";
// import axios from "axios";
// import { FETCH_URL } from "../../constants";
import { Table } from "react-bootstrap";
// require("bootstrap/less/bootstrap.less")

class ShopScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 0,
      selectedType: props.location.stateLink ? props.location.stateLink : 0,
      // selectedType: props.location.stateLink,
      mainSearchText: "",
      checkInput: "",
      isMain: true,
    };
  }
  componentDidMount() {
    this.props.fetchJournalListData(
      this.props.mlang,
      null,
      null,
      this.state.pageNumber
    );
    window.scrollTo(0, 0);
  }

  updateText = (path, e) => {
    this.setState({
      [path]: e.target.value,
    });
  };

  _render_item = (item) => {
    const { mlang } = this.props;
    const { isMain } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log("JournalListData", this.props.JournalListData);
    // console.log("props.location.stateLink", this.props.location.stateLink);
    return (
      <Link
        to={`/shop/${item.id}`}
        // className={`${isMain ? "col-4" : "col-12"} mb-3 item`}
        className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 item"
      >
        <div
          className="card position-relative hvr-grow bg-white shadow-lg h-100 p-3"
          style={{ flex: 1 }}
        >
          <span
            className="position-absolute"
            style={{ display: "flex", top: 2, left: 2 }}
          >
            <i
              style={{
                textAlign: "center",
                fontSize: 20,
                color: item.actual == 1 ? "#24bc9c" : "#dc3545",
                verticalAlign: "middle",
              }}
              className={`${
                item.actual == 1 ? "fa fa-check-circle" : "fa fa-times-circle"
              }`}
            ></i>
          </span>
          <a
            href
            className="font-weight-bold title my-2"
            style={{ color: "#302b68" }}
          >
            <LinesEllipsisLoose
              text={item.name_standart}
              maxLine="2"
              lineHeight="24"
            />
          </a>
          <div className="desc" style={{ color: "black" }}>
            <LinesEllipsisLoose
              text={item.anotatsiya_standart}
              maxLine="3"
              lineHeight="20"
            />
          </div>
          {/* <span
            className="badge badge-danger position-absolute"
            style={{ display: item.date_standart == 0 ? "none" : "flex" }}
          >
            {item.date_standart}
            {mlang === "uz" ? "Yil" : mlang === "ru" ? "Год" : "Year"}
          </span> */}
        </div>
      </Link>
    );
  };

  setCategory = (e) => {
    const categoryId = e.target.value;
    // console.log(" categoryId: ", categoryId);
    this.setState(
      {
        selectedType: this.state.selectedType,
        selectedCategory: categoryId,
        selectedSearchCategory: "category",
        pageNumber: 1,
        isMain: false,
        activePage: 15,
      },
      () => {
        if (
          this.state.selectedCategory != 0 &&
          this.state.selectedType != 0 &&
          this.state.mainSearchText
        ) {
          this.props.fetchJournalListData(
            this.props.mlang,
            "searchAll",
            categoryId === "Kategoriyani tanlang"
              ? 0
              : categoryId === "Выбрать категорию"
              ? 0
              : categoryId === "Select a category"
              ? 0
              : categoryId,
            this.state.selectedType,
            this.state.mainSearchText
          );
        } else if (
          this.state.selectedCategory != 0 &&
          this.state.mainSearchText
        ) {
          this.props.fetchJournalListData(
            this.props.mlang,
            "searchCat",
            categoryId === "Kategoriyani tanlang"
              ? 0
              : categoryId === "Выбрать категорию"
              ? 0
              : categoryId === "Select a category"
              ? 0
              : categoryId,
            this.state.mainSearchText
          );
        } else if (
          this.state.selectedCategory != 0 &&
          this.state.selectedType == 0 &&
          this.state.mainSearchText == ""
        ) {
          this.props.fetchJournalListData(
            this.props.mlang,
            "category",
            categoryId === "Kategoriyani tanlang"
              ? 0
              : categoryId === "Выбрать категорию"
              ? 0
              : categoryId === "Select a category"
              ? 0
              : categoryId
          );
        }
      }
    );
  };
  setType = (e) => {
    const typeId = e.target.value;
    // console.log(" typeId: ", typeId);
    this.setState(
      {
        selectedCategory: this.state.selectedCategory,
        selectedType: typeId,
        selectedSearchCategory: "type",
        pageNumber: 1,
        isMain: false,
      },
      () => {
        if (
          this.state.selectedCategory != 0 &&
          this.state.selectedType != 0 &&
          this.state.mainSearchText
        ) {
          this.props.fetchJournalListData(
            this.props.mlang,
            "searchAllType",
            typeId === "Turni tanlang"
              ? 29
              : typeId === "Выберите типа"
              ? 29
              : typeId === "Select type"
              ? 29
              : typeId,
            this.state.selectedCategory,
            this.state.mainSearchText
          );
        } else if (this.state.selectedType != 0 && this.state.mainSearchText) {
          this.props.fetchJournalListData(
            this.props.mlang,
            "searchType",
            typeId === "Turni tanlang"
              ? 29
              : typeId === "Выберите типа"
              ? 29
              : typeId === "Select type"
              ? 29
              : typeId,
            this.state.mainSearchText
          );
        } else if (
          this.state.selectedType != 0 &&
          this.state.selectedCategory == 0 &&
          this.state.mainSearchText == ""
        ) {
          this.props.fetchJournalListData(
            this.props.mlang,
            "type",
            typeId === "Turni tanlang"
              ? 29
              : typeId === "Выберите типа"
              ? 29
              : typeId === "Select type"
              ? 29
              : typeId
          );
        }
      }
    );
  };

  searchJournals = (e) => {
    this.setState(
      {
        mainSearchText: e.target.value,
        isMain: this.state.mainSearchText.length <= 1 ? true : false,
        selectedType: this.state.selectedType,
        selectedCategory: this.state.selectedCategory,
      },
      () => {
        if (!this.state.mainSearchText.length) {
          this.props.fetchJournalListData(this.props.mlang, null, null, 1);
        }
        if (
          this.state.mainSearchText.length > 1 &&
          this.state.selectedCategory != 0 &&
          this.state.selectedType != 0
        ) {
          this.props.fetchJournalListData(
            this.props.mlang,
            "searchAll",
            this.state.selectedCategory,
            this.state.selectedType,
            this.state.mainSearchText,
            this.state.pageNumber
          );
        } else {
          this.props.fetchJournalListData(
            this.props.mlang,
            "search",
            this.state.mainSearchText,
            this.state.pageNumber
          );
        }
      }
    );
  };
  getData = (data) => {
    const { selectedCategory, selectedType, mainSearchText } = this.state;

    const type =
      selectedCategory != 0 && selectedType != 0 && mainSearchText.length
        ? "searchAll"
        : selectedCategory != 0 && mainSearchText.length
        ? "searchCat"
        : selectedType != 0 && mainSearchText.length
        ? "searchType"
        : selectedCategory != 0
        ? "category"
        : selectedType != 0
        ? "type"
        : mainSearchText.length
        ? "search"
        : null;

    const id =
      selectedCategory != 0 && type == "searchCat"
        ? selectedCategory
        : selectedType != 0 && type == "searchType"
        ? selectedType
        : selectedCategory != 0 && type == "category"
        ? selectedCategory
        : selectedType != 0 && type == "type"
        ? selectedType
        : mainSearchText.length != 0 && type == "search"
        ? mainSearchText
        : null;
    const cat_id =
      (type == "searchCat" || type == "searchType") && mainSearchText.length
        ? mainSearchText
        : null;
    const type_id = selectedType != 0 ? selectedType : null;

    this.props.fetchJournalListData(
      this.props.mlang,
      type,
      id,
      cat_id,
      type_id,
      data.page
    );
    window.scrollTo(0, 0);
  };

  render() {
    // console.log("mainSearchText: ", this.state.mainSearchText);
    const {
      selectedCategory,
      selectedType,
      mainSearchText,
      checkInput,
    } = this.state;
    const {
      mlang,
      JournalListData,
      LoadingJournalListData,
      JournalCategoryData,
      JournalTypesData,
      CheckData,
    } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log("KKKK", JournalListData);
    // let map1 = JournalListData.data.map((item) => item);
    // console.log("MAP 1: ", map1.length);

    // console.log("selectedCategory", selectedCategory);
    // console.log("selectedType", selectedType);
    // console.log("mainSearchText", mainSearchText);
    // console.log("CheckData", CheckData);

    return LoadingJournalListData ? (
      <Loader />
    ) : (
      <div className="container py-4">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="card shadow-lg mb-3">
              <div className="card-header font-weight-bold text-uppercase sidebar_title">
                <h5>{data.searchTitle}</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control search "
                    placeholder={data.search}
                    value={mainSearchText}
                    onChange={(e) => this.searchJournals(e)}
                  />
                </div>
              </div>
            </div>
            <div className="card shadow-lg mb-3">
              <div className="card-header font-weight-bold text-uppercase sidebar_title">
                <h5>{data.searchCategory}</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => this.setCategory(e)}
                  >
                    <option
                      // disabled
                      selected={selectedCategory === 0 ? true : false}
                    >
                      {data.selectCategory}
                    </option>
                    {JournalCategoryData.map((item) => (
                      <option
                        key={item.id + ""}
                        value={item.id}
                        selected={selectedCategory == item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="card shadow-lg mb-3">
              <div className="card-header font-weight-bold text-uppercase sidebar_title">
                <h5>{data.searchType}</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => this.setType(e)}
                  >
                    <option
                      // disabled
                      selected={selectedType === 0 ? true : false}
                    >
                      {data.selectType}
                    </option>
                    {JournalTypesData.map((item) => (
                      <option
                        key={item.id + ""}
                        value={item.id}
                        selected={selectedType == item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="card shadow-lg mb-3">
              <div className="card-header font-weight-bold text-uppercase sidebar_title">
                <h5>{data.checkTitle}</h5>
              </div>
              <form
                onSubmit={(event) => {
                  this.props.fetchCheckData(checkInput.replace("#", ""));
                  event.preventDefault();
                  window.scrollTo(0, 0);
                }}
                className="card-body"
              >
                <div className="form-group">
                  <input
                    pattern=".{11,11}"
                    type="text"
                    className="form-control search "
                    title={data.checkPlaceholder}
                    placeholder={data.checkPlaceholder}
                    value={checkInput}
                    onChange={(e) => this.updateText("checkInput", e)}
                  />
                  <input
                    value={data.checkSearch}
                    style={{
                      backgroundColor: "#00bbd1",
                      // backgroundColor: "#302b68",
                      color: "#fff",
                      margin: "0 auto",
                      display: "block",
                    }}
                    className="btn  mt-3"
                    type="submit"
                  />
                </div>
              </form>
            </div>
            <div className="card shadow-lg mb-3">
              <div className="card-header font-weight-bold text-uppercase sidebar_title">
                <h5>Xalqaro standartlar</h5>
              </div>
              <form
                onSubmit={(event) => {
                  // this.props.fetchCheckData(checkInput.replace("#", ""));
                  event.preventDefault();
                  window.scrollTo(0, 0);
                }}
                className="card-body"
              >
                <div className="form-group">
                  <Link
                    to="/fonds"
                    style={{
                      backgroundColor: "#00bbd1",
                      // backgroundColor: "#302b68",
                      color: "#fff",
                      margin: "0 auto",
                      display: "block",
                    }}
                    className="btn  mt-3"
                  >
                    Xalqaro standartlar
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xl-9 col-md-9 col-lg-9 col-sm-12 col-12">
            <div
              style={{ display: mainSearchText ? "block" : "none" }}
              className="card shadow-lg mb-3"
            >
              <div className="card-header font-weight-bold text-uppercase sidebar_title">
                <h5
                  style={{ display: mlang === "uz" ? "block" : "none" }}
                >{`${mainSearchText} so'rovi boyicha ${JournalListData.total} ta standart topildi`}</h5>
                <h5
                  style={{ display: mlang === "ru" ? "block" : "none" }}
                >{`${JournalListData.total} СТАНДАРТОВ ОБНАРУЖЕНЫ ПО ЗАПРОСУ НА ${mainSearchText}`}</h5>
                <h5
                  style={{ display: mlang === "en" ? "block" : "none" }}
                >{`${JournalListData.total} STANDARDS WERE FOUND ON ${mainSearchText} REQUEST`}</h5>
              </div>
            </div>

            <div
              className="row"
              style={{ display: checkInput ? "none" : "flex" }}
            >
              {JournalListData.data.map((item) => this._render_item(item))}
            </div>
            <div style={{ display: checkInput ? "block" : "none" }}>
              <div
                style={{
                  display: CheckData.success === true ? "block" : "none",
                }}
                className="table-responsive mt-5"
              >
                {/* <table className="table chiter-table text-center "> */}
                <Table
                  className=" d-none d-lg-block "
                  striped
                  bordered
                  responsive
                >
                  <tbody>
                    <tr className="text-center">
                      <th style={{ verticalAlign: "middle" }}>#</th>
                      <th style={{ verticalAlign: "middle" }}>
                        {data.checkCategory}
                      </th>
                      <th style={{ verticalAlign: "middle" }}>
                        {data.checkName}
                      </th>
                      <th style={{ verticalAlign: "middle" }}>
                        {data.checkPage}
                      </th>
                      <th style={{ verticalAlign: "middle" }}>
                        {data.checkOrderer}
                      </th>
                      <th style={{ minWidth: "95px", verticalAlign: "middle" }}>
                        {data.checkOrderDate}
                      </th>
                    </tr>
                    <tr className="text-center">
                      <td style={{ verticalAlign: "middle" }}>1</td>
                      <td style={{ verticalAlign: "middle" }}>
                        {CheckData.data &&
                          CheckData.data.standart &&
                          CheckData.data &&
                          CheckData.data.standart.category.name}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {CheckData.data &&
                          CheckData.data.standart &&
                          CheckData.data &&
                          CheckData.data.standart.name_standart}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {CheckData.data &&
                          CheckData.data.standart &&
                          CheckData.data &&
                          CheckData.data.standart.page_standart}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {(CheckData.data &&
                          CheckData.data.order &&
                          CheckData.data &&
                          CheckData.data.order.user.username) ||
                          (CheckData.data &&
                            CheckData.data.order.user.company) ||
                          (CheckData.data && CheckData.data.order.user.phone)}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {CheckData.data &&
                          CheckData.data.order &&
                          CheckData.data &&
                          CheckData.data.order.created_at.slice(0, 10)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="d-lg-none " striped bordered responsive>
                  <tbody>
                    <tr className="text-center">
                      <th style={{ verticalAlign: "middle" }}>#</th>
                      <td style={{ verticalAlign: "middle" }}>1</td>
                    </tr>
                    <tr className="text-center">
                      <th style={{ verticalAlign: "middle" }}>
                        {data.checkCategory}
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {CheckData.data &&
                          CheckData.data.standart &&
                          CheckData.data &&
                          CheckData.data.standart.category.name}
                      </td>
                    </tr>
                    <tr className="text-center">
                      <th style={{ verticalAlign: "middle" }}>
                        {data.checkName}
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {CheckData.data &&
                          CheckData.data.standart &&
                          CheckData.data &&
                          CheckData.data.standart.anotatsiya_standart}
                      </td>
                    </tr>
                    <tr className="text-center">
                      <th style={{ verticalAlign: "middle" }}>
                        {data.checkPage}
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {CheckData.data &&
                          CheckData.data.standart &&
                          CheckData.data &&
                          CheckData.data.standart.page_standart}
                      </td>
                    </tr>
                    <tr className="text-center">
                      <th style={{ verticalAlign: "middle" }}>
                        {data.checkOrderer}
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {(CheckData.data &&
                          CheckData.data.order &&
                          CheckData.data &&
                          CheckData.data.order.user.username) ||
                          (CheckData.data &&
                            CheckData.data.order.user.company) ||
                          (CheckData.data && CheckData.data.order.user.phone)}
                      </td>
                    </tr>
                    <tr className="text-center">
                      <th style={{ minWidth: "95px", verticalAlign: "middle" }}>
                        {data.checkOrderDate}
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {CheckData.data &&
                          CheckData.data.order &&
                          CheckData.data &&
                          CheckData.data.order.created_at.slice(0, 10)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <h3
                className="text-center"
                style={{
                  display:
                    CheckData.error == "Bu kod bo'yicha standart topilmadi"
                      ? "block"
                      : "none",
                }}
              >
                {data.checkNotFound}
              </h3>
            </div>
          </div>
        </div>
        <div
          className="row mt-lg-5"
          style={{
            display:
              JournalListData.last_page === 1 || checkInput ? "none" : "block",
          }}
        >
          <div className="col-md-12 d-flex justify-content-center">
            <Pagination
              changePage={this.getData}
              data={this.props.JournalListData}
              nextButtonText={data.next}
              prevButtonText={data.previous}
              className="pagination"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    JournalListData: state.fetchDataReducer.JournalListData,
    JournalCategoryData: state.fetchDataReducer.JournalCategoryData,
    JournalTypesData: state.fetchDataReducer.JournalTypesData,
    LoadingJournalListData: state.fetchDataReducer.LoadingJournalListData,
    CheckData: state.fetchDataReducer.CheckData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ShopScreen);
