import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import CurrentTime from "../components/CurrentTime";
// import Loader from "../components/Loader";
import { Dropdown } from "react-bootstrap";
// import axios from "axios";

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartData: [],
    };
  }

  componentDidMount() {
    if (this.props.userToken) {
      this.props.fetchCartData(this.props.userToken, null);
    }
    // this.updateState()
  }

  // componentDidUpdate() {
  //   if (this.state.cartData != this.props.cartData) {
  //     this.updateState()
  //   }
  // }

  // updateState = () => {
  //   this.setState({
  //     cartData: this.props.CartData,
  //   })
  // }
  changeLang = (lang) => {
    this.props.changeLanguage(lang);
    Cookies.set("userLang", lang);
  };


  logOut() {
    // const accessToken = Cookies.get('accessToken')
    // const accessStr = accessToken.replace(' ', '')

    // console.log(accessStr)

    // if (accessStr) {
    //   axios.post(`https://sso.egov.uz/sso/oauth/Authorization.do?grant_type=one_log_out&client_id=uzsti&client_secret=8eGzh5eeaKtpFpS5AvCKYQ==&access_token="${accessStr}"&scope=logout`)
    //     .then((response) => {
    //       console.log(response)
    //     });
    // }

    this.props.setToken("");
    Cookies.remove("userToken");
    Cookies.remove("accessToken");
    Cookies.remove("userCode");
  }

  render() {
    const {
      // changeLanguage,
      mlang,
      userToken,
      isLogged,
      CartData,
      // LoadingCartData,
      grayBtn,
      AboutData,
    } = this.props;

    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

    const languages = [
        {
            title: "O'zbekcha",
            shortTitle: "O'z",
            flag: 'uz'
        },
        {
            title: "Русский",
            shortTitle: "Ру",
            flag: 'ru'
        },
        {
            title: "English",
            shortTitle: "Eng",
            flag: 'en'
        }
    ]
    const activeLanguage = languages.filter((l)=>l.flag === mlang)[0]
    
    // console.log("CartData.data.total", CartData);
    return (
      <div
        className="topbar"
        style={{ position: "sticky", top: 0, zIndex: 1000 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-6 col-6">
              <div className="phone_box">
                <p>
                  <i className="fa fa-phone" style={{ marginTop: 2 }} />
                  <a href="tel:+998712538567" className="text-white">
                    {AboutData.main_phone}
                  </a>
                </p>
              </div>
              <div className="mail_box">
                <p>
                  <i style={{ marginTop: 3 }} className="fa fa-envelope" />

                  <a href={`mailto:${AboutData.email}`} className="text-white">
                    {AboutData.email}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-6 col-6">
              <div className="right-boxo">
                <span
                  onClick={grayBtn}
                  className="bvi-open"
                  title="Maxsus imkoniyatlar"
                  style={{
                    visibility: "visible",
                    cursor: "pointer",
                  }}
                >
                  <i className="bvi-icon bvi-eye bvi-2x" />
                  {/* {data.specialservices} */}
                </span>
                <CurrentTime mlang={this.props.mlang} />
                <Dropdown>
                  <Dropdown.Toggle
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#353c4e",
                      borderColor: "#353c4e",
                      boxShadow: "none",
                    }}
                    id="dropdown-basic"
                  >
                    <i style={{ fontSize: "25px" }} className="fa fa-globe" /> &nbsp; {activeLanguage.shortTitle} &nbsp;
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      languages.map((l) => 
                          l.flag !== activeLanguage.flag &&
                          <Dropdown.Item key={l.flag} onClick={() => this.changeLang(l.flag)}>

                                {l.title} 
                          </Dropdown.Item>
                        )
                    }
                  </Dropdown.Menu>
                </Dropdown>
                <div style={{ position: "relative" }}>
                  <Link to="/cart">
                    <i
                      style={{ color: "white", fontSize: "25px" }}
                      className="fa fa-shopping-cart"
                    ></i>
                  </Link>
                  <div
                    style={{
                      fontWeight: 700,
                      position: "absolute",
                      top: -6,
                      right: -6,
                      background: "white",
                      width: 20,
                      height: 20,
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 12,
                      display:
                        userToken && CartData
                          ? CartData.length
                            ? "flex"
                            : "none"
                          : "none",
                      borderRadius: 50,
                    }}
                  >
                    {userToken && CartData ? CartData.length : 0}
                  </div>
                </div>
                <div className="auth">
                  <Link to={userToken && isLogged ? "/profile" : "/login"}>
                    {userToken && isLogged ? data.cabinet : data.login}
                  </Link>
                  {/* <Link to="/register">{data.register}</Link> */}
                  <Link
                    onClick={() => this.logOut()}
                    to={userToken && isLogged ? "/" : "/register"}
                  >
                    {userToken && isLogged ? data.signOutButton : data.register}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    userToken: state.fetchDataReducer.userToken,
    CartData: state.fetchDataReducer.CartData,
    LoadingCartData: state.fetchDataReducer.LoadingCartData,
    AboutData: state.fetchDataReducer.AboutData,
    isLogged: state.fetchDataReducer.isLogged,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
