import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../redux/actions";
import {langData} from "../index.i18n";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {FETCH_URL} from "../constants";
// import { _translator } from "../helpers";
import InputMask from "react-input-mask";

export class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Password: "",
            ConfirmPassword: "",
            OrganizationName: "",
            FullName: "",
            PhoneNumber: "",
            Email: "",
            Region: null,
            City: null,
            BankName: "",
            BankNumber: "",
            TIN: "",
            MFO: "",
            OKED: "",
            Adress: "",
            isCompany: 1,
            isReq: false,
            reqCode: "",
            // IND
            indRegion: null,
            indCity: null,
            indPassword: "",
            indConfirmPassword: "",
            indFullName: "",
            indPhoneNumber: "",
            indEmail: "",
            indTIN: "",
            indAdress: "",
            captcha: "",
            imgData: "", key: ""
        };

        this.signUp = this.signUp.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getCaptcha()
    }

    updateState = (title, e) => {
        this.setState({
            [title]: e.target.value,
        });
    };

    signUp = (event) => {
        const {
            OrganizationName,
            Password,
            // ConfirmPassword,
            // FullName,
            PhoneNumber,
            // Email,
            Region,
            City,
            // BankName,
            // BankNumber,
            TIN,
            // MFO,
            // OKED,
            // Adress,
            // indRegion,
            indCity,
            isCompany,
            indPassword,
            // indConfirmPassword,
            indFullName,
            indPhoneNumber,
            // indEmail,
            indTIN,
            captcha,
            key, imgData
            // indAdress,
        } = this.state;
        const {mlang} = this.props;
        event.preventDefault();
        // function emailIsValid(Email) {
        //   return /\S+@\S+\.\S+/.test(Email);
        // }
        function phoneIsValid(PhoneNumber) {
            return /^\+?\d+$/.test(PhoneNumber);
        }

        // function passwordIsValid(Password) {
        //   return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(Password);
        // }
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        // console.log("PHONE", PhoneNumber);

        if (isCompany) {
            if (
                !OrganizationName ||
                !Password ||
                // !ConfirmPassword ||
                // !FullName ||
                !PhoneNumber ||
                // !Email ||
                !Region ||
                !City ||
                // !BankName ||
                // !BankNumber ||
                !TIN
                // !MFO ||
                // !OKED ||
                // !Adress
            ) {
                toast.error(data.fillAllInputs);
                // console.log("ISHLAMADI?-------");
                // } else if (emailIsValid(Email) === false) {
                //   toast.error(data.invalidMailAddress);
            } else if (
                phoneIsValid(PhoneNumber) === false ||
                PhoneNumber.length !== 13
            ) {
                toast.error(data.invalidPhone);
                // } else if (passwordIsValid(Password) === false) {
                //   toast.error(data.invalidPassword);
            } else if (Password.length < 7) {
                toast.error(data.invalidPassword);
                // } else if (Password !== ConfirmPassword) {
                //   toast.error(data.passwordMatch);
            } else {
                axios
                    .post(`${FETCH_URL}register`, {
                        // username: FullName,
                        password: Password,
                        // password_confirmation: ConfirmPassword,
                        // email: Email,
                        phone: PhoneNumber.slice(4),
                        is_corporate: isCompany,
                        company: OrganizationName,
                        // address: Adress,
                        // bank_name: BankName,
                        // band_account: BankNumber,
                        city: City,
                        // city: parseInt(Region, 10),
                        // mfo: MFO,
                        // oked: OKED,
                        tin: TIN,
                        captcha,
                        key
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.setState(
                                {
                                    Password: "",
                                    ConfirmPassword: "",
                                    OrganizationName: "",
                                    FullName: "",
                                    PhoneNumber: "",
                                    Email: "",
                                    Region: null,
                                    City: null,
                                    BankName: "",
                                    BankNumber: "",
                                    TIN: "",
                                    MFO: "",
                                    OKED: "",
                                    Adress: "",
                                    isReq: true,
                                    captcha: ""
                                },
                                () => {
                                    toast.success(data.successReg);
                                    // console.log("JJJJ", response.data.message);
                                    window.scrollTo(0, 0);
                                }
                            );
                        } else {
                            toast.error(
                                response.data.error.err_msg[0] ===
                                "The phone has already been taken."
                                    ? `${data.invalidPhoneNum}`
                                    : `${data.invalidEmail}`
                            );
                            // console.log("res err: ", response.data.error.err_msg.length);
                        }
                    })
                    .catch((e) => alert(e));
            }
        } else {
            if (
                !indPassword ||
                // !indConfirmPassword ||
                !indFullName ||
                // !indPhoneNumber ||
                // !Email ||
                !indTIN ||
                // !indRegion ||
                !indCity
                // !indAdress
            ) {
                toast.error(data.fillAllInputs);
                // } else if (emailIsValid(Email) === false) {
                //   toast.error(data.invalidMailAddress);
            } else if (
                phoneIsValid(indPhoneNumber) === false ||
                indPhoneNumber.length !== 13
            ) {
                toast.error(data.invalidPhone);
                // } else if (passwordIsValid(indPassword) === false) {
                //   toast.error(data.invalidPassword);
            } else if (indPassword.length < 7) {
                toast.error(data.invalidPassword);
                // } else if (indPassword !== indConfirmPassword) {
                //   toast.error(data.passwordMatch);
            } else {
                axios
                    .post(`${FETCH_URL}register`, {
                        username: indFullName,
                        password: indPassword,
                        // password_confirmation: indConfirmPassword,
                        // email: indEmail,
                        phone: indPhoneNumber.slice(4),
                        is_corporate: isCompany,
                        // address: indAdress,
                        city: indCity,
                        tin: indTIN,
                        captcha,
                        key
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.setState(
                                {
                                    indPassword: "",
                                    indConfirmPassword: "",
                                    OrganizationName: "",
                                    indFullName: "",
                                    indPhoneNumber: "",
                                    indEmail: "",
                                    Region: null,
                                    City: null,
                                    indRegion: null,
                                    indCity: null,
                                    BankName: "",
                                    BankNumber: "",
                                    indTIN: "",
                                    MFO: "",
                                    OKED: "",
                                    indAdress: "",
                                    isReq: true,
                                    captcha: "",
                                    imgData:"",
                                    key:""
                                },
                                () => {
                                    toast.success(data.successReg);
                                    window.scrollTo(0, 0);
                                }
                            );
                        } else {
                            toast.error(
                                response.data.error.err_msg[0] ===
                                "The phone has already been taken."
                                    ? `${data.invalidPhoneNum}`
                                    : `${data.invalidEmail}`
                            );
                            // console.log(
                            //   "res err: ",
                            //   response.data.error.err_msg[0]
                            //   //  ===
                            //   //   "The phone has already been taken."
                            // );
                        }
                    })
                    .catch((e) => alert(e));
            }
        }
    };

    getCaptcha = () => {
        axios.get("https://admin.uzsti.uz/captcha/api/math").then((res) => {
            this.setState({imgData: res?.data?.img ? res?.data?.img?.replaceAll("\\", "") : "", key: res?.data?.key})
        })
    }
    sentVerification = () => {
        const {reqCode} = this.state;
        const {mlang} = this.props;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        axios
            .get(`${FETCH_URL}admin/verify/${reqCode}`)
            .then((res) => {
                if (res.data.success) {
                    this.setState(
                        {
                            reqCode: "",
                            isReq: false,
                        },
                        () => {
                            toast.success("data.successReg");
                            // toast.success(res.data.message);
                            this.props.history.push("/login");
                        }
                    );
                } else {
                    toast.error(data.wrongCode);
                    // toast(res.data.errors.err_msg[0]);
                }
            })
            .catch((err) => alert(err));
    };
    oneIdRender = () => {
        const oneIdUrl = "https://sso.egov.uz/sso/oauth/Authorization.do";
        const clientId = "client_id";

        const data =
            this.props.mlang === "uz"
                ? langData.uz
                : this.props.mlang === "ru"
                    ? langData.ru
                    : langData.en;

        return (
            <form style={{marginTop: 10}} action={oneIdUrl}>
                <button
                    className="btn btn-primary btn-block"
                    // value="submit"
                    onClick={() => this.setState({currentURL: window.location.href})}
                >
                    {data.oneIdReg}
                </button>
                <input type="hidden" name="response_type" value="one_code"/>
                <input type="hidden" name={clientId} value="uzsti"/>
                <input
                    type="hidden"
                    name="redirect_uri"
                    value={`${window.location.href}`}
                />
                <input type="hidden" name="scope" value="legal"/>
                <input type="hidden" name="state" value="auth"/>
            </form>
        );
    };

    render() {
        const oneIdUrl = "https://sso.egov.uz/sso/oauth/Authorization.do";
        const clientId = "client_id";
        const {
            OrganizationName,
            Password,
            // ConfirmPassword,
            // FullName,
            // PhoneNumber,
            // Email,
            Region,
            City,
            // BankName,
            // BankNumber,
            TIN,
            // MFO,
            // OKED,
            // Adress,
            indRegion,
            indCity,
            isReq,
            reqCode,
            indPassword,
            // indConfirmPassword,
            indFullName,
            // indPhoneNumber,
            // indEmail,
            indTIN,
            // indAdress,
        } = this.state;
        const {mlang} = this.props;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        // console.log("isCompany: ", this.state.isCompany);
        // console.log("LENGTH ", indPassword);
        // console.log("LENGTH 2", indPassword.length);
        // console.log("1 LENGTH ", Password);
        // console.log("1 LENGTH 2", Password.length);
        // console.log("OrganizationName", OrganizationName);
        // console.log("Password", Password);
        // console.log("ConfirmPassword", ConfirmPassword);
        // console.log("FullName", FullName);
        // console.log("PhoneNumber", PhoneNumber);
        // console.log("PhoneNumber", PhoneNumber.length);
        // console.log("Email", Email);
        // console.log("Region reg", Region);
        // console.log("City reg", City);
        // console.log("indRegion reg", indRegion);
        // console.log("indCity reg", indCity);
        // console.log("BankName", BankName);
        // console.log("BankNumber", BankNumber);
        // console.log("TIN", TIN);
        // console.log("MFO", MFO);
        // console.log("OKED", OKED);
        // console.log("Adress", Adress);
        // console.log("LINE--------------");

        return (
            <div>
                <div className="sptb ariza">
                    <div className="container">
                        <div className="row" style={{display: isReq ? "none" : "block"}}>
                            <ul
                                className="nav nav-tabs d-flex justify-content-center align-items-center mt-5"
                                id="myMainTab"
                                role="tablist"
                            >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active font-weight-bold text-uppercase"
                                        id="one-tab"
                                        data-toggle="tab"
                                        href="#one"
                                        role="tab"
                                        aria-controls="one"
                                        aria-selected="true"
                                    >
                                        {data.oniReg}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link font-weight-bold text-uppercase"
                                        id="site-tab"
                                        data-toggle="tab"
                                        href="#site"
                                        role="tab"
                                        aria-controls="site"
                                        aria-selected="false"
                                    >
                                        {data.siteReg}
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content pt-4" id="myMainTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="one"
                                    role="tabpanel"
                                    aria-labelledby="one-tab"
                                >
                                    {" "}
                                    <div className="reg">
                                        <div
                                            className="reg__box"
                                            style={{backgroundColor: "#fff", paddingTop: 0}}
                                        >
                                            <img
                                                style={{
                                                    display: "block",
                                                    margin: "0 auto",
                                                    paddingBottom: 10,
                                                }}
                                                src="/assets/images/one_id.png"
                                                alt=""
                                            />
                                            <h3
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    color: "#00bbd1",
                                                    // color: "#302b68",
                                                }}
                                            >
                                                {data.oidWelcome}
                                            </h3>
                                            <p style={{textAlign: "center", lineHeight: 2}}>
                                                {data.oidTextReg}
                                            </p>
                                            <form action={oneIdUrl}>
                                                <button
                                                    type="submit"
                                                    // value="submit"
                                                    onClick={() =>
                                                        this.setState({
                                                            currentURL: window.location.href,
                                                        })
                                                    }
                                                >
                                                    {data.oidReg}
                                                </button>
                                                <input
                                                    type="hidden"
                                                    name="response_type"
                                                    value="one_code"
                                                />
                                                <input type="hidden" name={clientId} value="uzsti"/>
                                                <input
                                                    type="hidden"
                                                    name="redirect_uri"
                                                    value={`${window.location.href}`}
                                                />
                                                <input type="hidden" name="scope" value="legal"/>
                                                <input type="hidden" name="state" value="auth"/>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id="site"
                                    role="tabpanel"
                                    aria-labelledby="site-tab"
                                >
                                    <ul
                                        className="nav nav-tabs d-flex justify-content-center align-items-center mt-1"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active font-weight-bold text-uppercase"
                                                id="home-tab"
                                                data-toggle="tab"
                                                href="#home"
                                                role="tab"
                                                aria-controls="home"
                                                aria-selected="true"
                                                onClick={() => this.setState({isCompany: 1})}
                                            >
                                                {data.regJury}
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link font-weight-bold text-uppercase"
                                                id="profile-tab"
                                                data-toggle="tab"
                                                href="#profile"
                                                role="tab"
                                                aria-controls="profile"
                                                aria-selected="false"
                                                onClick={() => this.setState({isCompany: 0})}
                                            >
                                                {data.regPhys}
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-4" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                        >
                                            <form
                                                onSubmit={this.signUp}
                                                style={{
                                                    maxWidth: "700px",
                                                    margin: "auto",
                                                }}
                                            >
                                                <div className="card border-primary box-shadow mb-5">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regPhone}{" "}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    {/* <input
                              type="number"
                              className="form-control border-primary "
                              name="phone"
                              placeholder={data.regPhonePlace}
                              // maxLength={12}
                              onChange={(e) =>
                                this.updateState("PhoneNumber", e)
                              }
                              value={PhoneNumber}
                            /> */}
                                                                    <InputMask
                                                                        alwaysShowMask={true}
                                                                        // maskChar={null}
                                                                        mask="+\9\98999999999"
                                                                        className="form-control border-primary"
                                                                        // type="phone"
                                                                        // placeholder={data.phoneNumberEnter}
                                                                        onChange={(e) =>
                                                                            this.updateState("PhoneNumber", e)
                                                                        }
                                                                        value={this.state.PhoneNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>{data.regEmail}</label>
                            <input
                              type="email"
                              className="form-control border-primary email "
                              name="email"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                              placeholder={data.regEmailEnt}
                              onChange={(e) => this.updateState("Email", e)}
                              value={Email}
                            />
                          </div>
                        </div> */}

                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regPassword}{" "}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control border-primary "
                                                                        name="company_name"
                                                                        placeholder={data.regPasswordEnt}
                                                                        onChange={(e) =>
                                                                            this.updateState("Password", e)
                                                                        }
                                                                        value={Password}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>
                              {data.regConfirmPass}
                              <i className="text-danger">*</i>
                            </label>
                            <input
                              type="password"
                              className="form-control border-primary "
                              name="company_name"
                              placeholder={data.regConfirmPass}
                              onChange={(e) =>
                                this.updateState("ConfirmPassword", e)
                              }
                              value={ConfirmPassword}
                            />
                          </div>
                        </div> */}

                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regOrgName}{" "}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-primary "
                                                                        name="company_name"
                                                                        placeholder={data.regOrgNameEnt}
                                                                        onChange={(e) =>
                                                                            this.updateState("OrganizationName", e)
                                                                        }
                                                                        value={OrganizationName}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>
                              {data.regLiablePerson}{" "}
                              <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              className="form-control border-primary "
                              defaultValue=""
                              name="ConfirmPassword"
                              placeholder={data.regLiablePersonEnt}
                              onChange={(e) =>
                                this.updateState("FullName", e)
                              }
                              value={FullName}
                            />
                          </div>
                        </div> */}
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regInn}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={9}
                                                                        className="form-control border-primary tin "
                                                                        name="TIN"
                                                                        placeholder={data.regInnEnt}
                                                                        onChange={(e) => this.updateState("TIN", e)}
                                                                        value={TIN}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regArea}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <select
                                                                        className="form-control border-primary qty-of-trainees "
                                                                        name="city"
                                                                        onChange={(e) =>
                                                                            this.updateState("Region", e)
                                                                        }
                                                                        value={Region}
                                                                    >
                                                                        <option disabled selected value="">
                                                                            {data.regAreaEnt}
                                                                        </option>
                                                                        {cityData.map((region) => (
                                                                            <option
                                                                                key={region.id + ""}
                                                                                value={region.id}
                                                                            >
                                                                                {region.name}
                                                                                {/* {_translator("title", city, mlang)} */}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regCity}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <select
                                                                        className="form-control border-primary qty-of-trainees "
                                                                        name="city"
                                                                        onChange={(e) =>
                                                                            this.updateState("City", e)
                                                                        }
                                                                        value={City}
                                                                    >
                                                                        <option disabled selected value="">
                                                                            {data.regCityEnt}
                                                                        </option>
                                                                        {Region
                                                                            ? cityData
                                                                                .filter(
                                                                                    (region) => region.id === Region
                                                                                )[0]
                                                                                .city.map((city) => (
                                                                                    <option
                                                                                        key={city.id + ""}
                                                                                        value={city.id}
                                                                                    >
                                                                                        {city.name}
                                                                                        {/* {_translator("title", city, mlang)} */}
                                                                                    </option>
                                                                                ))
                                                                            : []}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group d-flex justify-content-between">
                                                                    <img style={{cursor: "pointer"}}
                                                                         onClick={this.getCaptcha} height={49}
                                                                         src={this.state?.imgData}
                                                                    />
                                                                    <span><i className="fa fa-refresh"
                                                                             aria-hidden="true"></i></span>
                                                                    <input onChange={(e) => {
                                                                        this.setState({captcha: e?.target?.value})
                                                                    }} placeholder="Natija" className="form-control border-primary qty-of-trainees"
                                                                           type="text"/>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>
                              {data.regBank} <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              className="form-control border-primary email "
                              name="BankName"
                              placeholder={data.regBankEnt}
                              onChange={(e) =>
                                this.updateState("BankName", e)
                              }
                              value={BankName}
                            />
                          </div>
                        </div> */}
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>
                              {data.regBankNum}
                              <i className="text-danger">*</i>
                            </label>
                            <input
                              type="number"
                              className="form-control border-primary email "
                              name="BankNumber"
                              placeholder={data.regBankNumEnt}
                              onChange={(e) =>
                                this.updateState("BankNumber", e)
                              }
                              value={BankNumber}
                            />
                          </div>
                        </div> */}

                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>
                              MFO <i className="text-danger">*</i>
                            </label>
                            <input
                              type="number"
                              className="form-control border-primary email "
                              name="MFO"
                              placeholder={data.regMfo}
                              onChange={(e) => this.updateState("MFO", e)}
                              value={MFO}
                              maxLength={5}
                            />
                          </div>
                        </div> */}
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>
                              OKED <i className="text-danger">*</i>
                            </label>
                            <input
                              type="number"
                              className="form-control border-primary email "
                              name="OKED"
                              placeholder={data.regOked}
                              onChange={(e) => this.updateState("OKED", e)}
                              value={OKED}
                            />
                          </div>
                        </div> */}
                                                            {/* <div className="col-12">
                          <div className="form-group">
                            <label htmlFor>
                              {data.regLocation}{" "}
                              <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              className="form-control border-primary "
                              name="address"
                              placeholder={data.regLocationEnt}
                              onChange={(e) => this.updateState("Adress", e)}
                              value={Adress}
                            />
                          </div>
                        </div> */}
                                                        </div>

                                                        <div className="row mt-3">
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="form-group">
                          <a
                            href="#"
                            className="btn btn-outline-danger btn-block"
                          >
                            <i
                              className="fa fa-download"
                              aria-hidden="true"
                            />{" "}
                            {data.regDownloadFile}
                          </a>
                        </div>
                      </div>
                     */}
                                                            <div
                                                                className="offset-md-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group text-center">
                                                                    <button
                                                                        style={{
                                                                            backgroundColor: "#00bbd1",
                                                                            borderColor: "#00bbd1",
                                                                            // backgroundColor: "#302b68",
                                                                            // borderColor: "#302b68",
                                                                        }}
                                                                        className="btn btn-primary btn-block"
                                                                        type="submit"
                                                                    >
                                                                        {data.regSubmit}
                                                                    </button>
                                                                    {/* {this.oneIdRender()} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="profile"
                                            role="tabpanel"
                                            aria-labelledby="profile-tab"
                                        >
                                            <form
                                                onSubmit={this.signUp}
                                                style={{
                                                    maxWidth: "700px",
                                                    margin: "auto",
                                                }}
                                            >
                                                <div className="card border-primary box-shadow mb-5">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regPhone}{" "}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    {/* <input
                              type="number"
                              className="form-control border-primary "
                              name="phone"
                              placeholder={data.regPhonePlace}
                              // maxLength={12}
                              onChange={(e) =>
                                this.updateState("indPhoneNumber", e)
                              }
                              value={indPhoneNumber}
                            /> */}
                                                                    <InputMask
                                                                        alwaysShowMask={true}
                                                                        // maskChar={null}
                                                                        mask="+\9\98999999999"
                                                                        className="form-control border-primary"
                                                                        // type="phone"
                                                                        // placeholder={data.phoneNumberEnter}
                                                                        onChange={(e) =>
                                                                            this.updateState("indPhoneNumber", e)
                                                                        }
                                                                        value={this.state.indPhoneNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>{data.regEmail}</label>
                            <input
                              type="email"
                              className="form-control border-primary email "
                              name="email"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                              placeholder={data.regEmailEnt}
                              onChange={(e) =>
                                this.updateState("indEmail", e)
                              }
                              value={indEmail}
                            />
                          </div>
                        </div> */}
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regPassword}{" "}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control border-primary "
                                                                        name="company_name"
                                                                        placeholder={data.regPasswordEnt}
                                                                        onChange={(e) =>
                                                                            this.updateState("indPassword", e)
                                                                        }
                                                                        value={indPassword}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor>
                              {data.regConfirmPass}
                              <i className="text-danger">*</i>
                            </label>
                            <input
                              type="password"
                              className="form-control border-primary "
                              name="company_name"
                              placeholder={data.regConfirmPass}
                              onChange={(e) =>
                                this.updateState("indConfirmPassword", e)
                              }
                              value={indConfirmPassword}
                            />
                          </div>
                        </div> */}
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regFullName}{" "}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-primary "
                                                                        defaultValue=""
                                                                        name="ConfirmPassword"
                                                                        placeholder={data.regFullNameEnt}
                                                                        onChange={(e) =>
                                                                            this.updateState("indFullName", e)
                                                                        }
                                                                        value={indFullName}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regPassport}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={9}
                                                                        className="form-control border-primary tin "
                                                                        name="TIN"
                                                                        placeholder={"AA0000000"}
                                                                        onChange={(e) =>
                                                                            this.updateState("indTIN", e)
                                                                        }
                                                                        value={indTIN}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regArea}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <select
                                                                        className="form-control border-primary qty-of-trainees "
                                                                        name="city"
                                                                        onChange={(e) =>
                                                                            this.updateState("indRegion", e)
                                                                        }
                                                                        value={indRegion}
                                                                    >
                                                                        <option disabled selected value="">
                                                                            {data.regAreaEnt}
                                                                        </option>
                                                                        {cityData.map((region) => (
                                                                            <option
                                                                                key={region.id + ""}
                                                                                value={region.id}
                                                                            >
                                                                                {region.name}
                                                                                {/* {_translator("title", region, mlang)} */}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor>
                                                                        {data.regCity}
                                                                        <i className="text-danger">*</i>
                                                                    </label>
                                                                    <select
                                                                        className="form-control border-primary qty-of-trainees "
                                                                        name="city"
                                                                        onChange={(e) =>
                                                                            this.updateState("indCity", e)
                                                                        }
                                                                        value={indCity}
                                                                    >
                                                                        <option disabled selected value="">
                                                                            {data.regCityEnt}
                                                                        </option>
                                                                        {indRegion
                                                                            ? cityData
                                                                                .filter(
                                                                                    (region) => region.id === indRegion
                                                                                )[0]
                                                                                .city.map((city) => (
                                                                                    <option
                                                                                        key={city.id + ""}
                                                                                        value={city.id}
                                                                                    >
                                                                                        {city.name}
                                                                                        {/* {_translator("title", city, mlang)} */}
                                                                                    </option>
                                                                                ))
                                                                            : []}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="form-group d-flex justify-content-between">
                                                                    <img style={{cursor: "pointer"}}
                                                                         onClick={this.getCaptcha} height={49}
                                                                         src={this.state?.imgData}
                                                                    />
                                                                    <span><i className="fa fa-refresh"
                                                                             aria-hidden="true"></i></span>
                                                                    <input onChange={(e) => {
                                                                        this.setState({captcha: e?.target?.value})
                                                                    }} placeholder="Natija" className="form-control border-primary qty-of-trainees"
                                                                           type="text"/>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12">
                          <div className="form-group">
                            <label htmlFor>
                              {data.regLivingLocation}{" "}
                              <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              className="form-control border-primary "
                              name="address"
                              placeholder={data.regLivingLocationEnt}
                              onChange={(e) =>
                                this.updateState("indAdress", e)
                              }
                              value={indAdress}
                            />
                          </div>
                        </div> */}
                                                        </div>
                                                        <div className="files-group"></div>
                                                        <div className="row mt-1">
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-3">
                                                                <div className="form-group text-center">
                                                                    <button
                                                                        style={{
                                                                            backgroundColor: "#00bbd1",
                                                                            borderColor: "#00bbd1",
                                                                            // backgroundColor: "#302b68",
                                                                            // borderColor: "#302b68",
                                                                        }}
                                                                        className="btn btn-primary btn-block"
                                                                        type="submit"
                                                                    >
                                                                        {data.regSubmit}
                                                                    </button>
                                                                    {/* {this.oneIdRender()} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{display: isReq ? "block" : "none"}}>
                            <div className="col-md-12">
                                <div className="reg">
                                    <div className="reg__box">
                                        <h2>{data.requiredCode}</h2>
                                        <span>{data.enterCode}</span>
                                        <div>
                                            <input
                                                className="inputt"
                                                type="text"
                                                placeholder={data.smsCode}
                                                onChange={(e) => this.updateState("reqCode", e)}
                                                value={reqCode}
                                            />

                                            <button
                                                type="button"
                                                value="submit"
                                                onClick={() => this.sentVerification()}
                                            >
                                                {data.smsConfirm}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </div>
        );
    }
}

const cityData = [
    {
        id: "8",
        name: "Qoraqalpog‘iston Respublikasi",
        city: [
            {
                id: "10",
                name: "Нукус шаҳар",
            },
            {
                id: "12",
                name: "Амударё тумани",
            },
            {
                id: "13",
                name: "Беруний тумани",
            },
            {
                id: "14",
                name: "Қонликўл тумани",
            },
            {
                id: "15",
                name: "Қораўзак тумани",
            },
            {
                id: "16",
                name: "Кегейли тумани",
            },
            {
                id: "17",
                name: "Қўнғирот тумани",
            },
            {
                id: "18",
                name: "Муйноқ тумани",
            },
            {
                id: "19",
                name: "Нукус тумани",
            },
            {
                id: "20",
                name: "Тахтакўприк тумани",
            },
            {
                id: "21",
                name: "Тўрткўл тумани",
            },
            {
                id: "22",
                name: "Хўжайли тумани",
            },
            {
                id: "23",
                name: "Чимбой тумани",
            },
            {
                id: "24",
                name: "Шўманай тумани",
            },
            {
                id: "25",
                name: "Элликқалъа тумани",
            },
        ],
    },
    {
        id: "9",
        name: "Buxoro viloyati",
        city: [
            {
                id: "26",
                name: "Бухоро шаҳар",
            },
            {
                id: "27",
                name: "Бухоро туман",
            },
            {
                id: "28",
                name: "Вобкент туман",
            },
            {
                id: "29",
                name: "Ғиждувон туман",
            },
            {
                id: "30",
                name: "Жондор туман",
            },
            {
                id: "32",
                name: "Когон туман",
            },
            {
                id: "33",
                name: "Олот туман",
            },
            {
                id: "34",
                name: "Пешку туман",
            },
            {
                id: "35",
                name: "Ромитан туман",
            },
            {
                id: "36",
                name: "Шофиркон туман",
            },
            {
                id: "37",
                name: "Қоракўл туман",
            },
            {
                id: "38",
                name: "Қоровулбозор туман",
            },
            {
                id: "210",
                name: "Когон шаҳар",
            },
        ],
    },
    {
        id: "10",
        name: "Samarqand viloyati",
        city: [
            {
                id: "39",
                name: "Самарқанд шаҳар",
            },
            {
                id: "40",
                name: "Оқдарё тумани",
            },
            {
                id: "41",
                name: "Булунғур тумани",
            },
            {
                id: "42",
                name: "Жомбой тумани",
            },
            {
                id: "43",
                name: "Каттақўрғон тумани",
            },
            {
                id: "44",
                name: "Каттақўрғон шаҳар",
            },
            {
                id: "45",
                name: "Қўшрабод тумани",
            },
            {
                id: "46",
                name: "Нарпай тумани",
            },
            {
                id: "47",
                name: "Нуробод тумани",
            },
            {
                id: "48",
                name: "Пайариқ тумани",
            },
            {
                id: "49",
                name: "Пастдарғом тумани",
            },
            {
                id: "50",
                name: "Пахтачи тумани",
            },
            {
                id: "51",
                name: "Самарқанд тумани",
            },
            {
                id: "53",
                name: "Тайлоқ тумани",
            },
            {
                id: "54",
                name: "Ургут тумани",
            },
            {
                id: "209",
                name: "Иштихон тумани",
            },
        ],
    },
    {
        id: "11",
        name: "Navoiy viloyati",
        city: [
            {
                id: "55",
                name: "Навоий шаҳар",
            },
            {
                id: "56",
                name: "Кармана тумани",
            },
            {
                id: "57",
                name: "Навбахор тумани",
            },
            {
                id: "58",
                name: "Нурота тумани",
            },
            {
                id: "59",
                name: "Хатирчи тумани",
            },
            {
                id: "60",
                name: "Қизилтепа тумани",
            },
            {
                id: "61",
                name: "Конимех тумани",
            },
            {
                id: "62",
                name: "Учқудуқ тумани",
            },
            {
                id: "63",
                name: "Зарафшон шаҳар",
            },
            {
                id: "64",
                name: "Томди тумани",
            },
        ],
    },
    {
        id: "12",
        name: "Andijon viloyati",
        city: [
            {
                id: "65",
                name: "Андижон шаҳар",
            },
            {
                id: "66",
                name: "Хонобод шаҳар",
            },
            {
                id: "67",
                name: "Андижон тумани",
            },
            {
                id: "68",
                name: "Асака тумани",
            },
            {
                id: "69",
                name: "Балиқчи тумани",
            },
            {
                id: "70",
                name: "Бўз тумани",
            },
            {
                id: "71",
                name: "Булоқбоши тумани",
            },
            {
                id: "72",
                name: "Жалолқудуқ тумани",
            },
            {
                id: "73",
                name: "Избоскан тумани",
            },
            {
                id: "74",
                name: "Улуғнор тумани",
            },
            {
                id: "75",
                name: "Қўрғонтепа тумани",
            },
            {
                id: "76",
                name: "Мархамат тумани",
            },
            {
                id: "77",
                name: "Олтинкўл тумани",
            },
            {
                id: "78",
                name: "Пахтаобод тумани",
            },
            {
                id: "79",
                name: "Ҳўжаобод тумани",
            },
            {
                id: "80",
                name: "Шахрихон тумани",
            },
        ],
    },
    {
        id: "13",
        name: "Farg‘ona viloyati",
        city: [
            {
                id: "82",
                name: "Марғилон шаҳар",
            },
            {
                id: "83",
                name: "Фарғона шаҳар",
            },
            {
                id: "84",
                name: "Қувасой шаҳар",
            },
            {
                id: "85",
                name: "Қўқон шаҳар",
            },
            {
                id: "86",
                name: "Боғдод тумани",
            },
            {
                id: "87",
                name: "Бешариқ тумани",
            },
            {
                id: "88",
                name: "Бувайда тумани",
            },
            {
                id: "89",
                name: "Данғара тумани",
            },
            {
                id: "90",
                name: "Ёзёвон тумани",
            },
            {
                id: "91",
                name: "Олтиариқ тумани",
            },
            {
                id: "92",
                name: "Қўштепа тумани",
            },
            {
                id: "93",
                name: "Риштон тумани",
            },
            {
                id: "94",
                name: "Сўх тумани",
            },
            {
                id: "95",
                name: "Тошлоқ тумани",
            },
            {
                id: "96",
                name: "Учкўприк тумани",
            },
            {
                id: "97",
                name: "Фарғона тумани",
            },
            {
                id: "98",
                name: "Фурқат тумани",
            },
            {
                id: "99",
                name: "Ўзбекистон тумани",
            },
            {
                id: "100",
                name: "Қува тумани",
            },
        ],
    },
    {
        id: "14",
        name: "Surxondaryo viloyati",
        city: [
            {
                id: "101",
                name: "Ангор тумани",
            },
            {
                id: "102",
                name: "Бойсун тумани",
            },
            {
                id: "103",
                name: "Денов тумани",
            },
            {
                id: "104",
                name: "Жарқўрғон тумани",
            },
            {
                id: "105",
                name: "Қизириқ тумани",
            },
            {
                id: "106",
                name: "Қумқўрғон тумани",
            },
            {
                id: "107",
                name: "Музработ тумани",
            },
            {
                id: "108",
                name: "Олтинсой тумани",
            },
            {
                id: "109",
                name: "Сариосиё тумани",
            },
            {
                id: "110",
                name: "Термиз тумани",
            },
            {
                id: "111",
                name: "Термиз шаҳар",
            },
            {
                id: "112",
                name: "Узун тумани",
            },
            {
                id: "113",
                name: "Шеробод тумани",
            },
            {
                id: "114",
                name: "Шўрчи тумани",
            },
        ],
    },
    {
        id: "15",
        name: "Sirdaryo viloyati",
        city: [
            {
                id: "115",
                name: "Оқолтин тумани",
            },
            {
                id: "116",
                name: "Боёвут тумани",
            },
            {
                id: "117",
                name: "Гулистон тумани",
            },
            {
                id: "118",
                name: "Мирзаобод тумани",
            },
            {
                id: "119",
                name: "Сайхунобод тумани",
            },
            {
                id: "120",
                name: "Сирдарё тумани",
            },
            {
                id: "121",
                name: "Сардоба тумани",
            },
            {
                id: "122",
                name: "Ховос тумани",
            },
            {
                id: "123",
                name: "Гулистон шаҳар",
            },
            {
                id: "124",
                name: "Ширин шаҳар",
            },
            {
                id: "126",
                name: "Янгиер шаҳар",
            },
        ],
    },
    {
        id: "16",
        name: "Xorazm viloyati",
        city: [
            {
                id: "127",
                name: "Урганч шаҳар",
            },
            {
                id: "128",
                name: "Боғот тумани",
            },
            {
                id: "129",
                name: "Гурлан тумани",
            },
            {
                id: "130",
                name: "Хозарасп тумани",
            },
            {
                id: "131",
                name: "Хива тумани",
            },
            {
                id: "132",
                name: "Хонқа тумани",
            },
            {
                id: "133",
                name: "Урганч тумани",
            },
            {
                id: "134",
                name: "Қўшкўприк тумани",
            },
            {
                id: "135",
                name: "Шовот тумани",
            },
            {
                id: "136",
                name: "Янгиариқ тумани",
            },
            {
                id: "137",
                name: "Янгибозор тумани",
            },
            {
                id: "217",
                name: "Хива шаҳар",
            },
        ],
    },
    {
        id: "17",
        name: "Toshkent viloyati",
        city: [
            {
                id: "138",
                name: "Ангрен шаҳар",
            },
            {
                id: "139",
                name: "Бекобод шаҳар",
            },
            {
                id: "140",
                name: "Олмалиқ шаҳар",
            },
            {
                id: "141",
                name: "Чирчиқ шаҳар",
            },
            {
                id: "142",
                name: "Бекобод тумани",
            },
            {
                id: "143",
                name: "Бўка тумани",
            },
            {
                id: "144",
                name: "Бўстонлиқ тумани",
            },
            {
                id: "145",
                name: "Қибрай тумани",
            },
            {
                id: "146",
                name: "Зангиота тумани",
            },
            {
                id: "148",
                name: "Қуйичирчиқ тумани",
            },
            {
                id: "149",
                name: "Оққўрғон тумани",
            },
            {
                id: "150",
                name: "Охонгарон тумани",
            },
            {
                id: "151",
                name: "Паркент тумани",
            },
            {
                id: "152",
                name: "Пскент тумани",
            },
            {
                id: "153",
                name: "Ўртачирчиқ тумани",
            },
            {
                id: "154",
                name: "Чиноз тумани",
            },
            {
                id: "155",
                name: "Юқоричирчиқ тумани",
            },
            {
                id: "156",
                name: "Янгийўл тумани",
            },
        ],
    },
    {
        id: "18",
        name: "Qashqadaryo viloyati",
        city: [
            {
                id: "158",
                name: "Қарши шаҳар",
            },
            {
                id: "159",
                name: "Ғузор тумани",
            },
            {
                id: "160",
                name: "Қарши тумани",
            },
            {
                id: "161",
                name: "Касби тумани",
            },
            {
                id: "162",
                name: "Косон тумани",
            },
            {
                id: "163",
                name: "Китоб тумани",
            },
            {
                id: "164",
                name: "Миришкор тумани",
            },
            {
                id: "165",
                name: "Муборак тумани",
            },
            {
                id: "166",
                name: "Нишон тумани",
            },
            {
                id: "167",
                name: "Чироқчи тумани",
            },
            {
                id: "168",
                name: "Шахрисабз тумани",
            },
            {
                id: "170",
                name: "Қамаши тумани",
            },
            {
                id: "171",
                name: "Дехқонобод тумани",
            },
            {
                id: "172",
                name: "Яккабоғ тумани",
            },
        ],
    },
    {
        id: "19",
        name: "Jizzax viloyati",
        city: [
            {
                id: "173",
                name: "Жиззах шаҳар",
            },
            {
                id: "174",
                name: "Бахмал тумани",
            },
            {
                id: "175",
                name: "Дўстлик тумани",
            },
            {
                id: "176",
                name: "Ғаллаорол тумани",
            },
            {
                id: "177",
                name: "Жиззах тумани",
            },
            {
                id: "178",
                name: "Зарбдор тумани",
            },
            {
                id: "179",
                name: "Зафаробод тумани",
            },
            {
                id: "180",
                name: "Зомин тумани",
            },
            {
                id: "181",
                name: "Пахтакор тумани",
            },
            {
                id: "182",
                name: "Мирзачўл тумани",
            },
            {
                id: "183",
                name: "Фориш тумани",
            },
            {
                id: "184",
                name: "Янгиобод тумани",
            },
            {
                id: "211",
                name: "Арнасой тумани",
            },
        ],
    },
    {
        id: "21",
        name: "Namangan viloyati",
        city: [
            {
                id: "185",
                name: "Наманган шаҳар",
            },
            {
                id: "186",
                name: "Мингбулоқ тумани",
            },
            {
                id: "189",
                name: "Поп тумани",
            },
            {
                id: "190",
                name: "Норин тумани",
            },
            {
                id: "191",
                name: "Тўрақўрғон тумани",
            },
            {
                id: "192",
                name: "Уйчи тумани",
            },
            {
                id: "194",
                name: "Чортоқ тумани",
            },
            {
                id: "195",
                name: "Чуст тумани",
            },
            {
                id: "196",
                name: "Янгиқўрғон тумани",
            },
            {
                id: "214",
                name: "Наманган тумани",
            },
            {
                id: "215",
                name: "Учқўрғон тумани",
            },
            {
                id: "216",
                name: "Косонсой тумани",
            },
        ],
    },
    {
        id: "22",
        name: "Toshkent shahar",
        city: [
            {
                id: "198",
                name: "Юнусобод тумани",
            },
            {
                id: "199",
                name: "Миробод тумани",
            },
            {
                id: "200",
                name: "Яккасарой тумани",
            },
            {
                id: "201",
                name: "Олмазор тумани",
            },
            {
                id: "202",
                name: "Бектемир тумани",
            },
            {
                id: "203",
                name: "Яшнобод тумани",
            },
            {
                id: "204",
                name: "Чилонзор тумани",
            },
            {
                id: "205",
                name: "Учтепа тумани",
            },
            {
                id: "207",
                name: "Мирзо Улуғбек тумани",
            },
            {
                id: "208",
                name: "Сергели тумани",
            },
            {
                id: "212",
                name: "Шайхонтохур тумани",
            },
        ],
    },
];

// const cityData = [
//   {
//     id: 1,
//     title: "Toshkent shahar",
//     title_ru: "город Ташкент",
//     title_en: "Tashkent city",
//   },
//   {
//     id: 2,
//     title: "Toshkent viloyati",
//     title_ru: "Ташкентская область",
//     title_en: "Tashkent region",
//   },

//   {
//     id: 3,
//     title: "Andijon viloyati",
//     title_ru: "Андижанская область",
//     title_en: "Andijan region",
//   },
//   {
//     id: 4,
//     title: "Buxoro viloyati",
//     title_ru: "Бухарская область",
//     title_en: "Bukhara region",
//   },
//   {
//     id: 5,
//     title: "Fargʻona viloyati",
//     title_ru: "Ферганская область",
//     title_en: "Fergana region",
//   },
//   {
//     id: 6,
//     title: "Jizzax viloyati",
//     title_ru: "Джизакская область",
//     title_en: "Jizzakh region",
//   },
//   {
//     id: 7,
//     title: "Xorazm viloyati",
//     title_ru: "Хорезмская область",
//     title_en: "Khorezm region",
//   },
//   {
//     id: 8,
//     title: "Namangan viloyati",
//     title_ru: "Наманганская область",
//     title_en: "Namangan region",
//   },
//   {
//     id: 9,
//     title: "Navoiy viloyati",
//     title_ru: "Навоийская область",
//     title_en: "Navoi region",
//   },
//   {
//     id: 10,
//     title: "Qashqadaryo viloyati",
//     title_ru: "Кашкадарьинская область",
//     title_en: "Qashqadaryo region",
//   },
//   {
//     id: 11,
//     title: "Qoraqalpogʻiston Respublikasi",
//     title_ru: "Республика Каракалпакстан",
//     title_en: "Karakalpakistan Republic",
//   },
//   {
//     id: 12,
//     title: "Samarqand viloyati",
//     title_ru: "Самаркандская область",
//     title_en: "Samarkand region",
//   },
//   {
//     id: 13,
//     title: "Sirdaryo viloyati",
//     title_ru: "Сырдарьинская область",
//     title_en: "Sirdaryo region",
//   },
//   {
//     id: 14,
//     title: "Surxondaryo viloyati",
//     title_ru: "Сурхандарьинская область",
//     title_en: "Surkhondaryo region",
//   },
// ];

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
