import {combineReducers} from 'redux';
import fetchDataReducer from './fetchDataReducer';
import languageReducer from './languageReducer';

const rootReducer = combineReducers({
  fetchDataReducer,
  languageReducer,
});

export default rootReducer;
