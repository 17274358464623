export const langData = {
  uz: {
    vacancies: "Bo'sh ish o'rinlari",
    vacancyText:
      "Standartlashtirish, sertifikatlashtirish va texnk jixatdan tartibga solish ilmiy-tadqiqot institutidagi bo'sh ish o'rinlari to'g'risida",
    departmentName: "Bo'lim nomi",
    positionname: "Lavozim nomi",
    vacancyAmount: "Bo'sh ish joylari soni",
    tr: "T/r",
  },
  ru: {
    vacancies: "Вакансии",
    vacancyText:
      "О вакансиях в НИИ стандартизации, сертификации и технического регулирования",
    departmentName: "Название отдела",
    positionname: "Название должности",
    vacancyAmount: "Кол-во вакансий",
    tr: "Т/р",
  },
  en: {
    vacancies: "Vacancies",
    vacancyText:
      "About vacancies in the Research Institute of Standardization, Certification and Technical Regulation",
    departmentName: "Department name",
    positionname: "Position name",
    vacancyAmount: "Number of vacancies",
    tr: "T/r",
  },
}
