import React, { Component } from "react";
import { _translator } from "../helpers";

export default class VacancyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }
  render() {
    const { data, mlang, id } = this.props;
    return (
      <React.Fragment>
        <tr>
          <th>{id}</th>
          <th>
            <button
              className="badge badge-sm badge-outline-info"
              onClick={() => this.setState({ toggle: !this.state.toggle })}
            >
              <i aria-hidden="true" className="far fa-folder-open" />
            </button>
          </th>
          <th>{_translator("department", data, mlang)}</th>
          <th>{_translator("position", data, mlang)}</th>
          <th>{data.qty}</th>
        </tr>
        <tr
          className="child-elements"
          style={{ display: this.state.toggle ? "table-row" : "none" }}
        >
          <th></th>
          <th colspan="5">
            <p
              dangerouslySetInnerHTML={{
                __html: _translator("more_info", data, mlang),
              }}
            ></p>
          </th>
        </tr>
      </React.Fragment>
    );
  }
}
