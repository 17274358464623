import React from "react";
import {connect} from "react-redux";
import {langData} from "../../index.i18n";

class StandartRegisterAndDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        fetch('https://admin.uzsti.uz/api/v1/laws').then(resp => resp.json())
            .then(resp => {
                if (resp?.success) {
                    this.setState({
                        data: resp?.data
                    })
                }
            })
    }

    render() {
        let data=langData[this.props.mlang];
        return (<div className="m-5">
            <h2 className="text-center">{data?.stadartTitle}</h2>
            <ul className="list-group list-group-flush">
                {this.state.data?.map(item=><li className="list-group-item">
                    <a className="text-info mx-5 px-5" target="_blank" href={`https://admin.uzsti.uz/storage/${item?.files[0]?.file}`}>
                        {this.props?.mlang==="uz"?item?.title:item?.[`title_${this.props?.mlang}`]}
                    </a>
                </li>)}
            </ul>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language
    };
}

export default connect(mapStateToProps)(StandartRegisterAndDelete);