// WARNING !!! - MOBILNIY VA DESKTOP GA 2 TA ODDELNIY NAVBAR QILINGAN
// SHUNGA O'ZGARTIRISH KIRITGANDA SHUNI HISOBINI OLISH KERAK

import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import Loader from "../components/Loader";
import SearchBarNavbar from "../components/SearchBars/SearchBarNavbar";
import {_translator, _translatorTitle} from "../helpers";
import {langData} from "../index.i18n";
import * as Actions from "../redux/actions";
import Toolbar from "./Toolbar";
// import TextMorquee from "../components/TextMorquee";
import Ticker from "react-ticker";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchToggle: false,
            aboutHide: false,
            newsHide: false,
            serviceHide: false,
            activityHide: false,
            comityHide: false,
            // isHover: false,
            // currentPath: this.props.match.
        };
    }

    updateState = () => {
        this.setState({
            searchToggle: !this.state.searchToggle,
        });
    };

    componentDidMount() {
        const inputCheck = document.querySelector("#main-menu-state");
        let linksa = document.querySelectorAll(".menua > li > a");
        let extraLink = document.querySelectorAll(".extra__link a");

        linksa.forEach(function (item) {
            item.addEventListener("click", function (e) {
                inputCheck.checked = false;
            });
        });

        extraLink.forEach(function (item) {
            item.addEventListener("click", function (e) {
                inputCheck.checked = false;
            });
        });
    }

    render() {
        const {
            NewsListData,
            mlang,
            LoadingMainScreen,
            ServicesData,
            MainSliderData,
            DocumentsCategoryData,
            CommitteesData,
            grayBtn,
        } = this.props;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

        // console.log(" navbar data: ", this.props.CartData.data)
        // console.log("CommitteesData: ", CommitteesData);
        // console.log("ServicesData: ", ServicesData);
        // console.log("InformationData: ", this.props.InformationData);
        // console.log("InstituteData: ", this.props.InstituteData);
        // console.log("MainSliderData: ", MainSliderData);
        // console.log("CommitteesData: ", DocumentsCategoryData);

        // console.log("DATA: ", NewsListData.data[0].title);
        // console.log("DATA: ", item);
        // let dataa = NewsListData.data;
        // console.log("DATAa ", dataa.title);

        // dataa.map((item) => (
        //   <li key={item.id + ""}>{console.log("DATA-B", item)}</li>
        // ));

        // let str = "";

        // for (let i = 0; i < 3; i++) {
        //   // console.log("MAL2 ", dataa[i].title);
        //   str = `${str}     ${dataa[i].title}`;
        // }

        // console.log("MAL ", str);

        return LoadingMainScreen ? (
            <Loader/>
        ) : (
            <>
                <Toolbar grayBtn={grayBtn}/>
                <div className="container" style={{marginTop: 5}}>
                    <Ticker height={24}>
                        {({index}) => (
                            <>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        marginRight: 20,
                                        fontStyle: "Italic",
                                    }}
                                >
                                    {data.testData}
                                </p>
                            </>
                        )}
                    </Ticker>

                    {/* <TextMorquee news="Xalqaro standartlar bilan uyg‘unlashgan davlat standartlari" /> */}
                    {/* {NewsListData.data.map((item) => ( */}
                    {/* <TextMorquee data={NewsListData.data[0]} /> <--- shunisi togrisi, tepadagi Text ni obtashalganda shuni kommentdan chiqarish kk*/}
                    {/* <TextMorquee data={NewsListData.data[1]} /> */}
                    {/* ))} */}
                </div>

                <header
                    className="header"
                    style={{position: "sticky", top: 40, backgroundColor: "#fff"}}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav className="main-nav">
                                    <input id="main-menu-state" type="checkbox"/>
                                    <label className="main-menu-btn" htmlFor="main-menu-state">
                                        <span className="main-menu-btn-icon"/> Toggle main menu
                                        visibility
                                    </label>
                                    <h2
                                        className="nav-brand mt-0"
                                        style={{
                                            paddingTop: 5,
                                        }}
                                    >
                                        <Link to="/" className="p-0">
                                            <img
                                                className="logo"
                                                style={{maxWidth: 121, marginTop: 8}}
                                                // src={require(`../assets/images/${
                                                //   mlang === "uz"
                                                //     ? "logo5.png"
                                                //     : mlang === "ru"
                                                //     ? "logo-rus.png"
                                                //     : "logo-eng.png"
                                                // }`)}
                                                src={process.env.PUBLIC_URL + `/assets/images/${
                                                    mlang === "uz"
                                                        ? "logo5.png"
                                                        : mlang === "ru"
                                                            ? "logo-rus.png"
                                                            : "logo-eng.png"
                                                }`}
                                                alt="logo"
                                                width={150}
                                            />
                                        </Link>
                                    </h2>
                                    <ul
                                        id="main-menu"
                                        className="sm sm-clean d-lg-none "
                                        style={{
                                            "maxHeight": "calc(100vh - 150px)",
                                            "overflowY": "auto",
                                        }}
                                    >
                                        {/* <li>
                      <Link to="/shop">{data.standart}</Link>
                    </li> */}
                                        <li className="extra__link">
                                            <Link to="/">{data.mainPage}</Link>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                onClick={() =>
                                                    this.setState({
                                                        aboutHide: !this.state.aboutHide,
                                                        newsHide: false,
                                                        serviceHide: false,
                                                        activityHide: false,
                                                        comityHide: false,
                                                    })
                                                }
                                                style={{cursor: "pointer"}}
                                            >
                                                {data.aboutInstitute}
                                            </a>
                                            <ul
                                                className="menua"
                                                // style={{ display: this.state.isHover ? "block" : "none" }}
                                                style={{
                                                    display: this.state.aboutHide ? "block" : "none",
                                                }}
                                            >
                                                {/* <li>
                          <Link to="/tasks">{data.taskAndFunctions}</Link>
                        </li> */}

                                                <li>
                                                    <Link to="/management">{data.management}</Link>
                                                </li>
                                                {/* <li>
                          <Link to="/structure">{data.structure}</Link>
                        </li> */}
                                                {/* <li>
                          <Link to="/council">{data.council}</Link>
                        </li> */}
                                                <li className="docs">
                          <span style={{cursor: "pointer"}}>
                            {data.regulations}
                          </span>
                                                    <ul className="menub">
                                                        {DocumentsCategoryData.map((item) => (
                                                            <li key={item.id + ""}>
                                                                <Link to={`/documents/${item.id}`}>
                                                                    {_translator("name", item, mlang)}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li className="docs">
                                                            <Link to="/staff">{data.sections}</Link>
                                                            {/* <ul
                                className="menub "
                                style={{
                                  "maxHeight": "calc(100vh - 310px)",
                                  "overflowY": "auto",
                                }}
                              >
                                {this.props.DepartmentsData.map((item) => (
                                  <li key={item.id + ""}>
                                    <Link to={`/staff/${item.id}`}>
                                      {_translator("title", item, mlang)}
                                    </Link>
                                  </li>
                                ))}
                              </ul> */}
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/vacancy">{data.vacancies}</Link>
                                                </li>
                                                {this.props.InstituteData.map((item) => (
                                                    <li key={item.id + ""}>
                                                        <Link to={`/institute/${item.id}`}>
                                                            {_translator("title", item, mlang)}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>

                                        <li>
                      <span
                          onClick={() =>
                              this.setState({
                                  newsHide: !this.state.newsHide,
                                  aboutHide: false,
                                  serviceHide: false,
                                  activityHide: false,
                                  comityHide: false,
                              })
                          }
                          style={{cursor: "pointer"}}
                      >
                        {data.news}
                      </span>
                                            <ul
                                                style={{
                                                    display: this.state.newsHide ? "block" : "none",
                                                    "maxHeight": "calc(100vh - 150px)",
                                                    "overflowY": "auto",
                                                }}
                                                className="menua"
                                            >
                                                <li>
                                                    <Link to="/news">{data.newsIn}</Link>
                                                </li>
                                                <li>
                                                    <Link to="/gallery">{data.photoGallery}</Link>
                                                </li>
                                                {this.props.InformationData.map((item) => (
                                                    <li key={item.id + ""}>
                                                        <Link to={`/information/${item.id}`}>
                                                            {_translator("title", item, mlang)}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        {/* <li>
                    <a style={{ cursor: "pointer" }}>{data.corporateProcurement}</a>
                    <ul className="menua">
                      {this.props.TendersData.map((item) => (
                        <li>
                          <Link to={`/tenders/${item.id}`}>
                            {_translator("title", item, mlang)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li> */}

                                        <li>
                                            <a
                                                onClick={() =>
                                                    this.setState({
                                                        aboutHide: false,
                                                        newsHide: false,
                                                        serviceHide: !this.state.serviceHide,
                                                        activityHide: false,
                                                        comityHide: false,
                                                    })
                                                }
                                                style={{cursor: "pointer"}}
                                            >
                                                {data.services}
                                            </a>
                                            <ul
                                                style={{
                                                    display: this.state.serviceHide ? "block" : "none",
                                                }}
                                                className="menua"
                                            >
                                                {ServicesData.map((item) => (
                                                    <li key={item.id.toString()}>
                                                        <Link to={`/services/${item.id}`}>
                                                            {_translator("title", item, mlang)}
                                                        </Link>
                                                    </li>
                                                ))}
                                                {/* <li>
                          <a target="_blank" href="https://e-study.uzsti.uz/">
                            {data.researchAndDevelopmentDepartment}
                          </a>
                        </li> */}
                                                {MainSliderData.map((item) => (
                                                    <li key={item.id.toString()}>
                                                        <a href={item.url}>
                                                            {_translator("title", item, mlang)}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() =>
                                                    this.setState({
                                                        aboutHide: false,
                                                        newsHide: false,
                                                        serviceHide: false,
                                                        activityHide: !this.state.activityHide,
                                                        comityHide: false,
                                                    })
                                                }
                                                style={{cursor: "pointer"}}
                                            >
                                                {data.deparments}
                                            </a>
                                            <ul
                                                className="menua "
                                                style={{
                                                    "maxHeight": "calc(100vh - 150px)",
                                                    "overflowY": "auto",
                                                    display: this.state.activityHide ? "block" : "none",
                                                }}
                                            >
                                                {this.props.DepartmentsData.map((item) => (
                                                    <li key={item.id + ""}>
                                                        <Link to={`/departments/${item.id}`}>
                                                            {_translator("title", item, mlang)}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>

                                        {/* <li>
                      <Link to="/documents">{data.regulations}</Link>
                    </li> */}

                                        <li className="extra__link">
                                            <Link to="/shop" onClick={this.shopLink}>
                                                {data.buyButton}
                                            </Link>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() =>
                                                    this.setState({
                                                        aboutHide: false,
                                                        newsHide: false,
                                                        serviceHide: false,
                                                        activityHide: false,
                                                        comityHide: !this.state.comityHide,
                                                    })
                                                }
                                                // onMouseEnter={() => this.setState({ navHide: false })}
                                                style={{cursor: "pointer"}}
                                            >
                                                {data.techCommities}
                                            </a>

                                            {/* <li className="docs">
                          <a style={{ cursor: "pointer" }}>Oziq-ovqat sanoati</a>
                          <ul className="menub">
                            <li>
                              <Link to="/committee">
                                {data.techCommityTeam}
                              </Link>
                            </li>
                            <li>
                              <Link to="/committee">
                                {data.techCommityActivity}
                              </Link>
                            </li>
                            <li>
                              <Link to="/committee">
                                {data.techCommityWorkPlan}
                              </Link>
                            </li>
                            <li>
                              <Link to="/committee">
                                {data.techCommityDocs}
                              </Link>
                            </li>
                            <li>
                              <Link to="/committee">
                                {data.techCommityContact}
                              </Link>
                            </li>
                          </ul>
                        </li> */}

                                            <ul
                                                className="menua"
                                                style={{
                                                    display: this.state.comityHide ? "block" : "none",
                                                    "maxHeight": "calc(100vh - 150px)",
                                                    "overflowY": "auto",
                                                    minWidth: "20vw",
                                                }}
                                            >
                                                {CommitteesData.map((item) => (
                                                    <li
                                                        className="docs"
                                                        key={item.id + ""}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <a
                                                            // onClick={() => this.setState({ navHide: true })}
                                                            href={`/committee/${item.id}`}
                                                            // to={{
                                                            //   pathname: `/committee/${item.id}`,
                                                            //   state: { applied: true },
                                                            // }}
                                                        >
                                                            {_translatorTitle("", item, mlang)}
                                                        </a>

                                                        <a
                                                            // onClick={() => this.setState({ navHide: true })}
                                                            href="/feedback"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i className="fa fa-comment"/>
                                                        </a>
                                                        {/* <ul className="menub">
                              <Link
                                onClick={() => this.setState({ nav: true })}
                                to="/feedback"
                              >
                                {data.leaveFeedback}
                              </Link>
                            </ul> */}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li className="extra__link">
                                            <Link to="/contacts">{data.contact}</Link>
                                        </li>

                                        <li>
                      <span
                          className="main-search"
                          onClick={() =>
                              this.setState({
                                  searchToggle: !this.state.searchToggle,
                              })
                          }
                      >
                        <i className="fa fa-search"/>
                      </span>
                                        </li>
                                    </ul>

                                    <ul
                                        id="main-menu"
                                        className={`sm sm-clean d-none d-lg-block ${
                                            mlang === "ru" ? " ru-font" : " "
                                        }`}
                                        // style={{
                                        //   "maxHeight": "calc(100vh - 150px)",
                                        //   "overflowY": "auto",
                                        // }}
                                    >
                                        {/* <li>
                      <Link to="/shop">{data.standart}</Link>
                    </li> */}
                                        <li className="extra__link">
                                            <Link to="/">{data.mainPage}</Link>
                                        </li>
                                        <li>
                                            <a style={{cursor: "pointer"}}>{data.aboutInstitute}</a>
                                            <ul
                                                className="menua"
                                                // style={{ display: this.state.isHover ? "block" : "none" }}
                                            >
                                                {/* <li>
                          <Link to="/tasks">{data.taskAndFunctions}</Link>
                        </li> */}

                                                <li>
                                                    <Link to="/management">{data.management}</Link>
                                                </li>
                                                <li>
                                                    <Link to="/employees">{data.employees}</Link>
                                                </li>
                                                {/* <li>
                          <Link to="/structure">{data.structure}</Link>
                        </li> */}
                                                <li className="docs">
                                                    <a style={{cursor: "pointer"}}>
                                                        {data.regulations}
                                                    </a>
                                                    <ul className="menub">
                                                        {DocumentsCategoryData.map((item) => (
                                                            <li key={item.id + ""}>
                                                                <a href={`/documents/${item.id}`}>
                                                                    {_translator("name", item, mlang)}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li className="docs">
                                                            <Link to="/staff">{data.sections}</Link>
                                                            {/* <ul
                                className="menub "
                                style={{
                                  "maxHeight": "calc(100vh - 310px)",
                                  "overflowY": "auto",
                                }}
                              >
                                {this.props.DepartmentsData.map((item) => (
                                  <li key={item.id + ""}>
                                    <Link to={`/staff/${item.id}`}>
                                      {_translator("title", item, mlang)}
                                    </Link>
                                  </li>
                                ))}
                              </ul> */}
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/vacancy">{data.vacancies}</Link>
                                                </li>
                                                {this.props.InstituteData.map((item) => (
                                                    <li key={item.id + ""}>
                                                        <Link to={`/institute/${item.id}`}>
                                                            {_translator("title", item, mlang)}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>

                                        <li>
                                            <a
                                                style={{
                                                    cursor: "pointer",
                                                    // whiteSpace: "pre-wrap",
                                                    // width: 120,
                                                }}
                                            >
                                                {data.news}
                                            </a>
                                            <ul
                                                className="menua "
                                                style={{
                                                    "maxHeight": "calc(100vh - 150px)",
                                                    "overflowY": "auto",
                                                }}
                                            >
                                                <li>
                                                    <Link to="/news">{data.newsIn}</Link>
                                                </li>
                                                <li>
                                                    <Link to="/gallery">{data.photoGallery}</Link>
                                                </li>
                                                <li>
                                                    <Link to="/budget">{data.budget}</Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/standartRegisterAndDelete">{data.standartRegisterAndDelete}</Link>
                                                </li>
                                                {/* {this.props.InformationData.map((item) => (
                          <li key={item.id + ""}>
                            <Link to={`/information/${item.id}`}>
                              {_translator("title", item, mlang)}
                            </Link>
                          </li>
                        ))} */}
                                            </ul>
                                        </li>
                                        {/* <li>
                    <a style={{ cursor: "pointer" }}>{data.corporateProcurement}</a>
                    <ul className="menua">
                      {this.props.TendersData.map((item) => (
                        <li>
                          <Link to={`/tenders/${item.id}`}>
                            {_translator("title", item, mlang)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li> */}

                                        <li>
                                            <a style={{cursor: "pointer"}}>{data.services}</a>
                                            <ul className="menua">
                                                {ServicesData.map((item) => (
                                                    <li key={item.id.toString()}>
                                                        <Link to={`/services/${item.id}`}>
                                                            {_translator("title", item, mlang)}
                                                        </Link>
                                                    </li>
                                                ))}
                                                {/* <li>
                          <a target="_blank" href="https://e-study.uzsti.uz/">
                            {data.researchAndDevelopmentDepartment}
                          </a>
                        </li> */}
                                                {MainSliderData.map((item) => (
                                                    <li key={item.id.toString()}>
                                                        <a href={item.url}>
                                                            {_translator("title", item, mlang)}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <a style={{cursor: "pointer"}}>{data.deparments}</a>
                                            <ul
                                                className="menua "
                                                style={{
                                                    "maxHeight": "calc(100vh - 150px)",
                                                    "overflowY": "auto",
                                                }}
                                            >
                                                {this.props.DepartmentsData.map((item) => (
                                                    <li key={item.id + ""}>
                                                        <Link to={`/departments/${item.id}`}>
                                                            {_translator("title", item, mlang)}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>

                                        {/* <li>
                      <Link to="/documents">{data.regulations}</Link>
                    </li> */}

                                        <li className="extra__link">
                                            <Link to="/shop" onClick={this.shopLink}>
                                                {data.buyButton}
                                            </Link>
                                        </li>
                                        <li>
                                            <a
                                                // onMouseEnter={() => this.setState({ navHide: false })}
                                                style={{cursor: "pointer"}}
                                            >
                                                {data.techCommities}
                                            </a>

                                            {/* <li className="docs">
                          <a style={{ cursor: "pointer" }}>Oziq-ovqat sanoati</a>
                          <ul className="menub">
                            <li>
                              <Link to="/committee">
                                {data.techCommityTeam}
                              </Link>
                            </li>
                            <li>
                              <Link to="/committee">
                                {data.techCommityActivity}
                              </Link>
                            </li>
                            <li>
                              <Link to="/committee">
                                {data.techCommityWorkPlan}
                              </Link>
                            </li>
                            <li>
                              <Link to="/committee">
                                {data.techCommityDocs}
                              </Link>
                            </li>
                            <li>
                              <Link to="/committee">
                                {data.techCommityContact}
                              </Link>
                            </li>
                          </ul>
                        </li> */}

                                            <ul
                                                className="menua"
                                                style={{
                                                    // display: this.state.navHide ? "none" : "inline-block",
                                                    "maxHeight": "calc(100vh - 150px)",
                                                    "overflowY": "auto",
                                                    minWidth: "20vw",
                                                }}
                                            >
                                                {CommitteesData.map((item) => (
                                                    <li
                                                        className="docs"
                                                        key={item.id + ""}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <a
                                                            // onClick={() => this.setState({ navHide: true })}
                                                            href={`/committee/${item.id}`}
                                                            // to={{
                                                            //   pathname: `/committee/${item.id}`,
                                                            //   state: { applied: true },
                                                            // }}
                                                        >
                                                            {_translatorTitle("", item, mlang)}
                                                        </a>

                                                        <a
                                                            // onClick={() => this.setState({ navHide: true })}
                                                            href="/feedback"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i className="fa fa-comment"/>
                                                        </a>
                                                        {/* <ul className="menub">
                              <Link
                                onClick={() => this.setState({ nav: true })}
                                to="/feedback"
                              >
                                {data.leaveFeedback}
                              </Link>
                            </ul> */}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/contacts">{data.contact}</Link>
                                        </li>

                                        <li>
                                            <a
                                                className="main-search"
                                                onClick={() =>
                                                    this.setState({
                                                        searchToggle: !this.state.searchToggle,
                                                    })
                                                }
                                            >
                                                <i className="fa fa-search"/>
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="header-right-link">
                                        <div
                                            className={`search-option ${
                                                this.state.searchToggle ? "search-active" : ""
                                            }`}
                                            // style={{ display: "none" }}
                                        >
                                            <SearchBarNavbar
                                                title={data.search}
                                                updateState={this.updateState}
                                            />
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        {/* <TextMorquee /> */}
                    </div>
                </header>
            </>
        );
    }
}

function mapStateToProps(state) {
    console.log(state.languageReducer.language)
    return {
        mlang: state.languageReducer.language,
        LoadingMainScreen: state.fetchDataReducer.LoadingMainScreen,
        ServicesData: state.fetchDataReducer.ServicesData,
        MainSliderData: state.fetchDataReducer.MainSliderData,
        DepartmentsData: state.fetchDataReducer.DepartmentsData,
        InformationData: state.fetchDataReducer.InformationData,
        CommitteesData: state.fetchDataReducer.CommitteesData,
        TendersData: state.fetchDataReducer.TendersData,
        NewsListData: state.fetchDataReducer.NewsListData,
        DocumentsCategoryData: state.fetchDataReducer.DocumentsCategoryData,
        InstituteData: state.fetchDataReducer.InstituteData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
