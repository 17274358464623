// import { data } from "jquery";
// import Cookies from "js-cookie";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions";
import fondData from "./data.json";
class Fond extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedType: 0,
    };
  }
  render() {
    console.log("lang: ", this.props.mlang);
    return (
      <div>
        <div className="container" style={{ textAlign: "center" }}>
          <div
            className="my-lg-5 fonds-title"
            style={{
              // backgroundColor: "rgb(73, 197, 235)",
              borderRadius: "10px",
              textAlign: "center",
              display: "inline-block",
              padding: "0 15px",
            }}
          ></div>
          <div className="row my-lg-5 justify-content-center fonds-content">
            {fondData &&
              fondData.map((fond, index) => (
                <div
                  key={fond.id}
                  className="card font-div position-relative hvr-grow  col-lg-2  col-md-3 col-sm-6 col-xs-12 my-3 mt-4 mx-2"
                  style={{
                    display: "block",
                    backgroundColor: "rgb(73, 197, 235)",
                    borderRadius: "30px",
                    textAlign: "center",
                    paddingTop: "20px",
                    cursor: "pointer",
                  }}
                >
                  {/* <Link
                    // to="shop"
                    to={{
                      pathname: "shop",
                      // search: "?sort=name",
                      // hash: "#the-hash",
                      stateLink: JSON.stringify(fond.id),
                    }}
                  > */}
                  <Link to={fond.id === 36 ? "shop2" : "fond2"}>
                    <img
                      className="img"
                      src={`${fond.img}`}
                      style={{
                        width: "120px  ",
                        height: "80px",
                        objectFit: "contain",
                      }}
                      alt="fond"
                    />
                    <span
                      style={{ color: fond.code === "71" ? "#000" : "red" }}
                    >
                      {fond.code}
                    </span>
                    <p
                      className="text-center align-items-center"
                      style={{
                        fontSize:
                          fond.title_en.split(" ").length < 4
                            ? "16px"
                            : fond.title_en.split(" ").length > 13
                            ? "10px"
                            : "12px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      {this.props.mlang === "uz"
                        ? fond.title_uz
                        : this.props.mlang === "ru"
                        ? fond.title_ru
                        : fond.title_en}
                    </p>
                    {/* </Link> */}
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Fond);
