import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as Actions from "../../redux/actions"
import { langData } from "./index.i18n"
import { Link } from "react-router-dom"

class PhonePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { mlang, AboutData } = this.props
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en

    return (
      <div className="col-md-8">
        <div className="pro__settings" style={{ width: "100%" }}>
          <h2 style={{ marginBottom: 20 }}>{data.mainTitle}</h2>
          <div className="pro__table">
            <table>
              <tbody>
                <tr>
                  <th>{data.departments}</th>
                  <th>{data.phoneNumbers}</th>
                </tr>
                <tr>
                  <th>{data.mainPhone}</th>
                  <th>{AboutData.main_phone}</th>
                </tr>
                <tr>
                  <th>{data.accountingPhone}</th>
                  <th>{AboutData.phone_accountant}</th>
                </tr>
                <tr>
                  <th>{data.receptionPhone}</th>
                  <th>{AboutData.phone_reception}</th>
                </tr>
                <tr>
                  <th>{data.standartizationPhone}</th>
                  <th>{AboutData.phone_standardization}</th>
                </tr>
                <tr>
                  <th>{data.certificationPhone}</th>
                  <th>{AboutData.phone_certification}</th>
                </tr>
                <tr>
                  <th>{data.metrologyPhone}</th>
                  <th>{AboutData.phone_metrology}</th>
                </tr>
                <tr>
                  <th>{data.developmentPhone}</th>
                  <th>{AboutData.phone_training_center}</th>
                </tr>
                <tr>
                  <th>{data.hotelPhone}</th>
                  <th>{AboutData.phone_hotel}</th>
                </tr>
                <tr>
                  <th>{data.journalPhone}</th>
                  <th>{AboutData.phone_standard_magazine}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    AboutData: state.fetchDataReducer.AboutData,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PhonePage)
