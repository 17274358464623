// BU CHECK-STANDART PAGE ISHLATILMAGAN, SHOP SCREEN PAGE DA ODDELNIY CHECK-STANDART BOR
//
//
//
//

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
// import CheckStandartCard from "../components/CheckStandartCard";
import { Table } from "react-bootstrap";

class CheckStandart extends Component {
  constructor() {
    super();
    this.state = {
      checkInput: "",
      // checkInputData: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // componentDidUpdate(prevProps) {
  //   // Популярный пример (не забудьте сравнить пропсы):
  //   if (this.props.userID !== prevProps.userID) {
  //     this.fetchCheckData(this.state.checkInput);
  //   }
  // }
  updateText = (path, e) => {
    this.setState({
      [path]: e.target.value,
    });
  };

  render() {
    const { mlang, CheckData } = this.props;
    const { checkInput } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    console.log(
      "CheckData",
      CheckData
      // && CheckData.standart.category.name
    );
    // console.log("CHEK INPUT", this.state.checkInput);

    return (
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-xl-12 col-lg-12 chiter">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2 style={{ textAlign: "left" }}>{data.checkTitle}</h2>
              <form
                onSubmit={(event) => {
                  this.props.fetchCheckData(checkInput.replace("#", ""));
                  event.preventDefault();
                  window.scrollTo(0, 0);
                }}
                className="form-inline"
                style={{ paddingTop: "7px" }}
              >
                <input
                  pattern=".{11,11}"
                  type="text"
                  className="form-control search "
                  title={data.checkPlaceholder}
                  placeholder={data.checkPlaceholder}
                  value={checkInput}
                  onChange={(e) => this.updateText("checkInput", e)}
                />
                {/* <button
                  className="btn btn-primary"
                  onClick={(event) => {
                    this.props.fetchCheckData(checkInput);
                    event.preventDefault();
                    // this.setState({
                    //   checkInputData: true,
                    // });
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  {data.checkSearch}
                </button> */}
                <input
                  value={data.checkSearch}
                  style={{ backgroundColor: "#00bbd1", color: "#fff" }}
                  className="btn  ml-3"
                  type="submit"
                />
              </form>
            </div>

            <div
              style={{ display: CheckData.success === true ? "block" : "none" }}
              className="table-responsive mt-5"
            >
              {/* <table className="table chiter-table text-center "> */}
              <Table striped bordered responsive>
                <tbody>
                  <tr>
                    <th>#</th>
                    <th>{data.checkCategory}</th>
                    <th>{data.checkName}</th>
                    <th>{data.checkPage}</th>
                    <th>{data.checkOrderer}</th>
                    <th style={{ minWidth: "95px" }}>{data.checkOrderDate}</th>
                  </tr>
                  <tr>
                    <th>1</th>
                    <th>
                      {CheckData.data &&
                        CheckData.data.standart &&
                        CheckData.data &&
                        CheckData.data.standart.category.name}
                    </th>
                    <th>
                      {CheckData.data &&
                        CheckData.data.standart &&
                        CheckData.data &&
                        CheckData.data.standart.name_standart}
                    </th>
                    <th>
                      {CheckData.data &&
                        CheckData.data.standart &&
                        CheckData.data &&
                        CheckData.data.standart.page_standart}
                    </th>
                    <th>
                      {CheckData.data &&
                        CheckData.data.order &&
                        CheckData.data &&
                        CheckData.data.order.user.username}
                    </th>
                    <th>
                      {CheckData.data &&
                        CheckData.data.order &&
                        CheckData.data &&
                        CheckData.data.order.created_at.slice(0, 10)}
                    </th>
                  </tr>
                </tbody>
              </Table>
            </div>
            <h3
              className="mt-5"
              style={{
                display:
                  CheckData.error === "Bu kod bo'yicha standart topilmadi"
                    ? "block"
                    : "none",
              }}
            >
              {data.checkNotFound}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    CheckData: state.fetchDataReducer.CheckData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckStandart);
