import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../constants";
import { _translator } from "../helpers";

function AdminCard({ mlang, data, setManagerData }) {
  return (
    <div className="team-block col-md-12 col-sm-12 col-xs-12">
      <Link to={`/management/${data.id}`}>
        <div className="inner-box">
          <div className="image">
            <img
              src={IMAGE_URL + data.avatar}
              className="attachment-globalindustry_265x400 size-globalindustry_265x400 wp-post-image"
              alt=""
            />
            <ul className="social-icon-one">
              <li>
                <a href="#" target="_blank">
                  <span className="fa fa fa-facebook" />
                </a>
              </li>
              <li>
                <a className="telegram" href="#" target="_blank">
                  <span className="fa fa fa-facebook">
                    <svg
                      enableBackground="new 0 0 24 24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m9.417 15.181-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931l3.622-16.972.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693 12.643-7.911c.595-.394 1.136-.176.691.218z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="lower-box">
            <h3>
              <a href="#">{_translator("username", data, mlang)}</a>
            </h3>
            <div className="designation">
              {_translator("position", data, mlang)}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    LeadersSliderData: state.fetchDataReducer.LeadersSliderData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCard);
