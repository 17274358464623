export const langData = {
    uz: {
        decision: "Qaror",
        type_1: "O'zgartirildi",
        type_2: "Amal qilish muddati uzaytirilgan",
        type_3: "Bekor qilingan",
        decision_date: "Qaror sanasi",
        subscribe: "Obuna bo'lish",
        notifications: "Bildirishnomalar",
        view_reestr: "Reestrni ko'rish",
        generate_contract_for_subscrbe: "Statndartlar reestringa obuna bo'lish uchun shartnoma rasmiylashtirish",
        status: "Holati",
        from_when: "Qachondan",
        to_when: "Qachongacha",
        standartRegisterAndDelete: "Standartlarni kuchga kiritish va bekor qilish qarorlari",
        stadartTitle: "Standartga doir normativ hujjatlarni amalga kiritish, bekor qilish va amal qilish muddatini to'xtatish to'g'isida axborot",
        successReg:
            "Siz ko'rsatgan telefon raqami muvaffaqiyatli ro'yxatdan o'tdi, shu raqamga tasdiqlash kodi yuborildi. Iltimos, telefon raqamingizni tasdiqlang",
        wrongCode:
            "Tasdiqlash kodini noto'g'ri kiritdingiz, qaytadan urunib ko'ring",
        oniReg: "ONE ID orqali ro'yxatdan o'tish",
        siteReg: "Sayt orqali ro'yxatdan o'tish",
        oidWelcome: "Xush kelibsiz",
        oidText:
            "Shaxsiy kabinetga kirish uchun iltimos Yagona identifikasiya tizimi (ONE ID) orqali avtorizasiyadan o‘ting",
        oidTextReg:
            "Shaxsiy kabinetga kirish uchun iltimos Yagona identifikasiya tizimi (ONE ID) orqali ro'yxatdan o‘ting",
        oidReg: "ONE ID orqali ro'yxatdan o‘tish",
        oidLogin: "ONE ID orqali kirish",
        siteLogin: "Sayt orqali kirish",

        msgReceived: "Xabaringiz qabul qilindi!",
        allFieldMustBeFilled: "Barcha maydonlarni to'ldirish shart!",
        leaveFeedback: "Fikr qoldirish",
        changesAccepted: "O'zgartirishlar qabul qilindi",
        userNotFound: "Bunday telefon raqam mavjud emas",
        passwordRestored: "Parol tiklandi",
        passwordRestoredText: "Vaqtinchalik parol jo'natildi",
        restoreText:
            "Telefon raqamingizga vaqtinchalik parol jo'natildi, Profil ga kirib parolni o'zgartirib qo'ying",
        enterRes: "Profilga kirish",
        noInformation: "Ma'lumot kiritilmagan",
        dataAdded: "Xabaringiz jo'natildi",
        expressOpinion: "Loyiha yuzasidan fikringizni bildiring",
        testData:
            "Standartlar institutining rasmiy web sayti test rejimida ishga tushirildi!!!",
        feedbackText:
            "Standartlashtirish sohasidagi loyihalarini muhokama qilish yuzasidan rejalashtirilgan tadbirlar taqvimi",

        // Check Standart Page
        checkTitle: "Standartni tekshirish",
        checkPlaceholder: "Standartni kodi 11 xonadan iborat bo'lishi kerak",
        checkSearch: "Qidirish",
        innTitle: "Tashkilot standarti",
        innPlaceholder: "STIR ni kirgizing",
        checkNotFound: "Bu kod bo'yicha standart topilmadi",
        checkCategory: "Standart kategoriyasi",
        checkName: "Standart nomi",
        checkPage: "Standart sahifasi",
        checkOrderer: "Buyurtmachi",
        checkOrderDate: "Buyurtma sanasi",

        // Check INN
        innCompanyName: "Kompaniya nomi",
        innProductName: "Mahsulot nomi",
        innBankName: "Bank nomi",
        innAccountNumber: "Hisob raqam",
        innTin: "STIR",
        innMfo: "MFO",
        innOked: "OKED",
        innAddress: "Manzil",
        innPhone: "Telefon",

        // NOTE Navbar
        mainPage: "Bosh sahifa",
        standart: "Standartlar",

        // NOTE Navbar - Tech Commity
        techCommity: "Texnik qoʼmita",
        techCommities: "Texnik qoʼmitalar",
        techCommityTeam: "Texnik qoʼmita tarkibi",
        techCommityActivity: "Texnik qoʼmita faoliyati",
        techCommityWorkPlan: "Texnik qo'mita ish-rejasi va hisobotlari",
        techCommityDocs: "Normativ-huquqiy hujjatlar",
        techCommityContact: "Texnik qoʼmita bilan aloqa bogʼlash",

        // NOTE Toolbar
        specialservices: "Maxsus imkoniyatlar",
        login: "Kirish",
        cabinet: "Kabinetga kirish",
        // register: "Registratsiya",
        loadingLang: "Iltimos kuting, fayl yuklanmoqda...",

        // NOTE Search Input
        searchTitle: "Izlash",
        searchAll: "Umumiy qidirish",
        searchReestr: "Reestr raqam bilan qidirish",
        searchType: "Standartlar turi",
        searchCategory: "Standartlar kategoriyasi",
        selectType: "Turni tanlang",
        search: "Qidiruv...",
        keyWords: "Kalit so'zlar",
        noNewsFound: "Yangilik topilmadi",

        // NOTE About Institute - Dropdown Menu
        aboutInstitute: "Institut haqida",
        taskAndFunctions: "Vazifa va Funksiyalar",
        regulations: "Hujjatlar",
        management: "Rahbariyat",
        employees:"Xodimlar",
        vacancies: "Bo'sh ish o'rinlari",
        council: "Ilmiy kengash",
        structure: "Institut tuzilmasi",
        help: "Yordam",
        sections: "Bo'limlar",

        // NOTE News - Dropdown Menu
        news: "Axborot xizmati",
        newsIn: "Yangiliklar",
        allNews: "Barcha yangiliklar",
        photoGallery: "Fotogalareya",
        budget: "Davlat byudjetining ijrosi",
        magazines: "Biznes Jurnallar",
        lastNews: "So'nggi yangiliklar",
        mostRead: "Eng ko'p o'qilganlar",
        latestNews: "So'nggi yangiliklar",
        usefulLinks: "Foydali havolalar",

        // NOTE Korporativ harid - Dropdown Menu
        corporateProcurement: "Korporativ harid",
        tenders: "Tenderlar",
        events: "Konkurslar",
        auctions: "Auksionlar",

        // NOTE Deparments - Dropdown Menu
        deparments: "Faoliyatlar",
        scientificResearchDepartmentOfConformityAssessment:
            "Standartlashtirish ilmiy-tadqiqot bo‘limi",
        departmentForCoordinationOfTechnicalCommitteesDevelopmentAndTranslationOfStandards:
            "Texnik qo‘mitalarni muvofiqlashtirish, standartlarni ishlab chiqib va tarjima bo‘limi",
        departmentOfStandardsExamination: "Standartlar ekspertizasi bo‘limi",
        departmentOfStandardsImplementationAndMarketingResearch:
            "Standartlarni sotish va marketing tadqiqotlari bo'limi",
        scientificResearchDepartmentOfStandardization:
            "Muvofiqlikni baholash ilmiy-tadqiqot bo‘limi",
        departmentOfCertification: "Sertifikatlashtirish bo'limi",
        departmentOfTestingAndNondestructiveControl:
            "Putur yetkazmasdan tekshirish va sinash bo'limi",
        accountingAndFinancialEconomicDepartment:
            "Buxgalteriya va moliya iqtisod bo‘limi",
        departmentOfInternationalRelations: "Xalqaro munosabatlar bo‘limi",
        aboutTheJournal: "“Standart” jurnali taxririyati va nashriyoti",
        departmentOfTheFarm: "Xo‘jalik bo‘limi",
        humanResourcesDepartment: "Kadrlar bo‘limi",
        hotel: "Mehmonxona",
        generalEatingObject: "Umumiy ovqatlanish ob'ekti",
        ictInitializeAndDevelopmentDepartment:
            "AKTni joriy qilish va rivojlantirish guruhi",
        ExecuteControlGroup: "Ijro nazorati guruhi",
        office: "Devonxona",
        researchAndDevelopmentDepartment:
            "Qayta tayyorlash va malaka oshirish markazi",
        pressSecretary: "Matbuot kotibi",
        chiefLegalAdviser: "Bosh yuriskonsult",

        // NOTE Services - Dropdown Menu
        services: "Xizmatlar",
        standartizationServises: "Standartlashtirish xizmati",
        metrologyServices: "Metrologiya xizmatlari",
        qualityAssuranceBody: "Sifat menejmentini sertifikatlash organi",
        personnelSertificationBody: "Xodimlarni sertifikatlash organi",
        aboutHotel: "Mehmonxona haqida",
        archive: "Arxiv",
        tegs: "Teglar",

        contact: "Bog'lanish",

        // NOTE What we do
        allServises: "Institutning barcha xizmatlari",
        // inStandatizationField: "Standartlashtirish sohasida",
        // inMetrologyField: "Metrologiya sohasida",
        // inCertificationField: "Sertifikatlashtirish sohasida",
        // inProfessyDevelopmentField: "Malaka oshirish sohasida",
        aboutInstituteText:
            "Standartlashtirish, sertifikatlashtirish va texnik jihatdan tartibga solish ilmiy-tadqiqot instituti(Standartlar instituti) “O’zstandart” agentligining standartlashtirish, sertifikatlashtirish va sifatni boshqarish sohasida faoliyat yurituvchi O’rta Osiyo mintaqasidagi yagona ilmiy-tadqiqot instituti va malaka oshirish markazi hisoblanadi.",
        details: "Batafsil ",
        viewAllProjects: "Hamma rasmlarni ko'rish",

        // NOTE Team
        director: "Direktor",
        manager: "Menejer",
        editor: "Muharrir",
        operator: "Operator",
        receptionDays: "Qabul kunlari: ",
        genDirector: "Bosh direktor",
        deputyDirector: "Direktor o'rinbosari",

        // NOTE Pricing
        InternetShop: "Standartlar elektron fondi",
        buy: "Harid qilish",
        buyButton: "Elektron fond",
        category: "Kategoriya",
        categories: "Kategoriyalar",
        selectCategory: "Kategoriyani tanlang",

        // NOTE Contact
        firstName: "Ism",
        secondName: "Familiya",
        email: "Elektron pochta",
        phoneNumber: "Telefon raqam",
        phoneNumberEnter: "+998XXXXXXXXX",
        messageText: "Sizning xatingiz",
        sendMessage: "Xabar yuborish",
        sendUsMessage: "Bizga xabar yuboring",
        submit: "Yuborish",
        // NOTE Contact Page
        contactUs: "Biz bilan bog'lanish",
        organizationName: "Tashkilotingiz nomi",
        subjectOfMessage: "Mavzu",
        youHaveQuestion: "Savolingiz bormi?",
        callUs: "Bizga qo'ng'iroq qiling",
        fax: "Faks",
        contactBy: "Biz bilan bog'laning",
        bus: "Avtobuslar",

        // Profile Settings
        birthDate: "Tug'ilgan sana",
        newPassword: "Yangi parol",
        enterNewPassword: "Yangi parolni kiriting",
        gender: "Jins",
        chooseGender: "Jinsni tanlang",
        male: "Erkak",
        female: "Ayol",
        deliveryAddress: "Yetkazib berish manzili",
        enterDeliveryAddress: "Yetkazib berish manzilini kiriting",
        mustFilled: "* to'ldirish majburiy joylar",
        update: "Yangilash",
        get_a_subscription_contract: "Obuna bo'lish uchun shartnoma olish",

        AdviceToSignUp:
            "Standartlashtirish sohasida malakangizni oshirishni xohlaysizmi?",
        SingUpNowButton: "Hoziroq kursga yoziling",

        // NOTE 404 Page
        sorryText: "Kechirasiz, siz so'ragan sahifa topilmadi",
        backButton: "Asosiyga qaytish",

        // NOTE Log In/Out
        oneid: "ONE ID orqali kirish",
        signInTitle: "Avtorizatsiya",
        signInLogin: "Login",
        signInPassword: "Parol",
        signInButton: "Kirish",
        cabSignIn: "Kabinetga kirish",
        notHaveAccount: "Hisobingiz yo'qmi?",
        signOutButton: "Chiqish",
        savePassword: "Saqlab qolish",
        forgotPassword: "Parolni unutdingizmi?",
        restorePassword: "Parolni tiklash",

        // NOTE Register,
        register: "Ro'yxatdan o'tish",
        registerText: "Akkount ochish",
        fullNameTitle: "Login",
        passwordTitle: "Parol",
        emailTitle: "Elektron pochta",
        rePasswordTitle: "Parolni tasdiqlang",
        haveAccount: "Siz ro'yxatdan o'tganmisiz?",
        requiredCode: "Smsdagi kodni kiriting",
        enterCode: "Kodni kiriting",
        smsCode: "Smsdagi kodni tasdiqlang",
        smsConfirm: "Tasdiqlash",

        // NOTE Alerts/Placeholders
        enterToProfile: "Profilingizga kiring",
        enterLogin: "Loginni kiriting",
        enterEmail: "Elektron pochtani kiriting",
        enterPhoneNumber: "Telefon raqam kiriting",
        enterPhoneAndPassword: "Telefon raqam va Parolingizni kiriting",
        enterEmailAndPassword: "Email va Parolingizni kiriting",
        enterPassword: "Parolni kiriting",
        passwordMatch: "Parol mos kelmadi, qaytadan urinib ko'ring",
        fillAllInputs: "Iltimos, * qatorlarni to'ldiring",
        passwordLength: "Parol 6 xonadan ko'p bo'lishi kerak",
        invalidMailAddress:
            "Noto'g'ri elektron pochta kiritdingiz, yana urunib ko'ring",
        invalidPassword:
            "Noto'g'ri parol kiritdingiz, parol 6 xonadan ko'p bo'lishi kerak",
        // invalidPassword:
        //   "Noto'g'ri parol kiritdingiz, parol 6 xonadan ko'p bo'lishi, kamida 1 ta katta harf, 1 ta kichik harf va 1 ta sondan iborat bo'lishi kerak",
        invalidPhone:
            "Noto'g'ri telefon raqam kiritdingiz, telefon raqam +998901234567 ko'rinishida bo'lishi kerak",
        invalidPhoneNum: "Bu telefon raqamdan foydalanilgan, boshqa raqam kiriting",
        invalidEmail: "Bu pochtadan foydalanilgan, boshqa pochta kiriting",
        invalidLogin:
            "Telefon raqam yoki parol noto'gri kiritildi, qaytadan urunib ko'ring",

        // NOTE Checkout Page
        personalDetails: "Shaxsiy malumotlar",
        FIO: "F.I.O",
        username: "Foydalanuvchi nomi",
        address: "Manzil",
        comment: "Sharxlar",
        reminderToOrder: "Buyurtmaga eslatmalar",
        reminderToOrderText:
            "Buyurtmangizga eslatmalar misol uchun yetkazib berishdagi shartlar va h.k",
        yourOrder: "Buyurtmangiz",
        paymentMethod: "To'lov usulini tanlang",
        continueButton: "Davom etish",

        // NOTE Orders Page
        invoices: "Hisob-faktura",
        profileSettings: "Profil sozlamalari",
        myOrders: "Mening buyurtmalarim",
        subscriptions: "Standartlar reestrini ko'rish",
        favorites: "Tanlanganlar",
        journalName: "Jurnal nomi",
        paidMethod: "To'lov turi",
        paidPrice: "To'lov narxi",
        paidDay: "To'lov sanasi",

        // NOTE Footer
        allRightsReserved: "Barcha huquqlar himoyalangan",
        termsOfUse: "Foydalanish shartlari",
        instituteStandard: "Standartlar instituti",
        locationOfInstitute: "100059 Toshkent shahar, Cho`pon ota ko`chasi 9V",
        footerAbout:
            "Standartlashtirish, sertifikatlashtirish va texnik jihatdan tartibga solish ilmiy-tadqiqot instituti(Standartlar instituti) “O’zstandart” agentligining standartlashtirish, sertifikatlashtirish va sifatni boshqarish sohasida faoliyat yurituvchi O’rta Osiyo mintaqasidagi yagona ilmiy-tadqiqot instituti va malaka oshirish markazi hisoblanadi.",

        // NOTE Sidebar Useful links
        standartUz:
            "O‘zbekiston standartlashtirish, metrologiya va sertifikatlashtirish agentligi",
        palataUz: "O‘zbekiston Respublikasi Oliy Majlisi Qonunchilik Palatasi",
        presidentUz: "O‘zbekiston Respublikasi Prezidentining rasmiy veb-sayti",
        lexUz:
            "O‘zbekiston Respublikasi Qonun hujjatlari ma’lumotlari milliy bazasi",
        ombudsmanUz:
            "O'zbekiston Respublikasi Oliy Majlisining Inson huquqlari bo'yicha Vakili (Ombudsman) rasmiy sayti",

        // NOTE Side Panel
        helpLine: "Ishonch telefonlari",
        myOpinion: "Milliy standart loyihasiga fikr bildirish",
        narrator: "Ekran suxandoni",
        questionAndAnswer: "Savollar va javoblar",
        stats: "Murojaatlar statistikasi",

        // NOTE Murojatlar va Takliflar
        feedbackTitle: "Murojaat va takliflar",

        // NOTE Documents
        docDownload: "Ko'chirib olish",
        docDate: "Sana",
        docNumber: "Raqami",
        docFilter: "Filtr",
        docShow: "Chiqarish",

        // NOTE Feedback Page
        feedText:
            "Standartlashtirish sohasidagi loyihalarini muhokama qilish yuzasidan rejalashtirilgan tadbirlar taqvimi",
        feedStats: "Statistika",
        feedAllStats: "Umumiy loyihalar bo'yicha statistika",
        feedAllProjects: "Barcha loyihalar",
        feedPrepare: "Muhokamaga tayyorlanayotgan",
        feedProcess: "Muhokama jarayonida",
        feedDoing: "Muhokamasi yakunlanayotgan",
        feedDone: "Muhokamasi yakunlangan",
        feedRepeat: "Loyiha qayta ishlanmoqda",
        feedConfirmedDocs: "Tasdiqlangan hujjatlar",
        feedComments: "Izohlar",
        feedNewEdition: "Yangi tahrir",
        feedQuestionary: "So`rovnoma",
        feedAll: "Umumiy soni",
        feedSeen: "Ko'rib chiqilgan",
        feedReceived: "Qabul qilingan",
        feedRejected: "Qabul qilinmagan",
        feedProject: "Standart loyihasi",
        feedExpNote: "Tushuntirish xati",

        // NOTE CART Page
        basketTitle: "Savatcha",
        basketProductName: "Mahsulot nomi",
        basketBackButton: "Do'konga qaytish",
        basketOrderButton: "Buyurtma berish",
        basketTotalPrice: "Umumiy",
        // basketModalTitle:
        //   "Sizning buyurtmangiz qabul qilindi. Iltimos shartnomani ko'chirib oling va to'lovni amalga oshiring",
        basketModalTitle:
            "Buyurtma bergandan so'ng shartnomani mening buyurtmalarim sahifasidan ko'chirib oling va to'lovni amalga oshiring",
        basketModalCancel: "Bekor qilish",
        basketModalBuy: "Sotib olish",

        noOpinon: "Hozircha izohlar yo'q",

        // NOTE Reg Page
        // Jismoniy shaxslar uchun
        regPhys: "Jismoniy shaxslar uchun",
        regFullName: "To'liq FISH",
        regFullNameEnt: "To'liq FISH ni kiriting...",
        regName: "Ism",
        regNameEnt: "Ismni kiriting...",
        regSurname: "Familiya",
        regSurnameEnt: "Familiyani kiriting...",
        regFatherName: "Otasining ismi (Sharfi) ",
        regFatherNameEnt: "Otasining ismi (Sharfi)ni kiriting... ",
        regInn: "INN raqami",
        regInnEnt: "INN raqamini kiriting...",
        regPassport: "Pasport seriyasi va raqami",
        regEmail: "Elektron pochta",
        regEmailEnt: "Email kiriting...",
        regPhone: "Telefon raqamingiz",
        regPhonePlace: "Ariza holati yuzasidan SMS xabar yuboriladi",
        regLivingLocation: "Yashash manzili",
        regLivingLocationEnt: "Yashash manzili kiriting...",
        regUploadFile: "Hujjatlarni yuklash pdf shaklda",
        regUploadFileBtn: "Fayllarni yuklash",
        regDownloadFile: "Hisob shartnomani yuklab olish",
        regSubmit: "Yuborish",
        regClose: "Yopish",
        regNotify: "ESLATMA",
        regModalText:
            "O‘zbekiston Respublikasi Prezidentining 2021 yil 02 iyundagi “Texnik jihatdan tartibga solish sohasida davlat boshqaruvini tubdan takomillashtirish to‘g‘risida”gi PF-6240-sonli Farmonining 5-bandiga asosan, tashkilot standartlari toifasiga kiruvchi standartlashtirishga doir normativ hujjatlar davlat ro‘yxatidan o‘tkazish bekor qilindi. Shuningdek, mazkur turdagi normativ hujjatlar ishlab chiqaruvchi korxonalar tomonidan ishlab chiqiladi, manfaatdor vazirlik va idoralar bilan kelishiladi va korxona tomonidan tasdiqlanadi. Bunda, tashkilot standartlarida belgilanadigan ko‘rsatkichlar (talablar) O‘zbekiston Respublikasida amalda bo‘lgan tegishli mahsulotlarga oid standartlar talablaridan past bo‘lgan hollarda, ushbu tashkilot standartlariga asosan mahsulot muvofiqligini tasdiqlashga yo‘l qo‘yilmasligi belgilab qo‘yilgan.",
        regWarn:
            "Iltimos  ESLATMA ni o'qing va belgilang. Belgilaganingizdan so'ng YUBORISH tugmasi ishlaydi!",
        // Yuridik shahslar uchun
        regJury: "Yuridik shaxslar uchun",
        regOrgName: "Tashkilot nomi",
        regOrgNameEnt: "Tashkilot nomini kiriting...",
        regLiablePerson: "Vakolatli shaxs FISh",
        regLiablePersonEnt: "Vakolatli shaxs FISh kiriting...",
        regStudents: "O'qishga talabgorlar soni",
        regStudentsEnt: "O'qishga talabgorlar sonini kiriting...",
        regLocation: "Yuridik manzil",
        regLocationEnt: "Yuridik manzilni kiriting...",
        regPassword: "Parol",
        regPasswordEnt: "Parolni kiriting...",
        regConfirmPass: "Parolni tasdiqlang",
        regArea: "Hudud",
        regAreaEnt: "Hududni tanlang",
        regCity: "Shahar",
        regCityEnt: "Shaharni tanlang",
        regBank: "Bank nomi",
        regBankEnt: "Bank nomini kiriting...",
        regBankNum: "Hisob raqami",
        regBankNumEnt: "Hisob raqamini kiriting...",
        regMfo: "MFO ni kiriting...",
        regOked: "OKED ni kiriting...",
        organProductName: "Maxsulot nomi",
        organProductNamePlaceholder: "Maxsulot nomini kiriting",
        oneIdReg: "ONE ID orqali ro'yxatdan o'tish",

        // NOTE Orders
        orderSum: "Umumiy narxi",
        orderCount: "Umumiy soni",
        orderStatus: "Statusi",
        orderTitle: "Nomi",
        orderPrice: "Narxi",
        orderPage: "Sahifalar soni",
        orderPaid: "To'lov amalga oshirilgan",
        orderNotPaid: "To'lov amalga oshirilmagan",
        orderCont: "Shartnomani yuklab olish",
        next: "Keyingi",
        previous: "Oldingi",
    },
    ru: {
        subscribe: "Подписаться",
        type_1: "Измененный",
        type_2: "Срок действия продлен",
        type_3: "Отменено",
        decision: "Решение",
        employees:"Сотрудники",
        decision_date: "Дата решения",
        standartRegisterAndDelete: "Решения о вступлении в силу и отмене стандартов",
        stadartTitle: "Информация о введении в действие, отмене и прекращении действия нормативных документов стандарта",
        notifications: "Уведомления",
        view_reestr: "Посмотреть реестр",
        generate_contract_for_subscrbe: "Составление договора на подписку на реестр граждан",
        // RU ------------------------------- RU ---------------- RU ------------------ RU ------------------------ RU --------------
        successReg:
            "Успешно зарегистрированы на указанный вами номер телефона отправлен код подтвердление. Пожалуйста подтвердите номер телефона",
        from_when: "С каких пор",
        to_when: "До тех пор",
        wrongCode: "Вы неправильно ввели код подтверждения, попробуйте еще раз",
        oniReg: "Регистрация через ONE ID",
        siteReg: "Регистрация через сайт",
        oidWelcome: "Добро пожаловать",
        status: "Положение дел",
        oidTextReg:
            "Для входа в личный кабинет зарегистрируйтесь, используя ONE ID",
        oidReg: "Зарегистрируйтесь с ONE ID",
        oidText:
            "Для входа в личный кабинет, пожалуйста, войдите в Единую систему идентификации (ONE ID).",
        oidLogin: "Войти через ONE ID",
        siteLogin: "Войти через сайт",
        get_a_subscription_contract: "Получить договор подписки",

        msgReceived: "Ваше сообщение было получено!",
        allFieldMustBeFilled: "Все поля должны быть заполнены!",
        leaveFeedback: "Оставьте отзыв",
        changesAccepted: "Поправки приняты",
        userNotFound: "Нет такого номера телефона",
        passwordRestored: "Пароль восстановлен",
        passwordRestoredText: "Временный пароль отправлен",
        restoreText:
            "На ваш номер телефона отправлен временный пароль, войдите в Профиль и измените пароль",
        enterRes: "Войти в профиль",
        noInformation: "Информация не введена",
        dataAdded: "Ваше сообщение было отправлено",
        expressOpinion: "Выскажите свое мнение о проекте",
        testData:
            "Официальный сайт института стандартов запущен в тестовом режиме !!!",
        feedbackText:
            "График запланированных мероприятий по обсуждению проектов в области стандартизации",

        // Check Standart Page
        checkTitle: "Проверить стандарт",
        checkPlaceholder: "Код Стандарта должен состоять из 11 знаков",
        checkSearch: "Поиск",
        innTitle: "Стандарт организации",
        innPlaceholder: "Введите ИНН",
        checkNotFound: "Стандарт для этого кода не найден",
        checkCategory: "Категория стандарта",
        checkName: "Названия стандарта",
        checkPage: "Страница стандарта",
        checkOrderer: "Заказчик",
        checkOrderDate: "Дата заказа",

        // Check INN
        innCompanyName: "Название компании",
        innProductName: "Наименование товара",
        innBankName: "Название банка",
        innAccountNumber: "Номер счета",
        innTin: "ИНН",
        innMfo: "МФО",
        innOked: "ОКЕД",
        innAddress: "Адрес",
        innPhone: "Телефон",

        // NOTE Navbar
        mainPage: "Главная",
        standart: "Стандарты",

        // NOTE Navbar - Tech Commity
        techCommity: "Технический комитет",
        techCommities: "Технические комитеты",
        techCommityTeam: "Состав Технического комитета",
        techCommityActivity: "Деятельность Технического комитета",
        techCommityWorkPlan: "План работы и отчеты Технического комитета",
        techCommityDocs: "Нормативно-правовые документы",
        techCommityContact: "Связаться с техническим комитетом",

        // NOTE Toolbar
        specialservices: "Специальные объекты",
        login: "Авторизоваться",
        register: "Регистрация",
        cabinet: "Кабинет",
        loadingLang: "Подождите, файл загружается...",

        // NOTE Search Input
        searchTitle: "Поиск",
        searchAll: "Общий поиск",
        searchReestr: "Поиск по номеру реестра",
        searchType: "Тип стандартов",
        searchCategory: "Категория стандартов",
        selectType: "Выберите типа",
        search: "Поиск...",
        keyWords: "Ключевые слова",
        noNewsFound: "Новостей не найдено",

        // NOTE About Institute - Dropdown Menu
        aboutInstitute: "Об институте",
        taskAndFunctions: "Задачи и функции",
        regulations: "Документы",
        management: "Руководство",
        vacancies: "Вакансии",
        council: "Ученый секретарь",
        structure: "Структура института",
        help: "Помощь",
        sections: "Отделы",

        // NOTE News - Dropdown Menu
        news: "Информационная служба",
        newsIn: "Новости",
        allNews: "Все новости",
        photoGallery: "Фотогалерея",
        budget: "Исполнение государственного бюджета",
        magazines: "Наши журналы",
        lastNews: "Последняя новости",
        mostRead: "Популярное",
        latestNews: "Последние новости",
        usefulLinks: "Полезные ссылки",

        // NOTE Korporativ harid - Dropdown Menu
        corporateProcurement: "Корпоративные закупки",
        tenders: "Тендеры",
        events: "Конкурсы",
        auctions: "Аукционы",

        // NOTE Deparments - Dropdown Menu
        deparments: "Деятельность",
        scientificResearchDepartmentOfConformityAssessment:
            "Научно-исследовательский отдел оценки соответствия",
        departmentForCoordinationOfTechnicalCommitteesDevelopmentAndTranslationOfStandards:
            "Отдел координации технических комитетов, разработки и перевода стандартов",
        departmentOfStandardsExamination: "Отдел экспертизы стандартов",
        departmentOfStandardsImplementationAndMarketingResearch:
            "Отдел реализации стандартов и маркетинговых исследований",
        scientificResearchDepartmentOfStandardization:
            "Научно-исследовательский отдел стандартизации",
        departmentOfCertification: "Отдел сертификации",
        departmentOfTestingAndNondestructiveControl:
            "Отдел испытаний и неразрушающего контроля",
        accountingAndFinancialEconomicDepartment:
            "Бухгалтерия и финансово-экономический отдел",
        departmentOfInternationalRelations: "Отдел международных отношений",
        aboutTheJournal: "О журнале",
        departmentOfTheFarm: "Хозяйственный отдел",
        humanResourcesDepartment: "Отдел кадров",
        generalEatingObject: "Объект общего питание",
        ictInitializeAndDevelopmentDepartment: "Группа внедрения и развития ИКТ",
        ExecuteControlGroup: "Группа исполнительного контроля",
        office: "Офис",
        researchAndDevelopmentDepartment:
            "Центр переподготовки и повышения квалификации",
        pressSecretary: "Пресс-секретарь",
        chiefLegalAdviser: "Главный юрисконсульт",

        // NOTE Services - Dropdown Menu
        services: "Услуги",
        standartizationServises: "Услуги по стандартизации",
        metrologyServices: "Услуги по метрологии",
        qualityAssuranceBody: "Орган сертификации качества",
        personnelSertificationBody: "Орган по сертификации персонала",

        contact: "Контакты",

        // NOTE Hotel
        hotel: "Гостиница",
        aboutHotel: "О гостинице",
        archive: "Архив",
        tegs: "Теги",

        // NOTE What we do
        allServises: "Все услуги института",
        // inStandatizationField: "В области стандартизации",
        // inMetrologyField: "В области метрологии",
        // inCertificationField: "В области сертификации",
        // inProfessyDevelopmentField: "В сфере повышения квалификации",
        aboutInstituteText:
            "Научно-исследовательский институт стандартизации, сертификации и технического регулирования (Институт стандартов) - единственный научно-исследовательский институт и учебный центр в Центральной Азии в области стандартизации, сертификации и менеджмента качества Агентства «Узстандарт».",
        details: "Больше ",
        viewAllProjects: "Посмотреть всю галерею",

        // NOTE Team
        director: "Директор",
        manager: "Менеджер",
        editor: "Редактор",
        operator: "Оператор",
        receptionDays: "Приемные дни: ",
        genDirector: "Главный управляющий",
        deputyDirector: "Заместитель директора",

        // NOTE Pricing
        InternetShop: "Электронный-фонд стандартов",
        buy: "Купить",
        buyButton: "Электронный-фонд",
        category: "Категория",
        categories: "Категории",
        selectCategory: "Выберите категорию",

        // NOTE Contact
        firstName: "Имя",
        secondName: "Фамилия",
        email: "Электронная почта",
        phoneNumberEnter: "+998XXXXXXXXX",
        phoneNumber: "Номер телефона",
        messageText: "Напишите сообщение",
        sendMessage: "Отправить сообщение",
        sendUsMessage: "Напишите нам сообщение",
        submit: "Отправить",
        // NOTE Contact Page
        contactUs: "Связаться с нами",
        organizationName: "Название вашей организации",
        subjectOfMessage: "Тема",
        youHaveQuestion: "Есть вопрос?",
        callUs: "Позвоните нам",
        fax: "Факс",
        contactBy: "Связаться",
        bus: "Автобусы",

        // Profile Settings
        birthDate: "Дата рождение",
        newPassword: "Новый пароль",
        enterNewPassword: "Введите новый пароль...",
        gender: "Пол",
        chooseGender: "Выберите пол",
        male: "Мужчина",
        female: "Женщина",
        deliveryAddress: "Адрес доставки",
        enterDeliveryAddress: "Введите адрес доставки",
        mustFilled: "* обязательные для заполнения",
        update: "Обновить",

        AdviceToSignUp: "Вы хотите улучшить свои навыки в области стандартизации?",
        SingUpNowButton: "Запишитесь на курс сейчас",

        // NOTE 404 Page
        sorryText: "Извините, требуемая страница не найдена",
        backButton: "Вернуться на главную",

        // NOTE Log In/Out
        oneid: "Войти через ONE ID",
        enterToProfile: "Войдите в свою учетную запись",
        signInTitle: "Авторизоваться",
        signInLogin: "Логин",
        signInPassword: "Пароль",
        signInButton: "Войти",
        cabSignIn: "Войти в кабинет",
        notHaveAccount: "У вас ещё нет аккаунта?",
        signOutButton: "Выйти",
        savePassword: "Сохранить пароль",
        forgotPassword: "Забыл пароль?",
        restorePassword: "Восстановить пароль",

        // NOTE Register
        // register: "Регистрироваться",
        registerText: "Зарегистрировать аккаунт",
        fullNameTitle: "Логин",
        passwordTitle: "Пароль",
        emailTitle: "Электронная почта",
        rePasswordTitle: "Подтвердите Пароль",
        haveAccount: "Уже есть аккаунт?",
        requiredCode: "Введите код из смс",
        enterCode: "Введите код",
        smsCode: "Подтвердите код из смс",
        smsConfirm: "Подтвердить",

        // NOTE Alerts
        enterLogin: "Введите логин",
        enterEmail: "Введите ваш Email",
        enterPhoneNumber: "Введите номер телефона",
        enterPhoneAndPassword: "Введите номер телефона и пароль",
        enterEmailAndPassword: "Введите адрес электронной почты и пароль",
        enterPassword: "Введите пароль",
        passwordMatch: "Введённые пароли не совпадают. Попробуйте ещё раз",
        fillAllInputs: "Заполните, пожалуйста, все поля с *",
        passwordLength: "Пароль должен содержать не менее 6 символов",
        invalidMailAddress: "Неправильная электронная почта, попробуйте снова",
        invalidPassword:
            "Вы ввели неправильный пароль, пароль должен содержать более 6 цифр",
        // invalidPassword:
        //   "Вы ввели неправильный пароль, пароль должен содержать более 6 цифр, минимум 1 заглавную букву, 1 строчную букву и 1 цифру",
        invalidPhone:
            "Вы ввели неправильный номер телефона, номер телефона должен быть в виде +998901234567",
        invalidPhoneNum:
            "Этот номер телефона уже использовался, введите другой номер",
        invalidEmail:
            "Этот адрес электронной почты использовался, введите другой адрес электронной почты",
        invalidLogin:
            "Номер телефона или пароль введены неправильно, попробуйте еще раз",

        // NOTE Checkout Page
        personalDetails: "Персональные данные",
        FIO: "ФИО",
        username: "Имя пользователя",
        address: "Адрес",
        comment: "Комментарии",
        reminderToOrder: "Примечания к заказу",
        reminderToOrderText:
            "Примечания к вашему заказу, например особые примечания к доставке",
        yourOrder: "Ваш заказ",
        paymentMethod: "Выберите способ оплаты",
        continueButton: "Продолжить",

        // NOTE Orders Page
        invoices: "Счет-фактура",
        profileSettings: "Настройки профиля",
        myOrders: "Мои заказы",
        subscriptions: "Посмотреть реестр стандартов",
        favorites: "Избранные",
        journalName: "Имя журнала",
        paidMethod: "Метод оплаты",
        paidPrice: "Цена оплаты",
        paidDay: "Число оплаты",

        // NOTE Footer
        allRightsReserved: "Все права защищены",
        termsOfUse: "Условия пользования",
        instituteStandard: "Институт Стандартов",
        locationOfInstitute: "100059 город Ташкент, улица Чупон ота 9В",
        footerAbout:
            "Научно-исследовательский институт стандартизации, сертификации и технического регулирования (Институт стандартов) - единственный научно-исследовательский институт и учебный центр в Центральной Азии в области стандартизации, сертификации и менеджмента качества Агентства «Узстандарт».",

        // NOTE Sidebar Useful links
        standartUz: "Узбекское агентство стандартизации, метрологии и сертификации",
        palataUz: "Законодательная палата Олий Мажлиса Республики Узбекистан",
        presidentUz: "Официальный веб-сайт Президента Республики Узбекистан",
        lexUz: "Национальная база данных законодательства Республики Узбекистан",
        ombudsmanUz:
            "Официальный сайт Уполномоченного Олий Мажлиса Республики Узбекистан по правам человека (Омбудсман)",

        // NOTE Side Panel
        helpLine: "Телефоны доверия",
        myOpinion: "Комментируя проект национального стандарта",
        narrator: "Экранный диктор",
        questionAndAnswer: "Вопросы и ответы",
        stats: "Статистика обращении",

        // NOTE Murojatlar va Takliflar
        feedbackTitle: "Обращения и предложения",

        // NOTE Documents
        docDownload: "Скачать",
        docDate: "Дата",
        docNumber: "Номер",
        docFilter: "Фильтр",
        docShow: "Показать",

        // NOTE Feedback Page
        feedText:
            "График планируемых мероприятий по обсуждению проектов в области стандартизации",
        feedStats: "Статистика",
        feedAllStats: "Статистика по общим проектам",
        feedAllProjects: "Все проекты",
        feedPrepare: "Подготовка к обсуждению",
        feedProcess: "В процессе обсуждения",
        feedDoing: "Обсуждение подходит к концу",
        feedDone: "Обсуждение окончено",
        feedRepeat: "Проект в разработке",
        feedConfirmedDocs: "Утвержденные документы",
        feedComments: "Комментарии",
        feedNewEdition: "Новый выпуск",
        feedQuestionary: "Опросный лист",
        feedAll: "Общее число",
        feedSeen: "Проверено",
        feedReceived: "Принято",
        feedRejected: "Не принято",
        feedProject: "Проект стандарта",
        feedExpNote: "Пояснительная записка",

        // NOTE Basket Page
        basketTitle: "Корзина",
        basketProductName: "Наименование продукта",
        basketBackButton: "Вернуться в магазин",
        basketOrderButton: "Заказывать",
        basketTotalPrice: "Всего",
        // basketModalTitle:
        //   "Ваш заказ принят. Пожалуйста, скачайте договор и произведите оплату",
        basketModalTitle:
            "После того, как вы разместите свой заказ, загрузите контракт со страницы моих заказов и произведите оплату",
        basketModalCancel: "Отменять",
        basketModalBuy: "Купить",

        noOpinon: "Пока нет отзывов",

        // NOTE Reg Page
        // Jismoniy shaxslar uchun
        regPhys: "Для физических лиц",
        regFullName: "ФИО",
        regFullNameEnt: "Введите ФИО...",
        regName: "Имя",
        regNameEnt: "Введите имя...",
        regSurname: "Фамилия",
        regSurnameEnt: "Введите фамилию...",
        regFatherName: "Имя отца",
        regFatherNameEnt: "Введите имя вашего отца...",
        regInn: "номер ИНН",
        regInnEnt: "Введите номер ИНН...",
        regPassport: "Серия и номер паспорта",
        regEmail: "Электронная почта",
        regEmailEnt: "Введите адрес электронной почты...",
        regPhone: "Номер телефона",
        regPhonePlace: "Будет отправлено SMS о статусе заявки.",
        regLivingLocation: "Адрес проживания",
        regLivingLocationEnt: "Введите адрес вашего проживания...",
        regUploadFile: "Загрузить документы в формате pdf",
        regUploadFileBtn: "Загрузить файлы",
        regDownloadFile: "Скачать договор учетной записи",
        regSubmit: "Отправить",
        regClose: "Закрыть",
        regNotify: "ПРИМЕЧАНИЕ",
        regModalText:
            "Согласно пункту 5 Указа Президента Республики Узбекистан №УП-6240 от 2 июня 2021.г “О кардинальном совершенствовании государственного управления в области технического регулирования” государственная регистрация нормативных документов по стандартизации, входящих в категорию стандартов организации отменен; А также, нормативные документы данной категории разрабатываются производителями, согласовываются с заинтересованными министерствами и ведомствами и утверждаются самими производителями. При этом не допускается подтверждение соответствия продукции на основании стандартов организации в случаях, когда показатели (требования), устанавливаемые стандартами организации, ниже требований стандартов на соответствующую продукцию, действующих в Республике Узбекистан.",
        regWarn:
            "Прочтите ПРИМЕЧАНИЕ и отметьте. После того, как вы выбрали, кнопка ОТПРАВИТЬ будет работать!",
        // Yuridik shahslar uchun
        regJury: "Юридическим лицам",
        regOrgName: "Название организации",
        regOrgNameEnt: "Введите название организации...",
        regLiablePerson: "ФИО уполномоченного лица",
        regLiablePersonEnt: "Введите ФИО уполномоченного лица...",
        regStudents: "Кол-во претендентов",
        regStudentsEnt: "Введите кол-во претендентов...",
        regLocation: "Юридический адрес",
        regLocationEnt: "Введите юридический адрес...",
        regPassword: "Пароль",
        regPasswordEnt: "Введите пароль...",
        regConfirmPass: "Подтвердите Пароль",
        regArea: "Регион",
        regAreaEnt: "Выберите регион",
        regCity: "Город",
        regCityEnt: "Выберите город",
        regBank: "Название банка",
        regBankEnt: "Введите название банка...",
        regBankNum: "Номер счета",
        regBankNumEnt: "Введите номер счета...",
        regMfo: "Введите МФО...",
        regOked: "Введите ОКЕД...",
        organProductName: "Название продукта",
        organProductNamePlaceholder: "Введите название продукта",
        oneIdReg: "Регистрация через ONE ID",

        // NOTE Orders
        orderSum: "Итоговая цена",
        orderCount: "Общее число",
        orderStatus: "Статус",
        orderTitle: "Название",
        orderPrice: "Стоимость",
        orderPage: "Число страниц",
        orderPaid: "Платеж произведен",
        orderNotPaid: "Платеж не произведен",
        orderCont: "Скачать договор",
        next: "Cлед",
        previous: "Пред",
    },
    en: {
        decision: "Decision",
        type_1: "Changed",
        employees:"Employees",
        standartRegisterAndDelete: "Decisions on the entry into force and cancellation of standards",
        stadartTitle: "Information on the implementation, cancellation and termination of the normative documents of the standard",
        type_2: "The validity period has been extended",
        type_3: "Canceled",
        decision_date: "Date of decision",
        view_reestr: "View reestr",
        notifications: "Notifications",
        generate_contract_for_subscrbe: "Drawing up a contract to subscribe to the register of citizens",
        status: "Status",
        subscribe: "Subscribe",
        // EN ------------------------------- EN ---------------- EN ------------------ EN ------------------------ EN --------------
        successReg:
            "Successfully registered to the phone number you specified, a confirmation code was sent. Please confirm your phone number",
        wrongCode: "You entered the confirmation code incorrectly, try again",
        oniReg: "Registration via ONE ID",
        siteReg: "Registration through the site",
        oidWelcome: "Welcome",
        oidTextReg: "To enter your personal account, register using ONE ID",
        oidReg: "Register with ONE ID",
        oidText:
            "To log in to your personal account, please log in with the Single Identification System (ONE ID).",
        oidLogin: "Login with ONE ID",
        siteLogin: "Login via website",
        get_a_subscription_contract: "Get a subscription contract",
        from_when: "Since when",
        to_when: "Until when",

        msgReceived: "Your message has been received!",
        allFieldMustBeFilled: "All fields must be filled in!",
        leaveFeedback: "Leave feedback",
        changesAccepted: "Changes accepted",
        userNotFound: "There is no such phone number",
        passwordRestored: "Password restored",
        passwordRestoredText: "Temporary password sent",
        restoreText:
            "A temporary password has been sent to your phone number, enter your Profile and change your password",
        enterRes: "Login to profile",
        noInformation: "No information entered",
        dataAdded: "Your message has been sent",
        expressOpinion: "Express your opinion about the project",
        testData:
            "The official website of the Institute of Standards has been launched in test mode !!!",
        feedbackText:
            "Schedule of planned activities to discuss projects in the field of standardization",

        // Check Standart Page
        checkTitle: "Check standard",
        checkPlaceholder: "The code of Standard must consist of 11 characters",
        checkSearch: "Search",
        innTitle: "Organization standard",
        innPlaceholder: "Enter TIN",
        checkNotFound: "No standard found for this code",
        checkCategory: "Standard category",
        checkName: "Name of Standard",
        checkPage: "Pages of Standard",
        checkOrderer: "Customer",
        checkOrderDate: "Order date",

        // Check INN
        innCompanyName: "Company name",
        innProductName: "Product name",
        innBankName: "Bank name",
        innAccountNumber: "Account number",
        innTin: "TIN",
        innMfo: "ITB",
        innOked: "NCEA",
        innAddress: "Address",
        innPhone: "Phone",

        // NOTE Navbar
        mainPage: "Home Page",
        standart: "Standarts",

        // NOTE Navbar - Tech Commity
        techCommity: "Technical Committee",
        techCommities: "Technical Committees",
        techCommityTeam: "Composition of the Technical Committee",
        techCommityActivity: "Activities of the Technical Committee",
        techCommityWorkPlan: "Work plan and reports of the Technical Committee",
        techCommityDocs: "Normative and legal documents",
        techCommityContact: "Contact the Technical Committee",

        // NOTE Toolbar
        specialservices: "Special facilities",
        login: "Sign In",
        register: "Sign up",
        cabinet: "Cabinet",
        loadingLang: "Please wait, file is loading...",

        // NOTE Search Input
        searchTitle: "Search",
        searchAll: "General search",
        searchReestr: "Search by registry number",
        searchType: "Type of standards",
        searchCategory: "Category of standards",
        selectType: "Select type",
        search: "Search...",
        keyWords: "Keywords",
        noNewsFound: "No news found",

        // NOTE About Institute - Dropdown Menu
        aboutInstitute: "About Institute",
        taskAndFunctions: "Tasks and FUnctions",
        regulations: "Documents",
        management: "Management",
        vacancies: "Vacancies",
        council: "Scientific secretary",
        structure: "The structure of the institute",
        help: "Help",
        sections: "Departments",

        // NOTE News - Dropdown Menu
        news: "Information service",
        newsIn: "News",
        allNews: "All news",
        photoGallery: "Photo Gallery",
        budget: "Execution of the state budget",
        magazines: "Magazines",
        lastNews: "Latest news",
        mostRead: "Most read",
        latestNews: "Latest news",
        usefulLinks: "Useful links",

        // NOTE Korporativ harid - Dropdown Menu
        corporateProcurement: "Corporate Procurement",
        tenders: "Tenders",
        events: "Events",
        auctions: "Auctions",

        // NOTE Deparments - Dropdown Menu
        deparments: "Activity",
        scientificResearchDepartmentOfConformityAssessment:
            "Scientific-research Department of conformity assessment",
        departmentForCoordinationOfTechnicalCommitteesDevelopmentAndTranslationOfStandards:
            "Department for coordination of technical committees, development and translation of standards",
        departmentOfStandardsExamination: "Department of standards examination ",
        departmentOfStandardsImplementationAndMarketingResearch:
            "Department of standards implementation and marketing research ",
        scientificResearchDepartmentOfStandardization:
            "Scientific-research Department of standardization",
        departmentOfCertification: "Department of certification ",
        departmentOfTestingAndNondestructiveControl:
            "Department of testing and nondestructive control ",
        accountingAndFinancialEconomicDepartment:
            "Accounting and financial-economic Department",
        departmentOfInternationalRelations: "Department of international relations",
        aboutTheJournal: "About the journal",
        departmentOfTheFarm: "Department of the farm",
        humanResourcesDepartment: "Human Resources Department",
        generalEatingObject: "General food facility",
        ictInitializeAndDevelopmentDepartment:
            "ICT Implementation and Development Group",
        ExecuteControlGroup: "Executive control group",
        office: "Office",
        researchAndDevelopmentDepartment:
            "Center for retraining and advanced training",
        pressSecretary: "Press secretary",
        chiefLegalAdviser: "General Counsel",

        // NOTE Services - Dropdown Menu
        services: "Services",
        standartizationServises: "Standardization services",
        metrologyServices: "Metrology services",
        qualityAssuranceBody: "Quality Certification Body",
        personnelSertificationBody: "Personnel certification body",

        contact: "Contact",

        // NOTE Hotel
        hotel: "Hotel",
        aboutHotel: "About hotel",
        archive: "Archive",
        tegs: "Tegs",

        // NOTE What we do
        allServises: "All services of the Institute",
        // inStandatizationField: "In the field of standardization",
        // inMetrologyField: "In the field of metrology",
        // inCertificationField: "In the field of certification",
        // inProfessyDevelopmentField: "In the field of professional development",
        aboutInstituteText:
            "The Scientific Research Institute for Standardization, Certification and Technical Regulation (Institute of Standards) is the only research institute and training center in Central Asia in the field of standardization, certification and quality management of the Uzstandard Agency.",
        details: "In details ",
        viewAllProjects: "View all gallery",

        // NOTE Team
        director: "Director",
        manager: "Manager",
        editor: "Editor",
        operator: "Operator",
        receptionDays: "Reception days: ",
        genDirector: "CEO",
        deputyDirector: "Deputy Director",

        // NOTE Pricing
        InternetShop: "Standards Electron-fund",
        buy: "Buy",
        buyButton: "Electron-fund",
        category: "Category",
        categories: "CategorIes",
        selectCategory: "Select a category",

        // NOTE Contact
        firstName: "First name",
        secondName: "Second name",
        email: "Email",
        phoneNumberEnter: "+998XXXXXXXXX",
        phoneNumber: "Phone number",
        messageText: "Write a message",
        sendMessage: "Send message",
        sendUsMessage: "Send us message",
        submit: "Submit",
        // NOTE Contact Page
        contactUs: "Contact us",
        organizationName: "Your organization name",
        subjectOfMessage: "Subject",
        youHaveQuestion: "Do you have question?",
        callUs: "Call us",
        fax: "Fax",
        contactBy: "Contact by...",
        bus: "Buses",

        // Profile Settings
        birthDate: "Birth Date",
        newPassword: "New password",
        enterNewPassword: "Enter new password",
        gender: "Gender",
        chooseGender: "Select gender",
        male: "Male",
        female: "Female",
        deliveryAddress: "Delivery address",
        enterDeliveryAddress: "Enter delivery address",
        mustFilled: "* must be filled",
        update: "Update",

        AdviceToSignUp: "Are you looking to improve your standardization skills?",
        SingUpNowButton: "Sign up for the course now",

        // NOTE 404 Page
        sorryText: "Sorry, required page not found",
        backButton: "Back to main page",

        // NOTE Log In/Out
        oneid: "Sign in via ONE ID",
        signInTitle: "Sign In",
        signInLogin: "Your Login",
        signInPassword: "Your Password",
        signInButton: "Sign In",
        cabSignIn: "Enter your account",
        notHaveAccount: "Don't have an account?",
        signOutButton: "Log out",
        savePassword: "Save password",
        forgotPassword: "Forgot password?",
        restorePassword: "Restore password",

        // NOTE Register
        // register: "Create Account",
        registerText: "Sign Up new account",
        fullNameTitle: "Your Login",
        passwordTitle: "Password",
        emailTitle: "Email",
        rePasswordTitle: "Confirm password",
        haveAccount: "Have an account?",
        requiredCode: "Enter the code from the sms",
        enterCode: "Enter the code",
        smsCode: "Сonfirm code from the sms",
        smsConfirm: "Confirm",

        // NOTE Alerts
        enterToProfile: "Login to your profile",
        enterLogin: "Enter Login",
        enterEmail: "Enter Email",
        enterPhoneNumber: "Enter Phone number",
        enterPhoneAndPassword: "Enter your Phone number and Password",
        enterEmailAndPassword: "Enter your Email and Password",
        enterPassword: "Enter password",
        passwordMatch: "You entered two different passwords. Please try again",
        fillAllInputs: "Please fill all fields with *",
        passwordLength: "Password must contain 6 characters at least",
        invalidMailAddress: "Invalid email, please try again",
        invalidPassword:
            "You entered the wrong password, the password must be more than 6 digits",
        // invalidPassword:
        //   "You entered the wrong password, the password must be more than 6 digits, at least 1 uppercase letter, 1 lowercase letter and 1 number",
        invalidPhone:
            "You entered the wrong phone number, the phone number should be as the +998901234567",
        invalidPhoneNum:
            "This phone number has already been used, please enter another number",
        invalidEmail: "This email was used, please enter another email",
        invalidLogin: "Phone number or password entered incorrectly, try again",

        // NOTE Checkout Page
        personalDetails: "Personal data",
        FIO: "Full name",
        username: "Username",
        address: "Address",
        comment: "Comments",
        reminderToOrder: "Order Notes",
        reminderToOrderText: "Notes on your order, such as special shipping notes",
        yourOrder: "Your order",
        paymentMethod: "Select payment method",
        continueButton: "Continue",

        // NOTE Orders Page
        invoices: "Invoices",
        profileSettings: "Profile settings",
        myOrders: "My orders",
        subscriptions: "View the Standards Register",
        favorites: "Favorites",
        journalName: "Log name",
        paidMethod: "Payment method",
        paidPrice: "Payment price",
        paidDay: "Payment date",

        // NOTE Footer
        allRightsReserved: "All rights reserved",
        termsOfUse: "Terms of use",
        instituteStandard: "Institute of Standards",
        locationOfInstitute: "100059 Tashkent city, Chupon ota street 9V",
        footerAbout:
            "Research Institute of Standardization, Certification and Technical Regulation (Institute of Standards) is the only research institute and training center in Central Asia in the field of standardization, certification and quality management of the Agency 'Uzstandard'.",

        // NOTE Sidebar Useful links
        standartUz:
            "Standartization, metrology and certification agency of Uzbekistan",
        palataUz:
            "Legislative Chamber of the Oliy Majlis of the Republic of Uzbekistan",
        presidentUz: "Official website of the President of Uzbekistan",
        lexUz: "National database of legislation of the Republic of Uzbekistan",
        ombudsmanUz:
            "Official website Authorized Person of the Oliy Majlis of the Republic of Uzbekistan for Human Rights (Ombudsman)",

        // NOTE Side Panel
        helpLine: "Help lines",
        myOpinion: "Commenting on the draft national standard",
        narrator: "Narrator",
        questionAndAnswer: "Questions and answers",
        stats: "Appeals statistics",

        // NOTE Murojatlar va Takliflar
        feedbackTitle: "Appeals and suggestions",

        // NOTE Documents
        docDownload: "Download",
        docDate: "Date",
        docNumber: "Number",
        docFilter: "Filter",
        docShow: "Release",

        // NOTE Feedback Page
        feedText:
            "Schedule of planned events to discuss projects in the field of standardization",
        feedStats: "Statistics",
        feedAllStats: "Statistics on common projects",
        feedAllProjects: "All projects",
        feedPrepare: "Preparing for discussion",
        feedProcess: "In the process of the discussion",
        feedDoing: "The discussion is coming to an end",
        feedDone: "Discussion is over",
        feedRepeat: "Project in development",
        feedConfirmedDocs: "Approved documents",
        feedComments: "Comments",
        feedNewEdition: "New edition",
        feedQuestionary: "Questionnaire",
        feedAll: "Total number",
        feedSeen: "Verified",
        feedReceived: "Accepted",
        feedRejected: "Not accepted",
        feedProject: "Project of Standard",
        feedExpNote: "Explanatory note",

        // NOTE Basket Page
        basketTitle: "Cart",
        basketProductName: "Product name",
        basketBackButton: "Back to shop",
        basketOrderButton: "Order",
        basketTotalPrice: "Total",
        // basketModalTitle:
        //   "Your order is accepted. Please download the contract and make payment",
        basketModalTitle:
            "Once you have placed your order, download the contract from my orders page and make the payment",
        basketModalCancel: "Cancel",
        basketModalBuy: "Buy",

        noOpinon: "No Opinions so far",

        // NOTE Reg Page
        // Jismoniy shaxslar uchun
        regPhys: "For individuals",
        regFullName: "Full name",
        regFullNameEnt: "Enter full name...",
        regName: "Name",
        regNameEnt: "Enter name...",
        regSurname: "Surname",
        regSurnameEnt: "Enter surname...",
        regFatherName: "Father's name",
        regFatherNameEnt: "Enter father's name... ",
        regInn: "INN number",
        regInnEnt: "Enter INN number..",
        regPassport: "Passport series and number",
        regEmail: "E-mail",
        regEmailEnt: "Enter e-mail...",
        regPhone: "Phone number",
        regPhonePlace:
            "An SMS will be sent regarding the status of the application",
        regLivingLocation: "Residence address",
        regLivingLocationEnt: "Enter residence address...",
        regUploadFile: "Upload documents in pdf format",
        regUploadFileBtn: "Upload files",
        regDownloadFile: "Download the account agreement",
        regSubmit: "Submit",
        regClose: "Close",
        regNotify: "NOTE",
        regModalText:
            "According to paragraph 5 of the Decree of the President of the Republic of Uzbekistan No.UP-6240 dated June 2, 2021. 'On the cardinal improvement of public administration in the field of technical regulation', the state registration of normative documents on standardization included in the category of organization standards has been canceled; And also, normative documents of this category are developed by manufacturers, agreed with the interested ministries and departments and approved by the manufacturers themselves. At the same time, it is not allowed to confirm the conformity of products on the basis of the organization's standards in cases where the indicators (requirements) established by the organization's standards are lower than the requirements of the standards for the corresponding products in force in the Republic of Uzbekistan.",
        regWarn:
            "Read NOTE and mark. Once you have selected, the SUBMIT button will work!",
        // Yuridik shahslar uchun
        regJury: "For legal entities",
        regOrgName: "Name of organization",
        regOrgNameEnt: "Enter name of organization...",
        regLiablePerson: "The full name of the authorized person",
        regLiablePersonEnt: "Enter the full name of the authorized person...",
        regStudents: "Number of applicants",
        regStudentsEnt: "Enter the number of applicants",
        regLocation: "Legal address",
        regLocationEnt: "Enter the legal address...",
        regPassword: "Password",
        regPasswordEnt: "Enter the password...",
        regConfirmPass: "Confirm password",
        regArea: "Region",
        regAreaEnt: "Select region",
        regCity: "City",
        regCityEnt: "Select city",
        regBank: "Bank name",
        regBankEnt: "Enter the bank name...",
        regBankNum: "Account number",
        regBankNumEnt: "Enter the account number...",
        regMfo: "Enter the MFO...",
        regOked: "Enter the OKED...",
        organProductName: "Product name",
        organProductNamePlaceholder: "Enter the product name",
        oneIdReg: "Registration via ONE ID",

        // NOTE Orders
        orderSum: "Sum price",
        orderCount: "Total count",
        orderStatus: "Status",
        orderTitle: "Title",
        orderPrice: "Price",
        orderPage: "Number of pages",
        orderPaid: "Payment made",
        orderNotPaid: "Payment not made",
        orderCont: "Download the contract",
        next: "Next",
        previous: "Previous",
    },
};
