import React, { Component } from "react";
import "react-calendar/dist/Calendar.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { IMAGE_URL } from "../../constants";
import SideBar from "../../containers/SideBar";
import { _translator } from "../../helpers";
import * as Actions from "../../redux/actions";
import { langData } from "./index.i18n";

class Manager extends Component {
  state = {
    date: new Date(),
    paramsId: 0,
    isBio: true,
  };
  componentDidMount() {
    this.props.setLoader("ManagerData");
    window.scrollTo(0, 0);
    this.fetchItems();
  }

  fetchItems = () => {
    this.props.fetchDetailsData("ManagerData", this.props.match.params.id);
    this.setState({
      params: this.props.match.params.id,
    });
  };

  render() {
    const { mlang, LoadingManagerData, ManagerData } = this.props;
    const { isBio } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

    return LoadingManagerData ? (
      <Loader />
    ) : (
      <div>
        <div className="full_page">
          <div className="blog_page">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8">
                  <div className="rah__box">
                    <div className="rah__top">
                      <img src={IMAGE_URL + ManagerData.avatar} alt="" />
                      <ul>
                        <li>
                          <b>{_translator("position", ManagerData, mlang)}</b>
                        </li>
                        <li>
                          <b>{_translator("username", ManagerData, mlang)}</b>
                        </li>
                        <li>
                          {data.phoneNumber}: {ManagerData.phone}
                          {/* {data.address}:{ManagerData.email} */}
                        </li>
                        <li>
                          {data.receptionDays}
                          {_translator("reception_time", ManagerData, mlang)}
                        </li>
                        <li>
                          {data.email}: <a href="#">{ManagerData.email}</a>
                        </li>
                        <li>
                          <div
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                isBio: !isBio,
                              })
                            }
                          >
                            {isBio ? data.responsibilities : data.biography}
                          </div>
                        </li>
                      </ul>
                    </div>
                    {isBio ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: _translator("biography", ManagerData, mlang),
                        }}
                      />
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: _translator(
                            "responsibility",
                            ManagerData,
                            mlang
                          ),
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <SideBar />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /scroll_to_top start */}
        <a href="#" className="scrollToTop">
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    ManagerData: state.fetchDataReducer.ManagerData,
    LoadingManagerData: state.fetchDataReducer.LoadingManagerData,
    NewsListData: state.fetchDataReducer.NewsListData,
    LoadingNewsListData: state.fetchDataReducer.LoadingNewsListData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Manager);
