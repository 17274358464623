import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Loader from "../../components/Loader";
import * as Actions from "../../redux/actions";
import {langData} from "./index.i18n";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import {FETCH_URL, IMAGE_URL} from "../../constants";
import {toast, ToastContainer} from "react-toastify";
import {_currencyFormatter} from "../../helpers";
import {Image, Modal} from "antd";

export class ShopDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setLoader("JournalDetailsData");
        this.props.fetchJournalDetailsData(this.props.match.params.id);
    }

    addToCart = () => {
        const {mlang} = this.props;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        const {JournalDetailsData, isLogged} = this.props;
        const token = Cookies.get("userToken");
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        if (isLogged) {
            axios
                .post(
                    `${FETCH_URL}cart`,
                    {
                        standart_id: JournalDetailsData.id,
                    },
                    config
                )
                .then((res) => {
                    {
                        res.data.success
                            ? toast.success(data.addedToCard)
                            : res.data.error_no === 1
                                ? toast.warn(data.alreadyAddedToCard)
                                : toast.error(data.sameNotAllowed);
                        // : toast.warn("savatchaga qushilgan");
                    }
                    // console.log("RES ", res);
                    // window.location.reload()
                })
                .catch((err) => toast.error(JSON.stringify(err)));
        } else {
            this.props.history.push(`/login/${JournalDetailsData.id}`);
        }
    };

    openImageGallery(id) {
        const {userToken} = this.props;
        axios
            .get(`${FETCH_URL}preview/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        show: true,
                        data: response?.data?.images
                    })
                }
            })
            .catch((e) => console.log("error: ", e));
    }

    render() {
        const {mlang, LoadingJournalDetailsData, JournalDetailsData} = this.props;
        const {show} = this.state;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        // console.log("JournalDetailData: ", JournalDetailsData);
        const url = `https://api.allorigins.win/raw?url=${IMAGE_URL}${JournalDetailsData.fileHash}`;
        // const url = `https://cors-anywhere.herokuapp.com/${IMAGE_URL}${JournalDetailsData.fileHash}`;

        let prices = "";
        const price =
            JournalDetailsData.price === null
                ? (prices = "0")
                : (prices = JournalDetailsData.price);
        // console.log("JournalDetailsData", JournalDetailsData);
        return LoadingJournalDetailsData ? (
            <Loader/>
        ) : (
            <div>
                <section className="auth">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {/* Pulse button */}
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display:
                                            JournalDetailsData.actual == 0 ? "inline-block" : "none",
                                    }}
                                    // href=""
                                    className="pulsingButton"
                                >
                                    {JournalDetailsData.actual_text}
                                </div>

                                <div
                                    className="iso__top"
                                    style={{
                                        marginTop: JournalDetailsData.actual == 0 ? 20 : 80,
                                        marginBottom: JournalDetailsData.actual == 0 ? 20 : 80,
                                        // marginTop: JournalDetailsData.actual == 0 ? 20 : 80,
                                        // marginBottom: JournalDetailsData.actual == 0 ? 50 : 0,
                                    }}
                                >
                                    <h5>{JournalDetailsData.name_standart}</h5>
                                </div>
                                {/* <div
                  className="iso__last"
                  style={{
                    display: JournalDetailsData.actual == 1 ? "block" : "none",
                  }}
                >
                  <p
                    style={{
                      display: JournalDetailsData.date_standart
                        ? "block"
                        : "none",
                    }}
                  >
                    {data.actualDocInit}
                    {JournalDetailsData.date_standart}
                    {data.actualDocLast}
                  </p>
                </div> */}
                                <div className="iso__main">
                                    <div className="iso__text">
                                        <h4>
                                            {data.thesis}{" "}
                                            <a
                                                href
                                                style={{color: "white", cursor: "pointer"}}
                                                className="know_more"
                                                onClick={() => this.openImageGallery(JournalDetailsData?.id)}
                                            >
                                                {data.preview}
                                            </a>
                                        </h4>
                                        <p>{JournalDetailsData.anotatsiya_standart}</p>
                                        <h5>{data.genInfo}</h5>
                                        <ul>
                                            <li>
                                                <b>
                                                    {data.status}:
                                                    <span>
                            <i
                                className="fa fa-check-circle-o"
                                aria-hidden="true"
                                style={{marginLeft: "5px", marginRight: "5px"}}
                            />
                                                        {data.published}
                          </span>
                                                </b>
                                                <b
                                                    className="bbb"
                                                    style={{
                                                        display: JournalDetailsData.date_standart
                                                            ? "block"
                                                            : "none",
                                                    }}
                                                >
                                                    {data.dateOfPublication}:{" "}
                                                    <span style={{marginRight: 5, fontWeight: "bold"}}>
                            {JournalDetailsData.date_standart}
                          </span>
                                                    {data.year}
                                                </b>
                                            </li>
                                            <li>
                                                <b>{data.version}: 1</b>
                                            </li>
                                            <li>
                                                <b>{data.category}:</b>
                                                {JournalDetailsData.category.name}
                                            </li>
                                            <li>
                                                <b>{data.type}:</b>
                                                {JournalDetailsData.type.name}
                                            </li>
                                            <li>
                                                <b>{data.numberOfPages}:</b>
                                                {JournalDetailsData.page_standart}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="iso__buy">
                                        <h4>{data.purchaseThis}</h4>
                                        <ul>
                                            <li>
                                                <b>{data.format}</b> <b>{data.lang}</b>
                                            </li>
                                            <li className="active">
                                                <span style={{width: "50%", marginLeft: 5}}>PDF</span>
                                                <span
                                                    style={{
                                                        width: "50%",
                                                        // marginLeft: 5
                                                    }}
                                                >
                          {JournalDetailsData.language.name === "Ru"
                              ? `${data.russian}`
                              : JournalDetailsData.language.name}
                        </span>
                                            </li>
                                        </ul>
                                        <b style={{fontSize: 38}}>{_currencyFormatter(price)}</b>
                                        {/* <div className="iso__button">
                      <a className="know_more" href="#">
                        {data.purchase}
                      </a>
                    </div> */}

                                        <div
                                            onClick={() => this.addToCart()}
                                            // onClick={() => alert("Технические работы")}
                                            className="siner__buttons"
                                        >
                                            <p> {data.purchase}</p>
                                        </div>
                                        <div
                                            // onClick={() => alert("Технические работы")}
                                            className="siner__buttons"
                                        >
                                            <p style={{marginTop: 0}}>
                                                <Link style={{color: "#fff"}} to="/cart">
                                                    {data.goToCart}
                                                </Link>{" "}
                                            </p>
                                        </div>
                                        <div
                                            // onClick={() => alert("Технические работы")}
                                            className="siner__buttons"
                                        >
                                            <p style={{marginTop: 0}}>
                                                <Link style={{color: "#fff"}} to="/shop">
                                                    {data.backToShop}
                                                </Link>{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div style={{width: "95vh"}}>
                    <Modal
                        open={show}
                        onCancel={() => this.setState({show: false})}
                        width={1300}
                        title={<h3>
                            {JournalDetailsData.name_standart}
                        </h3>}
                        footer={false}
                    >
                        <Image.PreviewGroup
                            preview={{
                                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                            }}
                        >
                            {this.state?.data?.map(ph => <Image height={250} width={250}
                                                                src={`https://admin.uzsti.uz/storage/previews/${ph?.path}`}/>)}
                        </Image.PreviewGroup>
                    </Modal>
                </div>
                <ToastContainer autoClose={10000}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        isLogged: state.fetchDataReducer.isLogged,
        JournalDetailsData: state.fetchDataReducer.JournalDetailsData,
        LoadingJournalDetailsData: state.fetchDataReducer.LoadingJournalDetailsData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopDetails);
