import React, { Component } from "react";
import { StaffCard } from "../components/Staff";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
import Loader from "../components/Loader";

class Staff extends Component {
  componentDidMount() {
    this.props.fetchManagersListData("staff", 1);
    window.scrollTo(0, 0);
  }
  render() {
    const { mlang, LoadingManagersListData, ManagersListData } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log("STAFF DATA: ", ManagersListData);
    // console.log("STAFF ID, ", this.props.parameters.match.params.id);
    return LoadingManagersListData ? (
      <Loader />
    ) : (
      <div className="col-xl-8 col-lg-8">
        {ManagersListData
          // .filter(
          //   (data) => data.page_id == this.props.parameters.match.params.id
          // )
          .map((item) => (
            <StaffCard
              key={item.id.toString()}
              phoneNumber={data.phoneNumber}
              email={data.email}
              data={item}
              mlang={this.props.mlang}
            />
          ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    ManagersListData: state.fetchDataReducer.ManagersListData,
    LoadingManagersListData: state.fetchDataReducer.LoadingManagersListData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Staff);
