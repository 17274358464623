export const langData = {
  uz: {
    // NOTE Inner-page
    actualDocInit: "So'nggi bor bu standart ",
    actualDocLast: " yilda ko'rib chiqilgan va Aktual hisoblanadi",
    thesis: "Qisqa ma'lumot",
    preview: "Oldindan ko'rish",
    genInfo: "Umumiy ma'lumot",
    status: "Hozirgi holati",
    published: "Nashr qilingan",
    dateOfPublication: "Nashr qilingan sana",
    version: "Versiya",
    technicalCommittee: "Texnik qo'mita",
    purchaseThis: "Ushbu Standartni savatchaga qo'shish",
    addedToCard: "Savatchaga qo'shildi",
    alreadyAddedToCard: "Savatchaga qo'shilgan, savatchaga kirib sotib oling",
    sameNotAllowed:
      "Bitta savatchaga Pullik va Bepul Standartni qo'shib bo'lmaydi, Savatchaga kirib oldingi qo'shilgan Standartlar bilan operatsiyani yakuniga yetkazing!",
    format: "Format",
    lang: "Til",
    paper: "Qo'goz",
    purchase: "Savatchaga qo'shish",
    english: "Ingliz tilida",
    russian: "Rus tilida",
    uzbek: "O'zbek tilida",
    year: "Yil",
    category: "Kategoriyasi",
    type: "Sinflanishi",
    numberOfPages: "Sahifalar soni",
    backToShop: "Ortqa qaytish",
    // backToShop: "Do'konga qaytish",
    goToCart: "Savatchaga kirish",
    loadingLang: "Iltimos kuting, fayl yuklanmoqda...",
  },
  ru: {
    // RU ------------------------------- RU ---------------- RU ------------------ RU ------------------------ RU --------------

    // NOTE Inner-page
    actualDocInit: "Последний раз этот стандарт был пересмотрен в ",
    actualDocLast: "году. Поэтому данная версия остается актуальной",
    thesis: "Тезис",
    preview: "Предпросмотр",
    genInfo: "Общая информация",
    status: "Текущий статус",
    published: "Опубликовано",
    dateOfPublication: "Дата публикации",
    version: "Версия",
    technicalCommittee: "Технический комитет",
    purchaseThis: "Добавить этот Стандарт в корзину",
    addedToCard: "Добавлен в корзину",
    alreadyAddedToCard: "Добавлен в корзину, купите в корзине",
    sameNotAllowed:
      "Платные и бесплатные стандарты нельзя добавить в одну корзину, войдите в корзину и завершите транзакцию с ранее добавленными стандартами!",
    format: "Формат",
    lang: "Язык",
    paper: "Бумажный",
    purchase: "Добавить в корзину",
    english: "Английский",
    russian: "Русский",
    uzbek: "Узбекский",
    year: "Год",
    category: "Категория",
    type: "Классификация",
    numberOfPages: "Число страниц",
    backToShop: "Вернутся назад",
    // backToShop: "Вернуться в магазин",
    goToCart: "Перейти в корзину",
    loadingLang: "Подождите, файл загружается...",
  },
  en: {
    // EN ------------------------------- EN ---------------- EN ------------------ EN ------------------------ EN --------------

    // NOTE Inner-page
    actualDocInit: "This standard was last revised in ",
    actualDocLast: ". So this version remains actual",
    thesis: "Thesis",
    preview: "Preview",
    genInfo: "General information",
    status: "Current status",
    published: "Published",
    dateOfPublication: "Date of publication",
    version: "Version",
    technicalCommittee: "Technical committee",
    purchaseThis: "Add this Standard to the cart",
    addedToCard: "Added to cart",
    alreadyAddedToCard: "Added to cart, buy in cart",
    sameNotAllowed:
      "Paid and Free Standards cannot be added to one basket, enter the Basket and complete the transaction with the previously added Standards!",
    format: "Format",
    lang: "Language",
    paper: "Paper",
    purchase: "Add to Cart",
    english: "English",
    russian: "Russian",
    uzbek: "Uzbek",
    year: "Year",
    category: "Category",
    type: "Classification",
    numberOfPages: "Number of Pages",
    backToShop: "Go back",
    // backToShop: "Back to shop",
    goToCart: "Go to cart",
    loadingLang: "Please wait, file is loading...",
  },
};
