import React from "react";
export default class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: "0",
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        let maxScroll = document.body.scrollHeight - window.innerHeight;
        // console.log(maxScroll)
        if (currentScrollPos > 0 && currentScrollPos <= maxScroll) {
          this.setState({ opacity: "1" });
          // console.log(currentScrollPos)
        } else {
          this.setState({ opacity: "0" });
        }
      };
    }
  }
  render() {
    return (
      <div
        style={{ cursor: "pointer", opacity: this.state.opacity }}
        onClick={() => window.scrollTo(0, 0)}
        className="scrollToTop"
      >
        <i className="fa fa-arrow-up" aria-hidden="true" />
      </div>
    );
  }
}
