export const langData = {
  uz: {
    // NOTE Search Input
    searchTitle: "Izlash",
    searchAll: "Umumiy qidirish",
    searchReestr: "Reestr raqam bilan qidirish",
    searchType: "Standartlarning sinflanishi",
    searchCategory: "Standartlar kategoriyasi",
    selectType: "Sinflanishni tanlang",
    selectCategory: "Kategoriyani tanlang",
    search: "Qidiruv...",
    keyWords: "Kalit so'zlar",
    buy: "Harid qilish",
    category: "Kategoriya",
    next: "Keyingi",
    previous: "Oldingi",

    // Check Standart Page
    checkTitle: "Standartni tekshirish",
    checkPlaceholder: "Standartni kodi 11 xonadan iborat bo'lishi kerak",
    checkSearch: "Qidirish",
    checkNotFound: "Bu kod bo'yicha standart topilmadi",
    checkCategory: "Standart kategoriyasi",
    checkName: "Standart nomi",
    checkPage: "Standart sahifasi",
    checkOrderer: "Buyurtmachi",
    checkOrderDate: "Buyurtma sanasi",
  },
  ru: {
    // RU ------------------------------- RU ---------------- RU ------------------ RU ------------------------ RU --------------

    // NOTE Search Input
    searchTitle: "Поиск",
    searchAll: "Общий поиск",
    searchReestr: "Поиск по номеру реестра",
    searchType: "Классификация стандартов",
    searchCategory: "Категория стандартов",
    selectType: "Выберите классификацию",
    selectCategory: "Выбрать категорию",
    search: "Поиск...",
    keyWords: "Ключевые слова",
    buy: "Купить",
    category: "Категория",
    next: "Cлед",
    previous: "Пред",

    // Check Standart Page
    checkTitle: "Проверить стандарт",
    checkPlaceholder: "Код Стандарта должен состоять из 11 знаков",
    checkSearch: "Поиск",
    checkNotFound: "Стандарт для этого кода не найден",
    checkCategory: "Категория стандарта",
    checkName: "Названия стандарта",
    checkPage: "Страница стандарта",
    checkOrderer: "Заказчик",
    checkOrderDate: "Дата заказа",
  },
  en: {
    // EN ------------------------------- EN ---------------- EN ------------------ EN ------------------------ EN --------------

    // NOTE Search Input
    searchTitle: "Search",
    searchAll: "General search",
    searchReestr: "Search by registry number",
    searchType: "Classification of standards",
    searchCategory: "Category of standards",
    selectType: "Select a classification",
    selectCategory: "Select a category",
    search: "Search...",
    keyWords: "Keywords",
    buy: "Buy",
    category: "Category",
    next: "Next",
    previous: "Previous",

    // Check Standart Page
    checkTitle: "Check standard",
    checkPlaceholder: "The code of Standard must consist of 11 characters",
    checkSearch: "Search",
    checkNotFound: "No standard found for this code",
    checkCategory: "Standard category",
    checkName: "Name of Standard",
    checkPage: "Pages of Standard",
    checkOrderer: "Customer",
    checkOrderDate: "Order date",
  },
};
