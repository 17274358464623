import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import { langData } from '../index.i18n';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { FETCH_URL } from '../constants';
// import { post } from 'jquery';
import InputMask from 'react-input-mask';
import { _translator } from '../helpers';
import { Table } from 'react-bootstrap';

export class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkInput: '',
			selectedFile: null,
			Password: '',
			ConfirmPassword: '',
			OrganizationName: '',
			FullName: '',
			PhoneNumber: '',
			Email: '',
			City: null,
			BankName: '',
			BankNumber: '',
			TIN: '',
			MFO: '',
			OKED: '',
			Adress: '',
			isCompany: 1,
			isReq: false,
			reqCode: '',
			checked: false,
			// IND
			indPassword: '',
			indConfirmPassword: '',
			indFullName: '',
			indPhoneNumber: '',
			indEmail: '',
			indTIN: '',
			indAdress: '',
			isReq: false,
			reqCode: '',
			loader: false,
		};

		this.signUp = this.signUp.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	updateState = (title, e) => {
		this.setState({
			[title]: e.target.value,
		});
	};

	onChangeHandler = (event) => {
		// console.log(event.target.files[0]);
		this.setState({
			selectedFile: event.target.files[0],
			loaded: 0,
		});
	};

	onClickHandler = () => {
		const data = new FormData();
		data.append('file', this.state.selectedFile);
	};

	signUp = (event) => {
		const {
			// selectedFile,
			OrganizationName,
			// Password,
			// ConfirmPassword,
			FullName,
			PhoneNumber,
			// Email,
			City,
			BankName,
			BankNumber,
			TIN,
			MFO,
			OKED,
			Adress,
			isCompany,
			indPassword,
			indConfirmPassword,
			indFullName,
			indPhoneNumber,
			indEmail,
			indTIN,
			indAdress,
		} = this.state;
		const { mlang } = this.props;
		event.preventDefault();
		// function emailIsValid(Email) {
		// 	return /\S+@\S+\.\S+/.test(Email);
		// }
		function phoneIsValid(PhoneNumber) {
			return /^\+?\d+$/.test(PhoneNumber);
		}
		// function passwordIsValid(Password) {
		// 	return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(Password);
		// }
		const data =
			mlang === 'uz' ? langData.uz : mlang === 'ru' ? langData.ru : langData.en;
		// console.log("PHONE", PhoneNumber);
		// console.log("PHONE length", PhoneNumber.length);
		// console.log("selectedFile", selectedFile);
		// console.log("City", City);

		if (isCompany) {
			if (
				!OrganizationName ||
				// !Password ||
				// !ConfirmPassword ||
				!FullName ||
				!PhoneNumber ||
				// !Email ||
				!City ||
				!BankName ||
				!BankNumber ||
				!TIN ||
				!MFO ||
				!OKED ||
				!Adress
			) {
				toast.error(data.fillAllInputs);
				// console.log("ISHLAMADI?-------");
				// } else if (emailIsValid(Email) === false) {
				//   toast.error(data.invalidMailAddress);
				// } else if (
				//   phoneIsValid(PhoneNumber) === false ||
				//   PhoneNumber.length !== 12
				// ) {
				//   toast.error(data.invalidPhone);
				// } else if (passwordIsValid(Password) === false) {
				//   toast.error(data.invalidPassword);
				// } else if (Password.length < 7) {
				//   toast.error(data.invalidPassword);
				// } else if (Password !== ConfirmPassword) {
				//   toast.error(data.passwordMatch);
			} else {
				this.setState({ loader: true });
				const formData = new FormData();
				formData.append('file', this.state.selectedFile);
				formData.append('product_name', this.state.FullName);
				formData.append('phone', this.state.PhoneNumber.slice(4)); // < - phone
				formData.append('is_corporate', this.state.isCompany);
				formData.append('company_name', this.state.OrganizationName);
				formData.append('address', this.state.Adress);
				formData.append('bank_name', this.state.BankName);
				formData.append('account_number', this.state.BankNumber);
				formData.append('region', this.state.City);
				formData.append('mfo', this.state.MFO);
				formData.append('oked', this.state.OKED);
				formData.append('tin', this.state.TIN);
				// console.log("FORM DATA", formData);
				// return false;
				axios
					.post(`${FETCH_URL}company-product-app`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((response) => {
						if (response.data.success) {
							this.setState(
								{
									// Password: "",
									// ConfirmPassword: "",
									OrganizationName: '',
									FullName: '',
									PhoneNumber: '',
									// Email: "",
									City: null,
									BankName: '',
									BankNumber: '',
									TIN: '',
									MFO: '',
									OKED: '',
									Adress: '',
									// isReq: true,
									loader: false,
								},
								() => {
									toast.success(
										response.data.success === true
											? data.dataAdded
											: response.data.message
									);
									// console.log("JJJJ", response.data);
									window.scrollTo(0, 0);
								}
							);
						} else {
							toast.error(
								response.data.error.err_msg[0] ===
									'The phone has already been taken.'
									? `${data.invalidPhoneNum}`
									: `${data.invalidEmail}`
							);
							// console.log("res err: ", response.data.error.err_msg.length);
						}
					})
					.catch((e) => console.log(e));
			}
		} else {
			if (
				!indPassword ||
				!indConfirmPassword ||
				!indFullName ||
				!indPhoneNumber ||
				// !Email ||
				!indTIN ||
				!indAdress
			) {
				toast.error(data.fillAllInputs);
				// } else if (emailIsValid(Email) === false) {
				//   toast.error(data.invalidMailAddress);
			} else if (
				phoneIsValid(indPhoneNumber) === false ||
				indPhoneNumber.length !== 13
			) {
				toast.error(data.invalidPhone);
				// } else if (passwordIsValid(indPassword) === false) {
				//   toast.error(data.invalidPassword);
			} else if (indPassword.length < 7) {
				toast.error(data.invalidPassword);
			} else if (indPassword !== indConfirmPassword) {
				toast.error(data.passwordMatch);
			} else {
				axios
					.post(`${FETCH_URL}register`, {
						username: indFullName,
						password: indPassword,
						password_confirmation: indConfirmPassword,
						email: indEmail,
						phone: indPhoneNumber.slice(3),
						is_corporate: isCompany,
						address: indAdress,
						tin: indTIN,
					})
					.then((response) => {
						if (response.data.success) {
							this.setState(
								{
									indPassword: '',
									indConfirmPassword: '',
									OrganizationName: '',
									indFullName: '',
									indPhoneNumber: '',
									indEmail: '',
									City: null,
									BankName: '',
									BankNumber: '',
									indTIN: '',
									MFO: '',
									OKED: '',
									indAdress: '',
									isReq: true,
								},
								() => {
									toast.success(response.data.message);
									window.scrollTo(0, 0);
								}
							);
						} else {
							toast.error(
								response.data.error.err_msg[0] ===
									'The phone has already been taken.'
									? `${data.invalidPhoneNum}`
									: `${data.invalidEmail}`
							);
							// console.log(
							//   "res err: ",
							//   response.data.error.err_msg[0]
							//   //  ===
							//   //   "The phone has already been taken."
							// );
						}
					})
					.catch((e) => console.log(e));
			}
		}
	};
	sentVerification = () => {
		const { reqCode } = this.state;
		axios
			.get(`${FETCH_URL}admin/verify/${reqCode}`)
			.then((res) => {
				if (res.data.success) {
					this.setState(
						{
							reqCode: '',
							isReq: false,
						},
						() => {
							toast.success(res.data.message);
							this.props.history.push('/login');
						}
					);
				} else {
					toast(res.data.errors.err_msg[0]);
				}
			})
			.catch((err) => console.log(err));
	};

	updateText = (path, e) => {
		this.setState({
			[path]: e.target.value,
		});
	};

	render() {
		const {
			OrganizationName,
			Password,
			ConfirmPassword,
			FullName,
			PhoneNumber,
			Email,
			City,
			BankName,
			BankNumber,
			TIN,
			MFO,
			OKED,
			Adress,
			isReq,
			reqCode,
			indPassword,
			indConfirmPassword,
			indFullName,
			indPhoneNumber,
			indEmail,
			indTIN,
			indAdress,
			loader,
			checked,
			checkInput,
		} = this.state;
		const { mlang, CheckInn } = this.props;
		const data =
			mlang === 'uz' ? langData.uz : mlang === 'ru' ? langData.ru : langData.en;

		return (
			<div>
				<div className='sptb ariza'>
					<div
						className='container'
						style={{ display: 'flex', justifyContent: 'center' }}
					>
						<div
							className='row'
							style={{ display: isReq || checkInput ? 'none' : 'block' }}
						>
							<div className='tab-content pt-4' id='myTabContent'>
								<div
									className='tab-pane fade show active'
									id='home'
									role='tabpanel'
									aria-labelledby='home-tab'
								>
									<form
										onSubmit={this.signUp}
										style={{
											maxWidth: '700px',
											margin: 'auto',
										}}
									>
										<div className='card box-shadow mb-5'>
											<div className='card-body'>
												<div className='row'>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regOrgName}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='text'
																className='form-control border-primary '
																name='company_name'
																placeholder={data.regOrgNameEnt}
																onChange={(e) =>
																	this.updateState('OrganizationName', e)
																}
																value={OrganizationName}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.organProductName}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='text'
																className='form-control border-primary '
																defaultValue=''
																name='ConfirmPassword'
																placeholder={data.organProductNamePlaceholder}
																onChange={(e) =>
																	this.updateState('FullName', e)
																}
																value={FullName}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regCity}
																<i className='text-danger'>*</i>
															</label>
															<select
																className='form-control border-primary qty-of-trainees '
																name='city'
																onChange={(e) => this.updateState('City', e)}
																value={City}
															>
																<option disabled selected value=''>
																	{data.regCityEnt}
																</option>
																{cityData.map((city) => (
																	<option key={city.id + ''} value={city.id}>
																		{/* {city.title} */}
																		{_translator('title', city, mlang)}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regBank} <i className='text-danger'>*</i>
															</label>
															<input
																type='text'
																className='form-control border-primary email '
																name='BankName'
																placeholder={data.regBankEnt}
																onChange={(e) =>
																	this.updateState('BankName', e)
																}
																value={BankName}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regBankNum}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='number'
																className='form-control border-primary email '
																name='BankNumber'
																placeholder={data.regBankNumEnt}
																onChange={(e) =>
																	this.updateState('BankNumber', e)
																}
																value={BankNumber}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regInn}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='text'
																maxLength={9}
																className='form-control border-primary tin '
																name='TIN'
																placeholder={data.regInnEnt}
																onChange={(e) => this.updateState('TIN', e)}
																value={TIN}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																MFO <i className='text-danger'>*</i>
															</label>
															<input
																type='number'
																className='form-control border-primary email '
																name='MFO'
																placeholder={data.regMfo}
																onChange={(e) => this.updateState('MFO', e)}
																value={MFO}
																maxLength={5}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																OKED <i className='text-danger'>*</i>
															</label>
															<input
																type='number'
																className='form-control border-primary email '
																name='OKED'
																placeholder={data.regOked}
																onChange={(e) => this.updateState('OKED', e)}
																value={OKED}
															/>
														</div>
													</div>
													<div className='col-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regLocation}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='text'
																className='form-control border-primary '
																name='address'
																placeholder={data.regLocationEnt}
																onChange={(e) => this.updateState('Adress', e)}
																value={Adress}
															/>
														</div>
													</div>
													<div className='col-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regPhone}
																<i className='text-danger'>*</i>
															</label>
															{/* <input
                                type="text"
                                className="form-control border-primary "
                                name="phone"
                                pattern="\d*"
                                placeholder={data.regPhone}
                                value={this.state.PhoneNumber}
                                onChange={(e) =>
                                  this.updateState("PhoneNumber", e)
                                }
                              /> */}
															<InputMask
																alwaysShowMask={true}
																// maskChar={null}
																mask='+\9\98999999999'
																className='form-control border-primary'
																// type="phone"
																// placeholder={data.phoneNumberEnter}
																onChange={(e) =>
																	this.updateState('PhoneNumber', e)
																}
																value={this.state.PhoneNumber}
															/>
														</div>
													</div>
													<div className='col-12'>
														<div className='form-group'>
															<label htmlFor>{data.regUploadFileBtn}</label>
															<input
																type='file'
																accept='application/pdf'
																className='form-control border-primary '
																name='address'
																onChange={this.onChangeHandler}
																// value={this.state.selectedFile}
															/>
														</div>
													</div>
												</div>

												<div className='row mt-3'>
													{/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <a
                              href="#"
                              className="btn btn-outline-danger btn-block"
                            >
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              />
                              {data.regDownloadFile}
                            </a>
                          </div>
                        </div>
                       */}
													<div className=' col-12 '>
														<p
															style={{
																textAlign: 'center',
																fontSize: 14,
																color: 'red',
																marginTop: '-10px',
															}}
														>
															* {data.regWarn}
														</p>
														<div
															style={{
																display: 'flex',
																justifyContent: 'center',
															}}
														>
															<input
																style={{
																	marginTop: 10,
																	marginRight: 20,
																	transform: 'scale(2)',
																}}
																onChange={() =>
																	this.setState({
																		checked: !checked,
																	})
																}
																type='checkbox'
																id='vehicle1'
																name='vehicle1'
																value='Bike'
															/>
															{/*  */}

															<div>
																<button
																	type='button'
																	className='btn btn-primary-outline mb-3'
																	data-toggle='modal'
																	data-target='#exampleModal'
																>
																	{data.regNotify}
																</button>

																{/* Modal */}
																<div
																	className='modal fade'
																	id='exampleModal'
																	tabIndex={-1}
																	role='dialog'
																	aria-labelledby='exampleModalLabel'
																	aria-hidden='true'
																>
																	<div className='modal-dialog' role='document'>
																		<div className='modal-content'>
																			<div
																				className='modal-header'
																				style={{
																					textAlign: 'center',
																				}}
																			>
																				<h5
																					style={{
																						fontWeight: 'bold ',
																						// textAlign: "center",
																					}}
																					className='modal-title'
																					id='exampleModalLabel'
																					style={{
																						display: 'block',
																						margin: '0 auto',
																						width: '100%',
																						fontWeight: 'bold ',
																					}}
																				>
																					{data.regNotify}
																				</h5>
																				<button
																					type='button'
																					className='close'
																					data-dismiss='modal'
																					aria-label='Close'
																				>
																					<span aria-hidden='true'>×</span>
																				</button>
																			</div>
																			<div
																				style={{ textAlign: 'justify' }}
																				className='modal-body'
																			>
																				{data.regModalText}
																			</div>
																			<div className='modal-footer'>
																				<button
																					type='button'
																					className='btn btn-secondary'
																					data-dismiss='modal'
																				>
																					{data.regClose}
																				</button>
																				{/* <button
                                          type="button"
                                          className="btn btn-primary"
                                        >
                                          Save changes
                                        </button> */}
																			</div>
																		</div>
																	</div>
																</div>
															</div>

															{/*  */}
														</div>
														<div className='form-group text-center'>
															<button
																// onClick={this.onClickHandler}
																className='btn btn-primary btn-block'
																type='submit'
																disabled={checked ? false : true}
															>
																{data.regSubmit}
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
								<div
									className='tab-pane fade'
									id='profile'
									role='tabpanel'
									aria-labelledby='profile-tab'
								>
									<form
										onSubmit={this.signUp}
										style={{
											maxWidth: '700px',
											margin: 'auto',
										}}
									>
										<div className='card border-primary box-shadow mb-5'>
											<div className='card-body'>
												<div className='row'>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regPhone} <i className='text-danger'>*</i>
															</label>
															<input
																type='number'
																className='form-control border-primary '
																name='phone'
																placeholder={data.regPhonePlace}
																// maxLength={12}
																onChange={(e) =>
																	this.updateState('indPhoneNumber', e)
																}
																value={indPhoneNumber}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>{data.regEmail}</label>
															<input
																type='email'
																className='form-control border-primary email '
																name='email'
																pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
																placeholder={data.regEmailEnt}
																onChange={(e) =>
																	this.updateState('indEmail', e)
																}
																value={indEmail}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regPassword}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='password'
																className='form-control border-primary '
																name='company_name'
																placeholder={data.regPasswordEnt}
																onChange={(e) =>
																	this.updateState('indPassword', e)
																}
																value={indPassword}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regConfirmPass}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='password'
																className='form-control border-primary '
																name='company_name'
																placeholder={data.regConfirmPass}
																onChange={(e) =>
																	this.updateState('indConfirmPassword', e)
																}
																value={indConfirmPassword}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regFullName}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='text'
																className='form-control border-primary '
																defaultValue=''
																name='ConfirmPassword'
																placeholder={data.regFullNameEnt}
																onChange={(e) =>
																	this.updateState('indFullName', e)
																}
																value={indFullName}
															/>
														</div>
													</div>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label htmlFor>
																{data.regPassport}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='text'
																maxLength={9}
																className='form-control border-primary tin '
																name='TIN'
																placeholder={'AA0000000'}
																onChange={(e) => this.updateState('indTIN', e)}
																value={indTIN}
															/>
														</div>
													</div>
													<div className='col-12'>
														<div className='form-group'>
															<label htmlFor>
																{data.regLivingLocation}
																<i className='text-danger'>*</i>
															</label>
															<input
																type='text'
																className='form-control border-primary '
																name='address'
																placeholder={data.regLivingLocationEnt}
																onChange={(e) =>
																	this.updateState('indAdress', e)
																}
																value={indAdress}
															/>
														</div>
													</div>
												</div>
												<div className='files-group'></div>
												<div className='row mt-5'>
													<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-3'>
														<div className='form-group text-center'>
															<button
																className='btn btn-primary btn-block'
																type='submit'
															>
																{data.regSubmit}
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className='row' style={{ display: isReq ? 'block' : 'none' }}>
							<div className='col-md-12'>
								<div className='reg'>
									<div className='reg__box'>
										<h2>{data.requiredCode}</h2>
										<span>{data.enterCode}</span>
										<div>
											<input
												className='inputt'
												type='text'
												placeholder={data.smsCode}
												onChange={(e) => this.updateState('reqCode', e)}
												value={reqCode}
											/>

											<button
												type='button'
												value='submit'
												onClick={() => this.sentVerification()}
											>
												{data.smsConfirm}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='tab-content pt-4' id='myTabContent'>
							<div className='card box-shadow mb-3 ml-5'>
								<form
									onSubmit={(event) => {
										this.props.fetchCheckInn(checkInput.replace('#', ''));
										event.preventDefault();
										window.scrollTo(0, 0);
									}}
									className='card-body '
								>
									<div className='form-group'>
										<label>{data.innTitle}</label>
										<input
											pattern='.{9,9}'
											type='text'
											className='form-control search '
											title={data.innPlaceholder}
											placeholder={data.innPlaceholder}
											value={checkInput}
											onChange={(e) => this.updateText('checkInput', e)}
										/>
										<input
											value={data.checkSearch}
											style={{
												backgroundColor: '#007bff',
												// backgroundColor: "#00bbd1",
												// backgroundColor: "#302b68",
												color: '#fff',
												margin: '0 auto',
												display: 'block',
											}}
											className='btn  mt-3'
											type='submit'
										/>
									</div>
								</form>
							</div>
						</div>
						<div
							style={{ display: checkInput ? 'block' : 'none', marginLeft: 20 }}
						>
							<div
								style={{
									display: CheckInn ? 'block' : 'none',
									marginTop: 24,
								}}
								className='table-responsive'
							>
								{/* <table className="table chiter-table text-center "> */}
								<Table
									// className=" d-none d-lg-block "
									style={{ display: 'none' }}
									striped
									bordered
									responsive
								>
									<tbody>
										<tr className='text-center'>
											{/* <th style={{ verticalAlign: "middle" }}>ID</th> */}
											<th style={{ verticalAlign: 'middle' }}>
												{data.innCompanyName}
											</th>
											<th style={{ verticalAlign: 'middle' }}>
												{data.innProductName}
											</th>
											<th style={{ verticalAlign: 'middle' }}>
												{data.innBankName}
											</th>
											<th style={{ verticalAlign: 'middle' }}>
												{data.innAccountNumber}
											</th>
											<th style={{ verticalAlign: 'middle' }}>{data.innTin}</th>
											<th style={{ verticalAlign: 'middle' }}>{data.innMfo}</th>
											<th style={{ verticalAlign: 'middle' }}>
												{data.innOked}
											</th>
											<th style={{ verticalAlign: 'middle' }}>
												{data.innAddress}
											</th>
											<th style={{ minWidth: '95px', verticalAlign: 'middle' }}>
												{data.innPhone}
											</th>
										</tr>
										<tr className='text-center'>
											{/* <td style={{ verticalAlign: "middle" }}>
                        {CheckInn[0] && CheckInn[0].id}
                      </td> */}
											<td style={{ verticalAlign: 'middle' }}>
												{CheckInn[0] && CheckInn[0].company_name}
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												{CheckInn[0] && CheckInn[0].product_name}
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												{CheckInn[0] && CheckInn[0].bank_name}
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												{CheckInn[0] && CheckInn[0].account_number}
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												{CheckInn[0] && CheckInn[0].tin}
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												{CheckInn[0] && CheckInn[0].mfo}
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												{CheckInn[0] && CheckInn[0].oked}
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												{CheckInn[0] && CheckInn[0].address}
											</td>
											<td style={{ verticalAlign: 'middle' }}>
												+998{CheckInn[0] && CheckInn[0].phone}
											</td>
											{/* <td style={{ verticalAlign: "middle" }}>
                        {CheckInn.data &&
                          CheckInn.data.order &&
                          CheckInn.data &&
                          CheckInn.data.order.created_at.slice(0, 10)}
                      </td> */}
										</tr>
									</tbody>
								</Table>
								{CheckInn?.map((item, index) => {
									return (
										<>
											<Table
												// className="d-lg-none "
												striped
												bordered
												responsive
											>
												<tbody>
													<tr className='text-center'>
														<th style={{ verticalAlign: 'middle' }}>
															{data.innCompanyName}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															{item.company_name}
														</td>
													</tr>
													<tr className='text-center'>
														<th style={{ verticalAlign: 'middle' }}>
															{data.innProductName}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															{item.product_name}
														</td>
													</tr>
													<tr className='text-center'>
														<th style={{ verticalAlign: 'middle' }}>
															{data.innBankName}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															{item.bank_name}
														</td>
													</tr>
													<tr className='text-center'>
														<th style={{ verticalAlign: 'middle' }}>
															{data.innAccountNumber}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															{item.account_number}
														</td>
													</tr>
													<tr className='text-center'>
														<th style={{ verticalAlign: 'middle' }}>
															{data.innTin}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															{item.tin}
														</td>
													</tr>
													<tr className='text-center'>
														<th
															style={{
																minWidth: '95px',
																verticalAlign: 'middle',
															}}
														>
															{data.innMfo}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															{item.mfo}
														</td>
													</tr>
													<tr className='text-center'>
														<th
															style={{
																minWidth: '95px',
																verticalAlign: 'middle',
															}}
														>
															{data.innOked}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															{item.oked}
														</td>
													</tr>
													<tr className='text-center'>
														<th
															style={{
																minWidth: '95px',
																verticalAlign: 'middle',
															}}
														>
															{data.innAddress}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															{item.address}
														</td>
													</tr>
													<tr className='text-center'>
														<th
															style={{
																minWidth: '95px',
																verticalAlign: 'middle',
															}}
														>
															{data.innPhone}
														</th>
														<td style={{ verticalAlign: 'middle' }}>
															+998{item.phone}
														</td>
													</tr>
												</tbody>
											</Table>
											<hr
												style={{
													marginTop: '20px',
													marginBottom: '30px',
													background: 'red',
													display:
														CheckInn?.length == index + 1 ? 'none' : 'block',
												}}
											></hr>
										</>
									);
								})}
							</div>
							<h3
								className='text-center'
								style={{
									// display: CheckInn[0] === undefined ? "block" : "none",
									display: 'none',
								}}
							>
								{data.checkNotFound}
							</h3>
						</div>
					</div>
				</div>
				<div
					style={{
						position: 'fixed',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						zIndex: 1000,
						backgroundColor: 'rgba(0, 0, 0, .5)',
						width: '100%',
						height: '100%',
						display: loader ? 'flex' : 'none',
					}}
				>
					<svg
						style={{
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							margin: 'auto',
						}}
						width={300}
						height={300}
					>
						{/* background */}
						<svg>
							<circle
								cx={150}
								cy={150}
								r={130}
								style={{
									stroke: 'lightblue',
									strokeWidth: 18,
									fill: 'transparent',
								}}
							/>
							<circle cx={150} cy={150} r={115} style={{ fill: '#2c3e50' }} />
							<path
								style={{
									stroke: '#2c3e50',
									strokeDasharray: 820,
									strokeDashoffset: 820,
									strokeWidth: 18,
									fill: 'transparent',
								}}
								d='M150,150 m0,-130 a 130,130 0 0,1 0,260 a 130,130 0 0,1 0,-260'
							>
								<animate
									attributeName='stroke-dashoffset'
									dur='6s'
									to={-820}
									repeatCount='indefinite'
								/>
							</path>
						</svg>
						{/* image */}
						<svg>
							<path
								id='hourglass'
								d='M150,150 C60,85 240,85 150,150 C60,215 240,215 150,150 Z'
								style={{ stroke: 'white', strokeWidth: 5, fill: 'white' }}
							/>
							<path
								id='frame'
								d='M100,97 L200, 97 M100,203 L200,203 M110,97 L110,142 M110,158 L110,200 M190,97 L190,142 M190,158 L190,200 M110,150 L110,150 M190,150 L190,150'
								style={{
									stroke: 'lightblue',
									strokeWidth: 6,
									strokeLinecap: 'round',
								}}
							/>
							<animateTransform
								xlinkHref='#frame'
								attributeName='transform'
								type='rotate'
								begin='0s'
								dur='3s'
								values='0 150 150; 0 150 150; 180 150 150'
								keyTimes='0; 0.8; 1'
								repeatCount='indefinite'
							/>
							<animateTransform
								xlinkHref='#hourglass'
								attributeName='transform'
								type='rotate'
								begin='0s'
								dur='3s'
								values='0 150 150; 0 150 150; 180 150 150'
								keyTimes='0; 0.8; 1'
								repeatCount='indefinite'
							/>
						</svg>
						{/* sand */}
						<svg>
							{/* upper part */}
							<polygon
								id='upper'
								points='120,125 180,125 150,147'
								style={{ fill: '#2c3e50' }}
							>
								<animate
									attributeName='points'
									dur='3s'
									keyTimes='0; 0.8; 1'
									values='120,125 180,125 150,147; 150,150 150,150 150,150; 150,150 150,150 150,150'
									repeatCount='indefinite'
								/>
							</polygon>
							{/* falling sand */}
							<path
								id='line'
								strokeLinecap='round'
								strokeDasharray='1,4'
								strokeDashoffset={200.0}
								stroke='#2c3e50'
								strokeWidth={2}
								d='M150,150 L150,198'
							>
								{/* running sand */}
								<animate
									attributeName='stroke-dashoffset'
									dur='3s'
									to={1.0}
									repeatCount='indefinite'
								/>
								{/* emptied upper */}
								<animate
									attributeName='d'
									dur='3s'
									to='M150,195 L150,195'
									values='M150,150 L150,198; M150,150 L150,198; M150,198 L150,198; M150,195 L150,195'
									keyTimes='0; 0.65; 0.9; 1'
									repeatCount='indefinite'
								/>
								{/* last drop */}
								<animate
									attributeName='stroke'
									dur='3s'
									keyTimes='0; 0.65; 0.8; 1'
									values='#2c3e50;#2c3e50;transparent;transparent'
									to='transparent'
									repeatCount='indefinite'
								/>
							</path>
							{/* lower part */}
							<g id='lower'>
								<path
									d='M150,180 L180,190 A28,10 0 1,1 120,190 L150,180 Z'
									style={{
										stroke: 'transparent',
										strokeWidth: 5,
										fill: '#2c3e50',
									}}
								>
									<animateTransform
										attributeName='transform'
										type='translate'
										keyTimes='0; 0.65; 1'
										values='0 15; 0 0; 0 0'
										dur='3s'
										repeatCount='indefinite'
									/>
								</path>
								<animateTransform
									xlinkHref='#lower'
									attributeName='transform'
									type='rotate'
									begin='0s'
									dur='3s'
									values='0 150 150; 0 150 150; 180 150 150'
									keyTimes='0; 0.8; 1'
									repeatCount='indefinite'
								/>
							</g>
							{/* lower overlay - hourglass */}
							<path
								d='M150,150 C60,85 240,85 150,150 C60,215 240,215 150,150 Z'
								style={{ stroke: 'white', strokeWidth: 5, fill: 'transparent' }}
							>
								<animateTransform
									attributeName='transform'
									type='rotate'
									begin='0s'
									dur='3s'
									values='0 150 150; 0 150 150; 180 150 150'
									keyTimes='0; 0.8; 1'
									repeatCount='indefinite'
								/>
							</path>
							{/* lower overlay - frame */}
							<path
								id='frame'
								d='M100,97 L200, 97 M100,203 L200,203'
								style={{
									stroke: 'lightblue',
									strokeWidth: 6,
									strokeLinecap: 'round',
								}}
							>
								<animateTransform
									attributeName='transform'
									type='rotate'
									begin='0s'
									dur='3s'
									values='0 150 150; 0 150 150; 180 150 150'
									keyTimes='0; 0.8; 1'
									repeatCount='indefinite'
								/>
							</path>
						</svg>
					</svg>
				</div>
				<ToastContainer />
			</div>
		);
	}
}
const cityData = [
	{
		id: 1,
		title: 'Toshkent shahar',
		title_ru: 'город Ташкент',
		title_en: 'Tashkent city',
	},
	{
		id: 2,
		title: 'Toshkent viloyati',
		title_ru: 'Ташкентская область',
		title_en: 'Tashkent region',
	},

	{
		id: 3,
		title: 'Andijon viloyati',
		title_ru: 'Андижанская область',
		title_en: 'Andijan region',
	},
	{
		id: 4,
		title: 'Buxoro viloyati',
		title_ru: 'Бухарская область',
		title_en: 'Bukhara region',
	},
	{
		id: 5,
		title: 'Fargʻona viloyati',
		title_ru: 'Ферганская область',
		title_en: 'Fergana region',
	},
	{
		id: 6,
		title: 'Jizzax viloyati',
		title_ru: 'Джизакская область',
		title_en: 'Jizzakh region',
	},
	{
		id: 7,
		title: 'Xorazm viloyati',
		title_ru: 'Хорезмская область',
		title_en: 'Khorezm region',
	},
	{
		id: 8,
		title: 'Namangan viloyati',
		title_ru: 'Наманганская область',
		title_en: 'Namangan region',
	},
	{
		id: 9,
		title: 'Navoiy viloyati',
		title_ru: 'Навоийская область',
		title_en: 'Navoi region',
	},
	{
		id: 10,
		title: 'Qashqadaryo viloyati',
		title_ru: 'Кашкадарьинская область',
		title_en: 'Qashqadaryo region',
	},
	{
		id: 11,
		title: 'Qoraqalpogʻiston Respublikasi',
		title_ru: 'Республика Каракалпакстан',
		title_en: 'Karakalpakistan Republic',
	},
	{
		id: 12,
		title: 'Samarqand viloyati',
		title_ru: 'Самаркандская область',
		title_en: 'Samarkand region',
	},
	{
		id: 13,
		title: 'Sirdaryo viloyati',
		title_ru: 'Сырдарьинская область',
		title_en: 'Sirdaryo region',
	},
	{
		id: 14,
		title: 'Surxondaryo viloyati',
		title_ru: 'Сурхандарьинская область',
		title_en: 'Surkhondaryo region',
	},
];
function mapStateToProps(state) {
	return {
		mlang: state.languageReducer.language,
		CheckInn: state.fetchDataReducer.CheckInn,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);
