import React from "react"
import SideBar from "../containers/SideBar"

export default function DetailsHOC(children) {
  return (
    <div>
      <div className="full_page">
        <div className="blog_page">
          <div className="container">
            <div className="row">
              {children}
              <div className="col-lg-4">
                <SideBar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
