import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { langData } from "./index.i18n";
import { MainTitle, TitleShort } from "../Titles";
import axios from "axios";
import { FETCH_URL } from "../../constants";
import { Link } from "react-router-dom";

export class FormMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIncognito: false,
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userPhone: "",
      userMessage: "",
    };
  }

  changeText = (title, event) => {
    // console.log(title, event.target.value);
    this.setState({
      [title]: event.target.value,
    });
  };

  submitForm = () => {
    const { mlang } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

    const {
      userFirstName,
      userLastName,
      userEmail,
      userPhone,
      userMessage,
      isIncognito,
    } = this.state;
    if (
      !isIncognito &&
      userFirstName &&
      userLastName &&
      userEmail &&
      userPhone &&
      userMessage
    ) {
      // const data = {
      //   first_name: userFirstName,
      //   last_name: userLastName,
      //   email: userEmail,
      //   phone: userPhone,
      //   msg: userMessage,
      //   type: 1,
      // }
      axios
        .post(`${FETCH_URL}application`, null, {
          params: {
            type: 1,
            msg: userMessage,
            first_name: userFirstName,
            last_name: userLastName,
            email: userEmail,
            phone: userPhone,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                userFirstName: "",
                userLastName: "",
                userEmail: "",
                userPhone: "",
                userMessage: "",
              },
              () => toast.success(data.msgReceived)
            );
          }
        })
        .catch((err) => console.warn(err));
    } else if (isIncognito && userMessage) {
      axios
        .post(`${FETCH_URL}application`, null, {
          params: {
            type: 0,
            msg: userMessage,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                userMessage: "",
              },
              () => toast.success(data.msgReceived)
            );
          }
        })
        .catch((err) => console.warn(err));
    } else {
      toast.error(data.allFieldMustBeFilled);
    }
  };

  render() {
    const { mlang, formSize } = this.props;
    const {
      userFirstName,
      userLastName,
      userEmail,
      userPhone,
      userMessage,
      isIncognito,
    } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    return (
      <div className={`col-md-${formSize}`}>
        <div className="about_detail">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                cursor: "pointer",
                opacity: isIncognito ? 0.3 : 1,
              }}
              onClick={() => this.setState({ isIncognito: false })}
            >
              <TitleShort title={data.contactUs} />
            </div>
            <div
              onClick={() => this.setState({ isIncognito: true })}
              style={{
                cursor: "pointer",
                opacity: isIncognito ? 1 : 0.3,
              }}
            >
              <MainTitle title={data.incognitoContactUs} />
            </div>
          </div>
          <div className="contact__form" id="contact_form">
            <div className="row">
              <div className="col-md-6">
                <input
                  style={{ display: isIncognito ? "none" : "block" }}
                  name="name"
                  id="firstname"
                  className="contact_form_detail"
                  required
                  type="text"
                  placeholder={data.firstName}
                  onChange={(e) => this.changeText("userFirstName", e)}
                  value={userFirstName}
                />
              </div>
              <div className="col-md-6">
                <input
                  style={{ display: isIncognito ? "none" : "block" }}
                  name="lname"
                  id="lastname"
                  className="contact_form_detail"
                  required
                  type="text"
                  placeholder={data.secondName}
                  onChange={(e) => this.changeText("userLastName", e)}
                  value={userLastName}
                />
              </div>
              <div className="col-md-6">
                <input
                  style={{ display: isIncognito ? "none" : "block" }}
                  name="email"
                  id="email"
                  className="contact_form_detail"
                  required
                  type="email"
                  placeholder={data.email}
                  onChange={(e) => this.changeText("userEmail", e)}
                  value={userEmail}
                />
              </div>
              <div className="col-md-6">
                <input
                  style={{ display: isIncognito ? "none" : "block" }}
                  name="phone"
                  id="phone"
                  className="contact_form_detail"
                  required
                  type="tel"
                  placeholder={data.phoneNumber}
                  onChange={(e) => this.changeText("userPhone", e)}
                  value={userPhone}
                />
              </div>
              <div className="col-md-12">
                <textarea
                  name="message"
                  id="message"
                  className="contact_form_detail1"
                  required
                  placeholder={data.messageText}
                  defaultValue={""}
                  // onChange={(e) => console.log("e: ", e.target.value)}
                  onChange={(e) => this.changeText("userMessage", e)}
                  value={userMessage}
                />
              </div>
              <div className="col-md-12">
                <div className="submit_btn_box">
                  <input
                    name="submit"
                    className="submit_btn"
                    type="submit"
                    value={data.sendMessage}
                    onClick={() => {
                      this.submitForm();
                    }}
                  />
                </div>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class FormOpinion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFullName: "",
      userPhone: "",
      userMessage: "",
    };
  }

  changeText = (title, event) => {
    // console.log(title, event.target.value);
    this.setState({
      [title]: event.target.value,
    });
  };

  submitForm = () => {
    const { mlang } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

    const { userFullName, userPhone, userMessage } = this.state;
    if (userFullName && userPhone && userMessage) {
      axios
        .post(`${FETCH_URL}reviews`, null, {
          params: {
            project_id: this.props.reviewId,
            name: userFullName,
            phone: userPhone,
            msg: userMessage,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                userFullName: "",
                userPhone: "",
                userMessage: "",
              },
              () => {
                toast.success(data.msgReceived);
                this.props.handleModal();
              }
            );
          }
        })
        .catch((err) => console.warn(err));
    } else {
      toast.error(data.allFieldMustBeFilled);
    }
  };

  render() {
    const { mlang, formSize, reviewFile } = this.props;
    const { userFullName, userPhone, userMessage } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log("FILE", this.props.reviewFile);
    return (
      <div className={`col-md-${formSize}`}>
        <div className="about_detail">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                // cursor: "pointer",
                // opacity: isIncognito ? 0.3 : 1,
                marginBottom: 20,
              }}
              // onClick={() => this.setState({ isIncognito: false })}
            >
              <TitleShort title={data.giveYourOpinion} />
            </div>
            {/* <div
              onClick={() => this.setState({ isIncognito: true })}
              style={{
                cursor: "pointer",
                opacity: isIncognito ? 1 : 0.3,
              }}
            >
              <MainTitle title={data.incognitoContactUs} />
            </div> */}
          </div>
          <div className="contact__form" id="contact_form">
            <div className="row">
              <div className="col-md-6">
                <input
                  style={{ display: "block" }}
                  name="name"
                  id="firstname"
                  className="contact_form_detail"
                  required
                  type="text"
                  placeholder={data.FIO}
                  onChange={(e) => this.changeText("userFullName", e)}
                  value={userFullName}
                />
              </div>

              <div className="col-md-6">
                <input
                  style={{ display: "block" }}
                  name="phone"
                  id="phone"
                  className="contact_form_detail"
                  required
                  type="number"
                  placeholder={data.phoneNumber}
                  onChange={(e) => this.changeText("userPhone", e)}
                  value={userPhone}
                />
              </div>
              <div className="col-md-12">
                <textarea
                  name="message"
                  id="message"
                  className="contact_form_detail1"
                  required
                  placeholder={data.messageText}
                  defaultValue={""}
                  // onChange={(e) => console.log("e: ", e.target.value)}
                  onChange={(e) => this.changeText("userMessage", e)}
                  value={userMessage}
                />
              </div>

              <div className="col-md-6">
                <div className="submit_btn_box">
                  <a href={`${FETCH_URL}${reviewFile}`}>
                    <button style={{ minWidth: 175 }} className="submit_btn">
                      {data.download}
                    </button>
                  </a>
                </div>
              </div>
              <div className="offset-md-2 col-md-1 ">
                <div className="submit_btn_box">
                  <input
                    style={{ width: "233px", textAlign: "center" }}
                    name="submit"
                    className="submit_btn"
                    type="submit"
                    value={data.sendMessage}
                    onClick={() => {
                      this.submitForm();
                    }}
                  />
                </div>
                <ToastContainer />
              </div>
              {/* <div className="col-lg-3">
                <div className="submit_btn_box">
                  <Link to={`/opinions/${this.props.reviewId}`}>
                    <button style={{ minWidth: 175 }} className="submit_btn">
                      {data.comments}
                    </button>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class FormOpinionSecond extends Component {
  constructor() {
    super();
    this.state = {
      userFullName: "",
      userPhone: "",
      userMessage: "",
    };
  }
  changeText = (title, event) => {
    // console.log(title, event.target.value);
    this.setState({
      [title]: event.target.value,
    });
  };

  submitForm = () => {
    const { mlang, reviewId } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

    const { userFullName, userPhone, userMessage } = this.state;
    if (userFullName && userPhone && userMessage) {
      axios
        .post(`${FETCH_URL}thought/${reviewId}`, null, {
          params: {
            fish: userFullName,
            phone: userPhone,
            commit: userMessage,
          },
        })
        // .then((response) => console.log("Thoughts response: ", response))
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                userFullName: "",
                userPhone: "",
                userMessage: "",
              },
              () => {
                toast.success(data.msgReceived);
              }
            );
          }
        })
        .catch((err) => console.warn(err));
    } else {
      toast.error(data.allFieldMustBeFilled);
    }
  };
  render() {
    const { mlang, reviewFile, title } = this.props;
    const { userFullName, userPhone, userMessage } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    return (
      <div className="contact__form col-lg-12 " id="contact_form">
        <div className="row">
          <div className="col-md-12">
            <h5
              style={{
                textAlign: "right",
                marginBottom: "20px",
                fontWeight: "bold",
              }}
            >
              {title}
            </h5>
          </div>

          <div className="col-md-6">
            <input
              name="name"
              id="firstname"
              className="contact_form_detail"
              required
              type="text"
              placeholder={data.FIO}
              style={{ display: "block" }}
              onChange={(e) => this.changeText("userFullName", e)}
              value={userFullName}
            />
          </div>
          <div className="col-md-6">
            <input
              name="phone"
              id="phone"
              className="contact_form_detail"
              required
              type="number"
              placeholder={data.phoneNumber}
              style={{ display: "block" }}
              onChange={(e) => this.changeText("userPhone", e)}
              value={userPhone}
            />
          </div>
          <div className="col-md-12">
            <textarea
              name="message"
              id="message"
              className="contact_form_detail1"
              required
              placeholder={data.opinionText}
              defaultValue={""}
              // onChange={(e) => console.log("e: ", e.target.value)}
              onChange={(e) => this.changeText("userMessage", e)}
              value={userMessage}
            />
          </div>
          <div
            className="col-md-12 form__buttonss"
            style={{ justifyContent: "start" }}
          >
            {/* <div className="col-md-6">
              <div className="submit_btn_box">
                <a
                // href="https://smsiti.smarttechnology.uz/api/v1/projects/uz/1606124662.doc"
                >
                  <button
                    className="submit_btn"
                    style={{ minWidth: "175px", cursor: "pointer" }}
                  >
                    {data.basketModalCancel}
                  </button>
                </a>
              </div>
            </div> */}
            {/* <div className="offset-10"></div> */}
            <div
              className="col-md-12"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="submit_btn_box">
                <input
                  style={{
                    // width: "233px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  name="submit"
                  className="submit_btn"
                  type="submit"
                  value={data.sendMessage}
                  onClick={() => {
                    this.submitForm();
                  }}
                />
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
