import React, { Component } from "react"
import { bindActionCreators } from "redux"
import * as Actions from "../redux/actions"
import { langData } from "../index.i18n"
import { connect } from "react-redux"

class Pagination extends Component {
  updateState = (id, type) => {
    const { PaginationPage, PaginationLength } = this.props
    if (type === "increase") {
      if (PaginationPage < PaginationLength) {
        this.props.setPagination(PaginationPage + 1)
        this.props.setLoader("NewsListData")
        this.props.fetchNewsListData(PaginationPage + 1)
        window.scrollTo(0, 0)
      }
    } else if (type === "decrease") {
      if (PaginationPage > 1) {
        this.props.setPagination(PaginationPage - 1)
        this.props.setLoader("NewsListData")
        this.props.fetchNewsListData(PaginationPage - 1)
        window.scrollTo(0, 0)
      }
    } else {
      this.props.setPagination(id)
      this.props.setLoader("NewsListData")
      this.props.fetchNewsListData(id)
      window.scrollTo(0, 0)
    }
  }
  render() {
    const { PaginationPage, PaginationLength } = this.props
    let pageNum = []
    for (let i = 0; i < PaginationLength; i++) {
      pageNum.push(i)
    }
    return PaginationLength > 0 ? (
      // 00bbd1
      <div className="pagination">
        <ul>
          <li>
            <a onClick={() => this.updateState(null, "decrease")}>‹</a>
          </li>
          {pageNum.map((index) => (
            <li key={index.toString()}>
              <a
                className={PaginationPage === index + 1 ? "selected" : ""}
                onClick={() => this.updateState(index + 1)}
              >
                {index + 1}
              </a>
            </li>
          ))}
          <li>
            <a onClick={() => this.updateState(null, "increase")}>›</a>
          </li>
        </ul>
      </div>
    ) : null
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    PaginationPage: state.fetchDataReducer.PaginationPage,
    PaginationLength: state.fetchDataReducer.PaginationLength,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
