import Cookies from "js-cookie";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import Loader from "../components/Loader";
import { langData } from "../index.i18n";
import * as Actions from "../redux/actions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FETCH_URL } from "../constants";
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentType: "payme",
    };
  }
  componentDidMount() {
    const { userToken } = this.props;
    if (!userToken) {
      this.props.history.push(`/login/${this.props.match.params.id}`);
    } else {
      this.props.setLoader("OrderData");
      this.props.fetchOrderData(userToken, this.props.match.params.id, "50000");
    }
  }

  submitOrder = () => {
    const { userToken } = this.props;
    const { OrderData } = this.props;
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    // const merchandId = "5f943e7d740f35d3638a9bb5"
    // const base64 = btoa(
    //   `m=${merchandId};ac.order_id=${OrderData.id};a=${OrderData.amount}`,
    // )
    axios(`${FETCH_URL}order/success/${OrderData.id}`, config)
      .then((res) => {
        if (res.data.success) {
          alert("Standart sotib olindi");
          this.props.history.push("/orders");
        } else {
          alert("Standart sotib olinmadi");
        }
      })
      .catch((e) => toast.error(e));

    // axios(`https://checkout.paycom.uz/${base64}`).then((res) =>
    //   console.log("payment res: ", res),
    // )
  };

  render() {
    const { mlang, OrderData, LoadingOrderData, isLogged } = this.props;
    const { paymentType } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

    // console.log("chekcout order data: ", OrderData);
    return LoadingOrderData ? (
      <Loader />
    ) : !LoadingOrderData && !isLogged ? (
      (this.props.setLoader("OrderData"),
      this.props.history.push(`/login/${this.props.match.params.id}`),
      null)
    ) : (
      <div>
        <div className="full_page">
          <section className="auth">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile">
                    <div className="che__left">
                      <h3>{data.yourOrder}</h3>
                      <ul>
                        <li
                          onClick={() => this.props.history.goBack()}
                          style={{ cursor: "pointer" }}
                        >
                          <b>{OrderData.standart.name_standart}</b>
                          <span style={{ width: "100px" }}>
                            {OrderData.amount}
                          </span>
                        </li>
                      </ul>
                      <div className="che__payment">
                        <h3>{data.paymentMethod}</h3>
                        <div
                          onChange={(e) =>
                            this.setState({ paymentType: e.target.value })
                          }
                        >
                          <label htmlFor="p-1">
                            <input
                              type="radio"
                              name="pay"
                              id="p-1"
                              value="payme"
                              defaultChecked
                            />
                            <img
                              src="assets/images/payme.svg"
                              alt=""
                              style={{ marginRight: 35 }}
                            />
                          </label>

                          <label htmlFor="p-2">
                            <input
                              type="radio"
                              name="pay"
                              id="p-2"
                              value="click"
                            />
                            <img src="assets/images/click-logo.png" alt="" />
                          </label>
                        </div>
                        <button
                          // id="nextbtn"
                          // data-toggle="modal"
                          // data-target="#exampleModalCenter"
                          onClick={() => this.submitOrder()}
                        >
                          {data.continueButton}
                        </button>
                      </div>
                    </div>
                    <div className="che__right">
                      <h3>{data.personalDetails}</h3>
                      <div className="che__input">
                        <span>{data.FIO}*</span>
                        <input
                          disabled
                          value={`${OrderData.user.first_name} ${OrderData.user.last_name}`}
                          type="text"
                        />
                      </div>
                      <div className="che__input">
                        <span>{data.email}</span>
                        <input
                          disabled
                          value={OrderData.user.email}
                          type="text"
                        />
                      </div>
                      <div className="che__input">
                        <span>{data.phoneNumber}</span>
                        <input
                          disabled
                          value={OrderData.user.phone}
                          type="text"
                        />
                      </div>

                      <h3>{data.comment}</h3>
                      <span>{data.reminderToOrder}</span>
                      <textarea
                        placeholder={data.reminderToOrderText}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Karta malulotlarini kiriting
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form action="#">
                    <span>Card</span>
                    <input type="text" placeholder="Номер карты" />
                    <input type="text" placeholder="ММ / ГГ" />
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary">
                    To'lov qilish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    userToken: state.fetchDataReducer.userToken,
    isLogged: state.fetchDataReducer.isLogged,
    OrderData: state.fetchDataReducer.OrderData,
    LoadingOrderData: state.fetchDataReducer.LoadingOrderData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
