import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../redux/actions";
import {langData} from "../index.i18n";
import {Link} from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import Cookies from "js-cookie";
import {toast, ToastContainer} from "react-toastify";
import {FETCH_URL} from "../constants";
import OrderTable from "../components/Tables";
import {Alert, Badge, Button, message, Space} from "antd";

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            password: "",
            username: "",
            notifications: []
        };
    }

    componentDidMount() {
        const {userToken} = this.props;
        this.getNotifications()
        // console.log(" profile token: ", userToken);

        if (!userToken) {
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            this.props.fetchUserData(userToken);
        }
    }

    getNotifications = () => {
        const {userToken} = this.props;
        axios

            .get(`${FETCH_URL}notifications`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            })
            .then((response) => {
                if (response.data.success) {
                    this.setState({notifications: response?.data?.data})
                }
            })
            .catch((e) => console.log("error: ", e));
    }

    readNotification = (id) => {
        const {userToken} = this.props;
        axios.post(
            `${FETCH_URL}notifications/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            }
        ).then(resp => {
            this.getNotifications();
        }).catch(err => {
            message.error("Error!")
        })
    }

    render() {
        const {mlang, LoadingUserData, UserData, isLogged} = this.props;
        const {notifications} = this.state;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        let fullName = "";
        if (UserData.first_name && UserData.last_name) {
            fullName = `${UserData.first_name} ${UserData.last_name}`;
        } else {
            fullName = UserData.username;
        }
        const statuses = {
            1: {
                text: data?.type_1,
                className: "info"
            },
            2: {
                text: data?.type_2,
                className: "warning"
            },
            3: {
                text: data?.type_3,
                className: "error"
            }
        }
        // console.log("FUll name", fullName);
        return LoadingUserData && isLogged ? (
            <Loader/>
        ) : !LoadingUserData && !isLogged ? (
            (this.props.setLoader("UserData"),
                this.props.history.push("/login"),
                null)
        ) : (
            <div>
                <div className="full_page">
                    <section className="auth">
                        <div className="container">
                            <div className="row">
                                {/*<div className="col-md-12 mt-4">*/}
                                {/*    <Alert showIcon className="mb-0" type={"success"} description={`Siz O’zbekiston standartlar institutidan xarid qilgan */}
                                {/*    (o’zgartirilayotgan standart) standart Buyruq qarorga*/}
                                {/*     asosan 1-sonli o’zgartirish ( Amalga kirish sanasi) dan*/}
                                {/*      amalga kiritiladi (O’zgarish kiritilgan standart qavs ichida).`}*/}
                                {/*           message={"Bildirishnoma!"}/>*/}
                                {/*    /!*<Alert showIcon className="my-3" type={"warning"} description={`Siz O’zbekiston standartlar institutidan xarid qilgan (bekor qilinadigan standart)*!/*/}
                                {/*    /!* standart Buyruq qaror ga asosan (bekor bo’lish sanasi) dan bekor qilinadi.`}*!/*/}
                                {/*    /!*       message={"Bildirishnoma!"}/>*!/*/}
                                {/*    /!*<Alert showIcon className="mb-0" type={"info"} description={`Siz O’zbekiston standartlar institutidan xarid qilgan (muddati uzaytrilayotgan standart) standart Buyruq*!/*/}
                                {/*    /!*qaror ga asosan (qachondan uzaytirilish sanasi)*!/*/}
                                {/*    /!* dan (qachongacha) gacha muddati uzaytirildi.`} message={"Bildirishnoma!"}/>*!/*/}
                                {/*</div>*/}
                                <div className="col-md-12 mt-4">
                                    <div className="profile">
                                        <div className="pro__left">
                                            <div className="pro__avatar">
                                                <div
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                        // background: "red",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        style={{width: "60%", height: "60%"}}
                                                        src="/assets/images/user.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="pro__text">
                                                    {/* <span>{`${UserData.first_name} ${UserData.last_name}`}</span> */}
                                                    <span>{fullName}</span>
                                                    <a href={`mailto:${UserData.email}`}>
                                                        {UserData.email}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="pro__nav">
                                                <ul>
                                                    <li>
                                                        <Link to="/profile">
                                                            <img
                                                                src="assets/images/user-profile2.svg"
                                                                alt=""
                                                            />
                                                            {data.profileSettings}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/orders">
                                                            <img src="assets/images/orders.svg" alt=""/>
                                                            {data.myOrders}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/subscription">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.subscriptions}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/reesters">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.view_reestr}
                                                        </Link>
                                                    </li>
                                                    <Badge className="w-100" count="999" overflowCount={10}>
                                                        <li className="activee">
                                                            <Link to="/notifications">
                                                                <img src="assets/images/subscription.svg" alt=""/>
                                                                {data.notifications}
                                                            </Link>
                                                        </li>
                                                    </Badge>

                                                    {/* <li>
                            <Link to="/">
                              <img
                                src="assets/images/heart-shape-outline.svg"
                                alt=""
                              />
                              {data.favorites}
                            </Link>
                          </li> */}
                                                    <li
                                                        onClick={() => {
                                                            this.props.setToken("");
                                                            Cookies.remove("userToken");
                                                            Cookies.remove("accessToken");
                                                            Cookies.remove("userCode");
                                                        }}
                                                    >
                                                        <Link to="/">
                                                            <img src="assets/images/power.svg" alt=""/>
                                                            {data.signOutButton}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="pro__settings">
                                            <h2>{data.notifications}</h2>
                                            <div>
                                                <div className="row" style={{
                                                    maxHeight: 500,
                                                    overflowY: "auto",
                                                    overflowX: "visible"
                                                }}>

                                                    {/*<table className="chiter-table table table-sm table-bordered">*/}
                                                    {/*    <thead>*/}
                                                    {/*    <tr>*/}
                                                    {/*        <th>№</th>*/}
                                                    {/*        <th>{data.comment}</th>*/}
                                                    {/*        <th>{data.decision}</th>*/}
                                                    {/*        <th style={{width: 160}}>{data.decision_date}</th>*/}
                                                    {/*        <th style={{width: 160}}>{data.docDate}</th>*/}
                                                    {/*        <th style={{width: 160}}>{data.status}</th>*/}
                                                    {/*    </tr>*/}
                                                    {/*    </thead>*/}
                                                    {/*    <tbody>*/}
                                                    {/*    {*/}
                                                    {/*        notifications?.data?.map((item, key) => <tr key={key}>*/}
                                                    {/*            <td>{key + 1}</td>*/}
                                                    {/*            <td>{item?.comment}</td>*/}
                                                    {/*            <td>{item?.lex}</td>*/}
                                                    {/*            <td>{item?.lex_date}</td>*/}
                                                    {/*            <td>{item?.implementation_date}</td>*/}
                                                    {/*            <td>*/}
                                                    {/*                <span*/}
                                                    {/*                    className={`text-light ${statuses?.[item?.type]?.className} px-1 rounded mb-0 py-2`}>*/}
                                                    {/*                    {statuses?.[item?.type]?.text}*/}
                                                    {/*                </span>*/}
                                                    {/*            </td>*/}
                                                    {/*        </tr>)*/}
                                                    {/*    }*/}
                                                    {/*    </tbody>*/}
                                                    {/*</table>*/}
                                                    <div className="col-md-12 mt-4">
                                                        {notifications?.data?.map((item, key) => <Alert showIcon
                                                                                                        action={
                                                                                                            item?.viewed_at ? "" :
                                                                                                                <Button
                                                                                                                    onClick={() => this.readNotification(item?.id)}>
                                                                                                                    <img
                                                                                                                        width={16}
                                                                                                                        src="assets/images/check_icon.png"
                                                                                                                        alt=""/>
                                                                                                                </Button>
                                                                                                        }
                                                                                                        className="my-2"
                                                                                                        type={statuses?.[item?.type].className}
                                                                                                        description={item?.message?.[mlang]}
                                                                                                        // description={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores aspernatur deleniti distinctio, eveniet ex fugit in maxime minus, modi nihil nostrum sapiente tenetur velit! Architecto blanditiis ea laboriosam nemo unde."}
                                                                                                        message={statuses?.[item?.type].text}/>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer/>
                    </section>
                </div>
                {/* /scroll_to_top start */}
                <Link to="/" className="scrollToTop">
                    <i className="fa fa-arrow-up" aria-hidden="true"/>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        userToken: state.fetchDataReducer.userToken,
        UserData: state.fetchDataReducer.UserData,
        isLogged: state.fetchDataReducer.isLogged,
        LoadingUserData: state.fetchDataReducer.LoadingUserData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
