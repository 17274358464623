import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import ContactUs from "./components/ContactUs";
import Loader from "./components/Loader";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./containers/Footer";
import Navbar from "./containers/Navbar";
import GuardedRoute from "./helpers/GuardedRoute";
import DetailsHOC from "./HOC/DetailsHOC";
import ErrorPage from "./Pages/404.js";
import Appeals from "./Pages/Appeals";
import Checkout from "./Pages/Checkout";
import Contacts from "./Pages/Contacts";
import Council from "./Pages/Council";
import Departments from "./Pages/Departments";
import Documents from "./Pages/Documents";
import EmailVerify from "./Pages/EmailVerify";
import Gallery from "./Pages/Gallery";
import Login from "./Pages/Login";
import MainScreen from "./Pages/MainScreen";
import Management from "./Pages/Management";
import Manager from "./Pages/Manager";
import MyOrders from "./Pages/MyOrders/index";
import NewsDetails from "./Pages/NewsDetails";
import NewsScreen from "./Pages/NewsScreen";
import Opinions from "./Pages/Opinions";
import PhonePage from "./Pages/PhonePage";
import Profile from "./Pages/Profile";
import Search from "./Pages/Search";
import ServiceDetails from "./Pages/ServiceDetails";
import ShopDetails from "./Pages/ShopDetails";
import ShopScreen from "./Pages/ShopScreen";
import Staff from "./Pages/Staff";
import TasksFunctions from "./Pages/TasksFunctions";
import Cart from "./Pages/Cart";
import Vacancy from "./Pages/Vacancy";
import CheckStandart from "./Pages/CheckStandart";
import Register from "./Pages/Register";
import * as Actions from "./redux/actions";
import Feedback from "./Pages/Feedback";
import Committee from "./Pages/Committee";
import Restore from "./Pages/Restore";
import ExitOrganization from "./Pages/ExitOrganization";
import InstituteStructure from "./Pages/InstituteStructure";
import InstituteDetails from "./Pages/InstituteDetails";
import InformationDetails from "./Pages/InformationDetails";
import BudgetScreen from "./Pages/BudgetScreen";
import Fonds from "./Pages/Fonds/Fonds";
import Fond2 from "./Pages/Fonds/Fond2";
import shop2 from "./Pages/ShopScreen/shop2";
import Subscriptions from "./Pages/Subscriptions";
import Reesters from "./Pages/Reesters";
import Notifications from "./Pages/Notifications";
import RegistryProfile from "./Pages/RegistryProfile";
import StandartRegisterAndDelete from "./Pages/standartRegisterAndDelete";
import Employees from "./Pages/Employees";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userLang: this.props.mlang,
      currentURL: null,
    };
  }
  componentDidMount() {
    const { userToken } = this.props;
    // Cookies.remove("userToken")
    const token = Cookies.get("userToken");
    // console.log("token userToken: ", token);
    // console.log("props UserToken: ", userToken);
    const userLang = Cookies.get("userLang");
    this.props.changeLanguage(userLang ?? "uz");
    this.props.fetchMainPageData(this.props.mlang);
    this.getUserIPAdress();
    this.props.setToken(token);
    // this.props.fetchTendersData()
    // this.props.fetchNewsListData(1)
    // this.props.fetchUserData(token)

    let currUrl = window.location.href;
    let isCurUrl = currUrl.includes("code");
    let cutUrl = currUrl.substring(22, 58);
    // let cutUrl = currUrl.substring(15, 51);

    // console.log("isCurUrl", isCurUrl);
    this.setState({
      currentURL: isCurUrl ? cutUrl : null,
    });
  }

  setLang() {
    this.setState({
      userLang: this.props.mlang,
      bgColor: false,
    });
  }
  changeColor = () => {
    this.setState(({ bgColor }) => {
      return {
        bgColor: !bgColor,
      };
    });
  };
  getUserIPAdress = () => {
    axios("https://api.ipify.org/?format=json")
      .then((res) => {
        Cookies.set("userIp", res.data.ip);
      })
      .catch((err) => console.log("error: ", err));
  };

  componentDidUpdate() {
    const { userToken } = this.props;
    const token = Cookies.get("userToken");
    if (this.props.mlang !== this.state.userLang) {
      this.props.setLoader("LoadingMainScreen");
      this.setLang();
      this.props.fetchMainPageData(this.props.mlang);
    }
    if (token && !userToken) {
      this.props.setToken(token);
    }
    if (!token && userToken) {
      this.props.setToken("");
    }
  }

  render() {
    // console.log("history: ", this.props.history);
    const userToken = Cookies.get("userToken");
    // console.log("cookies: ", userToken);
    // console.log("isLogged:  ");
    // console.log("current url", this.state.currentURL);
    let bgChanged = "";
    if (this.state.bgColor) {
      bgChanged += "grayColor";
    }

    // let element = document.querySelector("body");
    // // console.log("ELEL", element);

    // // The element to be appended
    // let child = document.createElement("<div> </div>");
    // child.innerHTML = "Child Container";

    // append
    // element.appendChild(child);
    // let scriptcha = document.createElement("script");
    // scriptcha.src =
    //   "https://code.responsivevoice.org/responsivevoice.js?key=kuFb4YvW";
    // document.body.appendChild(scriptcha);
    // let hasan = document.createElement("div");

    // function loadScript() {
    //   var script = document.createElement("script");
    //   script.type = "text/javascript";
    //   script.src =
    //     "https://maps.googleapis.com/maps/api/js?sensor=false&callback=initialize";
    //   document.body.appendChild(script);
    //   console.log("loadScript");
    // }

    // function initialize() {
    //   console.log("initialize");
    // }

    // window.onload = loadScript;

    // console.log("DKERY", hasan);
    // document.body.innerHTML +=
    //   '<script src="https://code.responsivevoice.org/responsivevoice.js?key=kuFb4YvW"></script>';
    // document.querySelector("body").innerHTML =
    // '<script src="https://code.responsivevoice.org/responsivevoice.js?key=kuFb4YvW"></script>';

    return this.props.LoadingMainScreen ? (
      <Loader />
    ) : (
      <BrowserRouter>
        <div className={bgChanged}>
          <Navbar grayBtn={this.changeColor} />
          <Switch>
            <Route path="/" component={MainScreen} exact />
            <Route
              exact
              path="/news"
              component={() => DetailsHOC(<NewsScreen />)}
            />
            <Route
              exact
              path="/budget"
              component={() => DetailsHOC(<BudgetScreen />)}
            />
            {/*<Route exact path="/organization" component={ExitOrganization} />*/}
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/fonds" component={Fonds} />
            <Route exact path="/fond2" component={Fond2} />
            <Route exact path="/contacts" component={Contacts} />
            <Route exact path="/shop" component={ShopScreen} />
            <Route exact path="/shop2" component={shop2} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/opinions/:id" component={Opinions} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/register" component={Register} />
            <Route
              exact
              path="/council"
              component={() => DetailsHOC(<Council />)}
            />
            <Route exact path="/staff" render={() => DetailsHOC(<Staff />)} />
            {/* <Route
              exact
              path="/staff/:id"
              render={(props) => DetailsHOC(<Staff parameters={props} />)}
            /> */}
            <GuardedRoute
              path="/profile"
              component={Profile}
              auth={userToken}
            />
            <GuardedRoute
              path="/orders"
              component={MyOrders}
              auth={userToken}
            />
            <GuardedRoute
              path="/notifications"
              component={Notifications}
              auth={userToken}
            />
            <GuardedRoute
                path="/subscription"
                component={Subscriptions}
                auth={userToken}
            />
            <GuardedRoute
                path="/reesters"
                component={Reesters}
                auth={userToken}
            />
            <GuardedRoute
                path="/registry/:id"
                component={RegistryProfile}
                auth={userToken}
            />
            <Route
                exact
                path="/management"
                component={() => DetailsHOC(<Management />)}
            />
            <Route
                exact
                path="/employees"
                component={() => DetailsHOC(<Employees />)}
            />
            <Route
              exact
              path="/tasks"
              component={() => DetailsHOC(<TasksFunctions />)}
            />
            <Route
              exact
              path="/structure"
              component={() => DetailsHOC(<InstituteStructure />)}
            />
            <Route
              exact
              path="/vacancy"
              component={() => DetailsHOC(<Vacancy />)}
            />
            <Route exact path="/check-standart" component={CheckStandart} />
            <Route
              exact
              path="/phones"
              component={() => DetailsHOC(<PhonePage />)}
            />
            <Route
              exact
              path="/appeals"
              component={() => DetailsHOC(<Appeals />)}
            />
            <Route exact path={`/documents/:id`} component={Documents} />
            <Route exact path="/committee/:id" component={Committee} />
            <Route exact path="/management/:id" component={Manager} />
            <Route exact path="/news/:id" component={NewsDetails} />
            <Route exact path="/services/:id" component={ServiceDetails} />
            <Route exact path="/institute/:id" component={InstituteDetails} />
            <Route
              exact
              path="/information/:id"
              component={InformationDetails}
            />
            <Route exact path="/standartRegisterAndDelete" component={StandartRegisterAndDelete} />
            <Route exact path="/departments/:id" component={Departments} />
            <Route exact path="/search/:id?" component={Search} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/restore" component={Restore} />
            <Route exact path="/login/:id" component={Login} />
            <Route exact path="/shop/:id" component={ShopDetails} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/checkout/:id" component={Checkout} />
            <Route exact path="/verify/:id" component={EmailVerify} />
            <Route component={ErrorPage} />

            {/* <Route exact path="/F/:id" component={Tenders}   /> */}
          </Switch>
          <ScrollToTop />

          <Footer />
          <ContactUs />
        </div>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    userToken: state.fetchDataReducer.userToken,
    isLogged: state.fetchDataReducer.isLogged,
    LoadingMainScreen: state.fetchDataReducer.LoadingMainScreen,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
