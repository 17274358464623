import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../components/Loader";
import NewsCard from "../components/NewsCard";
import { Pagination } from "react-laravel-paginex";
import { langData } from "../index.i18n";
import * as Actions from "../redux/actions";
import BudgetCard from "../components/BudgetCard";

class BudgetScreen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchBudgetData(1);
  }
  getData = (data) => {
    this.props.fetchBudgetData(data.page);
    window.scrollTo(0, 0);
  };

  render() {
    const { mlang, BudgetData, LoadingBudgetData } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    console.log("BudgetData :", BudgetData);

    return LoadingBudgetData ? (
      <Loader />
    ) : (
      <div className="col-xl-8 col-lg-8">
        <div className="row">
          {BudgetData.data.map((item) => (
            <BudgetCard
              //   widthSize={6}
              //   detailsButton={data.details}
              data={item}
              mlang={mlang}
              key={item.id.toString()}
            />
            // <NewsCard
            //   widthSize={6}
            //   detailsButton={data.details}
            //   data={item}
            //   mlang={mlang}
            //   key={item.id.toString()}
            // />
          ))}
        </div>
        <div
          className="row"
          style={{
            display: BudgetData.last_page === 1 ? "none" : "block",
          }}
        >
          <div className="col-md-12 d-flex justify-content-center">
            <Pagination
              changePage={this.getData}
              data={this.props.BudgetData}
              nextButtonText={data.next}
              prevButtonText={data.previous}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    BudgetData: state.fetchDataReducer.BudgetData,
    LoadingBudgetData: state.fetchDataReducer.LoadingBudgetData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(BudgetScreen);
