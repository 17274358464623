import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as Actions from "../redux/actions"
import { langData } from "../index.i18n"
import { Link } from "react-router-dom"

function ErrorPage({ mlang }) {
  const data =
    mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en

  return (
    <div>
      <div className="full_page">
        <div className="wrapper1">
          <main className="content1">
            <div className="error_con">
              <img
                className="img-fluid"
                src="assets/images/404.png"
                alt="error images"
              />
              <h1>{data.sorryText}</h1>
              <p>
                <br />
              </p>
              <Link to="/" className="back_to_home">
                {data.backButton}
              </Link>
            </div>
          </main>
        </div>
        {/* <div className="error_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="error_detail">
                  <p>© NetBizz All Rights Reserved. Terms | Privacy</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Link to="#" className="scrollToTop">
        <i className="fa fa-arrow-up" aria-hidden="true" />
      </Link>
      {/* /scroll_to_top start */}
      <Link to="#" className="scrollToTop">
        <i className="fa fa-arrow-up" aria-hidden="true" />
      </Link>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage)
