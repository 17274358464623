// import { data } from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import { IMAGE_URL } from "../../constants";
import SideBar from "../../containers/SideBar";
import * as Actions from "../../redux/actions";
import { langData } from "../../index.i18n";
// import CalendarCard from "../../containers/CalendarCard";
import { _translator } from "../../helpers";

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: "1991-01-01",
      dateTo: "2050-01-01",
    };
  }
  componentDidMount() {
    const { dateFrom, dateTo } = this.state;
    window.scrollTo(0, 0);
    this.props.setLoader("DocumentsData");
    this.props.fetchDetailsData(
      "DocumentsData",
      this.props.match.params.id,
      1,
      null,
      dateFrom,
      dateTo
    );
  }

  filterDocs = () => {
    const { dateFrom, dateTo } = this.state;
    this.props.fetchDetailsData(
      "DocumentsData",
      this.props.match.params.id,
      1,
      null,
      dateFrom,
      dateTo
    );
  };
  render() {
    const { mlang, DocumentsData, LoadingDocumentsData } = this.props;
    // console.log("documentsData: ", DocumentsData);
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    return LoadingDocumentsData ? (
      <Loader />
    ) : (
      <div className="full_page">
        <div className="blog_page">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8  chiter nashr">
                <div className="table-responsive">
                  <div className="nashr__filer">
                    <span>{data.docFilter}:</span>
                    <input
                      type="date"
                      onChange={(e) =>
                        this.setState({
                          dateFrom: e.target.value,
                        })
                      }
                    />
                    <i>__</i>
                    <input
                      type="date"
                      onChange={(e) =>
                        this.setState({
                          dateTo: e.target.value,
                        })
                      }
                    />
                    <button type="submit" onClick={() => this.filterDocs()}>
                      {data.docShow}
                    </button>
                  </div>
                  <ul className="nashr__main">
                    {DocumentsData.data.map((document) => (
                      <li key={document.id.toString()}>
                        <img src="assets/images/document.png" alt="" />
                        <div className="nashr__text">
                          <a
                            style={{ color: "#000" }}
                            href={
                              mlang === "uz"
                                ? document.link
                                : mlang === "ru"
                                ? document.link_ru
                                : document.link_en
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {_translator("title", document, mlang)}
                          </a>
                          <span>
                            {data.docDate}: {document.date}
                          </span>
                          <span>
                            {data.docNumber}:{" "}
                            {mlang === "uz"
                              ? document.registry
                              : mlang === "ru"
                              ? document.registry_ru
                              : document.registry_en}
                          </span>
                          <a
                            style={{
                              fontSize: 14,

                              marginTop: 10,
                              color: "#000",
                            }}
                            href={
                              mlang === "uz"
                                ? IMAGE_URL + document.file
                                : mlang === "ru"
                                ? IMAGE_URL + document.file_ru
                                : IMAGE_URL + document.file_en
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {data.docDownload}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <Pagination />
                </div>
              </div>

              <div className="col-lg-4">
                <SideBar />
                {/* <CalendarCard /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    DocumentsData: state.fetchDataReducer.DocumentsData,
    LoadingDocumentsData: state.fetchDataReducer.LoadingDocumentsData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
