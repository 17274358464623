import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../redux/actions";
import {langData} from "../index.i18n";
import {Link} from "react-router-dom";
import {_translatorTitle} from "../helpers";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {FETCH_URL, IMAGE_URL} from "../constants";
import {FormOpinionSecond} from "../components/Forms";
import {Table} from "react-bootstrap";
import {Statistic} from "antd";

const {Countdown} = Statistic;

export class Feedback extends Component {
    constructor() {
        super();
        this.state = {
            hidden: true,
            hiddenOthers: true,
            hiddenOthersToo: true,
            textStandart: "",
            textExplain: "",
            fileStandart: "",
            fileExplain: "",
            isStandart: false,
            isStandartSec: false,
            projectId: 0,
            thoughts: [],
            comitID: 0,
            comitText: "",
            selectedType: [],
            selectedDiscuss: [],
            selectedLast: [],
            showStat: false,
            showProg: false,
            showProgressIcon: false,
            showDoneIcon: false,
            hideStat: false,
            hideForm: false,
        };
    }

    componentDidMount() {
        // this.props.fetchDiscussStandartsTypeData("in_progress");
        // this.props.fetchDiscussStandartsTypeData("finished");
        // this.props.fetchDiscussStandartsData();
        window.scrollTo(0, 0);
    }

    render() {
        const {mlang, standartTypesData, ThoughtsData} = this.props;
        const {
            hidden,
            hiddenOthers,
            hiddenOthersToo,
            thoughts,
            selectedType,
            selectedDiscuss,
            selectedLast,
            isStandart,
            isStandartSec,
            fileStandart,
            fileExplain,
            showStat,
            showProg,
            showDoneIcon,
            showProgressIcon,
            hideStat,
            hideForm,
        } = this.state;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

        // console.log("Standart types: ", standartTypesData);
        // console.log("ProjectData types: ", this.state.projectId);
        // console.log("ProjectData types: ", this.state.fileStandart);

        // console.log("projectId: ", this.state.projectId);
        // console.log("PRO DATA: ", this.props.ProjectData);
        // console.log("comit Text: ", this.state.comitText);
        // console.log("selected Text: ", this.state.selectedType);
        // console.log("selected discuss: ", this.state.selectedDiscuss);
        // console.log("selected selectedLast: ", this.state.selectedLast);
        // console.log("DISCUSS ALL", this.props.StandartData);
        // console.log("DISCUSS IN PROG", this.props.StandartDataType);
        // console.log("DISCUSS FINISH", this.props.StandartDataType);
        let comitTextLet =
            mlang === "uz"
                ? `Mazkur loyiha ${this.state.comitText} qo'mitasi
    tomonidan joylandi`
                : mlang === "ru"
                    ? `Проект выполнен по заказу Комитета 
         ${
                        this.state.comitText === "Институт Стандартов"
                            ? "Институтом стандартов"
                            : this.state.comitText
                    }`
                    : `The project was commissioned by the Committee on ${this.state.comitText}`;

        return (
            <div>
                {/* <div style={{ display: "none" }}>
          {ThoughtsData.data.map((thought) => (
            <div className="col-md-6 pr-1">
              <div className="calendar__right">
                <a
                  onClick={() => {
                    this.setState({
                      thoughts: thought,
                    });
                  }}
                  className="a-before"
                ></a>
              </div>
            </div>
          ))}
        </div> */}
                <div className="full_page">
                    {/* /Faq start*/}
                    <section className="auth">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tadbir">
                                        <div className="tadbir__calendar">
                                            <div className="section_title mt-2 mb-5">
                                                <h2>{data.feedbackText}</h2>
                                                <img
                                                    className="img-fluid mt-3"
                                                    style={{
                                                        display: "block",
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                    }}
                                                    src="assets/images/title_border.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="row mb-3 font-weight-bold">
                                                <div
                                                    className="col-lg-4 col-md-6"
                                                    style={{
                                                        display: showStat || showProg ? "none" : "block",
                                                    }}
                                                >
                                                    <div className="list-group">
                                                        {standartTypesData.types.map((item) => (
                                                            <li
                                                                style={{listStyleType: "none"}}
                                                                key={item.id + ""}
                                                            >
                                <span
                                    onClick={() => {
                                        this.props.fetchProjectData(item.id);
                                        this.setState({
                                            selectedType: item.id,
                                            isStandart: false,
                                            hiddenOthers: true,
                                            hiddenOthersToo: true,
                                            hidden: false,
                                            hideStat: true,
                                        });
                                    }}
                                    style={{
                                        backgroundColor:
                                            selectedType && selectedType === item.id
                                                ? "#00bbd1"
                                                : "white",
                                        cursor: "pointer",
                                        borderColor:
                                            selectedType && selectedType === item.id
                                                ? "#00bbd1"
                                                : "rgba(0, 0, 0, 0.125)",
                                        color:
                                            selectedType && selectedType === item.id
                                                ? "white"
                                                : "black",
                                    }}
                                    className="list-group-item list-group-item-action"
                                    // to={`/committee/${item.id}`}
                                >
                                  {_translatorTitle("", item, mlang)}
                                </span>
                                                            </li>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-lg-4 col-md-6"
                                                    style={{maxHeight: "292px", overflow: "auto"}}
                                                >
                                                    <div
                                                        className={`list-group ${
                                                            hidden ? " hiddenBlock" : " "
                                                        }`}
                                                    >
                                                        {this.props.ProjectData.map((project) => (
                                                            <a
                                                                style={{
                                                                    cursor: "pointer",
                                                                    backgroundColor:
                                                                        selectedDiscuss &&
                                                                        selectedDiscuss === project.id
                                                                            ? "#00bbd1"
                                                                            : "white",
                                                                    borderColor:
                                                                        selectedDiscuss &&
                                                                        selectedDiscuss === project.id
                                                                            ? "#00bbd1"
                                                                            : "rgba(0, 0, 0, 0.125)",
                                                                    color:
                                                                        selectedDiscuss &&
                                                                        selectedDiscuss === project.id
                                                                            ? "white"
                                                                            : "black",
                                                                }}
                                                                onClick={() => {
                                                                    console.log(project)
                                                                    let date = new Date(project.created_at);
                                                                    date = date.getTime();
                                                                    let extra = 60 * 60 * 24
                                                                    date = date + extra * 60 * 1000
                                                                    this.setState({
                                                                        projectId: project.id,
                                                                        comitID: project.committee_id,
                                                                        comitText:
                                                                            mlang === "uz"
                                                                                ? project.committee.title_uz
                                                                                : mlang === "ru"
                                                                                    ? project.committee.title_ru
                                                                                    : project.committee.title_en,
                                                                        selectedDiscuss: project.id,

                                                                        isStandart: false,
                                                                        isStandartSec: false,
                                                                        hiddenOthersToo: true,
                                                                        hiddenOthers: false,
                                                                        issue_date: date - Date.now() > 0 ? date : 0
                                                                    });
                                                                }}
                                                                className="list-group-item list-group-item-action"
                                                            >
                                                                {_translatorTitle("", project, mlang)}
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div
                                                        className={`list-group ${
                                                            hiddenOthers ? " hiddenBlock" : " "
                                                        }`}
                                                    >
                                                        <a
                                                            style={{
                                                                cursor: "pointer",
                                                                backgroundColor:
                                                                    isStandart === true ? "#00bbd1" : "white",
                                                                borderColor:
                                                                    isStandart === true
                                                                        ? "#00bbd1"
                                                                        : "rgba(0, 0, 0, 0.125)",
                                                                color: isStandart === true ? "white" : "black",
                                                            }}
                                                            className="list-group-item list-group-item-action"
                                                            onClick={() => {
                                                                // console.log(
                                                                //   "PRO data: ",
                                                                //   this.props.ProjectData.filter(
                                                                //     (item) => item.id == this.state.projectId
                                                                //   )[0].committee_id
                                                                // );
                                                                let pr = this.props.ProjectData.find(
                                                                    (item) => Number(item.id) === Number(this.state.projectId)
                                                                )
                                                                console.log(pr)
                                                                this.setState({
                                                                    textStandart:
                                                                        mlang === "uz"
                                                                            ? pr.title_uz
                                                                            : mlang === "ru"
                                                                                ? pr.title_ru
                                                                                : pr.title_en,
                                                                    fileStandart: pr.file_standart_project,
                                                                    isStandart: true,
                                                                    isStandartSec: false,
                                                                    hiddenOthersToo: false,
                                                                    hideForm: true,
                                                                    // selectedLast: this.props.ProjectData.filter(
                                                                    //   (item) => item.id == this.state.projectId
                                                                    // )[0],
                                                                });
                                                            }}
                                                        >
                                                            {data.feedProject}
                                                        </a>
                                                        <a
                                                            style={{
                                                                cursor: "pointer",
                                                                backgroundColor:
                                                                    isStandartSec === true ? "#00bbd1" : "white",
                                                                borderColor:
                                                                    isStandartSec === true
                                                                        ? "#00bbd1"
                                                                        : "rgba(0, 0, 0, 0.125)",
                                                                color:
                                                                    isStandartSec === true ? "white" : "black",
                                                            }}
                                                            className="list-group-item list-group-item-action"
                                                            onClick={() => {
                                                                let pr = this.props.ProjectData.find(
                                                                    (item) => Number(item.id) === Number(this.state.projectId)
                                                                )
                                                                console.log(this.state.issue_date)
                                                                this.setState({
                                                                    textExplain: pr.text_explain,
                                                                    fileExplain: pr.file_explain,
                                                                    isStandart: false,
                                                                    isStandartSec: true,
                                                                    hiddenOthersToo: false,
                                                                    hideForm: false,
                                                                });
                                                            }}
                                                        >
                                                            {data.feedExpNote}
                                                        </a>
                                                        <div
                                                            className="d-flex p-3 justify-content-start mt-4 border">
                                                            {this.state.issue_date > 0 ?
                                                                <Countdown format={"DD HH:mm:ss"} title={
                                                                    <div>
                                                                        <div>Muhokama tugashigacha</div>
                                                                        <div>(Kun, soat, minut, sekund)</div>
                                                                    </div>
                                                                }
                                                                           value={this.state.issue_date}/> : <div
                                                                    className={`text-danger`}>Muhokama
                                                                    vaqti tugagan!</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*  */}
                                            <div className="row mb-3 font-weight-bold">
                                                <div
                                                    className="col-lg-12 col-md-12"
                                                    style={{display: showStat ? "block" : "none"}}
                                                >
                                                    <Table>
                                                        {this.props.StandartData.map((item, index) => (
                                                            <tr
                                                                // style={{ listStyleType: "none" }}
                                                                key={item.id + ""}
                                                            >
                                                                <th
                                                                    style={{
                                                                        verticalAlign: "middle",
                                                                        textAlign: "center",
                                                                    }}
                                                                    scope="row"
                                                                >
                                                                    {index + 1}
                                                                </th>
                                                                <td>
                                                                    <i
                                                                        style={{
                                                                            textAlign: "center",
                                                                            fontSize: 40,
                                                                            color: "#24bc9c",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                        className="fa fa-book"
                                                                    ></i>
                                                                </td>
                                                                <td
                                                                    style={{verticalAlign: "middle"}}
                                                                    onClick={() => {
                                                                        //
                                                                        // this.props.fetchProjectData(item.id);
                                                                        // this.setState({
                                                                        //   selectedType: item.id,
                                                                        //   isStandart: false,
                                                                        //   hiddenOthers: true,
                                                                        //   hiddenOthersToo: true,
                                                                        // });
                                                                    }}
                                                                    // style={{
                                                                    //   backgroundColor:
                                                                    //     selectedType && selectedType === item.id
                                                                    //       ? "#00bbd1"
                                                                    //       : "white",
                                                                    //   borderColor:
                                                                    //     selectedType && selectedType === item.id
                                                                    //       ? "#00bbd1"
                                                                    //       : "rgba(0, 0, 0, 0.125)",
                                                                    //   color:
                                                                    //     selectedType && selectedType === item.id
                                                                    //       ? "white"
                                                                    //       : "black",
                                                                    // }}
                                                                    // className="list-group-item list-group-item-action"
                                                                    // className=""
                                                                    // to={`/committee/${item.id}`}
                                                                >
                                                                    {_translatorTitle("", item, mlang)}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign: "center",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <a
                                                                        target="_blank"
                                                                        href={`${IMAGE_URL}${item.file_explain}`}
                                                                        className="drop__pdf"
                                                                        style={{zIndex: 2}}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                textAlign: "center",
                                                                                fontSize: 40,
                                                                                color: "#24bc9c",
                                                                                verticalAlign: "middle",
                                                                            }}
                                                                            className="fas fa-file-pdf"
                                                                        ></i>
                                                                    </a>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        minWidth: 150,
                                                                        textAlign: "center",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <p>{item.created_at.slice(0, 10)}</p>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        minWidth: 150,
                                                                        textAlign: "center",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    {(new Date(item.created_at).getTime()+60*24*60*60*1000)-Date.now()>0
                                                                        ?<p><Countdown title={"(kun,soat,minut,sekund)"} format="DD HH:mm:ss"
                                                                        value={new Date(item.created_at).getTime() + 60 * 24*60 * 60 * 1000}/>
                                                                    </p>:<p className="text-danger">Muhokama vaqti tugagan</p>}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </Table>
                                                </div>
                                            </div>
                                            {/*  */}
                                            {/*  */}
                                            <div className="row mb-3 font-weight-bold">
                                                <div
                                                    className="col-lg-12 col-md-12"
                                                    style={{display: showProg ? "block" : "none"}}
                                                >
                                                    <Table>
                                                        {this.props.StandartDataType.map((item, index) => (
                                                            <tr
                                                                // style={{ listStyleType: "none" }}
                                                                key={item.id + ""}
                                                            >
                                                                <th
                                                                    scope="row"
                                                                    style={{
                                                                        verticalAlign: "middle",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </th>
                                                                <td>
                                                                    <i
                                                                        style={{
                                                                            textAlign: "center",
                                                                            fontSize: 40,
                                                                            color: "#24bc9c",
                                                                            verticalAlign: "middle",
                                                                            display: showDoneIcon ? "block" : "none",
                                                                        }}
                                                                        class="fa fa-check-circle"
                                                                    ></i>
                                                                    {/* <i
                                    style={{
                                      textAlign: "center",
                                      fontSize: 40,
                                      color: "#24bc9c",
                                      display: showProgressIcon
                                        ? "block"
                                        : "none",
                                      verticalAlign: "middle",
                                    }}
                                    class="fa fa-times-circle"
                                  ></i> */}
                                                                    <i
                                                                        style={{
                                                                            textAlign: "center",
                                                                            fontSize: 40,
                                                                            color: "#24bc9c",
                                                                            display: showProgressIcon
                                                                                ? "block"
                                                                                : "none",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                        className="fa fa-spinner"
                                                                    ></i>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        verticalAlign: "middle",
                                                                        // minWidth: "52.1vw",
                                                                        // minWidth: 801,
                                                                    }}
                                                                    // colspan="3"
                                                                    onClick={() => {
                                                                        //
                                                                        // this.props.fetchProjectData(item.id);
                                                                        // this.setState({
                                                                        //   selectedType: item.id,
                                                                        //   isStandart: false,
                                                                        //   hiddenOthers: true,
                                                                        //   hiddenOthersToo: true,
                                                                        // });
                                                                    }}
                                                                    // style={{
                                                                    //   backgroundColor:
                                                                    //     selectedType && selectedType === item.id
                                                                    //       ? "#00bbd1"
                                                                    //       : "white",
                                                                    //   borderColor:
                                                                    //     selectedType && selectedType === item.id
                                                                    //       ? "#00bbd1"
                                                                    //       : "rgba(0, 0, 0, 0.125)",
                                                                    //   color:
                                                                    //     selectedType && selectedType === item.id
                                                                    //       ? "white"
                                                                    //       : "black",
                                                                    // }}
                                                                    // className="list-group-item list-group-item-action"
                                                                    // to={`/committee/${item.id}`}
                                                                >
                                                                    {_translatorTitle("", item, mlang)}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign: "center",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <a
                                                                        target="_blank"
                                                                        href={`${IMAGE_URL}${item.file_explain}`}
                                                                        className="drop__pdf"
                                                                        style={{zIndex: 2}}
                                                                    >
                                                                        {/* <img
                                      style={{
                                        cursor: "pointer",
                                        // display: item.file_explain
                                        //   ? "block"
                                        //   : "none",
                                      }}
                                      width="42px"
                                      height="42px"
                                      src="./assets/images/pdf.svg"
                                      alt=""
                                    /> */}
                                                                        <i
                                                                            style={{
                                                                                textAlign: "center",
                                                                                fontSize: 40,
                                                                                color: "#24bc9c",
                                                                                verticalAlign: "middle",
                                                                            }}
                                                                            class="fas fa-file-pdf"
                                                                        ></i>
                                                                    </a>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        minWidth: 150,
                                                                        textAlign: "center",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <p>{item.created_at.slice(0, 10)}</p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </Table>
                                                </div>
                                            </div>
                                            {/*  */}
                                            <hr
                                                style={{
                                                    borderColor: "#00bbd1",
                                                    borderWidth: 1,
                                                    marginTop: 10,
                                                    marginBottom: 10,
                                                }}
                                            />
                                            <div
                                                className={`row mb-3 drop__tabs ${
                                                    hiddenOthersToo ? " hiddenBlock" : " "
                                                } `}
                                            >
                                                <div
                                                    className="col-md-12"
                                                    style={{marginBottom: "-34px"}}
                                                >
                                                    <div
                                                        className={`drop__box tab-1 ${
                                                            hideForm ? "col-lg-7" : "col-lg-12"
                                                        }`}
                                                    >
                                                        <div className="drop__text">
                                                            {/* <h3
                                className="mb-4"
                                style={{ textAlign: "center" }}
                              ></h3> */}
                                                            <p
                                                                style={{
                                                                    maxHeight: "17rem",
                                                                    overflowY: "auto",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {this.state.isStandart
                                                                    ? this.state.textStandart
                                                                    : this.state.textExplain}
                                                            </p>
                                                        </div>
                                                        <a
                                                            target="_blank"
                                                            href={
                                                                this.state.isStandart
                                                                    ? `${IMAGE_URL}${this.state.fileStandart}`
                                                                    : `${IMAGE_URL}${this.state.fileExplain}`
                                                            }
                                                            className="drop__pdf"
                                                            style={{zIndex: 2}}
                                                        >
                                                            <img
                                                                style={{
                                                                    cursor: "pointer",
                                                                    display:
                                                                        this.state.fileStandart ||
                                                                        this.state.fileExplain
                                                                            ? "block"
                                                                            : "none",
                                                                }}
                                                                width="42px"
                                                                height="42px"
                                                                src="./assets/images/pdf.svg"
                                                                alt=""
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-lg-12 mb-3"
                                                    // className="col-lg-12 mt-4 mb-3 drop__contain"
                                                    style={{marginLeft: "auto", marginRight: "auto"}}
                                                >
                                                    {this.state?.issue_date && hideForm ? (
                                                        <FormOpinionSecond
                                                            title={data.expressOpinion}
                                                            mlang={this.props.mlang}
                                                            reviewId={this.state.projectId}
                                                            // reviewFile={
                                                            //   mlang === "uz"
                                                            //     ? thought.file
                                                            //     : mlang === "ru"
                                                            //     ? thought.file_ru
                                                            //     : thought.file_en
                                                            // }
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div
                                                id="stats"
                                                className="tadbir__content tadbir__cont mb-5 col-lg-12 col-md-12"
                                                style={{
                                                    paddingLeft: 0,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    marginTop: hideForm && this.state.issue_date > 0 ? "-83px" : "",
                                                    maxWidth: hideForm && this.state.issue_date > 0 ? "590px" : "",
                                                }}
                                            >
                                                <div
                                                    className="rounded"
                                                    style={{
                                                        padding: "20px",
                                                        // marginLeft: "auto",
                                                        // marginRight: "auto",
                                                        // backgroundColor: "gray",
                                                        justifyContent: "space-evenly",
                                                        width: "100%",
                                                        display: hideStat ? "none" : "flex",
                                                    }}
                                                >
                                                    <div style={{margin: "0 auto"}}>
                                                        <div
                                                            onClick={() => {
                                                                this.props.fetchDiscussStandartsData();
                                                                this.setState({
                                                                    showStat: true,
                                                                    showProg: false,
                                                                    hidden: true,
                                                                    hiddenOthers: true,
                                                                    hiddenOthersToo: true,
                                                                });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                            //   className="rounded-circle"
                                                            style={{
                                                                cursor: "pointer",
                                                                // backgroundColor: "rgb(84, 153, 218)",
                                                                backgroundColor: "#fff",
                                                                borderColor: "rgb(84, 153, 218)",
                                                                borderWidth: "10px",
                                                                borderStyle: "solid",
                                                                padding: "40px",
                                                                margin: "0 auto",
                                                                marginBottom: 15,
                                                                fontSize: "60px",
                                                                // color: "rgb(5, 31, 97)",
                                                                color: "rgb(84, 153, 218)",
                                                                textAlign: "center",
                                                                borderRadius: "50%",
                                                                height: "11rem",
                                                                width: "11rem",
                                                                paddingTop: 30,
                                                            }}
                                                        >
                                                            {standartTypesData.all}
                                                        </div>
                                                        <p
                                                            style={{
                                                                margin: "0 auto",
                                                                color: "rgb(84, 153, 218)",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {data.feedProcess}
                                                        </p>
                                                    </div>
                                                    <div style={{margin: "0 auto"}}>
                                                        <div
                                                            onClick={() => {
                                                                this.props.fetchDiscussStandartsTypeData(
                                                                    "in_progress"
                                                                );
                                                                this.setState({
                                                                    showStat: false,
                                                                    showProg: true,
                                                                    showProgressIcon: true,
                                                                    showDoneIcon: false,
                                                                    hidden: true,
                                                                    hiddenOthers: true,
                                                                    hiddenOthersToo: true,
                                                                });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                            //   className="rounded-circle"
                                                            style={{
                                                                cursor: "pointer",
                                                                // backgroundColor: "rgb(112, 173, 70)",
                                                                backgroundColor: "#fff",
                                                                borderColor: "rgb(112, 173, 70)",
                                                                borderWidth: "10px",
                                                                borderStyle: "solid",
                                                                padding: "40px",
                                                                margin: "0 auto",
                                                                marginBottom: 15,
                                                                fontSize: "60px",
                                                                // color: "rgb(5, 31, 97)",
                                                                color: "rgb(112, 173, 70)",
                                                                textAlign: "center",
                                                                borderRadius: "50%",
                                                                height: "11rem",
                                                                width: "11rem",
                                                                paddingTop: 30,
                                                            }}
                                                        >
                                                            {standartTypesData.in_progress}
                                                        </div>
                                                        <p
                                                            style={{
                                                                margin: "0 auto",
                                                                color: "rgb(112, 173, 70)",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {data.feedDoing}
                                                        </p>
                                                    </div>
                                                    <div style={{margin: "0 auto"}}>
                                                        <div
                                                            onClick={() => {
                                                                this.props.fetchDiscussStandartsTypeData(
                                                                    "finished"
                                                                );
                                                                this.setState({
                                                                    showStat: false,
                                                                    showProg: true,
                                                                    showDoneIcon: true,
                                                                    showProgressIcon: false,
                                                                    hidden: true,
                                                                    hiddenOthers: true,
                                                                    hiddenOthersToo: true,
                                                                });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                            //   className="rounded-circle"
                                                            style={{
                                                                cursor: "pointer",
                                                                // backgroundColor: "rgb(255, 192, 0)",
                                                                backgroundColor: "#fff",
                                                                borderColor: "rgb(255, 192, 0)",
                                                                borderWidth: "10px",
                                                                borderStyle: "solid",
                                                                padding: "40px",
                                                                margin: "0 auto",
                                                                marginBottom: 15,
                                                                fontSize: "60px",
                                                                // color: "rgb(5, 31, 97)",
                                                                color: "rgb(255, 192, 0)",
                                                                textAlign: "center",
                                                                borderRadius: "50%",
                                                                height: "11rem",
                                                                width: "11rem",
                                                                paddingTop: 30,
                                                            }}
                                                        >
                                                            {standartTypesData.finish}
                                                        </div>
                                                        <p
                                                            style={{
                                                                margin: "0 auto",
                                                                color: "rgb(255, 192, 0)",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {data.feedDone}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        marginLeft: hideForm&& this.state.issue_date > 0 ? "-260px" : "",
                                                        width: hideForm&& this.state.issue_date > 0 ? "" : "100%",
                                                    }}
                                                    className={`tadbir__koko ${
                                                        hiddenOthersToo ? " hiddenVisibility" : " "
                                                    }`}
                                                >
                                                    <div
                                                        className="drop__yellow"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                // minHeight: "13.125rem",
                                                                // maxHeight: "13.125rem",
                                                                // overflow: "auto",
                                                                fontWeight: "bold",
                                                                fontSize: "20px",
                                                            }}
                                                            // dangerouslySetInnerHTML={{
                                                            //   __html: this.state.comitText,
                                                            // }}
                                                        >
                                                            {comitTextLet}
                                                        </p>
                                                        <Link
                                                            to={`/committee/${this.state.comitID}`}
                                                            className="submit_btn"
                                                            style={{
                                                                maxHeight: "55px",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {data.techCommity}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-lg-5" style={{display: "none"}}>
                                            <div className="col-lg-12 d-flex justify-content-center">
                                                <ul className="pagination">
                                                    <li className="page-item active">
                                                        <a href className="page-link">
                                                            1
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        standartTypesData: state.fetchDataReducer.standartTypesData,
        ProjectData: state.fetchDataReducer.ProjectData,
        StandartData: state.fetchDataReducer.StandartData,
        StandartDataType: state.fetchDataReducer.StandartDataType,
        ThoughtsData: state.fetchDataReducer.ThoughtsData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
