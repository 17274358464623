import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import { IMAGE_URL } from "../../constants";

SwiperCore.use([Navigation, Thumbs]);

const SwiperSlider = ({ slides }) => {
  const [thumbSwiper, setThumbSwiper] = useState(null);
  console.log("Slides ", slides);
  return slides ? (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        thumbs={{ swiper: thumbSwiper }}
        navigation
        loop
      >
        {slides.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                src={`${IMAGE_URL}${item}`}
                alt="slide-img"
                data-fancybox="gallery"
                href={`${IMAGE_URL}${item}`}
                style={{ cursor: "pointer" }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper onSwiper={setThumbSwiper} spaceBetween={5} slidesPerView={4}>
        {slides.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                src={`${IMAGE_URL}${item}`}
                alt="slide-img"
                height="115"
                width="100%"
                style={{ cursor: "pointer" }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  ) : null;
};

export default SwiperSlider;
