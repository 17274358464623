export const langData = {
  uz: {
    mainTitle: "Ishonch telefonlari",
    departments: "Bo'limlar",
    phoneNumbers: "Telefon raqamlari",
    mainPhone: "Asosiy telefon",
    accountingPhone: "Buxgalteriya",
    receptionPhone: "Qabulxona",
    standartizationPhone: "Standartlashtirish bo`limi",
    certificationPhone: "Sertifikatlashtirish bo`limi",
    metrologyPhone: "Metrologiya bo`limi:",
    developmentPhone: "Malaka oshirish markazi",
    hotelPhone: "Mexmonxona",
    journalPhone: "Standart jurnali",
  },
  ru: {
    mainTitle: "Телефоны доверия",
    departments: "Отделы",
    phoneNumbers: "Телефонные номера",
    mainPhone: "Основной телефон",
    accountingPhone: "Бугхалтерия",
    receptionPhone: "Приемная",
    standartizationPhone: "Отдел стандартизации",
    certificationPhone: "Отдел сертификации",
    metrologyPhone: "Метрология:",
    developmentPhone: "Центр повышения квалификации",
    hotelPhone: "Гостиница",
    journalPhone: "Журнал Стандарт",
  },
  en: {
    mainTitle: "Helplines",
    departments: "Departments",
    phoneNumbers: "Phone numbers",
    mainPhone: "Main phone",
    accountingPhone: "Accontant",
    receptionPhone: "Reception",
    standartizationPhone: "Department of standardization",
    certificationPhone: "Certification Department",
    metrologyPhone: "Metrology:",
    developmentPhone: "Center of Excellence",
    hotelPhone: "Hotel",
    journalPhone: "Journal Standard",
  },
}
