import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { IMAGE_URL } from "../../constants";
import SideBar from "../../containers/SideBar";
import { _translator } from "../../helpers";
// import { langData } from "../../index.i18n";
import * as Actions from "../../redux/actions";

class Departments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: this.props.match.params.id,
    };
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {
    if (this.state.currentPath !== this.props.match.params.id) {
      this.updateState(this.props.match.params.id);
    }
  }

  updateState = (id) => {
    this.setState(
      {
        currentPath: id,
      },
      () => {
        this.props.setLoader("DepartmentData");
        this.props.fetchDetailsData(
          "DepartmentData",
          this.props.match.params.id
        );
      }
    );
  };
  componentDidMount() {
    this.props.setLoader("DepartmentData");
    this.props.fetchDetailsData("DepartmentData", this.props.match.params.id);
  }
  render() {
    const { mlang, DepartmentData, LoadingDepartmentData } = this.props;
    // const data =
    //   mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en
    // console.log("currentPath ", this.state.currentPath);
    return LoadingDepartmentData ? (
      <Loader />
    ) : (
      <div>
        <div className="full_page">
          <div className="blog_pages">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
                  {/* single-blog start */}
                  <article className="blog-post-wrapper">
                    <div className="blog-banner">
                      <div className="blog-content">
                        {/* <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          target="_blank"
                          href={
                            mlang === "uz" && DepartmentData.file
                              ? IMAGE_URL + DepartmentData.file
                              : mlang === "ru" && DepartmentData.file_ru
                              ? IMAGE_URL + DepartmentData.file_ru
                              : mlang === "en" && DepartmentData.file_en
                              ? IMAGE_URL + DepartmentData.file_en
                              : ""
                          }
                        > */}
                        <h4
                          style={{
                            fontWeight: "bold",
                            display: "inline-block",
                          }}
                        >
                          {_translator("title", DepartmentData, mlang)}
                        </h4>
                        {/* <i
                            style={{
                              display:
                                (mlang === "uz" && DepartmentData.file) ||
                                (mlang === "ru" && DepartmentData.file_ru) ||
                                (mlang === "en" && DepartmentData.file_en)
                                  ? "block"
                                  : "none",
                              textAlign: "center",
                              fontSize: 40,
                              color: "#302b68",
                              verticalAlign: "middle",
                            }}
                            class="fas fa-file-pdf"
                          ></i>
                        </a> */}
                        <p
                          className="description-style"
                          dangerouslySetInnerHTML={{
                            __html: _translator(
                              "description",
                              DepartmentData,
                              mlang
                            ),
                          }}
                          style={{ marginTop: 20, paddingRight: 10 }}
                        />
                      </div>
                    </div>
                  </article>
                  <div className="clear" />
                  {/* single-blog end */}
                  {/* <img
                    className="img-fluid"
                    style={{
                      display: this.state.currentPath == 25 ? "block" : "none",
                    }}
                    src={require("../../assets/images/finance.jpg")}
                  /> */}
                  <img
                    alt=''
                    style={{
                      display:
                        DepartmentData.image ||
                        DepartmentData.image_ru ||
                        DepartmentData.image_en
                          ? "block"
                          : "none",
                    }}
                    className="img-fluid"
                    src={
                      mlang === "uz"
                        ? IMAGE_URL + DepartmentData.image
                        : mlang === "ru"
                        ? IMAGE_URL + DepartmentData.image_ru
                        : IMAGE_URL + DepartmentData.image_en
                    }
                  />
                </div>
                <div className="col-lg-4 col-md-12">
                  <SideBar />
                </div>
              </div>
            </div>
          </div>
          {/* /blog_con end*/}
        </div>
        {/* /scroll_to_top start*/}
        <span href="#" className="scrollToTop">
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </span>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    DepartmentData: state.fetchDataReducer.DepartmentData,
    LoadingDepartmentData: state.fetchDataReducer.LoadingDepartmentData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Departments);
