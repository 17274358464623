import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions";
import Loader from "../../components/Loader";
import { _translator } from "../..//helpers";

class TasksFunctions extends Component {
  componentDidMount() {
    this.props.fetchDetailsData("TaskFunctionsData");
    window.scrollTo(0, 0);
  }
  render() {
    const { mlang, TaskFunctionsData, LoadingTaskFunctionsData } = this.props;

    return LoadingTaskFunctionsData ? (
      <Loader />
    ) : (
      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
        <article className="blog-post-wrapper">
          <div className="blog-banner">
            <div className="blog-content">
              <h4 style={{ fontWeight: "bold" }}>
                {_translator("title", TaskFunctionsData, mlang)}
              </h4>

              <p
                dangerouslySetInnerHTML={{
                  __html: _translator("description", TaskFunctionsData, mlang),
                }}
                style={{
                  marginTop: 20,
                  paddingRight: 10,
                }}
              />
            </div>
          </div>
        </article>
        <div className="clear" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    TaskFunctionsData: state.fetchDataReducer.TaskFunctionsData,
    LoadingTaskFunctionsData: state.fetchDataReducer.LoadingTaskFunctionsData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(TasksFunctions);
