import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions";
import { langData } from "./index.i18n";
import VacancyCard from "../../components/VacancyCard";
import Loader from "../../components/Loader";

class Vacancy extends Component {
  componentDidMount() {
    this.props.fetchDetailsData("VacancyData");
    window.scrollTo(0, 0);
  }
  render() {
    const { mlang, VacancyData, LoadingVacancyData } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

    return LoadingVacancyData ? (
      <Loader />
    ) : (
      <div className="col-xl-8 col-lg-8 chiter">
        <h2>{data.vacancies}</h2>
        <p>{data.vacancyText} </p>

        <div className="table-responsive">
          <table className="chiter-table table table-sm table-bordered">
            <tbody>
              <tr>
                <th>{data.tr}</th>
                <th>#</th>
                <th>{data.departmentName}</th>
                <th>{data.positionname}</th>
                <th>{data.vacancyAmount}</th>
              </tr>
              {VacancyData.map((item, index) => (
                <VacancyCard
                  data={item}
                  mlang={mlang}
                  key={index.toString()}
                  id={index + 1}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    VacancyData: state.fetchDataReducer.VacancyData,
    LoadingVacancyData: state.fetchDataReducer.LoadingVacancyData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Vacancy);
