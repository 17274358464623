import Moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import LinesEllipsisLoose from "react-lines-ellipsis/lib/loose";
// import LinesEllipsis from "react-lines-ellipsis";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
// import gerb from '../'
// import ombudsman from "/assets/images/logo.png";
// import lex from "../assets/images/logo2.png";
// import plata from "../assets/images/logo_small.png";
import { _kirilToLatin, _timeParserD, _translator } from "../helpers";
import { langData } from "../index.i18n";
import AdminCard from "./AdminCard";
// import NewsCard from "./NewsCard";
import ShoppingCard from "./ShoppingCard";
import UnitCard from "./UnitCard";
import { IMAGE_URL } from "../constants";

export function PartnersCarousel({ mlang }) {
  const data =
    mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
  const UsefulLinksData = [
    {
      id: 1,
      title: data.presidentUz,
      imgSrc: "/assets/images/logo.png",
      links: "https://president.uz/ru",
    },
    {
      id: 2,
      title: data.palataUz,
      imgSrc: "/assets/images/logo_small.png",
      links: "http://parliament.gov.uz/ru/",
    },
    {
      id: 3,
      title: data.ombudsmanUz,
      imgSrc: "/assets/images/logo.png",
      links: "http://ombudsman.uz/ru/",
    },

    { id: 4, title: data.lexUz, imgSrc: "/assets/images/logo2.png", links: "https://lex.uz/" },
    {
      id: 5,
      title: data.standartUz,
      imgSrc: "/assets/images/gerb.png",
      links: "https://www.standart.uz/ru",
    },
  ];
  const innerWidth = window.innerWidth;
  return (
    <div className="partners">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel
              className="owl-theme"
              margin={10}
              loop
              nav
              items={innerWidth <= 521 ? 1 : innerWidth <= 767 ? 3 : 5}
            >
              {UsefulLinksData.map((a) => (
                <div className="item d-flex flex-column align-items-center">
                  <img
                    style={{
                      width: "70px",
                      height: "70px",
                    }}
                    // className="img-fluid"
                    src={a.imgSrc}
                    alt="themeforest"
                  />
                  <a
                    href={a.links}
                    target="_blank"
                    style={{
                      color: "white",
                      fontSize: "13px",
                      lineHeight: "16px",
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    {a.title}
                  </a>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export function NewsBlogCarousel({ data, buttonTitle, mlang }) {
  const innerWidth = window.innerWidth;

  const time = new Date();
  return (
    <div className="row">
      <div className="col-md-12">
        <OwlCarousel
          className="owl-theme"
          margin={10}
          loop
          nav
          dots={false}
          items={innerWidth <= 521 ? 1 : innerWidth <= 767 ? 2 : 4}
        >
          {data.map((item) => (
            <div
              className="col-md-12"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div
                className="news_box blog_page "
                style={{ height: 450, paddingTop: 0 }}
              >
                <div className="news_img">
                  <img
                    className="img-fluid"
                    style={{ height: 200, objectFit: "cover" }}
                    src={IMAGE_URL + item.image}
                    alt="news-2"
                  />
                  <div className="news_date_sec">
                    <div className="date_box">
                      <h2 style={{ textTransform: "capitalize" }}>
                        <span className="large">
                          {_timeParserD(item.publish_date, mlang)}
                        </span>{" "}
                        <br />
                        {/* {_timeParserM(item.dateMonth, mlang)} */}
                        {mlang === "uz"
                          ? _kirilToLatin(
                              Moment(item.publish_date)
                                .locale(mlang)
                                .format("MMMM")
                            )
                          : Moment(item.publish_date)
                              .locale(mlang)
                              .format("MMMM")}{" "}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="news_detail">
                  <h3>
                    {/* <LinesEllipsis
                      text={item.title}
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                      maxLine="2"
                    /> */}
                    <LinesEllipsisLoose
                      className="news_detail_h"
                      text={_translator("title", item, mlang)}
                      maxLine="2"
                      lineHeight="28"
                    />
                  </h3>
                  <div
                    id="par"
                    style={{
                      height: 100,
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {
                      // <LinesEllipsis
                      //   text={_translator("description", item, mlang).replace(
                      //     /<[^>]+>/g,
                      //     ""
                      //   )}
                      //   maxLine="2"
                      //   ellipsis="..."
                      //   trimRight
                      //   basedOn="letters"
                      // />
                      <LinesEllipsisLoose
                        className="news_detail_p"
                        text={
                          item.description && mlang === "uz"
                            ? item.description
                                .replace(/<[^>]+>/g, "")
                                .replace(/&nbsp/g, "")
                                .replace(/[;]/g, "")
                            : item.description_ru && mlang === "ru"
                            ? item.description_ru
                                .replace(/<[^>]+>/g, "")
                                .replace(/&nbsp/g, "")
                                .replace(/[;]/g, "")
                            : item.description_en && mlang === "en"
                            ? item.description_en
                                .replace(/<[^>]+>/g, "")
                                .replace(/&nbsp/g, "")
                                .replace(/[;]/g, "")
                            : ""
                        }
                        maxLine="2"
                        lineHeight="28"
                      />
                    }
                  </div>

                  <Link
                    to={`/news/${item.id}`}
                    style={{
                      position: "absolute",
                      bottom: 50,
                      left: 0,
                      width: "100%",
                      // color: "#302b68",
                      color: "#00bbd1",
                    }}
                  >
                    {buttonTitle}
                    <i className="fa fa-arrow-right" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>

            // <NewsCard
            //   descLine={2}
            //   widthSize={12}
            //   detailsButton={buttonTitle}
            //   data={item}
            //   mlang={mlang}
            //   key={item.id.toString()}
            // />
            // <div className="item news_hover">
            //   <div className="blog_bg" />
            //   <div className="news_date">
            //     <p>
            //       {_timeParserD(item.publish_date, mlang)}
            //       <br />
            //       <span style={{ textTransform: "capitalize" }}>
            //         {/* {_timeParserM(item.publish_date, mlang)} */}
            //         {mlang === "uz"
            //           ? _kirilToLatin(
            //               Moment(item.publish_date).locale(mlang).format("MMMM")
            //             )
            //           : Moment(item.publish_date)
            //               .locale(mlang)
            //               .format("MMMM")}{" "}
            //       </span>
            //     </p>
            //   </div>
            //   <div className="blog_box">
            //     <div className="blog_detail">
            //       <h2>
            //         <LinesEllipsisLoose
            //           text={_translator("title", item, mlang)}
            //           maxLine="2"
            //           lineHeight="28"
            //         />
            //       </h2>
            //       <div className="border_blog" />
            //       <h5
            //         className="dynamic-content"
            //         style={{
            //           height: 320,
            //           overflow: "hiden",
            //           // overflowY: "hidden",
            //           paddingRight: "15px",
            //           display: "block",
            //         }}
            //         dangerouslySetInnerHTML={{
            //           __html: _translator("description", item, mlang),
            //         }}
            //       ></h5>
            //       <div className="blog_btn">
            //         <Link to={`/news/${item.id}`} className="blog_read">
            //           {buttonTitle}
            //         </Link>
            //       </div>
            //     </div>
            //   </div>
            //   <div className="blog_bg1" />
            // </div>
          ))}
        </OwlCarousel>
        <div className="clearfix" />
      </div>
    </div>
  );
}

export function ClientOpinionCarousel({ mlang }) {
  return (
    <div className="offset-lg-1 col-lg-10 col-md-12">
      <OwlCarousel className="owl-theme" margin={10} loop nav={true} items={1}>
        {ClientOpinionCarouselData.map((item, index) => (
          <div className="item" key={index.toString()}>
            <div className="clients_con">
              <p>{_translator("text", item, mlang)}</p>
            </div>
            <div className="client_name1" style={{ width: 400 }}>
              <div className="client_img">
                <a
                  href="https://e-study.uzsti.uz/"
                  target="_blank"
                  style={{ position: "relative", zIndex: 2, display: "block" }}
                >
                  <img
                    className="img-fluid"
                    src="assets/images/client_img.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="client_name">
                <p style={{ color: "#fff" }}>
                  {_translator("title", item, mlang)}
                </p>
                {/* <p>{_translator("position", item, mlang)}</p> */}
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}
const ClientOpinionCarouselData = [
  {
    title: "Muvofiqlikni baholashning qonuniy va normativ-huquqiy asoslari",
    title_ru: "Нормативно-правовая база оценки соответствия",
    title_en: "Legal and regulatory framework for conformity assessment",
    position: "O'qituvchi",
    position_ru: "Учитель",
    position_en: "Teacher",

    text: " Standartlashtirish, sertifikatlashtirish va texnik jihatdan tartibga solish ilmiy-tadqiqot institutidagi qayta tayyorlash va malaka oshirish markazi sizni standartlashtirish sohasidagi bilimingizga bilim qo'shish uchun o'z xizmatlarini taklif etadi",
    text_ru:
      "Центр переподготовки и повышения квалификации при Научно-исследовательском институте стандартизации, сертификации и технического регулирования предлагает свои услуги для пополнения ваших знаний в области стандартизации.",
    text_en:
      "The Center for Retraining and Advanced Training at the Research Institute of Standardization, Certification and Technical Regulation offers its services to add knowledge to your knowledge in the field of standardization",
  },
  {
    title: "Muvofiqlikni baholashning qonuniy va normativ-huquqiy asoslari",
    title_ru: "Нормативно-правовая база оценки соответствия",
    title_en: "Legal and regulatory framework for conformity assessment",
    position: "O'qituvchi",
    position_ru: "Учитель",
    position_en: "Teacher",

    text: " Standartlashtirish, sertifikatlashtirish va texnik jihatdan tartibga solish ilmiy-tadqiqot institutidagi qayta tayyorlash va malaka oshirish markazi sizni standartlashtirish sohasidagi bilimingizga bilim qo'shish uchun o'z xizmatlarini taklif etadi",
    text_ru:
      "Центр переподготовки и повышения квалификации при Научно-исследовательском институте стандартизации, сертификации и технического регулирования предлагает свои услуги для пополнения ваших знаний в области стандартизации.",
    text_en:
      "The Center for Retraining and Advanced Training at the Research Institute of Standardization, Certification and Technical Regulation offers its services to add knowledge to your knowledge in the field of standardization",
  },
  {
    title: "Muvofiqlikni baholashning qonuniy va normativ-huquqiy asoslari",
    title_ru: "Нормативно-правовая база оценки соответствия",
    title_en: "Legal and regulatory framework for conformity assessment",
    position: "O'qituvchi",
    position_ru: "Учитель",
    position_en: "Teacher",

    text: " Standartlashtirish, sertifikatlashtirish va texnik jihatdan tartibga solish ilmiy-tadqiqot institutidagi qayta tayyorlash va malaka oshirish markazi sizni standartlashtirish sohasidagi bilimingizga bilim qo'shish uchun o'z xizmatlarini taklif etadi",
    text_ru:
      "Центр переподготовки и повышения квалификации при Научно-исследовательском институте стандартизации, сертификации и технического регулирования предлагает свои услуги для пополнения ваших знаний в области стандартизации.",
    text_en:
      "The Center for Retraining and Advanced Training at the Research Institute of Standardization, Certification and Technical Regulation offers its services to add knowledge to your knowledge in the field of standardization",
  },
];

export function ManagementCarousel({ data, mlang }) {
  const innerWidth = window.innerWidth;

  return (
    <div className="row clearfix ">
      <OwlCarousel
        className="owl-theme"
        nav={true}
        // navContainerClass={"div.forArrows"}
        //  navContainer={"div.forArrows"}
        items={innerWidth <= 521 ? 1 : innerWidth <= 767 ? 2 : 4}
      >
        {data.map((item) => (
          <div className="item">
            <AdminCard data={item} />
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}

export function ShopCarousel({ data, mlang, buttonTitle }) {
  const innerWidth = window.innerWidth;

  return (
    <div className="row clearfix ">
      <OwlCarousel
        className="owl-theme-sec"
        nav={true}
        autoplay={true}
        loop={true}
        navContainerClass={"div.forArrows"}
        navContainer={"div.forArrows"}
        items={innerWidth <= 521 ? 1 : innerWidth <= 767 ? 2 : 4}
      >
        {/* {data.map((item) => (
          <div className="item">
            <ShoppingCard data={item} />
          </div>
        ))} */}
        {data.data.slice(0, 8).map((item, index) => (
          <ShoppingCard
            data={item}
            index={index}
            buttonTitle={buttonTitle}
            key={index.toString()}
          />
        ))}
      </OwlCarousel>
    </div>
  );
}

export function UniteCarousel({ data, mlang }) {
  const innerWidth = window.innerWidth;

  return (
    <OwlCarousel
      className="owl-theme"
      nav={true}
      autoplay={true}
      // navContainerClass={"div.forArrows"}
      // navContainer={"div.forArrows"}
      items={innerWidth <= 521 ? 1 : innerWidth <= 767 ? 2 : 2}
    >
      {data.map((item) => (
        <div className="item">
          <UnitCard data={item} mlang={mlang} key={item.id} />
        </div>
      ))}
    </OwlCarousel>
  );
}
