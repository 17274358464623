import { CHANGE_LANGUAGE } from "../actions"

const initialState = {
  language: "uz",
}

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      }

    default:
      return state
  }
}

export default languageReducer
