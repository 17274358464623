import axios from "axios";
import Cookies from "js-cookie";
import {FETCH_URL, JOURNAL_URL} from "../../constants";

export const FETCH_BLOG_DATA = "FETCH_BLOG_DATA";
export const FETCH_FORUM_CATEGORY = "FETCH_FORUM_CATEGORY";
export const FETCH_FORUM_DATA = "FETCH_FORUM_DATA";
export const FETCH_ATTENDANCE_DATA = "FETCH_ATTENDANCE_DATA";
export const ADD_FORUM = "ADD_FORUM";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_LOADER = "SET_LOADER";
export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_MAIN_SCREEN_DATA = "FETCH_MAIN_SCREEN_DATA";
export const FETCH_GALLERY_PAGE_DATA = "FETCH_GALLERY_PAGE_DATA";
export const FETCH_MANAGERS_LIST_DATA = "FETCH_MANAGERS_LIST_DATA";
export const FETCH_NEWS_LIST_DATA = "FETCH_NEWS_LIST_DATA";
export const FETCH_JOURNAL_LIST_DATA = "FETCH_JOURNAL_LIST_DATA";
export const FETCH_DOCUMENTS_DATA = "FETCH_DOCUMENTS_DATA";
export const FETCH_JOURNAL_DETAILS_DATA = "FETCH_JOURNAL_DETAILS_DATA";
export const SET_PAGINATION = "SET_PAGINATION";
export const SEARCH_NEWS = "SEARCH_NEWS";
export const FETCH_DETAILS_DATA = "FETCH_DETAILS_DATA";
export const FETCH_ORDER_DATA = "FETCH_ORDER_DATA";
export const SET_TOKEN = "SET_TOKEN";
export const FETCH_REVIEWS_DATA = "FETCH_REVIEWS_DATA";
export const FETCH_THOUGHTS_DATA = "FETCH_THOUGHTS_DATA";
export const FETCH_OPINIOS_DATA = "FETCH_OPINIOS_DATA";
export const FETCH_CART_DATA = "FETCH_CART_DATA";
export const FETCH_PROJECT_DATA = "FETCH_PROJECT_DATA";
export const FETCH_CHECK_DATA = "FETCH_CHECK_DATA";
export const FETCH_CHECK_INN = "FETCH_CHECK_INN";
export const FETCH_DISCUSS_STANDARTS_DATA = "FETCH_DISCUSS_STANDARTS_DATA";
export const FETCH_BUDGET_DATA = "FETCH_BUDGET_DATA";
export const FETCH_INSTITUTE_STRUCTURE_DATA = "FETCH_INSTITUTE_STRUCTURE_DATA";
export const FETCH_DISCUSS_STANDARTS_TYPE_DATA =
    "FETCH_DISCUSS_STANDARTS_TYPE_DATA";

export const changeLanguage = (lang) => {
    return {
        type: CHANGE_LANGUAGE,
        language: lang,
    };
};
export const setToken = (token) => {
    return {
        type: SET_TOKEN,
        token: token,
    };
};
export const setLoader = (title) => {
    return {
        type: SET_LOADER,
        title: `Loading${title}`,
    };
};
export const setPagination = (id) => {
    return {
        type: SET_PAGINATION,
        id,
    };
};
export const fetchOrderData = (token, journalId, price) => async (dispatch) => {
    const config = {
        headers: {Authorization: `Bearer ${token}`},
    };
    axios(`${FETCH_URL}checkout/${journalId}`, config)
        .then((res) => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_ORDER_DATA,
                    orderData: res.data.order,
                    isLogged: true,
                    loader: false,
                });
            } else {
                dispatch({
                    type: FETCH_ORDER_DATA,
                    isLogged: false,
                    loader: false,
                });
            }
        })
        .catch((e) => {
            console.log("checkout err res: ", e);
            alert(e);
        });
};
export const fetchMainPageData = (lang) => async (dispatch) => {
    const mainData = await axios(`${FETCH_URL}index`);
    const newsListData = await axios(`${FETCH_URL}news?page=${1}`);
    const aboutNews = await axios(`${FETCH_URL}about`);
    const committees = await axios(`${FETCH_URL}committees`);
    const standartTypes = await axios(`${FETCH_URL}standart-type`);

    dispatch({
        type: FETCH_MAIN_SCREEN_DATA,
        data: mainData.data.data,
        newsListData: newsListData.data,
        aboutData: aboutNews,
        committeesData: committees,
        standartTypesData: standartTypes,
    });
};
export const fetchDetailsData =
    (type, id, pageId, ipAddress, fromDate, toDate) => async (dispatch) => {
        let data = [];
        let title = "";
        switch (type) {
            case "ManagerData":
                data = await axios(`${FETCH_URL}leader/${id}`).then((res) => res.data);
                title = type;
                break;
            case "NewsData":
                // data = await axios(`${FETCH_URL}news/${id}`)
                data = await axios(`${FETCH_URL}news/${id}?ip=${ipAddress}`).then(
                    (res) => res.data
                );
                title = type;
                break;
            case "ServiceData":
                data = await axios(`${FETCH_URL}service/${id}`).then((res) => res.data);
                title = type;
                break;
            // case "CommitteeData":
            //   data = await axios(`${FETCH_URL}committee/${id}`).then(
            //     (res) => res.data
            //   );
            //   title = type;
            //   break;
            case "TaskFunctionsData":
                data = await axios(`${FETCH_URL}task-functions`).then(
                    (res) => res.data
                );
                title = type;
                break;
            case "VacancyData":
                data = await axios(`${FETCH_URL}vacancies`).then((res) => res.data);
                title = type;
                break;
            case "DocumentsData":
                data =  await axios(
                    `${FETCH_URL}document/${id}?from=${fromDate}&to=${toDate}`
                ).then((res) => res.data);
                title = type;
                break;
            case "DepartmentData":
                data = await axios(`${FETCH_URL}department/${id}`).then(
                    (res) => res.data
                );
                title = type;
                break;
            case "InstituteData":
                data = await axios(`${FETCH_URL}institute/${id}`).then(
                    (res) => res.data
                );
                title = type;
                break;
            case "InformationData":
                data = await axios(`${FETCH_URL}information_services/${id}`).then(
                    (res) => res.data
                );
                title = type;
                break;
            case "CouncilData":
                data = await axios(`${FETCH_URL}kengash`);
                title = type;
                break;

            default:
                console.log("wrong request");
                break;
        }

        // console.log("fromDate: ", fromDate)
        // console.log("toDate: ", toDate)
        // console.log(" data: ", data)
        dispatch({
            type: FETCH_DETAILS_DATA,
            data,
            title,
        });
    };
export const fetchJournalListData =
    (mlang, type, cat_id, type_id, id, pageNumber) => async (dispatch) => {
        // var config = {
        //   headers: {
        //     "Content-Length": 0,
        //     "Content-Type": "text/plain",
        //   },
        //   responseType: "text",
        // }
        // const mlang = lang === "uz" ? 1 : lang === "ru" ? 2 : 3
        let journalList = [];

        switch (type) {
            case "category":
                journalList = await axios(
                    `${FETCH_URL}standarts?category=${cat_id}&page=${pageNumber}`
                );
                console.log("category");
                break;
            case "type":
                journalList = await axios(
                    `${FETCH_URL}standarts?type=${cat_id}&page=${pageNumber}`
                );
                console.log("type");
                break;
            case "searchAll":
                journalList = await axios(
                    `${FETCH_URL}standarts?category=${cat_id}&type=${type_id}&q=${id}&page=${pageNumber}`
                );
                console.log("searchAll");
                // console.log("search cat_id", cat_id);
                // console.log("search type_id", type_id);
                // console.log("search keyword", id);
                break;
            case "searchAllType":
                journalList = await axios(
                    `${FETCH_URL}standarts?type=${cat_id}&category=${type_id}&q=${id}&page=${pageNumber}`
                );
                console.log("searchAllType");
                // console.log("search cat_id", cat_id);
                // console.log("search type_id", type_id);
                // console.log("search keyword", id);
                break;
            case "search":
                journalList = await axios(
                    `${FETCH_URL}standarts?q=${cat_id}&page=${pageNumber}`
                );
                console.log("search");
                // console.log("search cat_id", cat_id);
                // console.log("search type_id", type_id);
                console.log("search keyword", id);
                break;
            case "searchCat":
                journalList = await axios(
                    `${FETCH_URL}standarts?category=${cat_id}&q=${type_id}&page=${pageNumber}`
                );
                console.log("searchCat");
                // console.log("search cat_id", cat_id);
                // console.log("search type_id", type_id);
                // console.log("search keyword", id);
                break;
            case "searchType":
                journalList = await axios(
                    `${FETCH_URL}standarts?type=${cat_id}&q=${type_id}&page=${pageNumber}`
                );
                console.log("searchType");
                // console.log("search cat_id", cat_id);
                // console.log("search type_id", type_id);
                // console.log("search keyword", id);
                break;
            default:
                journalList = await axios(`${FETCH_URL}standarts?page=${pageNumber}`);
                console.log("default");
                break;
        }

        const journalTypes = await axios(`${FETCH_URL}standart-types`);
        const journalCategory = await axios(`${FETCH_URL}standart-categories`);

        dispatch({
            type: FETCH_JOURNAL_LIST_DATA,
            data: journalList.data.data,
            categoryData: journalCategory.data.data,
            typeData: journalTypes.data.data,
        });
    };
export const fetchJournalDetailsData = (id) => async (dispatch) => {
    axios(`${FETCH_URL}standart/${id}`)
        .then((response) => {
            dispatch({
                type: FETCH_JOURNAL_DETAILS_DATA,
                data: response.data.data,
            });
        })
        .catch((e) => {
            console.log(" error: ", e);
        });
};
export const fetchGalleryPageData = () => async (dispatch) => {
    axios(`${FETCH_URL}gallery`).then((response) => {
        dispatch({
            type: FETCH_GALLERY_PAGE_DATA,
            data: response.data.data,
        });
    });
};

export const fetchProjectData = (id) => async (dispatch) => {
    axios(`${FETCH_URL}discuss-standart/${id}`).then((response) =>
        dispatch({
            type: FETCH_PROJECT_DATA,
            data: response.data,
        })
    );
};

export const fetchCheckData = (id) => async (dispatch) => {
    axios(`${FETCH_URL}standart/search/${id}`).then((response) =>
        dispatch({
            type: FETCH_CHECK_DATA,
            data: response.data,
        })
    );
};

export const fetchCheckInn = (id) => async (dispatch) => {
    axios(`${FETCH_URL}company-product-app/${id}`).then((response) =>
        dispatch({
            type: FETCH_CHECK_INN,
            data: response.data,
        })
    );
};

export const fetchDiscussStandartsData = () => async (dispatch) => {
    axios(`${FETCH_URL}discuss-standarts`).then((response) => {
        // console.log("discuss-standarts: ", response.data);
        dispatch({
            type: FETCH_DISCUSS_STANDARTS_DATA,
            data: response.data,
        });
    });
};

export const fetchBudgetData = (page) => async (dispatch) => {
    axios(`${FETCH_URL}budget?page=${page}`).then((response) => {
        console.log("budget redux: ", response.data.data);
        dispatch({
            type: FETCH_BUDGET_DATA,
            data: response.data,
        });
    });
};

export const fetchInstituteStructureData = () => async (dispatch) => {
    axios(`${FETCH_URL}inst-structure`).then((response) => {
        console.log("inst-structure: ", response.data);
        dispatch({
            type: FETCH_INSTITUTE_STRUCTURE_DATA,
            data: response.data,
        });
    });
};

export const fetchDiscussStandartsTypeData = (type) => async (dispatch) => {
    let standartType = [];
    if (type === "in_progress") {
        standartType = await axios(`${FETCH_URL}discuss-standarts?type=${type}`);
    } else if (type === "finished") {
        standartType = await axios(`${FETCH_URL}discuss-standarts?type=${type}`);
    }
    dispatch({
        type: FETCH_DISCUSS_STANDARTS_TYPE_DATA,
        data: standartType.data,
    });
};

export const fetchManagersListData = (type, page) => async (dispatch) => {
    let staff = [];
    if (type === "managers") {
        staff = await axios(`${FETCH_URL}leaders?page=${page}`);
    } else {
        staff = await axios(`${FETCH_URL}staff?page=${page}`);
    }
    dispatch({
        type: FETCH_MANAGERS_LIST_DATA,
        data: staff.data.data,
    });
};
export const fetchNewsListData = (page) => async (dispatch) => {
    axios(`${FETCH_URL}news?page=${page}`).then((response) => {
        dispatch({
            type: FETCH_NEWS_LIST_DATA,
            data: response.data,
        });
    });
};
export const searchNews = (text, page) => async (dispatch) => {
    console.log("actions searchdata: ", text);
    axios
        .post(`${FETCH_URL}search?query=${text}&page=${page}`)
        .then((response) => {
            console.log("searchNews: ", response);
            dispatch({
                type: SEARCH_NEWS,
                data: response.data.data,
            });
        });
};
export const signIn = (email, password) => async (dispatch) => {
    axios
        .post(`${FETCH_URL}login?email=${email}&password=${password}`)
        .then((response) => {
            console.log("signIn response: ", response);
            if (response.data.success) {
                // localStorage.setItem("userToken", response.data.api_token)

                Cookies.set("userToken", response.data.api_token, {expires: 1});

                dispatch({
                    type: SIGN_IN,
                    data: response.data.api_token,
                });
            } else {
                alert(response.message);
            }
        });
};
export const fetchUserData = (token, page) => async (dispatch) => {
    const config = {
        headers: {Authorization: `Bearer ${token}`},
    };
    const userData = await axios(`${FETCH_URL}user`, config);
    const userOrders = await axios(`${FETCH_URL}orders?page=${page ? page : 1}`, config);

    if (userData.data.success) {
        dispatch({
            type: FETCH_USER_DATA,
            userData: userData.data.data,
            userOrders: userOrders.data.data,
            isLogged: true,
        });
    } else {
        dispatch({
            type: FETCH_USER_DATA,
            userData: userData.data.data,
            userOrders: userOrders.data.data,
            isLogged: false,
        });
    }
};
export const signUp =
    (firstName, lastName, phone, email, password, rePassword) =>
        async (dispatch) => {
            axios
                .post(`${FETCH_URL}register`, {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone: phone,
                    password: password,
                    password_confirmation: rePassword,
                })
                .then((response) => {
                    if (response.data.success) {
                        // localStorage.setItem("userToken", response.data.api_token)
                        dispatch({
                            type: SIGN_UP,
                            data: response.data.message,
                        });
                    } else {
                        alert(response.message);
                    }
                });
        };

export const fetchReviewsData = (id, data) => async (dispatch) => {
    axios(`${FETCH_URL}projects`).then((response) => {
        console.log("reviews data: ", response.data.data);
        dispatch({
            type: FETCH_REVIEWS_DATA,
            data: response.data.data,
        });
    });
};
export const fetchOpiniosData = (id) => async (dispatch) => {
    console.log("reviews id: ", id);
    axios(`${FETCH_URL}reviews/${id}`).then((response) => {
        console.log("reviews data: ", response.data.reviews);
        dispatch({
            type: FETCH_OPINIOS_DATA,
            data: response.data.reviews,
        });
    });
};
export const fetchThoughtData = (id, data) => async (dispatch) => {
    console.log("thought id: ");
    axios(`${FETCH_URL}thought`).then((response) => {
        console.log("thought data: ", response.data);
        dispatch({
            type: FETCH_THOUGHTS_DATA,
            data: response.data,
        });
    });
};
export const fetchCartData = (token, id) => async (dispatch) => {
    const config = {
        headers: {Authorization: `Bearer ${token}`},
    };

    if (id) {
        axios.get(`${FETCH_URL}cart/${id}`, config).then((response) => {
            if (response.data.success) {
                dispatch({
                    type: FETCH_CART_DATA,
                    data: response.data.data,
                });
            } else {
                console.log(JSON.stringify(response.data.error));
            }
        });
    } else {
        axios(`${FETCH_URL}cart`, config).then((response) => {
            if (response.data.success) {
                dispatch({
                    type: FETCH_CART_DATA,
                    data: response.data.data,
                });
            } else {
                console.log(JSON.stringify(response.data.error));
            }
        });
    }
};
