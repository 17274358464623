import React from "react";

export default function AboutUs({ title, text, buttonTitle }) {
  return (
    <section className="about" id="about">
      <div className="about-content">
         <div className="container">
          <div className="row">
             <div className="col-lg-12">
              <div className="about_detail">
                <div className="about_title">
                  <h2>{title}</h2>
                  <img
                    className="img-fluid"
                    src="assets/images/about_border.png"
                    alt="border_img"
                  />
                </div>
                <div className="about_con">
                  <p>{text}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
