import axios from "axios";
import Cookies from "js-cookie";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {bindActionCreators} from "redux";
import {FETCH_URL} from "../../constants";
import {langData} from "../../index.i18n";
import * as Actions from "../../redux/actions";
import Loader from "../../components/Loader";

// import InputMask from "react-input-mask";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passVis: true,
            phoneInput: "",
            passwordInput: "",
            currentURL: null,
            loading: false,
            captcha: "",
            key: "",
            imgData: ""
            // phoneInput: "+998911336742",
            // passwordInput: "Qwerty65432!",
            // phoneInput: 998946162526,
            // passwordInput: "A12345b",
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // alert(window.location.href);
        var url = new URL(window.location.href);
        var code = url.searchParams.get("code");
        const twelveHours = 24 / 48;
        this.getCaptcha();
        if (code) {
            this.setState({loading: true});
            axios.post(`${FETCH_URL}oneid?code=${code}`).then((response) => {
                if (response.data.success) {
                    Cookies.set("userCode", code, {
                        expires: twelveHours,
                    });
                    Cookies.set("accessToken", response.data.access_token, {
                        expires: twelveHours,
                    });
                    Cookies.set("userToken", response.data.api_token, {
                        expires: twelveHours,
                    });

                    window.location.href = "/profile";
                } else {
                    toast.error(response.data.errors.err_msg);
                }
            });
        }
    }

    updateText = (path, e) => {
        this.setState({
            [path]: e.target.value,
        });
    };

    getCaptcha=()=>{
        axios.get("https://admin.uzsti.uz/captcha/api/math").then((res) => {
            this.setState({imgData: res?.data?.img ? res?.data?.img?.replaceAll("\\", "") : "", key: res?.data?.key})
        })
    }

    signIn = () => {
        const {mlang} = this.props;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        const {phoneInput, passwordInput,captcha,key} = this.state;
        const twelveHours = 24 / 48;
        // var inFifteenMinutes = new Date(new Date().getTime() + 1 * 60 * 1000)
        if (!phoneInput && !passwordInput) {
            toast.error(data.enterPhoneAndPassword);
        } else if (!phoneInput) {
            toast.error(data.enterPhoneNumber);
        } else if (!passwordInput) {
            toast.error(data.enterPassword);
        } else if (phoneInput.length !== 12) {
            toast.error(data.invalidPhone);
        } else if (passwordInput.length < 6) {
            toast.error(data.invalidPassword);
        } else {
            axios
                .post(
                    `${FETCH_URL}login?phone=${this.state.phoneInput.slice(3)}&password=${
                        this.state.passwordInput
                    }&captcha=${this.state.captcha}&key=${this.state.key}`
                )
                .then((response) => {
                    // console.log("signIn response: ", response);
                    if (response.data.success) {
                        Cookies.set("userToken", response.data.api_token, {
                            expires: twelveHours,
                        });
                        this.props.setToken(response.data.api_token);
                        const userToken = Cookies.get("userToken");
                        this.props.setToken(userToken);
                        if (this.props.match.params.id) {
                            this.props.history.goBack();
                        } else {
                            this.props.history.push("/profile");
                        }
                        // if (this.props.match.params.type === "details") {
                        //   this.props.history.push({
                        //     pathname: `/shop/${this.props.match.params.id}`,
                        //   })
                        // } else if (this.props.match.params.type === "checkout") {
                        //   this.props.history.push({
                        //     pathname: `/checkout/${this.props.match.params.id}`,
                        //   })
                        // } else {
                        //   this.props.history.push({
                        //     pathname: "/profile",
                        //   })
                        // }
                    } else {
                        // console.log(
                        //   "err login: ",
                        //   response.data.errors.err_msg ===
                        //     "Provided username and password does not match!"
                        // );
                        if(response.data.errors.err_msg  === "Provided username and password does not match!"){
				toast.error(`${data.invalidLogin}`);
			}else{
				toast.error(response.data.errors.err_msg);
			}
                    }
                });
        }
    };

    render() {
        const oneIdUrl = "https://sso.egov.uz/sso/oauth/Authorization.do";
        const clientId = "client_id";
        const {mlang} = this.props;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        // console.log("params: ", this.props.match.params.id);
        // console.log("phone input", this.state.phoneInput);
        // console.log("currentURL", this.state.currentURL);
        let eyeIcon = "";
        if (this.state.passVis) {
            eyeIcon = "fa fa-eye";
        } else {
            eyeIcon = "fa fa-eye-slash";
        }
        return this.state.loading ? (
            <Loader/>
        ) : (
            <div>
                <div className="full_page">
                    <section className="auth">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul
                                        className="nav nav-tabs d-flex justify-content-center align-items-center mt-5"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active font-weight-bold text-uppercase"
                                                id="home-tab"
                                                data-toggle="tab"
                                                href="#home"
                                                role="tab"
                                                aria-controls="home"
                                                aria-selected="true"
                                            >
                                                {data.oidLogin}
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link font-weight-bold text-uppercase"
                                                id="profile-tab"
                                                data-toggle="tab"
                                                href="#profile"
                                                role="tab"
                                                aria-controls="profile"
                                                aria-selected="false"
                                            >
                                                {data.siteLogin}
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content pt-4" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                        >
                                            <div className="reg">
                                                <div
                                                    className="reg__box"
                                                    style={{backgroundColor: "#fff", paddingTop: 0}}
                                                >
                                                    <img
                                                        style={{
                                                            display: "block",
                                                            margin: "0 auto",
                                                            marginBottom: 10,
                                                        }}
                                                        src="/assets/images/one_id.png"
                                                        alt="one_id"
                                                    />
                                                    <h3
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            color: "#00bbd1",
                                                            paddingBottom: 15,
                                                        }}
                                                    >
                                                        {data.oidWelcome}
                                                    </h3>
                                                    <p style={{textAlign: "center", lineHeight: 2}}>
                                                        {data.oidText}
                                                    </p>
                                                    <form action={oneIdUrl}>
                                                        <button
                                                            type="submit"
                                                            // value="submit"
                                                            onClick={() =>
                                                                this.setState({
                                                                    currentURL: window.location.href,
                                                                })
                                                            }
                                                        >
                                                            {data.oneid}
                                                        </button>
                                                        <input
                                                            type="hidden"
                                                            name="response_type"
                                                            value="one_code"
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={clientId}
                                                            value="uzsti"
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="redirect_uri"
                                                            value={`${window.location.href}`}
                                                        />
                                                        <input type="hidden" name="scope" value="legal"/>
                                                        <input type="hidden" name="state" value="auth"/>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="tab-pane fade"
                                            id="profile"
                                            role="tabpanel"
                                            aria-labelledby="profile-tab"
                                        >
                                            <div className="reg">
                                                <div className="reg__box">
                                                    <h2>{data.cabSignIn}</h2>
                                                    <span>{data.enterToProfile}</span>
                                                    <form>
                                                        <input
                                                            className="inputt"
                                                            type="number"
                                                            placeholder={data.phoneNumberEnter}
                                                            onChange={(e) => this.updateText("phoneInput", e)}
                                                            value={this.state.phoneInput}
                                                        />
                                                        {/* <InputMask
                          alwaysShowMask={true}
                          // maskChar={null}
                          mask="+\9\98999999999"
                          className="inputt"
                          // type="phone"
                          // placeholder={data.phoneNumberEnter}
                          onChange={(e) => this.updateText("phoneInput", e)}
                          value={this.state.phoneInput}
                        /> */}
                                                        <div className="auth__pas">
                                                            <input
                                                                className="inputt"
                                                                type={this.state.passVis ? "password" : "text"}
                                                                placeholder={data.passwordTitle}
                                                                onChange={(e) =>
                                                                    this.updateText("passwordInput", e)
                                                                }
                                                                value={this.state.passwordInput}
                                                            />
                                                            <span
                                                                onClick={() =>
                                                                    this.setState({
                                                                        passVis: !this.state.passVis,
                                                                    })
                                                                }
                                                                className="btn-sound"
                                                            >
                                <i className={eyeIcon}/>
                              </span>
                                                            {/* <a href="#">{data.forgotPassword}</a> */}
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <img style={{cursor:"pointer"}} onClick={this.getCaptcha} height={49}
                                                                 src={this.state?.imgData}
                                                            />
                                                            <input onChange={(e)=>{this.updateText("captcha",e)}} placeholder="Natija" className="inputt"
                                                                   type="text"/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="reg__check">
                                                                    <Link to="/register">
                                                                        <h6 style={{textAlign: "center"}}>
                                                                            {data.register}
                                                                        </h6>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="reg__check">
                                                                    <Link to="/restore">
                                                                        <h6 style={{textAlign: "center"}}>
                                                                            {data.forgotPassword}
                                                                        </h6>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button
                                                            type="button"
                                                            value="submit"
                                                            onClick={() => this.signIn()}
                                                        >
                                                            {data.signInButton}
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer/>
                    </section>
                    {/* /call_to_action end */}
                </div>
                {/* /scroll_to_top start */}
                {/* <a href="#" className="scrollToTop">
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </a> */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
