import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import samplePDF from "./document.pdf";
import "./App.css";

// const url =
//   "https://cors-anywhere.herokuapp.com/https://smsiti.smarttechnology.uz/storage/pdfs/h8Y9q9VDtgDq.pdf";

export default function Modap({ pdfProps, loadingLang }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Document
      loading={loadingLang}
      file={pdfProps}
      onContextMenu={(e) => e.preventDefault()}
      className="pdf-container"
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from({length: numPages}, (_, i) => i + 1).map((page) => (
        <Page className="pdf-file" pageNumber={page} />
      ))}
    </Document>
  );
}
