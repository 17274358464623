import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../redux/actions";
import {langData} from "../index.i18n";
import {Link} from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import Cookies from "js-cookie";
import {toast, ToastContainer} from "react-toastify";
import {FETCH_URL} from "../constants";
import {Button, Empty, Input, Modal, Pagination} from "antd";
import {Form, FormLabel} from "react-bootstrap";

class Reesters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            password: "",
            username: "",
            open_contract: false,
            subscriptions: {},
            lastSubscribe: [],
            page: 1,
            modal: {
                open: false,
                item: null
            },
            name: "",
            code: "",
            status: "",
            oks: "",
            perPage:20
        };
    }

    componentDidMount() {
        const {userToken} = this.props;
        this.getLastSubscribes()
        // console.log(" profile token: ", userToken);

        if (!userToken) {
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            this.props.fetchUserData(userToken);
        }
    }

    getUserSubscriptions = () => {
        const {userToken} = this.props;
        axios

            .post(`${FETCH_URL}subscriptions`, null, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            })
            .then((response) => {
                if (response.data.success) {
                    let a = document.createElement('a');
                    a.href = `https://admin.uzsti.uz/${response?.data?.data?.contract}`;
                    a.target = "_blank"
                    a.download = response?.data?.data?.contract;
                    a.click();
                    a.remove();
                    this.setState({open_contract: false})
                }
            })
            .catch((e) => console.log("error: ", e));
    }


    getLastSubscribes = () => {
        const {userToken} = this.props;
        axios
            .get(`${FETCH_URL}registries?page=${this.state.page}&per_page=${this.state.perPage}${this.state.code ? `&code=${this.state.code}` : ""}${this.state.name ? `&name=${this.state.name}` : ""}${this.state.status ? `&status=${this.state.status}` : ""}${this.state.oks ? `&oks=${this.state.oks}` : ""}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            })
            .then((response) => {
                if (response.data.success) {
                    this.setState({lastSubscribe: response?.data?.data, page:this.state.page})
                }
            })
            .catch((e) => console.log("error: ", e));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.oks !== this.state.oks || prevState.page !== this.state.page || prevState.name.length !== this.state.name.length || prevState.code.length !== this.state.code.length || prevState.status !== this.state.status) {
            this.getLastSubscribes()
        }
    }

    changeText = (field, e) => {
        // console.log(field, e.target.value);
        this.setState({
            [field]: e.target.value,
        });
    };

    updateUserData = () => {
        const {userToken, mlang} = this.props;
        const dataLang =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        };
        const data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            phone: this.state.phoneNumber,
            password: this.state.password,
            username: this.state.username,
        };
        if (this.state.password.length < 7) {
            toast.error(dataLang.invalidPassword);
        } else {
            axios

                .post(`${FETCH_URL}profile`, data, config)
                .then((response) => {
                    // console.log("updateData: ", response);
                    if (response.data.success) {
                        toast.success(dataLang.changesAccepted);
                        // this.setState = { password: "" };
                    }
                })
                .catch((e) => console.log("error: ", e));
        }
    };

    render() {
        const {mlang, LoadingUserData, UserData, isLogged} = this.props;
        const {modal} = this.state;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        let fullName = "";
        if (UserData.first_name && UserData.last_name) {
            fullName = `${UserData.first_name} ${UserData.last_name}`;
        } else {
            fullName = UserData.username;
        }
        // console.log("FUll name", fullName);
        return LoadingUserData && isLogged ? (
            <Loader/>
        ) : !LoadingUserData && !isLogged ? (
            (this.props.setLoader("UserData"),
                this.props.history.push("/login"),
                null)
        ) : (
            <div>
                <Modal width={1200} title={modal?.item?.name} footer={false} open={modal?.open}
                       onCancel={() => this.setState({modal: {open: false, item: null}})}>
                    <h1 className="d-flex justify-content-end"><Button type="default">Faylni oldindan ko'rish</Button>
                    </h1>
                </Modal>
                <div className="full_page">
                    <section className="auth">
                        <div className="container-fluid px-5">
                            <div className="row px-3">
                                <div className="col-md-12">
                                    <div className="profile">
                                        {/* <div className="pro__left">
                                            <div className="pro__avatar">
                                                <div
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                        // background: "red",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        style={{width: "60%", height: "60%"}}
                                                        src="/assets/images/user.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="pro__text">
                                                    <span>{fullName}</span>
                                                    <a href={`mailto:${UserData.email}`}>
                                                        {UserData.email}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="pro__nav">
                                                <ul>
                                                    <li>
                                                        <Link to="/profile">
                                                            <img
                                                                src="assets/images/user-profile2.svg"
                                                                alt=""
                                                            />
                                                            {data.profileSettings}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/orders">
                                                            <img src="assets/images/orders.svg" alt=""/>
                                                            {data.myOrders}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/subscription">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.subscriptions}
                                                        </Link>
                                                    </li>
                                                    <li className="activee">
                                                        <Link to="/reesters">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.view_reestr}
                                                        </Link>
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            this.props.setToken("");
                                                            Cookies.remove("userToken");
                                                            Cookies.remove("accessToken");
                                                            Cookies.remove("userCode");
                                                        }}
                                                    >
                                                        <Link to="/">
                                                            <img src="assets/images/power.svg" alt=""/>
                                                            {data.signOutButton}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        <div className="pro__settings w-100">
                                            <div className="d-flex align-items-center">
                                                <svg onClick={() => this.props.history.goBack()}
                                                     style={{cursor: "pointer"}} width={25} height={25}
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="1.5" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
                                                </svg>
                                                <h2 className="ml-3">{data.subscriptions}</h2>
                                            </div>
                                            <div className="d-flex mb-4">
                                                <div className="mr-4">
                                                    <FormLabel>ГОСТ belgisi bo'yicha qidirish</FormLabel>
                                                    <Input value={this.state.code}
                                                           onChange={(e) => this.setState({code: e.target.value})}
                                                           className="py-0"
                                                           placeholder="ГОСТ belgisi     bo'yicha qidirish"/>
                                                </div>
                                                <div className="mr-4">
                                                    <FormLabel>Nomi bo'yicha qidirish</FormLabel>
                                                    <Input value={this.state.name} onChange={(e) => {
                                                        this.setState({name: e.target.value})
                                                    }} className="py-0" placeholder="Nomi bo'yicha qidirish"/>
                                                </div>
                                                <div className="mr-4">
                                                    <FormLabel>OKS bo'yicha qidirish</FormLabel>
                                                    <Input value={this.state.oks}
                                                           onChange={(e) => this.setState({oks: e.target.value})}
                                                           className="py-0" placeholder="OKS bo'yicha qidirish"/>
                                                </div>
                                                <div>
                                                    <FormLabel>Status bo'yicha filterlash</FormLabel>
                                                    <select onChange={(e) => this.setState({status: e.target.value})}>
                                                        <option style={{color:"rgba(0,0,0,.4)"}} value="">
                                                            Status tanlang!
                                                        </option>
                                                        <option className="bg-success text-light py-2 px-1 rounded" value="2">
                                                            Yaroqli
                                                        </option>
                                                        <option className="bg-warning text-light py-2 px-1 rounded" value="1">
                                                            Yaroqsiz
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="row">
                                                    <table className="chiter-table table table-sm table-bordered">
                                                        <thead>
                                                        <tr className="table-active" style={{height:"60px"}}>
                                                            <th>№</th>
                                                            <th>Code</th>
                                                            <th>Created at</th>
                                                            <th>Name</th>
                                                            <th>Introduced at</th>
                                                            <th>Rule accepts</th>
                                                            <th>Rule cancels</th>
                                                            <th>Status name</th>
                                                            <th>Oks</th>
                                                            <th>Comment</th>
                                                            <th></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state?.lastSubscribe?.data?.length?this.state?.lastSubscribe?.data?.map((item, index) => <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.code}</td>
                                                            <td>{item?.created_at ? new Date(item?.created_at).toLocaleDateString() : ""}</td>
                                                            <td style={{verticalAlign: "middle"}}>
                                                                <span className="mb-0" title={item?.name} style={{
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    width: "250px"
                                                                }}>
                                                                    {item?.name}
                                                                </span>
                                                            </td>
                                                            <td>{item?.introduced_at}</td>
                                                            <td>{item?.rule_accepts}</td>
                                                            <td>{item?.rule_cancels}</td>
                                                            <td>
                                                                <span style={{cursor: "pointer"}}
                                                                      className={`rounded ${item?.status === 2 ? "bg-success" : "bg-warning"} mb-0 text-light py-2 text-center`}>
                                                                    {item?.status_name}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="mb-0" title={item?.oks} style={{
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    width: "250px"
                                                                }}>
                                                                    {item?.oks}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="mb-0" title={item?.comment} style={{
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    width: "250px"
                                                                }}>
                                                                    {item?.comment}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <Link to={`/registry/${item?.id}`} style={{cursor: "pointer"}}
                                                                      className="rounded bg-info mb-0 text-light py-2 px-2 text-center">
                                                                    {data.view_reestr}
                                                                </Link>
                                                            </td>
                                                        </tr>):
                                                            <tr>
                                                                <td colSpan={11}><Empty description={"No data found!"}/></td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex pt-3 justify-content-end align-items-center">
                                                    <Pagination onChange={(a, p) => {
                                                        this.setState({page: a})
                                                    }}
                                                                showSizeChanger={false}
                                                                total={this.state?.lastSubscribe?.total}/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer/>
                    </section>
                </div>
                {/* /scroll_to_top start */}
                <Link to="/" className="scrollToTop">
                    <i className="fa fa-arrow-up" aria-hidden="true"/>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        userToken: state.fetchDataReducer.userToken,
        UserData: state.fetchDataReducer.UserData,
        isLogged: state.fetchDataReducer.isLogged,
        LoadingUserData: state.fetchDataReducer.LoadingUserData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Reesters);
