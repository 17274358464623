export const langData = {
  uz: {
    contactUs: "Bog'lanish",
    incognitoContactUs: "Anonim bog'laning",
    FIO: "F.I.Sh",
    firstName: "Ism",
    secondName: "Familiya",
    email: "Email",
    phoneNumber: "Telefon raqam",
    messageText: "Sizning xatingiz",
    opinionText: "Sizning fikringiz",
    sendMessage: "Xabar yuborish",
    comments: "Barcha izohlar",
    sendUsMessage: "Bizga xabar yuboring",
    submit: "Yuborish",
    msgReceived: "Xabaringiz qabul qilindi!",
    allFieldMustBeFilled: "Barcha maydonlarni to'ldirish shart!",
    giveYourOpinion: "O'z fikringizni bildiring",
    download: "Yuklab olish",
    basketModalCancel: "Bekor qilish",
  },
  ru: {
    contactUs: "Свяжитесь с нами",
    incognitoContactUs: "Связаться анонимно",
    FIO: "ФИО",
    firstName: "Имя",
    secondName: "Фамилия",
    email: "Е-майл",
    phoneNumber: "Номер телефона",
    messageText: "Напишите сообщение",
    opinionText: "Напишите мнение",
    sendMessage: "Отправить сообщение",
    comments: "Все комменты",
    sendUsMessage: "Напишите нам сообщение",
    submit: "Отправить",
    msgReceived: "Ваше сообщение было получено!",
    allFieldMustBeFilled: "Все поля должны быть заполнены!",
    giveYourOpinion: "Выскажите свое мнение",
    download: "Скачать",
    basketModalCancel: "Отменять",
  },
  en: {
    contactUs: "Contact us",
    incognitoContactUs: "Contact anonymously",
    FIO: "Full name",
    firstName: "First name",
    secondName: "Second name",
    email: "E-mail",
    phoneNumber: "Phone number",
    messageText: "Write a message",
    opinionText: "Write an opinion",
    sendMessage: "Send message",
    comments: "All comments",
    sendUsMessage: "Send us message",
    submit: "Submit",
    msgReceived: "Your message has been received!",
    allFieldMustBeFilled: "All fields must be filled in!",
    giveYourOpinion: "Give your opinion",
    download: "Download",
    basketModalCancel: "Cancel",
  },
};
