import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as Actions from "../../redux/actions"
import { langData } from "./index.i18n"

class Appeals extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { mlang } = this.props
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en

    return (
      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
        <article className="blog-post-wrapper">
          <div className="blog-banner">
            <div className="blog-content">
              <h4 style={{ fontWeight: "700" }}>{data.title}</h4>
              <p dangerouslySetInnerHTML={{ __html: data.text }} />
            </div>
          </div>
        </article>
        <div className="clear" />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Appeals)
