import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../components/Loader";
import { IMAGE_URL } from "../constants";
import SideBar from "../containers/SideBar";
import { _translator } from "../helpers";
import { langData } from "../index.i18n";
import * as Actions from "../redux/actions";

class InstituteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: this.props.match.params.id,
    };
  }
  componentDidUpdate() {
    if (this.state.currentPath !== this.props.match.params.id) {
      this.updateState();
    }
  }

  updateState = () => {
    this.setState(
      {
        currentPath: this.props.match.params.id,
      },
      () => {
        this.props.setLoader("ServiceData");
        this.props.fetchDetailsData("ServiceData", this.props.match.params.id);
      }
    );
  };

  componentDidMount() {
    this.props.fetchDetailsData("ServiceData", this.props.match.params.id);
    window.scrollTo(0, 0);
  }
  render() {
    const { mlang, ServiceData, LoadingServiceData } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log("Institute details:", ServiceData);
    return LoadingServiceData ? (
      <Loader />
    ) : (
      <div>
        <div className="full_page">
          <div className="blog_pages">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
                  {/* single-blog start */}
                  <article className="blog-post-wrapper">
                    <div className="blog-banner">
                      <div className="blog-content">
                        {/* <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          target="_blank"
                          href={
                            mlang === "uz" && ServiceData.file
                              ? IMAGE_URL + ServiceData.file
                              : mlang === "ru" && ServiceData.file_ru
                              ? IMAGE_URL + ServiceData.file_ru
                              : mlang === "en" && ServiceData.file_en
                              ? IMAGE_URL + ServiceData.file_en
                              : ""
                          }
                        > */}
                        <h4
                          style={{
                            fontWeight: "bold",
                            display: "inline-block",
                          }}
                        >
                          {_translator("title", ServiceData, mlang)}
                        </h4>
                        {/* <i
                            style={{
                              display:
                                (mlang === "uz" && ServiceData.file) ||
                                (mlang === "ru" && ServiceData.file_ru) ||
                                (mlang === "en" && ServiceData.file_en)
                                  ? "block"
                                  : "none",
                              textAlign: "center",
                              fontSize: 40,
                              color: "#302b68",
                              verticalAlign: "middle",
                            }}
                            class="fas fa-file-pdf"
                          ></i>
                        </a> */}

                        <p
                          className="description-style"
                          dangerouslySetInnerHTML={{
                            __html: _translator(
                              "description",
                              ServiceData,
                              mlang
                            ),
                          }}
                          style={{
                            marginTop: 20,
                            paddingRight: 10,
                          }}
                        />
                      </div>
                      {/* <img
                        className="img-fluid"
                        src={mlang === "uz"
                          ? { ServiceData.image }
                          : { ServiceData }}
                      /> */}
                      <img
                        style={{
                          display:
                            ServiceData.image ||
                            ServiceData.image_ru ||
                            ServiceData.image_en
                              ? "block"
                              : "none",
                        }}
                        className="img-fluid"
                        src={
                          mlang === "uz"
                            ? IMAGE_URL + ServiceData.image
                            : mlang === "ru"
                            ? IMAGE_URL + ServiceData.image_ru
                            : IMAGE_URL + ServiceData.image_en
                        }
                      />
                    </div>
                  </article>
                  <div className="clear" />
                  {/* single-blog end */}
                </div>
                <div className="col-lg-4 col-md-12">
                  <SideBar />
                </div>
              </div>
            </div>
          </div>
          {/* /blog_con end*/}
        </div>
        {/* /scroll_to_top start*/}
        <a href="#" className="scrollToTop">
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    ServiceData: state.fetchDataReducer.ServiceData,
    LoadingServiceData: state.fetchDataReducer.LoadingServiceData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(InstituteDetails);
