import Cookies from "js-cookie";
import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import {Pagination} from "react-laravel-paginex";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import Loader from "../../components/Loader";
import OrderTable from "../../components/Tables";
import {IMAGE_URL} from "../../constants";
import {langData} from "../../index.i18n";
import * as Actions from "../../redux/actions";
import "./styles.css";


class MyOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedJournalId: 0,
            selectedJournalTitle: "",
            selectedJournalPdf: "",
            modalData: [],
        };
    }

    componentDidMount() {
        const userToken = Cookies.get("userToken");
        if (userToken) {
            this.props.fetchUserData(userToken);
        } else {
            this.props.history.push("/login");
        }
    }

    getData = (data) => {
        const userToken = Cookies.get("userToken");
        this.props.fetchUserData(userToken, data.page);
        window.scrollTo(0, 0);
    }

    changeText = (field, e) => {
        // console.log(field, e.target.value);
        this.setState({
            [field]: e.target.value,
            userData: [],
            userOrders: [],
        });
    };
    drawModal = (id, title, pdf) => {
        return (
            <Modal
                show={this.state.show}
                onHide={() => this.setState({show: false})}
                // size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        src={`${IMAGE_URL}orders/${id}${pdf}?page=hsn#toolbar=0`}
                        title="title"
                        style={{height: window.innerHeight * 0.65, width: 800}}
                    />
                </Modal.Body>
            </Modal>
        );
    };

    showModal = (bool, data) => {
        this.setState({
            show: bool,
            modalData: data,
        });
    };

    render() {
        const {
            mlang,
            LoadingUserData,
            UserData,
            UserOrders,
            isLogged,
        } = this.props;
        const data =
            mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
        let fullName = "";
        if (UserData.first_name && UserData.last_name) {
            fullName = `${UserData.first_name} ${UserData.last_name}`;
        } else {
            fullName = UserData.username;
        }
        return LoadingUserData && isLogged ? (
            <Loader/>
        ) : !LoadingUserData && !isLogged ? (
            (this.props.setLoader("OrderData"),
                this.props.setLoader("UserData"),
                this.props.history.push("/login"),
                null)
        ) : (
            <div>
                <div className="full_page">
                    <section className="auth">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile">
                                        <div className="pro__left">
                                            <div className="pro__avatar">
                                                <div
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                        // background: "red",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        style={{width: "60%", height: "60%"}}
                                                        src="/assets/images/user.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="pro__text">
                                                    <span>{fullName}</span>
                                                    <Link to="/">{UserData.email}</Link>
                                                </div>
                                            </div>
                                            <div className="pro__nav">
                                                <ul>
                                                    <li>
                                                        <Link to="/profile">
                                                            <img
                                                                src="assets/images/user-profile2.svg"
                                                                alt=""
                                                            />
                                                            {data.profileSettings}
                                                        </Link>
                                                    </li>
                                                    <li className="activee">
                                                        <Link to="/orders">
                                                            <img src="assets/images/orders.svg" alt=""/>
                                                            {data.myOrders}
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/subscription">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.subscriptions}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/reesters">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.view_reestr}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/notifications">
                                                            <img src="assets/images/subscription.svg" alt=""/>
                                                            {data.notifications}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/">
                                                            <img src="assets/images/power.svg" alt=""/>
                                                            {data.signOutButton}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="pro__settings">
                                            <h2>{data.invoices}</h2>

                                            <div className="table-responsive">
                                                <table className="chiter-table table table-sm table-bordered">
                                                    <tbody>
                                                    <tr>
                                                        <th>№</th>
                                                        <th>#</th>
                                                        <th>{data.docDate}</th>
                                                        <th>{data.orderSum}</th>
                                                        <th>{data.orderCount}</th>
                                                        <th>{data.orderStatus}</th>
                                                        <th>{data.orderCont}</th>
                                                    </tr>

                                                    {UserOrders.data.map((item, index) => (
                                                        <OrderTable
                                                            loadingLang={data.loadingLang}
                                                            data={item}
                                                            mlang={mlang}
                                                            key={index.toString()}
                                                            id={item.id}
                                                            title={data.orderTitle}
                                                            price={data.orderPrice}
                                                            page={data.orderPage}
                                                            paid={data.orderPaid}
                                                            notPaid={data.orderNotPaid}
                                                            showModal={this.showModal}
                                                            userData={this.props.UserData}
                                                        />
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                className="row mt-lg-5"
                                                style={{
                                                    display: UserOrders.last_page === 1 ? "none" : "block",
                                                }}
                                            >
                                                <div className="col-md-12 d-flex justify-content-center">
                                                    <Pagination
                                                        changePage={this.getData}
                                                        data={UserOrders}
                                                        nextButtonText={data.next}
                                                        prevButtonText={data.previous}
                                                        className="pagination"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mlang: state.languageReducer.language,
        UserData: state.fetchDataReducer.UserData,
        isLogged: state.fetchDataReducer.isLogged,
        UserOrders: state.fetchDataReducer.UserOrders,
        LoadingUserData: state.fetchDataReducer.LoadingUserData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
