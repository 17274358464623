import React from "react";
import { IMAGE_URL } from "../constants";
import Moment from "moment";

import {
  _timeParserD,
  _timeParserM,
  _translator,
  _kirilToLatin,
} from "../helpers";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";

export default function NewsCard({ data, mlang, detailsButton, widthSize }) {
  const time = new Date();
  return (
    <div className={`col-md-${widthSize}`}>
      <div
        className="news_box blog_page "
        style={{ height: 450, paddingTop: 0 }}
      >
        <div className="news_img">
          <img
            className="img-fluid"
            style={{ height: 200, objectFit: "cover" }}
            src={IMAGE_URL + data.image}
            alt="news-2"
          />
          <div className="news_date">
            <div className="date_box">
              <h2 style={{ textTransform: "capitalize" }}>
                <span className="large">
                  {_timeParserD(data.publish_date, mlang)}
                </span>{" "}
                <br />
                {/* {_timeParserM(data.dateMonth, mlang)} */}
                {mlang === "uz"
                  ? _kirilToLatin(
                      Moment(data.publish_date).locale(mlang).format("MMMM")
                    )
                  : Moment(data.publish_date).locale(mlang).format("MMMM")}{" "}
              </h2>
            </div>
          </div>
        </div>
        <div className="news_detail">
          <h3>
            <LinesEllipsis
              text={
                data.title && mlang === "uz"
                  ? data.title.replace(/<[^>]+>/g, "")
                  : data.title_ru && mlang === "ru"
                  ? data.title_ru.replace(/<[^>]+>/g, "")
                  : data.title_en && mlang === "en"
                  ? data.title_en.replace(/<[^>]+>/g, "")
                  : ""
              }
              ellipsis="..."
              trimRight
              basedOn="letters"
              maxLine={2}
            />
          </h3>
          <div
            id="par"
            style={{
              height: 100,
              overflow: "hidden",
              textAlign: "justify",
            }}
          >
            {
              <LinesEllipsis
                text={
                  data.description && mlang === "uz"
                    ? data.description
                        .replace(/<[^>]+>/g, "")
                        .replace(/&nbsp/g, "")
                        .replace(/[;]/g, "")
                    : data.description_ru && mlang === "ru"
                    ? data.description_ru
                        .replace(/<[^>]+>/g, "")
                        .replace(/&nbsp/g, "")
                        .replace(/[;]/g, "")
                    : data.description_en && mlang === "en"
                    ? data.description_en
                        .replace(/<[^>]+>/g, "")
                        .replace(/&nbsp/g, "")
                        .replace(/[;]/g, "")
                    : ""
                }
                maxLine={4}
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            }
          </div>

          <Link
            to={`/news/${data.id}`}
            style={{
              position: "absolute",
              bottom: 50,
              left: 0,
              width: "100%",
            }}
          >
            {detailsButton}
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </Link>
        </div>
      </div>
    </div>
  );
}
