import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
import Moment from "moment";

import SideBar from "../containers/SideBar";
import Loader from "../components/Loader";
import { Pagination } from "react-laravel-paginex";
import { _translator } from "../helpers";

export class Opinions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchOpiniosData(this.props.match.params.id);
  }
  getData = (data) => {
    this.props.fetchOpiniosData(data.page);
    window.scrollTo(0, 0);
  };
  render() {
    const { mlang, OpiniosData, LoadingOpiniosData } = this.props;
    // console.log("opinios data: ", OpiniosData);
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    const selectedOpinion = OpiniosData
      ? OpiniosData.data.filter(
          (opinion) => opinion.id == this.props.match.params.id
        )[0]
      : "sayyod";
    // console.log("selectedOpinion: ", selectedOpinion);
    return LoadingOpiniosData ? (
      <Loader />
    ) : (
      <div>
        <div className="blog_page ">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 chiter nashr">
                <div className="table-responsive">
                  {selectedOpinion ? (
                    <h3>
                      {_translator("title", selectedOpinion.project, mlang)}
                    </h3>
                  ) : null}
                  <ul className="nashr__main">
                    {OpiniosData.data.length
                      ? OpiniosData.data.map((opinion) => (
                          <li>
                            <img src="assets/images/avatar.png" alt="" />
                            <div className="nashr__text">
                              <a href="#">{opinion.msg}</a>
                              <span>{opinion.name}</span>
                              <span>
                                {data.docDate}:{" "}
                                {Moment(opinion.updated_at).format(
                                  "DD-MM-YYYY, h:mm:ss"
                                )}
                              </span>
                            </div>
                          </li>
                        ))
                      : `${data.noOpinon}`}
                  </ul>
                </div>
                <div
                  className="row mt-lg-5"
                  style={{
                    display: OpiniosData.last_page === 1 ? "none" : "block",
                  }}
                >
                  <div className="col-md-12 d-flex justify-content-center">
                    <Pagination
                      changePage={this.getData}
                      data={this.props.OpiniosData}
                      nextButtonText={data.next}
                      prevButtonText={data.previous}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <SideBar />
                {/* <CalendarCard /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    OpiniosData: state.fetchDataReducer.OpiniosData,
    LoadingOpiniosData: state.fetchDataReducer.LoadingOpiniosData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Opinions);
