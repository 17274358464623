import React, { Component } from "react"
import { GiveOpinion, CitizensAppeals, Complaints } from "../components/Cards"
import { MainTitle } from "../components/Titles"

export default class UsefulLinks extends Component {
  render() {
    const { mlang, title } = this.props
    return (
      <section className="mini">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <MainTitle title={title} />
              <div className="row ">
                <div className="col-md-12 mini__content">
                  <CitizensAppeals mlang={mlang} />
                  <GiveOpinion mlang={mlang} />
                  <Complaints mlang={mlang} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
