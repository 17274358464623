import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../index.i18n";
import Ads from "../containers/Ads";
import { Link } from "react-router-dom";
import { Pagination } from "react-laravel-paginex";

import SearchBarMain from "../components/SearchBars/SearchBarMain";
import NewsCard from "../components/NewsCard";
import Loader from "../components/Loader";
import SideBar from "../containers/SideBar";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
  }
  componentDidMount() {
    this.props.searchNews(this.props.match.params.id, 1);
  }
  fetchSearchData = (text) => {
    // console.log("text: ", text);
    if (text.length) {
      this.setState(
        {
          searchText: text,
        },
        () => this.props.searchNews(text, 1)
      );
    }
  };
  getData = (data) => {
    this.props.searchNews(this.state.searchText, data.page);
    window.scrollTo(0, 0);
  };

  render() {
    const { mlang, SearchNews, LoadingSearchNews } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log("SearchNews: ", SearchNews.last_page);
    return LoadingSearchNews ? (
      <Loader />
    ) : (
      <div className="full_page">
        <div className="blog_page">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8">
                <div className="row">
                  <SearchBarMain
                    placeholderTitle={data.search}
                    searchNews={this.props.SearchNews}
                    fetchSearchData={this.fetchSearchData}
                  />
                  {/* <div style={{ width: 100, height: 100, overflow: "hidden" }}>
                    <Loader />
                  </div> */}
                </div>
                <div className="row">
                  {!SearchNews.data.length ? (
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      <p>{data.noNewsFound}</p>
                    </div>
                  ) : (
                    SearchNews.data.map((item) => (
                      <NewsCard
                        data={item}
                        mlang={mlang}
                        key={item.id.toString()}
                      />
                    ))
                  )}
                </div>
                <div
                  className="row"
                  style={{
                    display: SearchNews.last_page === 1 ? "none" : "block",
                  }}
                >
                  <div className="col-md-12 d-flex justify-content-center">
                    <Pagination
                      changePage={this.getData}
                      data={this.props.SearchNews}
                      nextButtonText={data.next}
                      prevButtonText={data.previous}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <SideBar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    SearchNews: state.fetchDataReducer.SearchNews,
    LoadingSearchNews: state.fetchDataReducer.LoadingSearchNews,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Search);
