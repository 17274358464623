import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import Loader from "../components/Loader";
import { _translator } from "..//helpers";
// import img from "../../public/assets/images/structure-uz.jpg";
class InstituteStructure extends Component {
  componentDidMount() {
    this.props.fetchInstituteStructureData();
    window.scrollTo(0, 0);
  }
  render() {
    const {
      mlang,
      InstituteStructureData,
      LoadingTaskFunctionsData,
    } = this.props;

    // console.log("TEST ", InstituteStructureData);
    return (
      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
        <article className="blog-post-wrapper">
          <div className="blog-banner">
            <div className="blog-content">
              {/* <h4 style={{ fontWeight: "bold" }}>
                {_translator("title", InstituteStructureData, mlang)}
              </h4> */}

              {/* <p
                dangerouslySetInnerHTML={{
                  __html: _translator(
                    "description",
                    InstituteStructureData,
                    mlang
                  ),
                }}
                style={{
                  marginTop: 20,
                  paddingRight: 10,
                }}
              /> */}
            </div>
            <img
              className="img-fluid"
              src={mlang === "uz"
                ? "/assets/images/structure-uz.jpg"
                : "/assets/images/structure-ru.jpg"}
              alt=""
            />
          </div>
        </article>
        <div className="clear" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    InstituteStructureData: state.fetchDataReducer.InstituteStructureData,
    LoadingTaskFunctionsData: state.fetchDataReducer.LoadingTaskFunctionsData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(InstituteStructure);
