// import { act } from "react-dom/test-utils";
import {
  FETCH_MAIN_SCREEN_DATA,
  FETCH_GALLERY_PAGE_DATA,
  // FETCH_MANAGER_DATA,
  // FETCH_NEWS_DATA,
  FETCH_NEWS_LIST_DATA,
  SET_LOADER,
  SET_PAGINATION,
  FETCH_MANAGERS_LIST_DATA,
  // FETCH_SERVICE_DATA,
  SIGN_IN,
  SIGN_UP,
  FETCH_JOURNAL_LIST_DATA,
  SEARCH_NEWS,
  // FETCH_TENDERS_DATA,
  // FETCH_TASKS_FUNCTIONS_DATA,
  // FETCH_VACANCY_DATA,
  FETCH_DOCUMENTS_DATA,
  FETCH_JOURNAL_DETAILS_DATA,
  FETCH_USER_DATA,
  FETCH_DETAILS_DATA,
  FETCH_ORDER_DATA,
  SET_TOKEN,
  FETCH_REVIEWS_DATA,
  FETCH_THOUGHTS_DATA,
  FETCH_OPINIOS_DATA,
  FETCH_CART_DATA,
  FETCH_PROJECT_DATA,
  FETCH_CHECK_DATA,
  FETCH_CHECK_INN,
  FETCH_DISCUSS_STANDARTS_TYPE_DATA,
  FETCH_DISCUSS_STANDARTS_DATA,
  FETCH_BUDGET_DATA,
  FETCH_INSTITUTE_STRUCTURE_DATA,
} from "../actions";

let dataState = {
  userToken: "",
  isLogged: false,
  UserData: [],
  UserOrders: [],
  PaginationPage: 1,
  ManagerData: [],
  MainSliderData: [],
  LeadersSliderData: [],
  NewsSliderData: [],
  ServicesData: [],
  DepartmentsData: [],
  DepartmentData: [],
  InformationData: [],
  GalleryData: [],
  GalleryPageData: [],
  NewsData: [],
  CartData: [],
  ServiceData: [],
  NewsListData: [],
  ManagersListData: [],
  SearchNews: [],
  TendersData: [],
  TaskFunctionsData: [],
  InstituteStructureData: [],
  VacancyData: [],
  CheckData: [],
  CheckInn: [],
  DocumentsCategoryData: [],
  DocumentsData: [],
  JournalListData: [],
  JournalCategoryData: [],
  JournalTypesData: [],
  JournalDetailsData: [],
  AboutData: [],
  CommitteeData: [],
  CouncilData: [],
  OrderData: [],
  ReviewsData: [],
  ThoughtsData: [],
  OpinionsData: [],
  ProjectData: [],
  StandartData: [],
  BudgetData: [],
  StandartDataType: [],
  LoadingBudgetData: true,
  LoadingStandartData: true,
  LoadingManagerData: true,
  LoadingGalleryPageData: true,
  LoadingMainScreen: true,
  LoadingNewsData: true,
  LoadingNewsListData: true,
  LoadingManagersListData: true,
  LoadingServiceData: true,
  LoadingSearchNews: true,
  LoadingTendersData: true,
  LoadingTaskFunctionsData: true,
  LoadingVacancyData: true,
  LoadingDocumentsData: true,
  LoadingJournalListData: true,
  LoadingUserData: true,
  LoadingJournalDetailsData: true,
  LoadingDepartmentData: true,
  LoadingCouncilData: true,
  LoadingOrderData: true,
  LoadingReviewsData: true,
  LoadingOpiniosData: true,
  LoadingCartData: true,
  messageData: "",
};

const fetchDataReducer = (state = dataState, action) => {
  switch (action.type) {
    case SIGN_IN:
      state = Object.assign({}, state, {
        userToken: action.data,
      });
      return state;
    case SIGN_UP:
      state = Object.assign({}, state, {
        messageData: action.data,
      });
      return state;
    case SET_TOKEN:
      return {
        ...state,
        userToken: action.token,
        isLogged: action.token ? true : false,
      };
    case FETCH_USER_DATA:
      state = Object.assign({}, state, {
        UserData: action.userData,
        UserOrders: action.userOrders,
        isLogged: action.isLogged,
        LoadingUserData: false,
      });
      return state;
    case SET_LOADER:
      state = Object.assign({}, state, {
        [action.title]: true,
      });
      return state;
    case SET_PAGINATION:
      state = Object.assign({}, state, {
        PaginationPage: action.id,
      });
      return state;
    case FETCH_ORDER_DATA:
      // console.log("reducer order data : ", action.orderData);
      state = Object.assign({}, state, {
        OrderData: action.orderData,
        isLogged: action.isLogged,
        LoadingOrderData: action.loader,
      });
      return state;
    case FETCH_MAIN_SCREEN_DATA:
      state = Object.assign({}, state, {
        MainSliderData: action.data.sliders,
        LeadersSliderData: action.data.leaders,
        NewsSliderData: action.data.news,
        ServicesData: action.data.servicePages,
        InstituteData: action.data.institute,
        GalleryData: action.data.images,
        DepartmentsData: action.data.departments,
        InformationData: action.data.information_services,
        JournalListData: action.journalData,
        NewsListData: action.newsListData.data,
        LoadingMainScreen: false,
        DocumentsCategoryData: action.data.document_categories,
        AboutData: action.aboutData.data.data,
        CommitteesData: action.committeesData.data.data,
        standartTypesData: action.standartTypesData.data,
      });
      return state;
    case FETCH_DETAILS_DATA:
      // console.log("data : ", action.data.data);

      state = Object.assign({}, state, {
        [action.title]: action.data.data,
        [`Loading${action.title}`]: false,
      });
      return state;
    case FETCH_JOURNAL_LIST_DATA:
      state = Object.assign({}, state, {
        JournalListData: action.data,
        JournalCategoryData: action.categoryData,
        JournalTypesData: action.typeData,
        LoadingJournalListData: false,
      });
      return state;
    case FETCH_DOCUMENTS_DATA:
      state = Object.assign({}, state, {
        DocumentsData: action.data,
        LoadingDocumentsData: false,
      });
      return state;
    case FETCH_GALLERY_PAGE_DATA:
      state = Object.assign({}, state, {
        GalleryPageData: action.data,
        LoadingGalleryPageData: false,
      });
      return state;
    case FETCH_MANAGERS_LIST_DATA:
      state = Object.assign({}, state, {
        ManagersListData: action.data,
        LoadingManagersListData: false,
      });
      return state;
    case FETCH_NEWS_LIST_DATA:
      // console.log("news list: ", action.data);
      // console.log("pagination count: ", action.paginationCount);
      state = Object.assign({}, state, {
        NewsListData: action.data.data,
        LoadingNewsListData: false,
      });
      return state;
    case SEARCH_NEWS:
      state = Object.assign({}, state, {
        SearchNews: action.data,
        LoadingSearchNews: false,
      });
      return state;
    case FETCH_JOURNAL_DETAILS_DATA:
      state = Object.assign({}, state, {
        JournalDetailsData: action.data,
        LoadingJournalDetailsData: false,
      });
      return state;
    case FETCH_REVIEWS_DATA:
      state = Object.assign({}, state, {
        ReviewsData: action.data,
        LoadingReviewsData: false,
      });
    case FETCH_THOUGHTS_DATA:
      state = Object.assign({}, state, {
        ThoughtsData: action.data,
        // LoadingReviewsData: false,
      });
      return state;
    case FETCH_PROJECT_DATA:
      state = Object.assign({}, state, {
        ProjectData: action.data,
        // LoadingReviewsData: false,
      });
      return state;
    case FETCH_CHECK_DATA:
      state = Object.assign({}, state, {
        CheckData: action.data,
        // LoadingReviewsData: false,
      });
    case FETCH_CHECK_INN:
      state = Object.assign({}, state, {
        CheckInn: action.data,
        // LoadingReviewsData: false,
      });
      return state;
    case FETCH_DISCUSS_STANDARTS_DATA:
      state = Object.assign({}, state, {
        StandartData: action.data,
        // LoadingReviewsData: false,
      });
    case FETCH_BUDGET_DATA:
      state = Object.assign({}, state, {
        BudgetData: action.data.data,
        LoadingBudgetData: false,
      });
    case FETCH_INSTITUTE_STRUCTURE_DATA:
      state = Object.assign({}, state, {
        InstituteStructureData: action.data.data,
        // LoadingReviewsData: false,
      });
      return state;
    case FETCH_DISCUSS_STANDARTS_TYPE_DATA:
      state = Object.assign({}, state, {
        StandartDataType: action.data,
        LoadingStandartData: false,
      });
      return state;
    case FETCH_OPINIOS_DATA:
      state = Object.assign({}, state, {
        OpiniosData: action.data,
        LoadingOpiniosData: false,
      });
      return state;
    case FETCH_CART_DATA:
      state = Object.assign({}, state, {
        CartData: action.data,
        LoadingCartData: false,
      });
      return state;
    default:
      return state;
  }
};

export default fetchDataReducer;
