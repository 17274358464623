import React, { Component } from "react";
import Moment from "moment";
import { FETCH_URL, IMAGE_URL } from "../../constants";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import {
  _currencyFormatter,
  _currencyTableFormatter,
  _translator,
} from "../../helpers";
import "./styles.css";
import Cookies from "js-cookie";
import Modap from "../../components/Modap";
import { Modal } from "react-bootstrap";

export default class OrderTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      show: false,
      modalData: [],
    };
  }

  fetchInvoice = () => {
    const token = Cookies.get("userToken");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(`${FETCH_URL}invoice`, null, config)
      .then((res) => {
        // console.log("res: ", res.data);
      })
      .catch((err) => toast.error(JSON.stringify(err)));
  };
  render() {
    const {
      data,
      mlang,
      id,
      title,
      price,
      page,
      paid,
      notPaid,
      showModal,
      loadingLang,
      userData,
    } = this.props;
    const { show, modalData } = this.state;
    return (
      <React.Fragment>
        <tr>
          <td>{id}</td>
          <td>
            <button
              className="badge badge-sm badge-outline-info"
              onClick={() => this.setState({ toggle: !this.state.toggle })}
            >
              <i aria-hidden="true" className="far fa-folder-open" />
            </button>
          </td>
          <td>{Moment(data.created_at).format("DD.MM.YYYY hh:mm")}</td>
          <td style={{ textAlign: "right" }}>
            {_currencyTableFormatter(parseFloat(data.amount))}
          </td>
          <td>{data.products.length}</td>
          {data.amount === "0.00" ? (
            <td>-</td>
          ) : (
            <td
              style={{ color: "white" }}
              className={data.state === 0 ? "bg-danger" : "bg-success"}
            >
              {data.state === 0 ? notPaid : paid}
            </td>
          )}
          <td>
            {data.contract === null ? (
              "-"
            ) : (
              <a
                target="_blank"
                href={`${IMAGE_URL}${data.contract}`}
                // onClick={this.fetchInvoice}
              >
                {data.amount === "0.00" ? (
                  "-"
                ) : (
                  <i
                    style={{
                      display: data.amount === "0.00" ? "none" : "block",
                    }}
                    className="fa fa-download"
                  ></i>
                )}
              </a>
            )}
          </td>
        </tr>
        <tr
          className="child-elements"
          style={{ display: this.state.toggle ? "table-row" : "none" }}
        >
          <th></th>
          <th>№</th>
          <th colSpan="3">{title}</th>
          {/* <th>Yuklab olish</th> */}
          <th>{price}</th>
          <th>{page}</th>
        </tr>
        {data.products.map((product, index) => (
          <tr
            className="child-elements"
            style={{ display: this.state.toggle ? "table-row" : "none" }}
          >
            <td></td>
            <td>{index + 1}</td>
            <td colSpan="3">
              {(product.price === "0" && product.printed_at !== null) ||
              (product.price != "0" &&
                product.printed_at !== null &&
                data.paid_at !== null) ? (
                <a
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  href={`${IMAGE_URL}orders/${userData.id}/${product.standart.fileHash}`}
                >
                  {product.standart.name_standart}
                </a>
              ) : (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    // console.log("product: ", product);
                    this.setState({
                      show: true,
                      modalData: product,
                    });
                  }}
                >
                  {product.standart.name_standart}
                </a>
              )}
            </td>
            {/* <td>
              <a
                target="_blank"
                href={require("../../assets/images/document.pdf")}
                // onClick={this.fetchInvoice}
              >
                <i class="fa fa-download"></i>
              </a>
            </td> */}
            <td>{_currencyFormatter(parseFloat(product.price))}</td>
            <td>{product.standart.page_standart}</td>
          </tr>
        ))}
        <Modal
          show={show}
          onHide={() => this.setState({ show: false })}
          size="lg"
          dialogClassName="modal-90h"
        >
          <Modal.Header closeButton>
            {show ? (
              <Modal.Title>{modalData.standart.name_standart}</Modal.Title>
            ) : null}
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 150px)",
              "overflow-y": "auto",
            }}
          >
            {show ? (
              <Modap
                loadingLang={loadingLang}
                pdfProps={`https://api.allorigins.win/raw?url=${IMAGE_URL}${modalData.standart.fileHash}`}
                // pdfProps={`https://cors-anywhere.herokuapp.com/${IMAGE_URL}${modalData.standart.fileHash}`}
              />
            ) : null}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
