import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PartnersCarousel } from "../../components/Carousels";
import ContactForm from "../../components/ContactForm";
import Loader from "../../components/Loader";
import MainCarousel from "../../components/MainCarousel";
import AboutUs from "../../containers/AboutUs";
import Achievments from "../../containers/Achievments";
import ClientOpinionList from "../../containers/ClientOpinionList";
// import Gallery from "../../containers/Gallery";
// import ManagementSection from "../../containers/ManagementSection";
import NewsList from "../../containers/NewsList";
// import ServiceList from "../../containers/ServiceList";
import ShoppingList from "../../containers/ShoppingList";
import UniteList from "../../containers/UniteList";
import UsefulLinks from "../../containers/UsefulLinks";
import { langData } from "../../index.i18n";
import * as Actions from "../../redux/actions";

class MainScreen extends React.Component {
  componentDidMount() {
    // setInterval(() => {
    //   if (document.querySelector(".carousel-control-next")) {
    //     document.querySelector(".carousel-control-next").click();
    //   }
    // }, 10000);
    // window.responsiveVoice.cancel();
    window.scrollTo(0, 0);
  }

  render() {
    const {
      mlang,
      MainSliderData,
      ServicesData,
      NewsSliderData,
      DepartmentsData,
    } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // if ("speechSynthesis" in window) {
    //   // Speech Synthesis supported 🎉
    // } else {
    //   // Speech Synthesis Not Supported 😣
    //   alert("Sorry, your browser doesn't support text to speech!");
    // }

    // var msg = new SpeechSynthesisUtterance();
    // msg.text = "Good Morning";
    // window.speechSynthesis.speak(msg);
    // window.responsiveVoice.speak("hello world", "UK English Male", {
    //   rate: 1.5,
    // });

    // if (window.voiceEnabled) {
    //   window.responsiveVoice.cancel();
    // } else {
    //   window.responsiveVoice.resume();
    // }
    // window.responsiveVoice.cancel();
    // console.log("MainSliderData ", MainSliderData);

    return this.props.LoadingMainScreen ? (
      <Loader />
    ) : (
      <div>
        <div className="full_page">
          {/* <Loader /> */}

          <MainCarousel
            data={MainSliderData}
            buttonTitle={data.details}
            mlang={mlang}
          />
          {/* <ServiceList
            data={ServicesData}
            ldata={data}
            mlang={this.props.mlang}
          /> */}

          <NewsList
            title={data.newsIn}
            data={NewsSliderData}
            buttonTitle={data.details}
            mlang={this.props.mlang}
            allNews={data.allNews}
          />
          <AboutUs
            title={data.aboutInstitute}
            text={data.aboutInstituteText}
            buttonTitle={data.details}
          />
          <Achievments mlang={this.props.mlang} />
          <UniteList
            title={data.deparments}
            data={DepartmentsData}
            mlang={this.props.mlang}
          />
          {/* <Gallery
            title={data.photoGallery}
            data={this.props.GalleryData}
            buttonTitle={data.viewAllProjects}
          /> */}
          <ClientOpinionList mlang={this.props.mlang} />
          {/* <ManagementSection
            data={this.props.LeadersSliderData}
            title={data.management}
          /> */}

          {/*<ShoppingList*/}
          {/*  title={data.InternetShop}*/}
          {/*  buttonTitle={data.buyButton}*/}
          {/*  data={this.props.JournalListData}*/}
          {/*/>*/}
          <PartnersCarousel mlang={this.props.mlang} />
          <UsefulLinks mlang={mlang} title={data.feedbackTitle} />
          <ContactForm mlang={mlang} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    MainSliderData: state.fetchDataReducer.MainSliderData,
    LoadingMainScreen: state.fetchDataReducer.LoadingMainScreen,
    ServicesData: state.fetchDataReducer.ServicesData,
    NewsSliderData: state.fetchDataReducer.NewsSliderData,
    DepartmentsData: state.fetchDataReducer.DepartmentsData,
    // GalleryData: state.fetchDataReducer.GalleryData,
    // LeadersSliderData: state.fetchDataReducer.LeadersSliderData,
    JournalListData: state.fetchDataReducer.JournalListData,
    LoadingJournalListData: state.fetchDataReducer.LoadingJournalListData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
