import React, { Component } from "react";
import { ManagementCard } from "../components/Staff";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../redux/actions";
import { langData } from "../Pages/Manager/index.i18n";
import Loader from "../components/Loader";

class Employees extends Component {
  componentDidMount() {
    this.props.fetchManagersListData("staff", 1);
    window.scrollTo(0, 0);
  }
  render() {
    const { mlang, LoadingManagersListData, ManagersListData } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log("Managerlar: ", ManagersListData.length);

    return LoadingManagersListData ? (
      <Loader />
    ) : (
      <div className="col-xl-8 col-lg-8">
        {ManagersListData.map((item) => (
          <ManagementCard
            key={item.id.toString()}
            genDirector={data.genDirector}
            receptionDays={data.receptionDays}
            phoneNumber={data.phoneNumber}
            email={data.email}
            biography={data.biography}
            responsibilities={data.responsibilities}
            data={item}
            mlang={this.props.mlang}
          />
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    ManagersListData: state.fetchDataReducer.ManagersListData,
    LoadingManagersListData: state.fetchDataReducer.LoadingManagersListData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Employees);
