import React, {Component} from "react"
import "react-toastify/dist/ReactToastify.css"
import {FormMain} from "../Forms"

export default class ContactForm extends Component {
    render() {
        return (
            <section className="contact_form">
                <div className="contact-content">
                    <div className="container">
                        <div className="row rve">
                            <div className="col-lg-6">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d5997.520111820882!2d69.229911!3d41.27056!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDE2JzE0LjAiTiA2OcKwMTMnNDcuNyJF!5e0!3m2!1sru!2s!4v1719292779460!5m2!1sru!2s"
                                    width="100%"
                                    height={420}
                                    frameBorder={0}
                                    style={{border: 0}}
                                    allowFullScreen
                                    aria-hidden="false"
                                    tabIndex={0}
                                    title="unique"
                                />
                            </div>
                            <FormMain formSize={6} mlang={this.props.mlang}/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
