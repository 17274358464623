import React from "react";
import MainCarouselCard from "./MainCarouselCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function MainCarousel({ data, buttonTitle, mlang }) {
  // console.log("MainSliderData - data", data[0].url);
  return (
    <div id="home" className="home">
      <div id="carouselExampleIndicators" className="carousel slide">
        <Carousel
          infiniteLoop
          useKeyboardArrows
          autoPlay
          showThumbs={false}
          showStatus={false}
        >
          {data.map((item, index) => (
            <div
            // className="item"
            key={index}
            >
              <MainCarouselCard
                isActive={true}
                data={item}
                buttonTitle={buttonTitle}
                mlang={mlang}
                link={item.url}
              />
            </div>
          ))}
        </Carousel>
      </div>
      {/* 
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <MainCarouselCard
            buttonTitle={buttonTitle}
            data={data[0]}
            isActive={true}
            mlang={mlang}
            link={data[0].url}
          />
          <MainCarouselCard
            buttonTitle={buttonTitle}
            data={data[1]}
            isActive={false}
            mlang={mlang}
            link={data[1].url}
          />
          <MainCarouselCard
            buttonTitle={buttonTitle}
            data={data[2]}
            isActive={false}
            mlang={mlang}
            link={data[2].url}
          />
          <MainCarouselCard
            buttonTitle={buttonTitle}
            data={data[3]}
            isActive={false}
            mlang={mlang}
            link={data[3].url}
          />
          <MainCarouselCard
            buttonTitle={buttonTitle}
            data={data[4]}
            isActive={false}
            mlang={mlang}
            link={data[4].url}
          />
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          {" "}
          <i className="fa fa-angle-left" aria-hidden="true" />{" "}
          <span className="sr-only">Previous</span>{" "}
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          {" "}
          <i className="fa fa-angle-right" aria-hidden="true" />{" "}
          <span className="sr-only">Next</span>{" "}
        </a>
      </div> */}
    </div>
  );
}
