import React from "react";
import { _translator } from "../helpers";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";

export default function UnitCard({ data, mlang }) {
  // console.log("unit card data:", _translator("description", data, mlang))
  // console.log("mlang: ", mlang)
  return (
    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
      <Link to={`/departments/${data.id}`}>
        <div className="services_box">
          <div className="services_img">
            <img
              className="img-fluid"
              src="assets/images/busisness-planning.png"
              alt="business_planning"
            />{" "}
          </div>
          <div className="services_con">
            <h3>{_translator("title", data, mlang)}</h3>
            {/* <p
              // dangerouslySetInnerHTML={_translator("description", data, mlang)}
              dangerouslySetInnerHTML={{
                __html: _translator("description", data, mlang),
              }}
            ></p> */}

            <p>
              <LinesEllipsis
                text={
                  //   _translator("description", data, mlang).replace(
                  //   /<[^>]+>/g,
                  //   ""
                  // )
                  data.description && mlang === "uz"
                    ? data.description
                        .replace(/<[^>]+>/g, "")
                        .replace(/&nbsp/g, "")
                        .replace(/[;]/g, "")
                    : data.description_ru && mlang === "ru"
                    ? data.description_ru
                        .replace(/<[^>]+>/g, "")
                        .replace(/&nbsp/g, "")
                        .replace(/[;]/g, "")
                    : data.description_en && mlang === "en"
                    ? data.description_en
                        .replace(/<[^>]+>/g, "")
                        .replace(/&nbsp/g, "")
                        .replace(/[;]/g, "")
                    : ""
                }
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
              {/* <Truncate
                lines={2}
                portrait={4}
                breakWord={false}
                responsive={false}
                dangerouslySetInnerHTML={{
                  __html: _translator("description", data, mlang).replace(
                    /<[^>]+>/g,
                    "",
                  ),
                }}
              /> */}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}
