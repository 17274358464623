import React from "react";
import { IMAGE_URL } from "../constants";
import { _translator } from "../helpers";
import CurrentTime from "./CurrentTime";

export default function MainCarouselCard({
  buttonTitle,
  data,
  isActive,
  mlang,
  link,
}) {
  // let title = ""
  // let description = ""

  // if (mlang === "uz") {
  //   title = data.title
  //   description = data.description
  // } else if (mlang === "ru") {
  //   title = data.title_ru
  //   description = data.description_ru
  // } else {
  //   title = data.title_en
  //   description = data.description_en
  // }

  // console.log(
  //   ' {_translator("description", data, mlang)',
  //   _translator("description", data, mlang)
  // );
  return (
    <div
      className={`carousel-item ${isActive ? "active" : ""}`}
      style={{
        backgroundImage: `url(${IMAGE_URL}${data.image})`,
        backgroundSize: "cover",
        height: "350px",
        width: "100%",
      }}
    >
      <div
        className="carousel-caption d-none d-md-block"
        style={{ paddingTop: 0 }}
      >
        <div className="container">
          <div className="row">
            <div
              style={{
                position: "absolute",
                top: 20,
                right: 20,
              }}
            >
              {/* <CurrentTime mlang={mlang} /> */}
            </div>
            <div className="col-lg-12">
              <h2>{_translator("title", data, mlang)}</h2>
              <p className="home__text">
                {_translator("description", data, mlang)}
              </p>
              <div className="slider_btn">
                <a href={link} target="_blank" className="get_started">
                  {buttonTitle}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
