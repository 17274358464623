import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./index.css"

export default class SearchBarNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputText: "",
    }
  }
  submitSearch() {
    this.setState(
      {
        inputText: "",
      },
      () => this.props.updateState(),
    )
  }
  render() {
    const { inputText } = this.state
    return (
      <div>
        <input
          type="text"
          placeholder={this.props.title}
          onChange={(e) => this.setState({ inputText: e.target.value })}
        />
        <Link
          className="origButton"
          type="submit"
          onClick={() => this.submitSearch()}
          to={`/search/${inputText}`}
        >
          <i className="fa fa-search" />
        </Link>
      </div>
    )
  }
}
