import React from "react";

export default function Loader() {
  return (
    <div className="preloader">
      <img alt="loader" src={process.env.PUBLIC_URL +"/assets/images/preloader.gif"} />
    </div>
    // <div
    //   id="loader-wrapper"
    //   style={{ background: "red", height: 500, width: 500 }}
    // >
    //   <div id="loader"></div>
    //   <div class="loader-section section-left"></div>
    //   <div class="loader-section section-right"></div>
    // </div>
  );
}
