import React from "react";

export function MainTitle({ title }) {
  return (
    <div className="row">
      <div className="col-md-12 text-center">
        <div className="section_title">
          <h2>{title}</h2>
          <img
            className="img-fluid"
            src="assets/images/title_border.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export function TitleShort({ title }) {
  return (
    <div className="about_title d-flex justify-content-between align-items-start">
      <div>
        <h2>{title}</h2>
        <img
          className="img-fluid"
          src="assets/images/about_border.png"
          alt="border_img"
        />
      </div>
      {/* {title === "Rahbariyat" ? <div className="forArrows owl-nav"></div> : ""} */}
    </div>
  );
}
export function TitleShortUnite({ title }) {
  return (
    <div className="about_title d-flex justify-content-between align-items-start">
      <div>
        <h2>{title}</h2>
        <img
          className="img-fluid"
          src="assets/images/about_border.png"
          alt="border_img"
        />
      </div>
      {window.innerWidth <= 991 ? (
        <div className="forArrows owl-nav"></div>
      ) : (
        ""
      )}
    </div>
  );
}

export function TitleShortMang({ title }) {
  return (
    <div className="about_title d-flex justify-content-between align-items-start">
      <div>
        <h2>{title}</h2>
        <img
          className="img-fluid"
          src="assets/images/about_border.png"
          alt="border_img"
        />
      </div>
      {title === "Rahbariyat" || "Руководство" || "Management" ? (
        <div className="forArrows owl-nav"></div>
      ) : (
        ""
      )}
    </div>
  );
}

export function TitleShortShop({ title }) {
  return (
    <div className="about_title d-flex justify-content-between align-items-start">
      <div>
        <h2>{title}</h2>
        <img
          className="img-fluid"
          src="assets/images/about_border.png"
          alt="border_img"
        />
      </div>
      {title === "Standartlar elektron do'koni" ||
      "Электронный-магазин стандартов" ||
      "Standards E-shop" ? (
        <div className="forArrows owl-nav"></div>
      ) : (
        ""
      )}
    </div>
  );
}
