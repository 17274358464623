import Cookies from "js-cookie";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { IMAGE_URL } from "../../constants";
import SideBar from "../../containers/SideBar";
import {
  _timeParserD,
  // _timeParserM,
  _translator,
  _kirilToLatin,
} from "../../helpers";
import SwiperSlider from "./SwiperSlider";
// import { langData } from "../../index.i18n";
import * as Actions from "../../redux/actions";
import Moment from "moment";

class NewsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: this.props.match.params.id,
      articleSlide: [
        // { src: "assets/images/sli1.jpg" },
        // { src: "assets/images/sli2.jpg" },
        // { src: "assets/images/sli3.jpg" },
        // { src: "assets/images/sli4.jpg" },
      ],
    };
  }

  componentDidUpdate() {
    if (this.state.currentPath !== this.props.match.params.id) {
      this.updateState();
    }
  }

  updateState = () => {
    const userIp = Cookies.get("userIp");
    this.setState(
      {
        currentPath: this.props.match.params.id,
      },
      () => {
        this.props.setLoader("NewsData");
        this.props.fetchDetailsData(
          "NewsData",
          this.props.match.params.id,
          null,
          userIp
        );
      }
    );
  };
  componentDidMount() {
    const userIp = Cookies.get("userIp");

    this.props.fetchDetailsData(
      "NewsData",
      this.props.match.params.id,
      null,
      userIp
    );
    // console.log("mlang: ", this.props.mlang)

    window.scrollTo(0, 0);
  }

  render() {
    // const time = new Date();
    const { mlang, NewsData, LoadingNewsData } = this.props;
    // const data = mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

    // const newsDataaaa = this.props.NewsData.slider;
    // console.log("NEWS DATA ", this.props.NewsData.slider_images);
    return LoadingNewsData ? (
      <Loader />
    ) : (
      <div>
        <div className="full_page">
          <div className="blog_pages">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  {/* single-blog start */}
                  <article className="blog-post-wrapper">
                    <div className="blog-banner">
                      {" "}
                      <a href="#" className="blog-images">
                        <img
                          className="img-fluid"
                          src={IMAGE_URL + NewsData.image}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <div className="blog-content">
                        <div
                          className="blog-meta"
                          style={{ display: "inline-block" }}
                        >
                          <span
                            className="date-type"
                            style={{ textTransform: "capitalize" }}
                          >
                            {_timeParserD(NewsData.publish_date, mlang)}
                            <br />
                            {/* {_timeParserM(NewsData.publish_date, mlang)}
                             */}
                            {mlang === "uz"
                              ? _kirilToLatin(
                                  Moment(NewsData.publish_date)
                                    .locale(mlang)
                                    .format("MMMM")
                                )
                              : Moment(NewsData.publish_date)
                                  .locale(mlang)
                                  .format("MMMM")}{" "}
                          </span>
                        </div>

                        <h4 style={{ fontWeight: "bold" }}>
                          {_translator("title", NewsData, mlang)}
                        </h4>

                        <p
                          className="dynamic-content-div"
                          dangerouslySetInnerHTML={{
                            __html: _translator("description", NewsData, mlang),
                          }}
                        />
                        <SwiperSlider slides={NewsData.slider_images} />
                      </div>
                    </div>
                  </article>
                  <div className="clear" />
                </div>
                <div className="col-lg-4 col-md-12">
                  <SideBar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    NewsData: state.fetchDataReducer.NewsData,
    LoadingNewsData: state.fetchDataReducer.LoadingNewsData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
