import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { langData } from "./index.i18n";
import { UsefulLinksData } from "./data";
import { _translator } from "../../helpers";
import LinesEllipsis from "react-lines-ellipsis";
import { FETCH_URL, IMAGE_URL } from "../../constants";

export function CitizensAppeals({ mlang }) {
  const data =
    mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

  return (
    <div className="fuqoro  ">
      <span>{data.citizensFeedback}</span>
      <img src="assets/images/fuqoro.png" alt="" />
      <Link to="/appeals">{data.more}</Link>
    </div>
  );
}

export class GiveOpinion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "",
      isSelected: false,
      VoteData: [],
      totalValue: 0,
    };
  }

  submitForm = async (e) => {
    const userIp = Cookies.get("userIp");
    const { selectedType } = this.state;
    if (selectedType.length) {
      axios(`${FETCH_URL}survey/${selectedType}?ip=${userIp}`).then((res) => {
        const data = res.data.data;
        console.log("res: ", res.data.data);
        this.setState({
          isSelected: true,
          VoteData: data,
          totalValue: data.a + data.b + data.c + data.d,
        });
      });
    }
    // console.log("done: ", e.target.value)
  };
  render() {
    const { VoteData, isSelected, selectedType, totalValue } = this.state;
    const { mlang, isSideMenu } = this.props;
    // console.log("opinion data: ", VoteData);
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    return (
      <div className="sorov " style={isSideMenu ? { width: "100%" } : null}>
        <div className="sorov__wrap">
          <div className="sorov__box">
            <p>{data.questioningText}</p>
            <div>
              <div>
                <label htmlFor="a">
                  <input
                    type="radio"
                    name="sorov"
                    id="a"
                    onClick={() => this.setState({ selectedType: "a" })}
                  />
                  {data.excellent}
                </label>
                <label htmlFor="b">
                  <input
                    type="radio"
                    name="sorov"
                    id="b"
                    onClick={() => this.setState({ selectedType: "b" })}
                  />
                  {data.good}
                </label>
                <label htmlFor="c">
                  <input
                    type="radio"
                    name="sorov"
                    id="c"
                    onClick={() => this.setState({ selectedType: "c" })}
                  />
                  {data.bad}
                </label>
                <label htmlFor="d">
                  <input
                    type="radio"
                    name="sorov"
                    id="d"
                    onClick={() => this.setState({ selectedType: "d" })}
                  />
                  {data.mayBeImproved}
                </label>
              </div>
              <div className="sorov__buttons">
                <button className="ovoz" onClick={() => this.submitForm()}>
                  {data.vote}
                </button>
              </div>
            </div>
          </div>
          <div
            className="sorov__back sorov__box"
            style={{ display: isSelected ? "block" : "none" }}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{data.excellent}</span>
                <span
                  style={{
                    fontWeight: "700",
                  }}
                >
                  {VoteData.a}
                </span>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${100 * (VoteData.a / totalValue)}%` }}
                  // aria-valuenow={VoteData.a / totalValue}
                  aria-valuenow={VoteData.a / totalValue}
                  aria-valuemin={0}
                  aria-valuemax={totalValue}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{data.good}</span>
                <span style={{ fontWeight: "700" }}>{VoteData.b}</span>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${100 * (VoteData.b / totalValue)}%` }}
                  aria-valuenow={VoteData.b / totalValue}
                  aria-valuemin={0}
                  aria-valuemax={totalValue}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{data.bad}</span>
                <span style={{ fontWeight: "700" }}>{VoteData.c}</span>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${100 * (VoteData.c / totalValue)}%` }}
                  aria-valuenow={VoteData.c / totalValue}
                  aria-valuemin={0}
                  aria-valuemax={totalValue}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{data.mayBeImproved}</span>
                <span style={{ fontWeight: "700" }}>{VoteData.d}</span>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${100 * (VoteData.d / totalValue)}%` }}
                  aria-valuenow={VoteData.d / totalValue}
                  aria-valuemin={0}
                  aria-valuemax={totalValue}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 40,
              }}
            >
              <button onClick={() => this.setState({ isSelected: false })}>
                {data.vote}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function Complaints({ isSideMenu, mlang }) {
  const data =
    mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

  return (
    <div className="shkoyat" style={isSideMenu ? { width: "100%" } : null}>
      <div className="top">
        <b>{data.anyComplaints}</b>
        <span>?</span>
      </div>
      <div className="bot">
        <b>{data.sendOnline}</b>
        <i className="fa fa-check" />
      </div>
      <a href="https://my.gov.uz/uz" target="_blank">
        my.gov.uz
      </a>
    </div>
  );
}

export class MostReadedNews extends React.Component {
  render() {
    const { NewsListData, mlang } = this.props;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    return (
      <div className="recent_post">
        <div className="sidebar_title">
          <h5>{data.mostRead}</h5>
        </div>
        <div className="post_box">
          <div className="sidebar-item recent-post">
            {NewsListData.data
              .sort((a, b) => b.views - a.views)
              .slice(0, 4)
              .map((item) => (
                <li key={item.id.toString()}>
                  <div className="thumb" style={{ paddingLeft: 10 }}>
                    <div
                      style={{
                        width: 50,
                        height: 50,
                        alignSelf: "center",
                      }}
                    >
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src={IMAGE_URL + item.image}
                        alt="Thumb"
                      />
                    </div>
                  </div>
                  <div className="info">
                    <Link to={`/news/${item.id}`}>
                      <LinesEllipsis
                        text={item.title}
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                        maxLine={2}
                      />
                    </Link>
                    <div className="meta-title">
                      <div style={{ flexDirection: "row" }}>
                        <span
                          className="post-date"
                          style={{ marginRight: 10 }}
                        >{`${item.publish_date}`}</span>
                        <i className="fa fa-eye" />
                        <span className="post-date" style={{ marginLeft: 5 }}>
                          {item.views}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export function UsefulLinksCard({ mlang }) {
  const data =
    mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;

  return (
    <div className="recent_post">
      <div className="sidebar_title">
        <h5>{data.usefulLinks}</h5>
      </div>
      <div className="post_box">
        <div className="sidebar-item recent-post">
          {UsefulLinksData.map((item) => (
            <li key={item.id.toString()}>
              <div className="thumb" style={{ paddingLeft: 10 }}>
                <a
                  href="#"
                  style={{
                    width: 50,
                    height: 50,
                    alignSelf: "center",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={item.imgSrc}
                    alt="Thumb"
                  />
                </a>
              </div>
              <div
                className="info"
                style={{
                  height: 50,
                  justifyContent: "center",
                }}
              >
                <a href={item.links} target="_blank">
                  {_translator("title", item, mlang)}
                </a>
              </div>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
}
