import Moment from "moment";
import uz from "moment/locale/uz";
import en from "moment/locale/en-gb";
import ru from "moment/locale/ru";

export const _timeParserM = (time, mlang) => {
  const date = Moment(time).locale(mlang).format("MMMM");

  return date;
};
export const _timeParserD = (time, mlang) => {
  const date = Moment(time).locale(mlang).format("D");
  return date;
};

export const _translator = (column, obj = null, mlang) => {
  return obj ? obj[column + `${mlang !== "uz" ? "_" + mlang : ""}`] : null;
};
export const _translatorTitle = (column, obj = null, mlang) => {
  return obj ?  obj[column + `${mlang === "uz" ? "title_uz" : "title_" + mlang}`] : null;
};
export const _currencyFormatter = (currency) => {
  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "UZS",
    minimumFractionDigits: 0,
  });
  return formatter.format(currency);
};
export const _currencyTableFormatter = (currency) => {
  const formatter = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 2,
  });
  return formatter.format(currency);
};

export const _kirilToLatin = (word) => {
  var answer = "";

  const A = new Array();
  A["Ё"] = "YO";
  A["Й"] = "Y";
  A["Ц"] = "TS";
  A["У"] = "U";
  A["К"] = "K";
  A["Е"] = "E";
  A["Н"] = "N";
  A["Г"] = "G";
  A["Ш"] = "SH";
  A["Щ"] = "SCH";
  A["З"] = "Z";
  A["Х"] = "H";
  A["Ъ"] = "'";
  A["ё"] = "yo";
  A["й"] = "y";
  A["ц"] = "ts";
  A["у"] = "u";
  A["к"] = "k";
  A["е"] = "e";
  A["н"] = "n";
  A["г"] = "g";
  A["ш"] = "sh";
  A["щ"] = "sch";
  A["з"] = "z";
  A["х"] = "h";
  A["ъ"] = "'";
  A["Ф"] = "F";
  A["Ы"] = "I";
  A["В"] = "V";
  A["А"] = "A";
  A["П"] = "P";
  A["Р"] = "R";
  A["О"] = "O";
  A["Л"] = "L";
  A["Д"] = "D";
  A["Ж"] = "ZH";
  A["Э"] = "E";
  A["ф"] = "f";
  A["ы"] = "i";
  A["в"] = "v";
  A["а"] = "a";
  A["п"] = "p";
  A["р"] = "r";
  A["о"] = "o";
  A["л"] = "l";
  A["д"] = "d";
  A["ж"] = "zh";
  A["э"] = "e";
  A["Я"] = "YA";
  A["Ч"] = "CH";
  A["С"] = "S";
  A["М"] = "M";
  A["И"] = "I";
  A["Т"] = "T";
  A["Ь"] = "'";
  A["Б"] = "B";
  A["Ю"] = "YU";
  A["я"] = "ya";
  A["ч"] = "ch";
  A["с"] = "s";
  A["м"] = "m";
  A["и"] = "i";
  A["т"] = "t";
  A["ь"] = "'";
  A["б"] = "b";
  A["ю"] = "yu";

  for (let i in word) {
    if (A[word[i]] === "undefined") {
      answer += word[i];
    } else {
      answer += A[word[i]];
    }
  }
  return answer; // <=== Was *above* the } on the previous line
};
